/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes, Navigate} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {SetPassword} from './components/SetPassword'
import {InviteSetPassword} from './components/InviteSetPassword'
import {InviteTestSetPassword} from './components/InviteTestSetPassword'
import {Login} from './components/Login'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <Outlet />
  )
}

const NoAuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='set-password/:id/:token' element={<SetPassword />} />
      <Route path='invite/:id/:token' element={<InviteSetPassword />} />
      <Route path='invite/test/:id/:token' element={<InviteTestSetPassword />} />
      <Route index  element={<Navigate to='/' />} />
    </Route>
  </Routes>
)

export {NoAuthPage}
