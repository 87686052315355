
import { FC, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { WorkspaceUserEditModal } from './WorkspaceUserEditModel'
import { useParams, useNavigate } from "react-router-dom";
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { ComponentLoading } from '../../modules/ComponentLoading'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

// import {initialUser, User} from '../../../app/modules/apps/user-management/users-list/core/_models' 
// import { initialUser, User } from './OrganizationValidationModal'
import clsx from 'clsx'
// import { useListView } from '../../../app/modules/apps/user-management/users-list/core/ListViewProvider'
// import {UsersListLoading} from '../../../app/modules/apps/user-management/users-list/components/loading/UsersListLoading' 
// import { createUser, updateUser } from '../../../app/modules/apps/user-management/users-list/core/_requests'
// import { useQueryResponse } from '../../../app/modules/apps/user-management/users-list/core/QueryResponseProvider'
import { ToastContainer, toast } from 'react-toastify';

import '../../../../node_modules/react-toastify/dist/ReactToastify.css';

const API_URL      = process.env.REACT_APP_API_URL
const ApiToken     = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

interface PostsProps {
    showHideSetPasswordModal: any;
    showHidePasswordModal: any;
    organizationId: any;
}

const OrganizationDeleteAllModal: FC<PostsProps> = ({
    showHideSetPasswordModal, 
    showHidePasswordModal, 
    organizationId
}) => {

    let { organisationUsersId }: any = useParams();
    const [getspinner, setspinner] = useState(false)
    const [getDeletedMessages, setDeletedMessages]: any = useState([]);
    const [getCountAll, setCountAll]: any = useState([]);
    const [getWorkspaceDocs, setWorkspaceDocs] : any = useState([]);
    const [getProfilesDocs, setProfilesDocs] : any = useState([]);
    
    const [getComponentLoading, setComponentLoading]: any = useState(true)
    const [checked, setChecked]: any = useState([]);
    const [getcharacters, setcharacters]: any = useState([]);

    const deleteCountAll = async () => {
        setChecked([])
        const config = {
            headers: { Authorization: BearerToeken }
        };
        axios.delete(process.env.REACT_APP_API_URL + '/internal/org/' + organizationId + '/all', config).then(async (res) => {
            if (res.data.status) {
                // setCountAll(res.data.countAll)
                setWorkspaceDocs(res.data.getWorkspaceDocs)
                setProfilesDocs(res.data.getProfilesDocs)
            }
            setTimeout(() => {
                setComponentLoading(false)
            }, 500);
        }).catch((error) => {
            setTimeout(() => {
                setComponentLoading(false)
            }, 500);
            setspinner(false)
            console.log('error=11',error)
            if(error.response.data.message != undefined){
                toast.error(error.response.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
            } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
            }
        });

    }

    // const getCharacter = async (i:any) => {
    //     const config = {
    //         headers: { Authorization: BearerToeken }
    //     };
    //     let res = await axios.delete(process.env.REACT_APP_API_URL + '/internal/org/' + organizationId + '/workspaces/'+i, config);
    //     getcharacters.push(res);
    //     setcharacters({ getcharacters });
    //     return res;
    // };

    // const deleteAll = async (e:any) => {
    //     // let ids = Array.from({length: 10}, (v, k) => k+1)
    //     // Promise.all(ids.map(id => getCharacter(id)))
    //     // return;
    //     e.preventDefault()
    //     setspinner(true)
    //     var xData : any  = []

    //     // Swal.fire({
    //     //   title: getHelpers.ConfirmDeleteMsg(),
    //     //   icon: getHelpers.ConfirmDeleteIcon().toString(),
    //     //   showCancelButton: true,
    //     //   confirmButtonColor: '#3085d6',
    //     //   cancelButtonColor: '#d33',
    //     //   confirmButtonText: 'Delete'
    //     // }).then((result) => {
    //     //   if (result.isConfirmed) {
    //         const config = {
    //           headers: { Authorization: BearerToeken }
    //         };

    //         let responseData : any = [];
    //         let promises = [];

    //         for (let index = 0; index < getWorkspaceDocs.length; index++) {
    //             const ws = getWorkspaceDocs[index];
    //             promises.push(
    //                 axios.delete(process.env.REACT_APP_API_URL + '/internal/org/' + organizationId + '/workspaces/'+ws.workspace_id, config)
    //                 // .then(async (res) => {
    //                 //     if (res.data.status) {
    //                 //         await setDeletedMessages([])
    //                 //         xData.push(res.data.deletedData)
    //                 //         await setDeletedMessages(xData)
    //                         // responseData.push(res.data.status);
    //                 //     } else {
    //                 //         toast.error(res.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
    //                 //         setTimeout(() => {
    //                 //             setspinner(false)
    //                 //             window.dispatchEvent(new CustomEvent(
    //                 //                 'organizationListRefresh', 
    //                 //                 {
    //                 //                     detail: {
    //                 //                         value:true
    //                 //                     }
    //                 //                 }
    //                 //             ))
    //                 //             setspinner(false)
    //                 //         }, 1000);
    //                 //     }
    //                 // }).catch((error) => {
    //                 //     setspinner(false)
    //                 //     console.log('error=11',error)
    //                 //     if(error.response.data.message != undefined){
    //                 //         toast.error(error.response.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
    //                 //     } else {
    //                 //         toast.error("Sorry,looks like there are some errors detected!.Please try again", { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
    //                 //     }
    //                 // })
    //                 // responseData.push(response);
    //             )
    //         }

    //         await Promise.all(promises).then(() => console.log(responseData));


    //     //   }
    //     // })

    // }

    const deleteAll = async (e:any) => {
        e.preventDefault()
        setspinner(true)
        var xData : any  = []

        const config = {
            headers: { Authorization: BearerToeken }
        };

        axios.delete(process.env.REACT_APP_API_URL + '/internal/org/' + organizationId + '/workspaces', config)
        .then(async (res) => {
            if (res.data.status) {

                var wsStatus : any = []
                for (let index = 0; index < getWorkspaceDocs.length; index++) {
                    const element = getWorkspaceDocs[index];
                    element.deleteStatus = 'deleted'
                    wsStatus.push(element)
                }
                
                setWorkspaceDocs(wsStatus)

                // await setDeletedMessages([])
                // xData.push(res.data.deletedData)
                // await setDeletedMessages(xData)
                ///////////
                setTimeout(() => {
                    axios.delete(process.env.REACT_APP_API_URL + '/internal/org/' + organizationId + '/organizations', config).then(async (res) => {
                        if (res.data.status) {

                            var orgStatus : any = []
                            for (let index = 0; index < getProfilesDocs.length; index++) {
                                const element = getProfilesDocs[index];
                                element.deleteStatus = 'deleted'
                                orgStatus.push(element)
                            }
                            
                            setProfilesDocs(orgStatus)

                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                            
                            setTimeout(() => {
                                window.dispatchEvent(new CustomEvent(
                                    'organizationListRefresh', 
                                    {
                                        detail: {
                                            value:true
                                        }
                                    }
                                ))
                                setspinner(false)
                            }, 1000);

                        }
                    }).catch((error) => {
                        setspinner(false)
                        console.log('error=',error)
                        if(error.response.data.message != undefined){
                            toast.error(error.response.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
                        } else {
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
                        }
                    });
                }, 500);
                ///////////
            } else {
                toast.error(res.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
                setTimeout(() => {
                    setspinner(false)
                    window.dispatchEvent(new CustomEvent(
                        'organizationListRefresh', 
                        {
                            detail: {
                                value:true
                            }
                        }
                    ))
                    setspinner(false)
                }, 1000);
            }
        }).catch((error) => {
            setspinner(false)
            console.log('error=11',error)
            if(error.response.data.message != undefined){
                toast.error(error.response.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
            } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
            }
        })

    }

    // Add/Remove checked item from list
    const handleCheck = (event:any) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    // Return classes based on whether item is checked
    var isChecked = (item:any) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";

    useEffect(() => {
        // internalRolePermissionList()
        if(organizationId){
            setComponentLoading(true)
            deleteCountAll()
        }
        setDeletedMessages([])
    }, [organizationId])

    return (
        <div style={{position:'relative'}}>

            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showHidePasswordModal}
                animation={false}
            >

                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        <div className='modal-content'>

                            {
                            getComponentLoading ?
                                (
                                <ComponentLoading></ComponentLoading>
                                ) : ('')
                            }

                            <div className='modal-header bg-primary'>
                                <h2 className='fw-bolder text-white'>Delete </h2>
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => { showHideSetPasswordModal() }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <form id='kt_modal_add_user_form' className='form'>

                                {
                                    getWorkspaceDocs.length > 0 || getProfilesDocs.length > 0?
                                    (
                                        <>
                                        <div className='modal-body scroll-y pb-2 minHeight200'>
                                            <h3 className='mb-1'>Are you sure, you want to delete this organization?</h3>
                                            <h3 className='mb-4'>This will also delete following data. Please check all the checkboxes to proceed deletion.</h3>

                                            {
                                                getWorkspaceDocs.length > 0?
                                                (
                                                    <h3 className='mb-3'>Workspaces:</h3>
                                                ):('')
                                            }
                                            {getWorkspaceDocs.map((item:any, index:any) => (
                                                <>
                                                    <div key={index} className="form-check form-check-custom form-check-solid mt-2 mb-2">
                                                        <input className="form-check-input" type="checkbox" value={item.workspace_id} id={item.workspace_id} onChange={handleCheck} />
                                                        <label className="form-check-label d-flex" htmlFor={item.workspace_id}>
                                                            {item.workspace_name} 

                                                            <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Data, Dashboards and Users.</Tooltip>}><span className="svg-icon svg-icon-muted svg-icon-18" style={{marginLeft:'5px'}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/><rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/><rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/></svg></span></OverlayTrigger>

                                                            {
                                                                item.deleteStatus == 'deleted'?
                                                                (<><span className='text-danger' style={{marginLeft:'5px'}}>({item.deleteStatus})</span></>):('')
                                                            }
                                                        </label>
                                                    </div>
                                                    {/* <div key={index}>
                                                        <label htmlFor={item.workspace_id}>
                                                            <input value={item.workspace_id} id={item.workspace_id} type="checkbox" onChange={handleCheck} />
                                                            <span className={isChecked(item.workspace_id)}> {item.workspace_name} including its dashboards and users.</span>
                                                        </label>
                                                    </div> */}
                                                </>
                                            ))}

                                            {
                                                getProfilesDocs.length > 0?
                                                (
                                                    <h3 className='mt-5 mb-3'>Contacts:</h3>
                                                ):('')
                                            }
                                            {getProfilesDocs.map((item:any, index:any) => (
                                                <>
                                                    <div key={index} className="form-check form-check-custom form-check-solid mt-2 mb-2">
                                                        <input className="form-check-input" type="checkbox" value={item.profile_id} id={item.profile_id} onChange={handleCheck} />
                                                        <label className="form-check-label" htmlFor={item.profile_id}>
                                                            {item.first_name} {item.last_name}
                                                            {
                                                                item.deleteStatus == 'deleted'?
                                                                (<><span className='text-danger' style={{marginLeft:'5px'}}>({item.deleteStatus})</span></>):('')
                                                            }
                                                        </label>
                                                    </div>
                                                    {/* <div key={index}>
                                                        <label htmlFor={item.profile_id}>
                                                            <input value={item.profile_id} id={item.profile_id} type="checkbox" onChange={handleCheck} />
                                                            <span className={isChecked(item.profile_id)}> {item.first_name} {item.last_name}</span>
                                                        </label>
                                                    </div> */}
                                                </>
                                            ))}
                                        </div>
                                        </>
                                    ):(
                                        <>
                                        <div className='modal-body scroll-y pb-2 minHeight200 d-flex align-items-center justify-content-center'>
                                            <h3 className='mb-1'>Are you sure, you want to delete this organization?</h3>
                                        </div>
                                        </>
                                    )
                                }

                                <div className="modal-footer bg-light deleteModalFooter">
                                    <div className='leftMessage '>
                                        {
                                            getspinner?
                                            (
                                                <>
                                                    <div className='d-flex'>
                                                        <span className="svg-icon svg-icon-muted svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/></svg></span>
                                                        <span style={{paddingLeft: '10px'}}>
                                                            Dont close this popup, until the completion of this action.
                                                        </span>
                                                    </div>
                                                </>
                                            ):('')
                                        }
                                    </div>
                                    <div>
                                        <button
                                            type='button'
                                            onClick={() => showHideSetPasswordModal()}
                                            className='btn btn-white'
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type='submit'
                                            className='btn btn-primary ms-2'
                                            data-kt-users-modal-action='submit'
                                            onClick={(e) => { deleteAll(e) }}
                                            disabled={checked.length != getWorkspaceDocs.length+getProfilesDocs.length?true:false}
                                        >{!getspinner && (
                                            <span className='indicator-label'>Delete</span>
                                        )}
                                            {getspinner && (
                                                <span >
                                                    Please wait...
                                                    <span className='spinner-border spinner-border-sm align-middle'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
            </Modal>
        </div>
    )
}

export { OrganizationDeleteAllModal }