/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { WorkspaceUserEditModal } from './WorkspaceUserEditModel'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state: any) => {
    return {
        profile: state.user.profile,
        userData: state.userData.permissions,
        userRolesData: state.userRolesData.roles
    }
}

type Props = {
    className: string;
    orgId: any
}
let WorkspaceDashboardsUrl: any;
const WorkspaceArchiveDashboardsList: React.FC<Props> = ({ className, orgId }) => {

    // import { json } from 'stream/consumers'
    const API_URL = process.env.REACT_APP_API_URL
    const ApiToken = localStorage.getItem('api_token')
    const BearerToeken = `Bearer ${ApiToken}`
    let { workspaceId } = useParams();
    //   let {status} = useParams();
    // console.log("status=",status);
    workspaceId = orgId ? orgId : workspaceId

    WorkspaceDashboardsUrl = `${API_URL}/internal-workspace/dashboards/` + workspaceId + '/trash'

    // data.wok

    //  console.log("url=",WorkspaceDashboardsUrl);

    const [orgDashboardsListState, setOrgDashboardsListState] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false)

    const [getComponentLoading, setComponentLoading] = useState(true)

    const showHideModel = () => {
        setShowModal(!setShowModal)
    }
    const fetchWorkspacesList: any = () => {
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const organizationsListList = axios.get(
            WorkspaceDashboardsUrl,
            config
        ).then(async (res: any) => {
            setComponentLoading(false)
            return setOrgDashboardsListState(res.data)
        }
        ).catch((error) => {
            setComponentLoading(false)
        }
        );
        return organizationsListList
    }
    const restoreDashboard = async (dahboardId: any) => {
        Swal.fire({
            title: 'Want to restore?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Restore'
        }).then((result) => {
            if (result.isConfirmed) {
                setComponentLoading(true)
                const config = {
                    headers: { Authorization: BearerToeken }
                };
                axios.delete(
                    process.env.REACT_APP_API_URL + '/internal-dashboards/active/' + dahboardId,
                    config
                )
                    .then(async (res) => {
                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });

                            // navigate('/internal/organizations');
                            fetchWorkspacesList();
                        } else {
                            toast.error(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                        setComponentLoading(false)
                    })
                    .catch((error) => {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                        setComponentLoading(false)
                    });
            }
        })
    }
    const deleteDashboard = async (dahboardId: any) => {
        Swal.fire({
            title: getHelpers.ConfirmDeletePermanentlyMsg(),
            icon: getHelpers.ConfirmDeleteIcon().toString(),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete Permanently'
        }).then((result) => {
            if (result.isConfirmed) {
                setComponentLoading(true)
                const config = {
                    headers: { Authorization: BearerToeken }
                };
                axios.delete(
                    process.env.REACT_APP_API_URL + '/dashboard/' + dahboardId,
                    config
                )
                    .then(async (res) => {
                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });

                            // navigate('/internal/organizations');
                            fetchWorkspacesList();
                        } else {
                            toast.error(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                        setComponentLoading(false)
                    })
                    .catch((error) => {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                        setComponentLoading(false)
                    });
            }
        })
    }



    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }: any) {
        const count = preFilteredRows.length

        return (
            <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }
    const setLocalStorage = (pageSize: any) => {
        // console.log('pageSize',pageSize)
        localStorage.setItem('pageSize', pageSize)
    }
    function OrgDashboardListTable(props: any) {
        const { columns, data } = props

        const defaultColumn: any = React.useMemo(
            () => ({
                // Default Filter UI
                Filter: DefaultColumnFilter,
            }),
            []
        )

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            // state,
            preGlobalFilteredRows,
            setGlobalFilter,
            page,
            // canPreviousPage,
            // canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize, globalFilter },
        }: any = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination,

        )

        // console.log("rows data", rows);
        let currentPageStartIndex: any = 2;
        let previousIndexPage: any = 0;

        return (
            <div>

                <div className='table-responsive'>
                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer dashboardsTableList" {...getTableProps()}>
                        <thead>

                            {headerGroups.map((headerGroup: any) => (
                                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column: any) => (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th className={" "} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            {/* {console.log('column=',column)} */}

                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? (<span className='table-sort-asc'></span>)
                                                    : (<span className='table-sort-desc'></span>)
                                                : ''}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row: any, i: any) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>

                                        {row.cells.map((cell: any) => {
                                            return <td  {...cell.getCellProps()}>{cell.render('Cell')}

                                            </td>
                                        })}



                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="row">
                    <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
                        <div className="dataTables_length" id="kt_customers_table_length">
                            <select name="kt_customers_table_length"
                                className="form-select form-select-sm form-select-solid"
                                value={pageSize}
                                onChange={e => {
                                    { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                                }}
                                style={{ width: '120px', height: '38px' }}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
                        <div id="kt_table_users_paginate">
                            <ul className="pagination">

                                <li className="page-item" onClick={() => previousPage()} >
                                    <a className="page-link prev-angle">
                                        <i className="fa fa-chevron-left"></i>
                                    </a>
                                </li>

                                {pageOptions.map((pageSize: any, rowIndex: any) => {

                                    if (currentPageStartIndex <= rowIndex) {
                                        currentPageStartIndex = currentPageStartIndex + 1
                                        previousIndexPage = rowIndex - 1
                                    }
                                    return (



                                        previousIndexPage <= currentPageStartIndex
                                            ?
                                            <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                                                <a className="page-link">{pageSize + 1} </a>

                                            </li>
                                            :
                                            null
                                    )
                                })}
                                <li className="page-item" onClick={() => nextPage()} >
                                    <a className="page-link next-angle">

                                        <i className="fa fa-chevron-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const chartFunc = (value: any) => {
        console.log('value=', value.charAt(0))
        let chatAt_value = value.charAt(0);
        return chatAt_value;
    }
    const columns: any = React.useMemo(
        () => [

            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }: any) => {
                    const data = row.original

                    return (
                        <>
                            {
                                data.gd_dashboard ?
                                    data.gd_dashboard[0] ?
                                        (
                                            <Link className='fw-bolder' key={data.gd_dashboard[0]._id} to={'/gooddata/' + data.gd_dashboard[0].workspaceId + '/' + data.gd_dashboard[0].identifier}>
                                                {data.name}
                                            </Link>
                                        ) :
                                        (<Link to={'#'}>
                                            {data.name}
                                        </Link>)

                                    : (
                                        <Link to={'#'}>
                                            {data.name}
                                        </Link>
                                    )
                            }

                        </>
                    )
                }

            },

            {
                Header: 'Created by',
                accessor: 'created_by',
                Cell: ({ row }: any) => {
                    const data = row.original
                    // {console.log('data.created_by',JSON.stringify(data))}
                    return (
                        <>
                            {
                                data.createdBy ?
                                    (
                                        data.createdBy[0] ?
                                            (
                                                data.createdBy[0].avatar_id ?
                                                    (
                                                        <div className='d-flex align-items-center' key={data._id}>
                                                            <div className="symbol symbol-35px symbol-circle text-uppercase me-3" data-bs-toggle="tooltip" data-kt-initialized="1" key={data.createdBy[0]._id}>
                                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{data.createdBy[0].first_name ? (data.createdBy[0].first_name + ' ' + data.createdBy[0].last_name) : (data.createdBy[0].email)}</Tooltip>}>
                                                                    {
                                                                        data.createdBy[0].avatar_id.file ?
                                                                            (
                                                                                <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(data.createdBy[0].first_name ?data.createdBy[0].first_name :data.createdBy[0].email ) + " img-alt-35"} src={getUserInfo.domainUrl() + 'avatars/' + data.createdBy[0].avatar_id.file.filename}  alt={chartFunc(data.createdBy[0].first_name ?data.createdBy[0].first_name :data.createdBy[0].email)} />
                                                                                // <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/' + data.createdBy[0].avatar_id.file.filename} />
                                                                            ) : (
                                                                                <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(data.createdBy[0].first_name ?data.createdBy[0].first_name :data.createdBy[0].email) + " img-alt-35"} src={getUserInfo.domainUrl() + 'avatars/default.png'}  alt={chartFunc(data.createdBy[0].first_name ?data.createdBy[0].first_name :data.createdBy[0].email)} />
                                                                                // <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/default.png'} />
                                                                            )
                                                                    }
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {
                                                                data.createdBy[0].first_name ?
                                                                    (
                                                                        <div className='d-flex align-items-center' key={data._id}>
                                                                            <div className="symbol symbol-35px symbol-circle me-3" data-bs-toggle="tooltip" data-kt-initialized="1">
                                                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{data.createdBy[0].first_name + ' ' + data.createdBy[0].last_name}</Tooltip>}>
                                                                                    <span className={"symbol-label text-inverse-warning fw-bolder bg-" + data.createdBy[0].first_name.charAt(0)}>
                                                                                        {data.createdBy[0].first_name.charAt(0).toUpperCase()}
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        data.createdBy[0].email ?
                                                                            (
                                                                                <>
                                                                                    <div className='d-flex align-items-center' key={data._id}>
                                                                                        <div className="symbol symbol-35px symbol-circle me-3" data-bs-toggle="tooltip" data-kt-initialized="1">
                                                                                            <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{data.createdBy[0].email}</Tooltip>}>
                                                                                                <span className={"symbol-label text-inverse-warning fw-bolder bg-" + data.createdBy[0].email.charAt(0)}>
                                                                                                    {data.createdBy[0].email.charAt(0).toUpperCase()}
                                                                                                </span>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                'N/A'
                                                                            )
                                                                    )
                                                            }
                                                        </>
                                                    )
                                            ) : ('N/A')
                                    ) : ('N/A')

                            }
                        </>
                    )
                }
            },

            {
                Header: 'Shared With',
                accessor: 'shared_with',
                Cell: ({ row }: any) => {
                    const data = row.original
                    return (
                        <div className="symbol-group symbol-hover flex-nowrap">
                            <>
                                {data.users ?
                                    data.users[0] ?
                                        (
                                            data.users.map((user: any) => (
                                                user.avatar_id && user.avatar_id[0] ?
                                                    (
                                                        <>
                                                            <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={user._id}>
                                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{user.first_name ? (user.first_name + ' ' + user.last_name) : (user.email)}</Tooltip>}>
                                                                    {
                                                                        // user.avatar_id[0] && user.avatar_id[0].length > 0 ?
                                                                        // (
                                                                        <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/' + user.avatar_id[0].file.filename} />
                                                                        // ) : (
                                                                        //   <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/default.png'} />
                                                                        // )
                                                                    }
                                                                </OverlayTrigger>
                                                            </div>
                                                        </>
                                                    ) : user.first_name && user.last_name ? (
                                                        <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={user._id}>
                                                            <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{user.first_name} {user.last_name}</Tooltip>}>
                                                                <span className={"symbol-label text-inverse-warning fw-bolder bg-" + user.first_name.charAt(0)}>
                                                                    {user.first_name.charAt(0)}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    )
                                                        : (
                                                            user.email ?
                                                                (
                                                                    <>
                                                                        <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={user._id}>
                                                                            <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{user.email}</Tooltip>}>
                                                                                <span className={"symbol-label text-inverse-warning fw-bolder bg-" + user.email.charAt(0)}>
                                                                                    {user.email.charAt(0)}
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    'N/A'
                                                                )
                                                        )

                                            ))

                                        )
                                        : ('N/A')
                                    : ('N/A')

                                }
                            </>
                        </div>
                    )
                }

            },


            {
                Header: 'Action',
                accessor: 'action',
                disableSortBy: true,
                Cell: ({ row }: any) => {
                    const data = row.original
                    // const org_id: any = data._id;
                    // console.log("data=",data);
                    return (
                        <>
                            {
                                _.includes(store.getState().userData.permissions, 'manage_organizations_dashboards') ?
                                    (
                                        <>
                                            {
                                                data.gd_dashboard ?
                                                    data.gd_dashboard[0] ?
                                                        (
                                                            <>
                                                                <div className='customActionButtons'>
                                                                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Restore</Tooltip>}>
                                                                        <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { restoreDashboard(data._id) }} >
                                                                            {/* <i className="bi bi-box-arrow-in-left"></i> */}
                                                                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <rect opacity="0.3" x="8.5" y="11" width="12" height="2" rx="1" fill="currentColor" />
                                                                                <path d="M10.3687 11.6927L12.1244 10.2297C12.5946 9.83785 12.6268 9.12683 12.194 8.69401C11.8043 8.3043 11.1784 8.28591 10.7664 8.65206L7.84084 11.2526C7.39332 11.6504 7.39332 12.3496 7.84084 12.7474L10.7664 15.3479C11.1784 15.7141 11.8043 15.6957 12.194 15.306C12.6268 14.8732 12.5946 14.1621 12.1244 13.7703L10.3687 12.3073C10.1768 12.1474 10.1768 11.8526 10.3687 11.6927Z" fill="currentColor" />
                                                                                <path opacity="0.5" d="M16 5V6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H6C5.44771 5 5 5.44772 5 6V18C5 18.5523 5.44771 19 6 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H14C15.1046 3 16 3.89543 16 5Z" fill="currentColor" />
                                                                            </svg>
                                                                            </span>
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Delete Permanently</Tooltip>}>
                                                                        <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { deleteDashboard(data._id) }} >
                                                                            {/* <i className="bi bi-trash"></i> */}
                                                                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                                                                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                                                                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                                                                            </svg>
                                                                            </span>
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </>
                                                        ) :
                                                        (
                                                            <>
                                                                <div className='customActionButtons'>
                                                                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Restore</Tooltip>}>
                                                                        <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { restoreDashboard(data._id) }} >
                                                                            {/* <i className="bi bi-box-arrow-in-left"></i> */}
                                                                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <rect opacity="0.3" x="8.5" y="11" width="12" height="2" rx="1" fill="currentColor" />
                                                                                <path d="M10.3687 11.6927L12.1244 10.2297C12.5946 9.83785 12.6268 9.12683 12.194 8.69401C11.8043 8.3043 11.1784 8.28591 10.7664 8.65206L7.84084 11.2526C7.39332 11.6504 7.39332 12.3496 7.84084 12.7474L10.7664 15.3479C11.1784 15.7141 11.8043 15.6957 12.194 15.306C12.6268 14.8732 12.5946 14.1621 12.1244 13.7703L10.3687 12.3073C10.1768 12.1474 10.1768 11.8526 10.3687 11.6927Z" fill="currentColor" />
                                                                                <path opacity="0.5" d="M16 5V6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H6C5.44771 5 5 5.44772 5 6V18C5 18.5523 5.44771 19 6 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H14C15.1046 3 16 3.89543 16 5Z" fill="currentColor" />
                                                                            </svg>
                                                                            </span>
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Delete Permanently</Tooltip>}>
                                                                        <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { deleteDashboard(data._id) }} >
                                                                            {/* <i className="bi bi-trash"></i> */}
                                                                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                                                                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                                                                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                                                                            </svg>
                                                                            </span>
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </>
                                                        )

                                                    : (
                                                        <>
                                                            <div className='customActionButtons'>
                                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Restore</Tooltip>}>
                                                                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { restoreDashboard(data._id) }} >
                                                                        {/* <i className="bi bi-box-arrow-in-left"></i> */}
                                                                        <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.3" x="8.5" y="11" width="12" height="2" rx="1" fill="currentColor" />
                                                                            <path d="M10.3687 11.6927L12.1244 10.2297C12.5946 9.83785 12.6268 9.12683 12.194 8.69401C11.8043 8.3043 11.1784 8.28591 10.7664 8.65206L7.84084 11.2526C7.39332 11.6504 7.39332 12.3496 7.84084 12.7474L10.7664 15.3479C11.1784 15.7141 11.8043 15.6957 12.194 15.306C12.6268 14.8732 12.5946 14.1621 12.1244 13.7703L10.3687 12.3073C10.1768 12.1474 10.1768 11.8526 10.3687 11.6927Z" fill="currentColor" />
                                                                            <path opacity="0.5" d="M16 5V6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H6C5.44771 5 5 5.44772 5 6V18C5 18.5523 5.44771 19 6 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H14C15.1046 3 16 3.89543 16 5Z" fill="currentColor" />
                                                                        </svg>
                                                                        </span>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Delete Permanently</Tooltip>}>
                                                                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { deleteDashboard(data._id) }} >
                                                                        {/* <i className="bi bi-trash"></i> */}
                                                                        <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                                                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                                                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                                                                        </svg>
                                                                        </span>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </>
                                                    )
                                            }
                                        </>
                                    ) : ('N/A')
                            }
                        </>
                    )
                }

            },
        ],
        []
    )



    useEffect(() => {

        fetchWorkspacesList()

    }, [])

    // console.log("orgDashboardsListState =  ", orgDashboardsListState)

    const data: any = orgDashboardsListState && orgDashboardsListState.length > 0 ? orgDashboardsListState : []

    return (
        <>
        <ToastContainer></ToastContainer>
        <div className={`card ${className} card-border-radius`}>

            {
                getComponentLoading ?
                    (
                        <ComponentLoading></ComponentLoading>
                    ) : ('')
            }

            {/* begin::Body */}
            <div>
                <Modal
                    className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                    id='kt_inbox_compose'
                    role='dialog'
                    data-backdrop='false'
                    aria-hidden='true'
                    tabIndex='-1'
                    show={showModal}
                    animation={false}
                >

                    <WorkspaceUserEditModal showHideModel={showHideModel} />
                </Modal>
            </div>




            {data.length > 0 ?
                <div className="card-body pt-0 bg-white  min-height-400" >
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
                        <OrgDashboardListTable columns={columns} data={data} />
                    </div>
                </div>
                :
                <div className='card'><div className='card-body text-center bg-light m-5 fs-5'>No data found</div></div>
            }

        </div>
        </>
    )
}

// export { WorkspaceArchiveDashboardsList }
export default connect(mapStateToProps)(WorkspaceArchiveDashboardsList);