import clsx from 'clsx'
import React, { FC, useState, useEffect } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { useNavigate, Navigate, Link, Routes, Route, useParams } from "react-router-dom";
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import axios from 'axios'
import { getFactory } from "@gooddata/api-client-bear";
import Dropdown from 'react-bootstrap/Dropdown';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'
import { DashboardShareModel } from '../toolbar/DashboardShareModel'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../../redux/actions/externalPermissions';
import { ActionExternalWorkspacePermissions } from '../../../../redux/actions/externalWorkspacePermissions';
import { ActionRoles } from '../../../../redux/actions/roles';
import { store } from '../../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
// let userLocalStorageData : any = localStorage.getItem('userinfo');
// userLocalStorageData = JSON.parse(userLocalStorageData)
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'
let currentActiveOrgName: any = ''
let currentActiveOrgId: any = ''
let dashboardNewName: any = ''
const GdDashboardShareEditButtons: FC = () => {
  let params: any = useParams();
  const dashboardID = params.dashboardId;
  const { config } = useLayout()
  const [showModal, setShowModal] = useState(false)
  const [getAllWorkspace, setAllWorkspace] = useState<any[]>([]);
  const [editViewMode, iseditViewMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [getDashboardName, setDashboardName] = useState('')
  const [getUserPermission, setUserPermission] = useState(false)
  const [getCount, setCount] = useState(0)


  const [getEditShowDashboad, setEditShowDashboad] = useState<boolean>(true);
  const [saveDashboard, setsaveDashboard] = useState<boolean>(true);
  const [goodDataMode, isgoodDataMode] = useState<boolean>(false)
  const navigate = useNavigate();

  const [getKey, setKey] = useState<any>(0);
  const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
  const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
  const [getExternalWorkspacePermissions, setUserAllExternalPermissions] = useState<any[]>([]);
  const [getExternalPermissions, setExternalPermissions] = useState<any[]>([]);
  const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);
  const [getDashboard, setDashboard] = useState<any>({});
  const [renameDashboardSave, setRenameDashboardSave] = useState<boolean>(true)
  const dispatch = useDispatch();

  const getSingleDashboard = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "dashboard/" + dashboardID,
    )
      .then(async (response) => {
        if (response.data) {
          // console.log('res=',response.data)
          setDashboard(response.data)
          fetchVerifyAccess(response.data)
        }
      })
      .catch(error => {
        console.log(error)

        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message) {
              toast.error(error.response.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            } else {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                  toast.error(error.response.data.errors[0]['msg'], {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                  });
                } else {
                  toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                  });
                }
              } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
                });
              }
            }
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        } else {
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        }
      })
  }

  const fetchVerifyAccess = async (dashboardData: any) => {
    const dashboard = dashboardData
    const API_URL = process.env.REACT_APP_API_URL
    const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
    const ApiToken = localStorage.getItem('api_token')
    const BearerToken = `Bearer ${ApiToken}`
    const config = {
      headers: { Authorization: BearerToken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          var internalP: any = []
          var externalP: any = []
          if (res.data.internalPermissions) {
            if (res.data.internalPermissions.permissions) {
              // dispatch(ActionExternalPermissions.externalUserPermissions(res.data.externalPermissions.permissions))
              dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
              setUserInternalPermissions(res.data.internalPermissions.permissions)
              // setUserExternalPermissions(res.data.externalPermissions.permissions)
              internalP = res.data.internalPermissions.permissions
              setKey(getKey + 1)

              // if (_.includes(res.data.externalPermissions.permissions,'manage_organizations_dashboards')) {
              //   setUserPermission(true);
              // } 
              // else {
              //   navigate('/error/404')
              // }

            }
          }
          if (res.data.externalPermissions) {
            // console.log('res.data.externalPermissions=',res.data.externalPermissions)
            if (res.data.externalPermissions.permissions) {
              // getWPermissions = res.data.externalPermissions.permissions
              var data = getHelpers.doesHasPermissions(res.data.externalPermissions.permissions, dashboard.workspace_id)
              dispatch(ActionExternalPermissions.externalUserPermissions(data))
              dispatch(ActionExternalWorkspacePermissions.externalWorkspacePermissions(res.data.externalPermissions.permissions))
              setUserExternalPermissions(data)
              setUserAllExternalPermissions(res.data.externalPermissions.permissions)
              externalP = getHelpers.doesHasPermissions(res.data.externalPermissions.permissions, dashboard.workspace_id)
              setKey(getKey + 1)
            }
          }

          if(externalP && externalP.length > 0){
            // console.log('externalP=',externalP)
            setExternalPermissions(externalP)
          }

          setTimeout(() => {
            getDashboardById(internalP, externalP)
          }, 500);

          if (res.data.internalRoles) {
            if (res.data.internalRoles.roles) {
              dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
              setUserInternalRoles(res.data.internalRoles.roles)
              setKey(getKey + 1)
            }
          }
        }

      })
      .catch((error) => { });
  }

  const findOrgId = async (data: any) => {
    let userData: any = await localStorage.getItem('userinfo');
    userData = JSON.parse(userData);
    let currentOrgId = ''
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id == userData.workspace_id) {
        currentOrgId = data[i].name;
        currentActiveOrgId = data[i]._id
      }
    }
    return currentOrgId;
  }
  const fetchAllOrg = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/workspaces",
    )
      .then(async (response) => {
        if (response.data) {
          currentActiveOrgName = await findOrgId(response.data.workspaces);
          setAllWorkspace(response.data.workspaces)
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  const showHideModel = () => {
    setShowModal(!setShowModal)
    // enternalRolePermissionList();
  }

  useEffect(() => {
    getSingleDashboard()
    // fetchVerifyAccess()
    // getDashboardById();
    fetchAllOrg();
    // console.log('in use Effect', params);
    let paramData = params['*']

    if (paramData.includes("dashboard/")) {
      isgoodDataMode(true)
    } else {
      isgoodDataMode(false)
    }

    if (!paramData.includes("edit")) {
      iseditViewMode(false)
    } else {
      iseditViewMode(true)
    }

  }, []);

  const switchWorkspace = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/workspace/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
        window.location.reload();
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }

      // }, 2000);
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }
  const createDashboard = () => {
    // console.log('data[i].organisation_id.name',currentActiveOrgId)
    let routePath = `external/dashboard/create/${currentActiveOrgId}`
    navigate(routePath)
  }
  // if (saveDashboard) {
  window.addEventListener('message', (event) => {
    if (event.data.gdc) {
      if (event.data.gdc.event.name == "availableCommands") {
        let checkEditOrNot = _.filter(
          event.data.gdc.event.data, function (o) {
            return o == 'saveDashboard';
          }
        );
        if (checkEditOrNot.length > 0) {
          // if (dashboardNewName == getDashboardName) {
          //   setRenameDashboardSave(false);
          //   setEditShowDashboad(true)
          // } else {
          // setRenameDashboardSave(true);
          setEditShowDashboad(false);
          // }
        } else {
          // console.log(dashboardNewName ,"==", getDashboardName)
          // if (dashboardNewName == getDashboardName) {
          //   setRenameDashboardSave(false);
          //   setEditShowDashboad(false);
          // } else {
          //   setEditShowDashboad(true)
          setRenameDashboardSave(true);
          // setEditShowDashboad(true)
          // }
          // setRenameDashboardSave(false);
        }
      }
    }
  },);
  // }
  const getDashboardById = async (internalP: any, externalP: any) => {
    // console.log('internalP: any, externalP: any', internalP, externalP)
    // alert(0);
    const config = {
      headers: { Authorization: BearerToeken }
    };
    await axios.get(
      process.env.REACT_APP_API_URL + '/dashboard-permission/' + dashboardID,
      config
    )
      .then(async (res) => {
        // console.log('')
        if (
          _.includes(externalP, 'manage_organizations_dashboards')
        ) {
          setUserPermission(true);
        } else {
          // navigate('/error/404')
          if (res.data.permission == 'can_edit') {
            setUserPermission(true);
          } else if (res.data.permission == 'can_view') {
            setUserPermission(false);
          } else {
            // last change view_workspaces
            // if(_.includes(externalP, 'view_workspaces')){
              // setUserPermission(false);
            // } else {
              navigate('/error/404')
            // }
          }
        }

        if (res.data.dashboardName) {
          await setDashboardName(res.data.dashboardName)
        }

        // }
      })
      .catch((error) => {
      });
  }
  window.addEventListener('newDashboardName', (event: any) => {
    if (event['detail'].name) {
      // console.log()
      // if (event['detail'].name != getDashboardName) {
      setDashboardName(event['detail'].name);
      // dashboardNewName = event['detail'].name
      //   setRenameDashboardSave(false);
      //   setEditShowDashboad(false);
      // } else {
      // if(getEditShowDashboad  == false){
      // }else{
      // setRenameDashboardSave(true);
      // setEditShowDashboad(true);
      // }
      // }
    }
  })
  const saveDashboardFunc = async () => {
    // alert(0)
    setLoading(true)
    // if (dashboardNewName == '') {
    var targetFrame: any = document?.getElementById("frame");
    let frame = targetFrame.contentWindow;
    var targetOrigin = process.env.REACT_APP_GD_BACKEND_URL;
    var input = {
      "gdc": {
        "product": "dashboard",
        "event": {
          "name": "saveDashboard",
          "data": {
            "title": getDashboardName
          },
          "contextId": "save-1665651431313"
        }
      }
    }
    await frame.postMessage(input, targetOrigin);
    // dashboardNewName = getDashboardName
    // } else {
    //   var targetFrame: any = document?.getElementById("frame");
    //   let frame = targetFrame.contentWindow;
    //   var targetOrigin = process.env.REACT_APP_GD_BACKEND_URL;
    //   var updateDashboard = {
    //     "gdc": {
    //       "product": "dashboard",
    //       "event": {
    //         "name": "saveDashboard",
    //         "data": {
    //           "title": dashboardNewName
    //         },
    //         "contextId": "save-1665651431313"
    //       }
    //     }
    //   }
    //   await frame.postMessage(updateDashboard, targetOrigin);
    // }
  }
  const saveDashboardUpdateTime = async () => {
    if (getCount == 0) {
      var count = await setCount(1);
      // console.log('dashboardNewName', dashboardNewName);
      setTimeout(async () => {
        await axios.get(
          process.env.REACT_APP_BASE_API_URL + 'gd/save/' + dashboardID
        )
          .then(async (response) => {
            if (response.data) {
              iseditViewMode(false)
              setTimeout(() => {
                setLoading(false)
              }, 200);
            }
          })
          .catch((error) => {
            console.log(error)
          });
      }, 2000);
    }
  }
  window.addEventListener('message', (event) => {
    if (event.data.gdc) {
      if (event.data.gdc.event.name == 'dashboardUpdated') {
        saveDashboardUpdateTime();
      }
    }
  },);
  const editDashboard = () => {
    window.dispatchEvent(new CustomEvent(
      'hideshowDashboardEditIcon',
      {
        detail: {
          toEdit: true
        }
      }
    ))
    setTimeout(() => {
      iseditViewMode(true)
    }, 500);
  }
  const viewDashboard = () => {
    window.dispatchEvent(new CustomEvent(
      'hideshowDashboardEditIcon',
      {
        detail: {
          toEdit: false
        }
      }
    ))
    setTimeout(() => {
      iseditViewMode(false)
    }, 500);

  }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <ToastContainer />
      {/* {JSON.stringify(_.includes(getExternalPermissions,'view_workspaces'))} */}
      {/* {JSON.stringify(_.includes(getExternalWorkspacePermissions.permissions,'view_workspaces'))} */}
      <div
        className={clsx('d-flex align-items-center')}
        id='kt_header_user_menu_toggle'
      >
        <div className='gdDashboardShareEdit'>
          {
            getUserPermission
              ?
              <>
                {/* {true ? */}
                  <div>
                    {
                      editViewMode ?
                        <span className='d-flex'>

                          {/* {
                            renameDashboardSave

                              ? */}
                          <button onClick={saveDashboardFunc} disabled={getEditShowDashboad} className='btn btn-primary fw-bolder d-flex align-items-center view-btns'  >
                            {!loading && 'Save'}
                            {loading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                Saving...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                          {/* :
                              <button onClick={saveDashboardDashboardNameFunc} disabled={getEditShowDashboad} className='btn btn-primary fw-bolder d-flex align-items-center view-btns'  >
                                {!loading && 'Save'}
                                {loading && (
                                  <span className='indicator-progress' style={{ display: 'block' }}>
                                    Saving...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                          } */}
                          <a
                            style={{ marginLeft: '10px' }}
                            href={'#/dashboard/' + dashboardID}
                            onClick={viewDashboard}
                            className='btn btn-sm btn-primary btn-primary fw-bolder  d-flex align-items-center view-btns'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                            id='kt_toolbar_primary_button'
                          >
                            {/* <i className="mt--1 bi bi-eye me-1"></i> */}
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor" />
                              <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor" />
                            </svg>
                            </span>
                            <span className='fw-bolder'>View</span>
                          </a>
                          <a
                            onClick={() => setShowModal(true)}
                            style={{ marginLeft: '10px' }}
                            className='btn btn-sm btn-primary btn-primary fw-bolder  d-flex align-items-center view-btns'
                          >
                            {/* <i className="mt--1 bi bi-share me-1"></i> */}
                            <span className="svg-icon svg-icon-muted svg-icon-18">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="currentColor" />
                                <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="currentColor" />
                                <path opacity="0.3" d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="currentColor" />
                              </svg>
                            </span>
                            <span className='fw-bolder'>Share</span>
                          </a>

                        </span>
                        :
                        <span className='d-flex'>
                          <a
                            href={'#/dashboard/' + dashboardID + '/edit'}
                            onClick={editDashboard}
                            className='btn btn-sm btn-primary btn-primary fw-bolder d-flex align-items-center '
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                            id='kt_toolbar_primary_button'
                          >
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                              <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                              <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                            </svg>
                            </span>
                            <span className='fw-bolder'>Edit</span>
                          </a>
                          <a
                            onClick={() => setShowModal(true)}
                            style={{ marginLeft: '10px' }}
                            className='btn btn-sm btn-primary btn-primary fw-bolder d-flex align-items-center '
                          >
                            <span className="svg-icon svg-icon-muted svg-icon-18">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="currentColor" />
                                <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="currentColor" />
                                <path opacity="0.3" d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="currentColor" />
                              </svg>
                            </span>
                            <span className='fw-bolder'>Share</span>
                          </a>
                        </span>
                    }
                  </div>
                  {/* :
                  ('')
                } */}
                </>
              :
              ('')
          }

        </div>

      </div>

      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_modal_share_dashboard'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={showModal}
        animation={false}
      >
        <DashboardShareModel showModal={showHideModel} />
      </Modal>

    </div>
  )
}

// export { GdDashboardShareEditButtons }
export default connect(mapStateToProps)(GdDashboardShareEditButtons);