import {useEffect,useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import axios from 'axios'

import {getLayout, ILayout, LayoutSetup, useLayout} from '../../_metronic/layout/core'

import { ComponentLoading } from '../../../src/app/modules/ComponentLoading'

const ApiToken = localStorage.getItem('api_token')

const MasterLayout = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [config, setConfig] = useState<ILayout>(getLayout())
  
  const [getIsLoadingComponent, setIsLoadingComponent] = useState(false)

  const init = () => {
    setIsLoadingComponent(true)
    console.log('init MasterLayout')
    axios.post(`${process.env.REACT_APP_API_URL}/v1/authentication/verify_token`)
        .then((res) => {
            // console.log('res>>>=',res.data)
            if(!res.data.api_token){
              navigate('/auth/login');
            } else {
              setIsLoadingComponent(false)
            }
        })
        .catch(err => {
            console.log('err>>>=',err.response.data)
            navigate('/auth/login');
            setIsLoadingComponent(false)
        });
  }

  useEffect(() => {
    init()
  }, [location.key])

  useEffect(() => {
    // alert('MasterLayout')
    var themeSettings = { 'colorScheme': 'lightGray' };
    localStorage.setItem('themeSettings', JSON.stringify(themeSettings));

    document.body.classList.add('aside-enabled');
    document.body.classList.add('aside-fixed');
    document.body.classList.add('lightGray');

    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <>
    {
      getIsLoadingComponent ?
        (
          <ComponentLoading></ComponentLoading>
        ) : (
          <>
            <PageDataProvider>
              <div className='page d-flex flex-row flex-column-fluid'>
                <AsideDefault />
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                  <HeaderWrapper />

                  <div id='kt_content' className='d-flex flex-column flex-column-fluid'>
                    <Toolbar />
                    <div className='post d-flex flex-column-fluid' id='kt_post'>
                      <Content>
                        <Outlet />
                      </Content>
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>

              <ScrollTop />
            </PageDataProvider>
          </>
        )
    }
    </>
  )
}

export {MasterLayout}
