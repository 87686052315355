/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../modules/auth/GetHelpers'

import * as Yup from 'yup'
import clsx from 'clsx'
import { FastField, useFormik } from 'formik'

import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

interface EditModalProps {
    OrganizationId: any
    fetchOrganizationsList(): any;
    showHideModal: any;
    showHideModelFunction(): any;
    editorgData: any;
}

const ExternalOrganizationEditModal: FC<EditModalProps> = ({ OrganizationId, fetchOrganizationsList, showHideModal, showHideModelFunction, editorgData }) => {
    const [getspinner, setspinner] = useState(false)
    const [getIndustry, setIndustry] = useState({});
    const [getIndustries, setIndustries] = useState<any[]>([]);

    const OrganizationsModalSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(50, 'Maximum 100 characters')
            .required('Name is required'),
        address: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 200 characters')
            .required('Address is required'),
        industry_id: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
            })
            .required('Industry is required')
    })
    const formik : any = useFormik({
        initialValues: {
            name: '',
            address: '',
            industry_id: {}
        },
        validationSchema: OrganizationsModalSchema,
        onSubmit: async (values) => {

            try {

                let formData: any = await {
                    name: values.name,
                    address: values.address,
                    industry_id: getIndustry,
                }

                const config = {
                    headers: { Authorization: BearerToeken }
                };
                await axios.put(
                    process.env.REACT_APP_API_URL + '/external/organizations/' + OrganizationId,
                    formData,
                    config
                )
                    .then(async (res) => {

                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                            setspinner(false)
                            showHideModelFunction()
                            fetchOrganizationsList()
                        } else {
                            setspinner(false)
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    })
                    .catch((error) => {
                        setspinner(false)
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });

                    });

            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)

            }
        },
    })

    const submitErrorCheck = () => {
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    const getIndustriesData = () => {
      axios.get(process.env.REACT_APP_BASE_API_URL + 'external/industries/list')
        .then(async (res) => {
          setspinner(false)
          setIndustries(res.data)
          // optionsOrganizations = res.data
        })
        .catch(error => {
          
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
  
          setspinner(false)
        })
    }

    const loadData = () => {
        if (editorgData) {
            formik.values.name = editorgData.name;
            formik.values.address = editorgData.address?editorgData.address:'';
            if(editorgData.industry_id){
                var orgObj = {
                    label: editorgData.industry_id['name'],
                    value: editorgData.industry_id['_id'],
                }
                // console.log('orgObj=',orgObj,JSON.stringify(orgObj))
                formik.values.organization = orgObj;
                setIndustry(orgObj)
            } else {
                setIndustry({})
            }
            // console.log('editorgData=',editorgData)
        }
    }

    const handleChange = (
      newValue: any,
      actionMeta: ActionMeta<any>
    ) => {
      setIndustry(newValue)
    };

    useEffect(() => {
        getIndustriesData();
        loadData();
    }, [editorgData])

    return (
        <>
            <ToastContainer />
            <div>
                <Modal
                    className='modal-sticky custom_modal modal-sticky-lg modal-sticky-bottom-right'
                    id='kt_inbox_compose'
                    role='dialog'
                    data-backdrop='false'
                    aria-hidden='true'
                    tabIndex='-1'
                    show={showHideModal}
                    animation={false}
                >
                    <div
                        className='modal fade show d-block'
                        id='kt_modal_add_role'
                        role='dialog'
                        tabIndex={-1}
                        aria-modal='false'
                    >
                        <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                            <div className="modal-content bg-white">
                                <div className="modal-header bg-primary">
                                    <h2 className="fw-bolder text-white"> Edit Organization </h2>
                                    <div
                                        className='btn btn-icon btn-sm btn-active-icon-primary'
                                        data-kt-users-modal-action='close'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { showHideModelFunction() }}
                                    >
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                </div>
                                <form id="kt_modal_edit_organization_form" className="form" onSubmit={formik.handleSubmit} noValidate >
                                    <div className='modal-body scroll-y pb-2'>
                                        <div className='row'>
                                        <div className='col-sm-6 mb-7'>
                                            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2 text-gray-900">
                                                <span className="fw-bolder fs-7">Name</span>
                                            </label>
                                            <input
                                                placeholder='Name'
                                                {...formik.getFieldProps('name')}
                                                type='text'
                                                name='name'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    { 'is-invalid': formik.touched.name && formik.errors.name },
                                                    {
                                                        'is-valid': formik.touched.name && !formik.errors.name,
                                                    }
                                                )}

                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.name}</span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block fs-8">

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6 mb-7'>
                                            <label className='fw-bolder fs-6 mb-2'>Industry</label>
                                            <Select
                                            isClearable
                                            onChange={handleChange}
                                            // onInputChange={handleInputChange}
                                            options={getIndustries}
                                            name='industry_id'
                                            value={getIndustry}
                                            />
                                            {formik.touched.industry_id && formik.errors.industry_id && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.industry_id}</span>
                                            </div>
                                            )}
                                        </div>
                                        <div className='col-sm-12 mb-7'>
                                            <label className='fw-bolder fs-6 mb-2'>Address</label>
                                            <input
                                            {...formik.getFieldProps('address')}
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                { 'is-invalid': formik.touched.address && formik.errors.address },
                                                {
                                                'is-valid': formik.touched.address && !formik.errors.address,
                                                }
                                            )}
                                            type='address'
                                            name='address'
                                            autoComplete='off'
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.address}</span>
                                            </div>
                                            )}
                                        </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer bg-light flex-row-reverse">
                                        <button type="submit" className="btn btn-primary btn-primary" onClick={() => submitErrorCheck()}>
                                            {!getspinner && (
                                                <span className='indicator-label'>Save</span>
                                            )}
                                            {/* Submit */}
                                            {getspinner && (
                                                <span >
                                                    Please wait...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                        <button type="reset" className="btn btn-white" data-bs-dismiss="modal" onClick={() => { showHideModelFunction() }}> Cancel </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </Modal>

            </div>

        </>
    )
}

export { ExternalOrganizationEditModal }
