/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { FC, useState, useEffect } from 'react'
import { KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import { DefaultTitle } from '../header/page-title/DefaultTitle'
import { Routes, Route, useParams } from "react-router-dom";
import { includes } from 'lodash'
import axios from 'axios'
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const Toolbar1: FC = () => {
  const { classes } = useLayout()
  let params: any = useParams();
  const dashboardID = params.dashboardId;
  var workspaceId: any = params.workspaceId;
  const [editViewMode, iseditViewMode] = useState<boolean>(false)
  const [goodDataMode, isgoodDataMode] = useState<boolean>(false)
  useEffect(() => {

    // console.log('params',params['*'])
    let paramData = params['*']
    if (paramData.includes("gooddata/")) {
      // console.log('not exist')
      isgoodDataMode(true)
    } else {
      isgoodDataMode(false)
    }
    // console.log(paramData,"fsafsa")
    if (!paramData.includes("edit")) {
      // console.log('not exist')
      iseditViewMode(false)
    } else {
      iseditViewMode(true)
    }


  }, []);
  const deleteDashboardFunction = async() => {
    const config = {
      headers: { Authorization: BearerToeken }
    };

    // var workSpaceId : any = {
    //   id:workspaceId
    // }
    // console.log('workSpaceId',workSpaceId)
    await axios.delete(
      process.env.REACT_APP_API_URL + '/dashboard/' +dashboardID ,
      config
    )
      .then(async (res) => {
        // console.log('res',res.data)
      })
      .catch((error) => {
        console.log(error)
      });
  }
  return (
    <></>
    // <div className='toolbar' id='kt_toolbar' style={{ zIndex: 120 }}>
    //   {/* begin::Container */}
    //   <div
    //     id='kt_toolbar_container'
    //     className={clsx(classes.toolbarContainer.join(' '), 'd-flex')}

    //   >
    //     {goodDataMode ?
    //       <span>{editViewMode ?
    //         <a
    //           href={'/gooddata/' + workspaceId + "/" + dashboardID}
    //           className='btn btn-sm btn-primary'
    //           data-bs-toggle='modal'
    //           data-bs-target='#kt_modal_create_app'
    //           id='kt_toolbar_primary_button'
    //         >
    //           back
    //         </a>
    //         :
    //         <a
    //           href="/"
    //           className='btn btn-sm btn-primary'
    //           data-bs-toggle='modal'
    //           data-bs-target='#kt_modal_create_app'
    //           id='kt_toolbar_primary_button'
    //         >
    //           back
    //         </a>
    //       }

    //       </span>

    //       :
    //       <div></div>

    //     }

    //     {/* <div  > */}
    //     <DefaultTitle />
    //     {/* </div> */}

    //     {/* begin::Actions */}
    //     <div className='w-100 d-flex flex-row-reverse'>
    //       {/* begin::Wrapper */}
    //       <div className='me-4'>
    //         {/* begin::Menu */}
    //         {/* <a
    //           href='#'
    //           className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
    //           data-kt-menu-trigger='click'
    //           data-kt-menu-placement='bottom-end'
    //           data-kt-menu-flip='top-end'
    //         >
    //           <KTSVG
    //             path='/media/icons/duotune/general/gen031.svg'
    //             className='svg-icon-5 svg-icon-gray-500 me-1'
    //           />
    //           Filter
    //         </a> */}

    //         {/* end::Menu */}
    //       </div>
    //       {/* end::Wrapper */}

    //       {/* begin::Button */}
    //       {/* {editViewMode &&
            
    //        } */}
    //       {goodDataMode ?

    //         <div>{editViewMode ?
    //           <span>
    //             <a

    //               className='btn btn-sm btn-primary'
    //               onClick={() => { deleteDashboardFunction() }}
    //             // data-bs-toggle='modal'
    //             // data-bs-target='#kt_modal_create_app'
    //             // id='kt_toolbar_primary_button'
    //             >
    //               Share
    //             </a>
    //             <a
    //               style={{ marginLeft: '10px' }}
    //               href={'/gooddata/' + workspaceId + "/" + dashboardID}
    //               className='btn btn-sm btn-primary'
    //               data-bs-toggle='modal'
    //               data-bs-target='#kt_modal_create_app'
    //               id='kt_toolbar_primary_button'
    //             >
    //               View
    //             </a>
    //           </span>



    //           :



    //           <a
    //             href={'/gooddata/' + workspaceId + "/" + dashboardID + '/edit'}
    //             className='btn btn-sm btn-primary'
    //             data-bs-toggle='modal'
    //             data-bs-target='#kt_modal_create_app'
    //             id='kt_toolbar_primary_button'
    //           >
    //             Edit
    //           </a>


    //         }</div>
    //         :
    //         <div></div>

    //       }




    //       {/* end::Button */}
    //     </div>
    //     {/* end::Actions */}
    //   </div>
    //   {/* end::Container */}
    // </div>
  )
}
{/* <style>
 .toolbar-fixed .toolbar{
  
 }
</style> */}

export { Toolbar1 }
