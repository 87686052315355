/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { isNotEmpty, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link, useParams, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import * as getHelpers from '../../modules/auth/GetHelpers'
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';
import { ComponentLoading } from '../../modules/ComponentLoading'
import OrganizationDetailHeader from '../../pages/customer-organizations/OrganizationDetailHeader'

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToken = `Bearer ${ApiToken}`
interface PageProps {
    //   organisationUsersId: any;
}
let OrgId: any;
const OrganizationDetailEditPage: FC<PageProps> = () => {

    const [getOrganizationData, setOrganizationData]: any = useState<any>({});
    let { organizationId }: any = useParams();
    const [getComponentLoading, setComponentLoading] = useState(true)
    const [getAvatarComponentLoading, setAvatarComponentLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false)

    const [getspinner, setspinner] = useState(false)
    const [getIndustries, setIndustries] = useState<any[]>([]);
    const [getIndustry, setIndustry] = useState({});

    const [getInitialValues, setInitialValues] = useState<any>()
    const [image, setImage] = useState('')

    const ApiToken = localStorage.getItem('api_token')
    const BearerToeken = `Bearer ${ApiToken}`

    const organizationURL = `${API_URL}/internal/organizations/` + organizationId

    const [OrganizationInitialValues, setOrganizationInitialValues] = useState({
        name: '',
        address: '',
        industry_id: {},
        avatar: '',
        avatar_id: ''
    })

    const OrganizationsModalSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(50, 'Maximum 100 characters')
            .required('Name is required'),
        // address: Yup.string()
        //     .min(2, 'Minimum 2 characters')
        //     .max(100, 'Maximum 200 characters')
        //     .required('Address is required'),
        industry_id: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        })
            .required('Industry is required')
    })

    const formik: any = useFormik({
        initialValues: OrganizationInitialValues,
        // initialValues: getInitialValues,
        validationSchema: OrganizationsModalSchema,
        onSubmit: async (values) => {

            try {

                // let formData: any = {
                //     name: values.name,
                //     address: values.address,
                //     industry_id: getIndustry,
                // }

                let formData: any = OrganizationInitialValues
                formData.name = values.name
                formData.address = values.address
                formData.industry_id = getIndustry

                updateOrganizationContact(formData)

            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)

            }
        },
    })

    const updateOrganizationContact = (formData: any) => {
        axios.put(
            process.env.REACT_APP_API_URL + '/internal/organizations/' + organizationId,
            formData,
            { headers: { Authorization: BearerToeken } }
        )
            .then(async (res) => {
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    setspinner(false)
                    window.dispatchEvent(new CustomEvent('dispatchOrganization', {
                        detail: {
                            value: true,
                            data: res.data
                        }
                    }))
                    setTimeout(() => {
                        navigate("/internal/organizations/" + organizationId);
                    }, 1500);
                } else {
                    setspinner(false)
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
            })
            .catch((error) => {
                setspinner(false)
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });

            });
    }

    const fetchOrganization: any = () => {
        const config = {
            headers: { Authorization: BearerToken }
        };
        const organizationsList = axios.get(
            organizationURL,
            config
        ).then(async (res: any) => {
            const organizationRes = res.data.organization
            if (organizationRes.name) {
                formik.initialValues.name = organizationRes.name
            }
            if (organizationRes.address) {
                formik.initialValues.address = organizationRes.address
            }
            if (organizationRes.industry_id) {
                var orgObj = {
                    label: organizationRes.industry_id['name'],
                    value: organizationRes.industry_id['_id'],
                    _id: organizationRes.industry_id['_id'],
                }
                setIndustry(orgObj)
            }
            if (organizationRes.avatar_id) {
                setImage(getUserInfo.domainUrl() + organizationRes.avatar_id.file_path)
            }
            setOrganizationData(organizationRes)
            setComponentLoading(false)
            setAvatarComponentLoading(false)
            return setOrganizationData;
        }
        ).catch((error) => {
            console.log('error=', error)
        });
        return organizationsList
    }

    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        // console.group();
        setIndustry(newValue)
        // console.log('handleChange getIndustry=',getIndustry)
        // console.groupEnd();
    }

    const submitErrorCheck = () => {
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    const getIndustriesData = () => {
        axios.get(process.env.REACT_APP_BASE_API_URL + 'internal/industries/list')
            .then(async (res) => {
                setspinner(false)
                setIndustries(res.data)
                // optionsOrganizations = res.data
            })
            .catch(error => {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });

                setspinner(false)
                // fetchorganizationsList();
            })
    }

    const onImageChange = (event: any) => {
        setAvatarComponentLoading(true)
        let fileSize = event.target.files[0]['size'];
        if (fileSize > 1060322) {
            toast.error("Max 1MB photo is allowed ", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
            setAvatarComponentLoading(false);
        } else {
            if (event.target.files && event.target.files[0]) {
                let img = event.target.files[0];
                if (img['type'] != 'image/jpeg' && img['type'] != 'image/jpg' && img['type'] != 'image/png') {
                    toast.error("Allowed file types: png, jpg, jpeg.", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                } else {
                    var formdata = new FormData();
                    formdata.append("avatar", event.target.files[0]);
                    uploadAvatar(formdata)
                }
            }
        }
    }

    const uploadAvatar = (data: any) => {
        axios.post(process.env.REACT_APP_BASE_API_URL + 'organization/avatar', data)
            .then(async (res) => {
                setLoading(false)
                setAvatarComponentLoading(false)
                if (res.data.status) {
                    // console.log('res.data.file_path=', res.data['file_path'])
                    // console.log('res.data.upload_id=', res.data['upload']['_id'])
                    let obj = OrganizationInitialValues;
                    // console.log('before',obj)
                    setImage(getUserInfo.domainUrl() + res.data['file_path'])
                    obj['avatar'] = getUserInfo.domainUrl() + res.data['file_path'];
                    obj['avatar_id'] = await res.data.upload._id;

                    setOrganizationInitialValues(obj)

                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });

                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                setAvatarComponentLoading(false)
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setLoading(false)
                // fetchorganizationsList();
            })
    }

    useEffect(() => {
        const config = {
            headers: { Authorization: BearerToken }
        };
        axios.get(
            GET_USER_BY_ACCESSTOKEN_URL,
            config
        )
            .then(async (res) => {
                if (res.data.status) {
                    if (res.data.userType == 'external') {
                        navigate('/error/404')
                    }
                } else if (res.data.userType == 'User type not found') {
                    if (getUserInfo.userType() == 'external') {
                        navigate('/error/404')
                    }
                }
            })
            .catch((error) => {
                console.clear()
                navigate('/error/404')
            });

        getIndustriesData();
        fetchOrganization()
    }, [])

    return (
        <>

            <ToastContainer></ToastContainer>

            <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">{getOrganizationData.name} Organization</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                        </li>
                        <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                        <li className="breadcrumb-item text-muted">
                            <Link to={'/internal/organizations'} className="text-muted text-hover-primary">Organizations</Link>
                        </li>
                        <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                        <li className="breadcrumb-item text-muted">Edit</li>
                    </ul>
                </div>
            </div>

            <OrganizationDetailHeader></OrganizationDetailHeader>

            <div className='card mb-5 mb-xl-10'>

                {
                    getComponentLoading ?
                        (
                            <ComponentLoading></ComponentLoading>
                        ) : ('')
                }

                <div
                    className='card-header cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Edit</h3>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>

                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9 border-0'>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Avatar</label>
                                <div className='col-lg-8'>
                                    <div
                                        className='image-input image-input-outline'
                                        data-kt-image-input='true'
                                        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                                    >
                                        {
                                            getAvatarComponentLoading ?
                                                (
                                                    <ComponentLoading></ComponentLoading>
                                                ) : ('')
                                        }
                                        <div
                                            className='image-input-wrapper w-125px h-125px'
                                            style={{ backgroundImage: `url(${image})` }}
                                        ></div>

                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                            <i className="bi bi-pencil-fill fs-7"></i>
                                            <input
                                                onChange={onImageChange}
                                                type="file"
                                                name="avatar"
                                                accept=".png, .jpg, .jpeg"
                                            />
                                            <input type="hidden" name="avatar_remove" />
                                        </label>

                                    </div>

                                    <div className="form-text text-gray-900">Allowed file types: png, jpg, jpeg.</div>

                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Name</label>

                                <div className='col-lg-8'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                placeholder='Name'
                                                {...formik.getFieldProps('name')}
                                                type='text'
                                                name='name'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    { 'is-invalid': formik.touched.name && formik.errors.name },
                                                    {
                                                        'is-valid': formik.touched.name && !formik.errors.name,
                                                    }
                                                )}
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.name}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Industry</label>

                                <div className='col-lg-8 fv-row'>
                                    <Select
                                        className='customSelectDropdown'
                                        isClearable
                                        onChange={handleChange}
                                        // onInputChange={handleInputChange}
                                        options={getIndustries}
                                        name='industry_id'
                                        value={getIndustry}
                                        theme={(theme) => ({
                                            ...theme,
                                            // borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'orangered',
                                                primary25: '#f6f6f9',
                                                primary: '#793CFB',
                                            },
                                        })}
                                    />
                                    {formik.touched.industry_id && formik.errors.industry_id && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.industry_id}</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Address</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        {...formik.getFieldProps('address')}
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            { 'is-invalid': formik.touched.address && formik.errors.address },
                                            {
                                                'is-valid': formik.touched.address && !formik.errors.address,
                                            }
                                        )}
                                        type='address'
                                        name='address'
                                        autoComplete='off'
                                    />
                                    {formik.touched.address && formik.errors.address && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.address}</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type="submit" className="btn btn-primary btn-primary" onClick={() => submitErrorCheck()}>
                                {!getspinner && (
                                    <span className='indicator-label'>Save</span>
                                )}
                                {/* Submit */}
                                {getspinner && (
                                    <span >
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>

                </div>
            </div>

        </>
    )

}

export { OrganizationDetailEditPage }
