import React, { FC, useEffect, useState } from 'react'
import { Routes, Route, useParams } from "react-router-dom";
import * as _ from "lodash";
import axios from 'axios';

const DashboardTitle: FC = () => {

  let params = useParams();
  const dashboardID = params.dashboardId;
  const ApiToken = localStorage.getItem('api_token')
  const BearerToeken = `Bearer ${ApiToken}`
  const [getDashboards, setdashboards] = useState<any[]>([]);
  useEffect(() => {
    const getDashboardsData = async () => {
      const config = {
        headers: { Authorization: BearerToeken }
      };
      await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/dashboard/' + dashboardID,
        config
      )
        .then(async (res) => {
          setdashboards(res.data.name)
        })
        .catch((error) => {
          console.log(error)
        });
    };

    getDashboardsData()


  }, []);

  return (
    <>

      <span className='fw-bolder'>{getDashboards}</span>
      
    </>
  )
}

export { DashboardTitle }
