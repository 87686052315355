import clsx from 'clsx'
import React, { FC, useState, useEffect } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search } from '../../../partials'
import { useLayout } from '../../core'
import { useNavigate, Link, useParams } from 'react-router-dom'
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import axios from 'axios'
import { getFactory } from "@gooddata/api-client-bear";
import Dropdown from 'react-bootstrap/Dropdown';
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from 'react-router-dom'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionCreators } from '../../../../redux/actions/profile';
import { ActionPermissions } from '../../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../../redux/actions/externalPermissions';
import { ActionExternalWorkspacePermissions } from '../../../../redux/actions/externalWorkspacePermissions';
import { ActionRoles } from '../../../../redux/actions/roles';
import {store} from '../../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
// let userLocalStorageData : any = localStorage.getItem('userinfo');
// userLocalStorageData = JSON.parse(userLocalStorageData)
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'
let currentActiveOrgName: any = ''
let currentActiveOrgId: any = ''

const Topbar: FC = () => {

  let { organisationUsersId } = useParams();

  const { config } = useLayout()
  const [getAllWorkspace, setAllWorkspace] = useState<any[]>([]);
  const [getAllOrganizations, setAllOrganizations] = useState<any[]>([]);
  const [getActiveOrganizationId, setActiveOrganizationId] = useState<any>('');
  const [getActiveOrganizationName, setActiveOrganizationName] = useState<any>('');

  const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
  const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
  const [getExternalWorkspacePermissions, setUserAllExternalPermissions] = useState<any[]>([]);
  const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);
  const [getKey, setKey] = useState<any>(0);


  const dispatch = useDispatch();
  
  const fetchVerifyAccess = async () => {
    const API_URL = process.env.REACT_APP_API_URL
    const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
    const ApiToken = localStorage.getItem('api_token')
    const BearerToken = `Bearer ${ApiToken}`
    const config = {
      headers: { Authorization: BearerToken }
    };
    axios.get(
        GET_USER_BY_ACCESSTOKEN_URL,
        config
    )
      .then(async (res) => {
        if (res.data.status) {
          if(res.data.internalPermissions){
            if(res.data.internalPermissions.permissions){
              dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
              setUserInternalPermissions(res.data.internalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.externalPermissions){
            // console.log('res.data.externalPermissions=',res.data.externalPermissions)
            if(res.data.externalPermissions.permissions){
              // getWPermissions = res.data.externalPermissions.permissions
              var data = getHelpers.doesHasPermissions(res.data.externalPermissions.permissions,organisationUsersId)
              dispatch(ActionExternalPermissions.externalUserPermissions(data))
              dispatch(ActionExternalWorkspacePermissions.externalWorkspacePermissions(res.data.externalPermissions.permissions))
              setUserExternalPermissions(data)
              setUserAllExternalPermissions(res.data.externalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.internalRoles){
            if(res.data.internalRoles.roles){
              dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
              setUserInternalRoles(res.data.internalRoles.roles)
              setKey(getKey+1)
            }
          }
        }
      })
      .catch((error) => {});
  }

  const navigate = useNavigate();
  const findOrgId = async (data: any) => {
    let userData: any = await localStorage.getItem('userinfo');
    userData = JSON.parse(userData);
    let currentOrgId = ''
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id == userData.workspace_id) {
        currentOrgId = data[i].name;
        currentActiveOrgId = data[i]._id
      }
    }
    return currentOrgId;
  }
  const fetchAllWorkspaces = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/workspaces",
    )
      .then(async (response) => {
        if (response.data) {
          currentActiveOrgName = await findOrgId(response.data.workspaces);
          setAllWorkspace(response.data.workspaces)
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };
  const fetchAllOrganizations = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/organizations",
    )
      .then(async (response) => {
        if (response.data) {
          // currentActiveOrgName = await findOrgId(response.data.workspaces);
          setAllOrganizations(response.data.organizations)
          if (response.data.activeOrganization) {
            setActiveOrganizationId(response.data.activeOrganization._id)
            setActiveOrganizationName(response.data.activeOrganization.name)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const getMe = () => {
    axios.get(process.env.REACT_APP_BASE_API_URL + "me").then(async (res) => {
      let responseData = await res.data
      // console.log('responseData',responseData.role)
      if (responseData.role) {
        // setExternalRole(responseData.role.label);
        let localStorageUserData: any = await localStorage.getItem('userinfo');
        localStorageUserData = await JSON.parse(localStorageUserData);
        localStorageUserData.role.label = responseData.role.label;
        localStorageUserData.first_name = responseData.first_name;
        localStorageUserData.last_name = responseData.last_name;
        localStorageUserData.email = responseData.email;
        localStorageUserData = await JSON.stringify(localStorageUserData)
        await localStorage.setItem('userinfo', localStorageUserData)
        // getUserInfo.roleLabel()/
      }
    }).catch(error => {
      console.log(error)
    });
  };
  useEffect(() => {
    fetchVerifyAccess()
    // console.log('permissions=',getUserExternalPermissions)
    // console.log('store.getState().userData.permissions1=',store.getState().userExternalData.externalPermissions)
    getMe()
    fetchAllWorkspaces()
    fetchAllOrganizations()

  }, []);

  const switchWorkspace = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/workspace/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
        window.location.reload();
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }

      // }, 2000);
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }

  const switchOrganization = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/organization/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
        window.location.reload();
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }

      // }, 2000);
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }

  const createDashboard = () => {
    // console.log('data[i].organisation_id.name',currentActiveOrgId)
    // let routePath = `external/dashboard/create/${currentActiveOrgId}`
    let routePath = `external/dashboard/create`
    navigate(routePath)
  }
  const chartFunc = (value: any) => {
    // console.log('value=', value.charAt(0))
    let chatAt_value = value.charAt(0);
    return chatAt_value;
  }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* <ToastContainer /> */}

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >

        {/* <Link
          to={'/users/637ba51d15e20e069f5f6d1c'}
        >
        637ba51d15e20e069f5f6d1c
        </Link>
        <Link
          to={'/users/63a9d75dc2e23209602cbfb6'}
        >
        63a9d75dc2e23209602cbfb6
        </Link> */}

        <div className='hideShowButtonsTopBar'>
          {
            getUserInfo.userType() == 'external' ?
            // _.includes(store.getState().userExternalData.externalPermissions, 'manage_workspaces') ?
            // _.includes(getHelpers.doesHasPermissions(getExternalWorkspacePermissions,organisationUsersId),'manage_organizations_dashboards')?
              <button onClick={() => { createDashboard() }} className='btn btn-md fw-bolder btn-primary d-flex align-items-center'>
                {/* <i className="fa fa-plus fs-18 me-2"></i> */}
                <span className="svg-icon svg-icon-muted svg-icon-18">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
                  </svg>
                </span>
                Create Dashboard
              </button>
              :
              ('')
          }
        </div>

        {/* {
          _.includes(store.getState().userExternalData.externalPermissions,'view_workspaces')
          // getUserInfo.userType() == 'external'
          ?
          <Dropdown style={{ marginLeft: '10px' }} className="hideShowButtonsTopBar">
            {currentActiveOrgName != ''
              ?
              <Dropdown.Toggle id="dropdown-basic" className='d-flex align-items-center'>
                <i className="fa fa-university fs-18 me-2"></i>
                <span className='fw-bolder'>{currentActiveOrgName}</span>
              </Dropdown.Toggle>
              :
              <Dropdown.Toggle id="dropdown-basic">
                Select Workspace
              </Dropdown.Toggle>
            }
            <Dropdown.Menu>
              <Dropdown.Item href="#/workspaces">View All Workspaces</Dropdown.Item>
              {getAllWorkspace.map((objWorkspace: any, index) => (
                <Dropdown.Item key={index} onClick={() => switchWorkspace(objWorkspace._id)}
                  className={
                    `nav-link text-active-primary me-6  ` +
                    (currentActiveOrgId == objWorkspace._id && 'active')
                  }
                >
                  {objWorkspace.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          :
          ('')
        } */}

        {
          // _.includes(store.getState().userExternalData.externalPermissions,'view_workspaces')?

          getAllOrganizations.length > 0?

          <Dropdown style={{ marginLeft: '10px' }} className="hideShowButtonsTopBar btn-outline">
            {getActiveOrganizationName != ''
              ?
              <Dropdown.Toggle id="dropdown-basic" className='d-flex align-items-center'>
                {/* <i className="fa fa-university fs-18 me-2"></i> */}
                <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"/>
                    <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"/>
                  </svg>
                  </span>
                  <span className='fw-bolder'>{getActiveOrganizationName}</span>
                </Dropdown.Toggle>
                :
                <Dropdown.Toggle id="dropdown-basic">
                  Select Organization
                </Dropdown.Toggle>
              }
              <Dropdown.Menu>
                {/* <Dropdown.Item href="#/organizations">View All Organizations</Dropdown.Item> */}
                {getAllOrganizations.map((object: any, index) => (
                  <Dropdown.Item key={index} onClick={() => switchOrganization(object._id)}
                    className={
                      `nav-link text-active-primary me-6  ` +
                      (getActiveOrganizationId == object._id && 'active')
                    }
                  >
                    {object.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            :
            ('')
        }

        <div
          className={clsx('d-flex align-items-center cursor-pointer symbol symbol-30px symbol-md-40px', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          style={{ marginLeft: '10px' }}
        >

          <span className="mx-3 pt-1">
            <p className="hideDesignationMobile text-gray-900 pl-2 fs-7 mb-0 line-height-16">
              {
                getUserInfo.info() ?
                  (
                    <span className="fw-bolder">
                      {
                        getUserInfo.fullName() ?
                          (
                            <span>{getUserInfo.fullName()}</span>
                          ) : (
                            <span>{getUserInfo.email()}</span>
                          )
                      }
                    </span>
                  ) : (
                    <span className="fw-bolder">Profile</span>
                  )
              }

              <br />
              <span className="text-gray-900 fs-8 text-capitalize">
                <span>
                  {
                    getUserInfo.userType() === 'internal' ?
                      (
                        <>
                          <span>{getUserInfo.userType()}</span>
                          {' ' + getUserInfo.roleLabel()}
                        </>
                      ) : ('')
                  }
                </span>
              </span>
            </p>
          </span>
          {
            getUserInfo.userAvatar() ?
              (
                <div className="symbol symbol-circle symbol-50px overflow-hidden avatarIconSetting" data-bs-toggle="tooltip" data-kt-initialized="1">
                  <div>
                    <span className="topbarAvatar symbol-label text-inverse-warning fw-bolder text-uppercase">
                    <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(getUserInfo.fullName()) + " img-alt-profile"} src={getUserInfo.domainUrl() + getUserInfo.userAvatar()} alt={chartFunc(getUserInfo.fullName())} />
                      {/* <img className='w-100' alt='Logo' src={getUserInfo.domainUrl() + getUserInfo.userAvatar()}
                       /> */}
                    </span>
                  </div>
                </div>
              )
              : getUserInfo.fullName() ?
                (
                  <>
                    <div className="symbol symbol-circle symbol-50px overflow-hidden avatarIconSetting" data-bs-toggle="tooltip" data-kt-initialized="1">
                      <div>
                        <span className="topbarAvatar symbol-label text-inverse-warning fw-bolder text-uppercase bg-primary fs-4">
                          {getUserInfo.fullName().charAt(0)}
                        </span>
                      </div>
                    </div>
                  </>
                )
                :
                (
                  <>
                    <div className="symbol symbol-circle symbol-50px overflow-hidden avatarIconSetting" data-bs-toggle="tooltip" data-kt-initialized="1">
                      <div>
                        <span className="topbarAvatar symbol-label text-inverse-warning fw-bolder text-uppercase bg-primary fs-4">
                          {getUserInfo.email().charAt(0)}
                        </span>
                      </div>
                    </div>
                  </>
                )
          }
        </div>
        <HeaderUserMenu />

        {/* {config.header.left === 'menu' && (
          <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_header_menu_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
            </div>
          </div>
        )} */}

      </div>

    </div>
  )
}

// export { Topbar }
export default connect(mapStateToProps)(Topbar);