
import { FC, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { WorkspaceUserEditModal } from './WorkspaceUserEditModel'
import { useParams, useNavigate } from "react-router-dom";
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../modules/auth/GetHelpers'
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { initialUser, User } from './OrganizationValidationModal'
import clsx from 'clsx'
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useDetectClickOutside } from 'react-detect-click-outside';

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../redux/actions/externalPermissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const OrganizationsListUrl = `${API_URL}/internal/customers`

interface ModalProps {
    fetchWorkspaceUsersList(): any
}

const ExternalWorkspaceAddUserModal: FC<ModalProps> = ({ fetchWorkspaceUsersList }) => {

    let { organisationUsersId }: any = useParams();
    const [getComponentLoading, setComponentLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    // const { setItemIdForUpdate } = useListView()
    // const { refetch } = useQueryResponse()
    const [activeInactive, setActiveInactive] = useState(true)
    const [getResendInvite, setResendInvite] = useState(true)
    const [getfname, setfname] = useState('')
    const [getlname, setlname] = useState('')
    const [getspinner, setspinner] = useState(false)
    const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);
    const [getRenderKey, setRenderKey] = useState<any>(0);
    const [getContacts, setContacts] = useState<any[]>([]);
    const [getFilteredContacts, setFilteredContacts] = useState<any[]>([]);
    const [getFilteredUserData, setFilteredUserData] = useState<any>({});
    const [getFilteredResponse, setFilteredResponse] = useState<any>({});
    // const [getShowUserInvite, setShowUserInvite] = useState<any>(true);
    const [getSelectedEmail, setSelectedEmail] = useState<any>("");
    const [searchInput, setSearchInput] = useState<any>("");
    // const [getCustomErrors, setCustomErrors] = useState<any>({});
    const [getDisableBtn, setDisableBtn] = useState<any>(true)
    const [getCloseList, setCloseList] = useState<any>(true)
    var [initialFormValues, setInitialFormValues] = useState<any>({
        role: '',
        first_name: '',
        last_name: '',
        job_title: '',
        password: '',
        confirm_password: '',
        email: '',
        invite: '',
        resend_invite: '',
    })


    const closeDropdown = () => {
        setCloseList(true)
        setFilteredContacts([])
    }

    const ref = useDetectClickOutside({ onTriggered: closeDropdown });

    const showHideModel: any = () => {
        setShowModal(!setShowModal)
        setFilteredResponse({})
        setFilteredUserData({})
        setFilteredContacts([])
    }
    const internalRolePermissionList = () => {
        const internalRolesPermissionsUrl = `${API_URL}/external/roles-permissions`

        const config = {
            headers: { Authorization: BearerToeken }
        };
        const internalRolesList = axios.get(
            internalRolesPermissionsUrl,
            config
        ).then(async (res) => {
            // const { data: rolespermissionsDataResult } = res
            // console.log('res.data=',res.data)
            res.data.forEach((element: any) => {
                if (element.name == 'admin') {
                    initialFormValues.role = element._id
                    setInitialFormValues(initialFormValues)
                }
            });
            return setinternalRolesPermissionsListResult(res.data)
        }
        ).catch(console.log);
        return internalRolesList

    }
    const handleFNameChange = (event: any) => {
        setfname(event.target.value);
    }
    const handleLNameChange = (event: any) => {
        setlname(event.target.value);
    }
    const handleRoleChange = (event: any) => {
        // console.log('in form')
        // setGetRole(event.target.value);
        // console.log('formik.values.role',formik.values.role)
        formik.values.role = event.target.value;
        // console.log('formik.values.role1',formik.values.role)
    }

    const addWorkspaceUserSchema = Yup.object().shape({
        // email: Yup.string()
        //     .email('Wrong email format')
        //     .required('Email is required'),
        password: !activeInactive ? Yup.string()
            .min(8, 'Minimum 8 characters')
            .max(50, 'Maximum 8 characters')
            .matches(
                /^(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                'Need one special character'
            )
            .required('Password is required') : Yup.string(),
        confirm_password: !activeInactive ? Yup.string()
            .min(8, 'Minimum 8 characters')
            .max(50, 'Maximum 8 characters')
            .required('Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match') : Yup.string(),
        job_title: !activeInactive ? Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            // .required('First name is required') 
            : Yup.string(),
        first_name: !activeInactive ? Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('First name is required') : Yup.string(),
        last_name: !activeInactive ? Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('Last name is required') : Yup.string(),
        role: Yup.string().required('Role is required')
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            // refetch()
        }
        // setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema: addWorkspaceUserSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {

                // if(!_.isEmpty(getCustomErrors)){
                //     console.log('getCustomErrors2=',getCustomErrors)
                //     return;
                // } else {

                // console.log('values=',values)
                setspinner(true)

                let formData: any = {}
                formData.email = getSelectedEmail
                // formData.email = values.email
                formData.invite = activeInactive
                formData.resend_invite = getResendInvite
                formData.role = values.role
                formData.first_name = values.first_name
                formData.last_name = values.last_name
                formData.job_title = values.job_title
                formData.password = values.password

                // const formDataObj = {
                //     email: values.email,
                //     invite: activeInactive,
                //     role: values.role,
                //     first_name: values.first_name,
                //     last_name: values.last_name,
                //     job_title: values.job_title,
                //     password: values.password
                // }

                saveWorkspaceUser(formData)

                // }


            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again!", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });


                console.error(ex)
            } finally {

                setSubmitting(true)
                cancel(true)
            }
        },
    })

    const saveWorkspaceUser = (data: any) => {
        axios.post(process.env.REACT_APP_BASE_API_URL + 'external/users/' + organisationUsersId, data)
            .then((res) => {
                setspinner(false)
                // console.log('error1=',res.data)
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                        setShowModal(false);
                        fetchWorkspaceUsersList();
                } else {

                    setShowModal(true);

                    if (res.data.errors && res.data.errors.length > 0) {
                        if (res.data.errors[0] && res.data.errors[0]['msg']) {
                            toast.error(res.data.errors[0]['msg'], {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                }
            })
            .catch(error => {
                setShowModal(true);
                // console.log('error2=',error.response.data.errors.length,error.response.data.errors[0]['msg'])
                if (error.response.data.errors && error.response.data.errors.length > 0) {
                    if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                        toast.error(error.response.data.errors[0]['msg'], {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                } else {
                    if (error.response.data.message) {
                        toast.error(error.response.data.message, {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                }
                setspinner(false)
            })
    }

    // const setCustomErrorsFunction = () => {
    //     var errorsObj : any = {}
    //     console.log('getSelectedEmail=',getSelectedEmail)
    //     if(getSelectedEmail == ''){
    //         errorsObj.email = 'email is required';
    //         setCustomErrors(errorsObj)
    //         console.log('getCustomErrors=',getCustomErrors,errorsObj)
    //         return;
    //     }
    // }

    const notify = () => {
        // setCustomErrorsFunction()
        // console.log('getCustomErrors=',getCustomErrors)
        // console.log('formik.errors=', formik.errors)
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    const addUserBtn = () => {
        fetchContacts()
    }

    const fetchContacts = () => {
        axios.get(process.env.REACT_APP_BASE_API_URL + '/contacts/' + organisationUsersId)
            .then(async (res) => {
                setspinner(false)
                setSelectedEmail('')
                setSearchInput('')
                var formatUsers = []
                for (const key in res.data.users) {
                    const element = res.data.users[key];
                    element['name'] = element.email
                    formatUsers.push(element)
                }
                setContacts(formatUsers)
                //   console.log('getContacts=',formatUsers)
                setRenderKey(getRenderKey + 1)
                setComponentLoading(false)
            })
            .catch(error => {
                setComponentLoading(false)
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)
            })
    }

    const fetchFilteredUser = (formData: any) => {
        axios.post(process.env.REACT_APP_BASE_API_URL + 'users/filter/' + organisationUsersId, formData)
            .then(async (res) => {
                setspinner(false)
                setFilteredUserData(res.data.user)
                setFilteredResponse(res.data.filtered)
                setComponentLoading(false)
            })
            .catch(error => {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)
            })
    }

    const handleChangeUsers = (e: any) => {
        e.preventDefault();

        setFilteredResponse({})

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!e.target.value) {
            // console.log("Cannot be blank");
        } else if (!regex.test(e.target.value)) {
            // console.log("Invalid email format");
            setDisableBtn(true)
        } else {
            if (getSelectedEmail != e.target.value) {
                setDisableBtn(true)
                setCloseList(false)
            }
            var formData: any = {}
            formData.email = e.target.value
            fetchFilteredUser(formData)
        }

        setSearchInput(e.target.value);
        setSelectedEmail(e.target.value);
        // console.log('e.target.value=',getSelectedEmail,e.target.value)

        if (e.target.value != '') {
            // console.log('e.target.value=',getSelectedEmail,e.target.value)
            var searchVal: any = e.target.value.toLowerCase()
            let pattern = new RegExp(`^${searchVal}`);

            var filteredData = getContacts.filter((item: any) => {
                var itemEmail = item.email.toLowerCase()
                var itemName = ''

                if (item.profile) {
                    if (item.profile.full_name) {
                        itemName = item.profile.full_name.toLowerCase()
                    } else {
                        itemName = item.full_name.toLowerCase()
                    }
                } else {
                    itemName = item.full_name.toLowerCase()
                }

                console.log('itemName and Email--', itemName, itemEmail)

                const withEmail = pattern.test(itemEmail)
                const withName = pattern.test(itemName)

                if (withEmail) {
                    return pattern.test(itemEmail)
                }
                if (withName) {
                    return pattern.test(itemName)
                }

                console.log('item--', withEmail, withName)
            });

            if (filteredData.length > 0) {
                setFilteredContacts(filteredData)
                setCloseList(false)
            } else {
                setFilteredContacts([])
            }
        }
        setActiveInactive(true)
        // setShowUserInvite(true)
    };


    const selectUserData = (data: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (regex.test(data)) {
            var formData: any = {}
            formData.email = data
            fetchFilteredUser(formData)
            if (
                getFilteredResponse.passwordSet &&
                getFilteredResponse.userExist &&
                getFilteredResponse.userInWorkspace
            ) {
                setDisableBtn(true)
            } else {
                setDisableBtn(false)
            }
            setFilteredContacts([])
            setSearchInput(data)
            setSelectedEmail(data)
            setCloseList(true)

            setActiveInactive(true)
            // setShowUserInvite(false)
            setFilteredUserData(data)

            initialFormValues.email = data
            setInitialFormValues(initialFormValues)
            // console.log('getSelectedEmail=',getSelectedEmail)
        }
    }
    ////////////

    useEffect(() => {
        internalRolePermissionList()
    }, [])

    return (
        <div className='btn_col'>
            {
                _.includes(store.getState().userExternalData.externalPermissions,'manage_users')?
                (
                    <button type="button" className="btn btn-primary btn-md fw-bolder btn-z-1500 btn-primary  my-1" onClick={() => { setShowModal(true); addUserBtn() }}>
                        {/* <i className="bi bi-person-plus-fill"></i> */}
                        <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
<rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
<rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
</svg>
                        </span>
                        <span className='btnTxt'>Create User</span>
                    </button>
                ):('')
            }

            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showModal}
                animation={false}
            >
                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        {/* begin::Modal content */}
                        <div className='modal-content'>
                            {/* <UserEditModalHeader /> */}
                            <div className='modal-header bg-primary'>
                                <h2 className='fw-bolder text-white'>Create User</h2>
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => { showHideModel() }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate autoComplete="off">
                                <div className='modal-body scroll-yx pb-2'>

                                    {
                                        getComponentLoading ?
                                            (
                                                <ComponentLoading></ComponentLoading>
                                            ) : ('')
                                    }

                                    <ToastContainer />
                                    <div
                                        className='d-flex flex-column scroll-yx me-n7 pe-7'
                                        id='kt_modal_add_user_scroll'
                                        data-kt-scroll='true'
                                        data-kt-scroll-activate='{default: false, lg: true}'
                                        data-kt-scroll-max-height='auto'
                                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                        data-kt-scroll-offset='300px'
                                    >
                                        <div className="row">
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'> Role </label>
                                                <select
                                                    {...formik.getFieldProps('role')}
                                                    name='role'
                                                    // onChange={changeRole}
                                                    // value={initialFormValues.role}
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.role && formik.errors.role },
                                                        {
                                                            'is-valid': formik.touched.role && !formik.errors.role,
                                                        }
                                                    )}
                                                >
                                                    <option value="">Select Role</option>
                                                    {internalRolesPermissionsListResult.map((role) => (
                                                        <option value={role._id}>
                                                            {role.label.charAt(0).toUpperCase() + role.label.slice(1)}
                                                        </option>
                                                    )
                                                    )}
                                                    {formik.touched.role && formik.errors.role && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert'>{formik.errors.role}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </select>
                                            </div>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Email</label>
                                                <div className="custom_autocomplete_input" ref={ref}>

                                                    <input
                                                        placeholder='Email'
                                                        className='form-control form-control-solid mb-3 mb-lg-0'
                                                        type='email'
                                                        name='email'
                                                        autoComplete='off'
                                                        onChange={handleChangeUsers}
                                                        value={searchInput}
                                                    />

                                                    {
                                                        getCloseList == false && searchInput.length > 0 ?
                                                            (
                                                                <div
                                                                    className={clsx(
                                                                        'inputWrapper',
                                                                        { 'scroll': getFilteredContacts.length > 5 }
                                                                    )}
                                                                >
                                                                    {
                                                                        getFilteredContacts.length > 0 ?
                                                                            (
                                                                                getFilteredContacts.map((item: any, index: any) =>
                                                                                    <div className='d-flex align-items-center item' key={index} onClick={() => selectUserData(item.email)}>
                                                                                        <div className='symbol symbol-circle symbol-40px overflow-hidden me-2'>
                                                                                            {
                                                                                                item.avatar_id ? (
                                                                                                    item.avatar_id['file'] ?
                                                                                                        (
                                                                                                            <span className={"symbol-label"}>
                                                                                                                <img width="100%" src={getUserInfo.domainUrl() + 'avatars/' + item.avatar_id['file']['filename']} />
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            item.profile ?
                                                                                                                (
                                                                                                                    item.profile.full_name ?
                                                                                                                        (
                                                                                                                            <span className={'symbol-label text-uppercase bg-' + item.profile.full_name.charAt(0)}>
                                                                                                                                <span className='text-white fs-6 fw-bolder'>
                                                                                                                                    {item.profile.full_name.charAt(0)}
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        ) : (
                                                                                                                            item.full_name ? (
                                                                                                                                <span className={'symbol-label text-uppercase bg-' + item.email.charAt(0)}>
                                                                                                                                    <span className='text-white fs-6 fw-bolder'>
                                                                                                                                        {item.email.charAt(0)}
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            ) : (
                                                                                                                                <span className={'symbol-label text-uppercase bg-' + item.email.charAt(0)}>
                                                                                                                                    <span className='text-white fs-6 fw-bolder'>
                                                                                                                                        {item.email.charAt(0)}
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            )
                                                                                                                        )
                                                                                                                ) : item.full_name ? (
                                                                                                                    <span className={'symbol-label text-uppercase bg-' + item.email.charAt(0)}>
                                                                                                                        <span className='text-white fs-6 fw-bolder'>
                                                                                                                            {item.email.charAt(0)}
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                ) : (
                                                                                                                    <span className={'symbol-label text-uppercase bg-' + item.email.charAt(0)}>
                                                                                                                        <span className='text-white fs-6 fw-bolder'>
                                                                                                                            {item.email.charAt(0)}
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                )
                                                                                                        )
                                                                                                ) : (
                                                                                                    item.profile ?
                                                                                                        (
                                                                                                            item.profile.full_name ?
                                                                                                                (
                                                                                                                    <span className={'symbol-label text-uppercase bg-' + item.profile.full_name.charAt(0)}>
                                                                                                                        <span className='text-white fs-6 fw-bolder'>
                                                                                                                            {item.profile.full_name.charAt(0)}
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                ) : (
                                                                                                                    item.full_name ? (
                                                                                                                        <span className={'symbol-label text-uppercase bg-' + item.email.charAt(0)}>
                                                                                                                            <span className='text-white fs-6 fw-bolder'>
                                                                                                                                {item.email.charAt(0)}
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    ) : (
                                                                                                                        <span className={'symbol-label text-uppercase bg-' + item.email.charAt(0)}>
                                                                                                                            <span className='text-white fs-6 fw-bolder'>
                                                                                                                                {item.email.charAt(0)}
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                )
                                                                                                        ) : item.full_name ? (
                                                                                                            <span className={'symbol-label text-uppercase bg-' + item.email.charAt(0)}>
                                                                                                                <span className='text-white fs-6 fw-bolder'>
                                                                                                                    {item.email.charAt(0)}
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span className={'symbol-label text-uppercase bg-' + item.email.charAt(0)}>
                                                                                                                <span className='text-white fs-6 fw-bolder'>
                                                                                                                    {item.email.charAt(0)}
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        )
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                        <div>
                                                                                            <span className='text-dark text-hover-primary fs-7 d-block'>
                                                                                                {
                                                                                                    <div>
                                                                                                        {
                                                                                                            item.profile ?
                                                                                                                (
                                                                                                                    item.profile.full_name ?
                                                                                                                        (
                                                                                                                            <div className='fw-bolder'>
                                                                                                                                {item.profile.full_name}
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            item.full_name ?
                                                                                                                                (
                                                                                                                                    <div className='fw-bolder'>
                                                                                                                                        {item.full_name}
                                                                                                                                    </div>
                                                                                                                                ) : ('')
                                                                                                                        )
                                                                                                                ) : (
                                                                                                                    item.full_name ?
                                                                                                                        (
                                                                                                                            <div className='fw-bolder'>
                                                                                                                                {item.full_name}
                                                                                                                            </div>
                                                                                                                        ) : ('')
                                                                                                                )
                                                                                                        }
                                                                                                        {item.email}
                                                                                                    </div>
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            ) : (
                                                                                searchInput ?
                                                                                    (
                                                                                        <>
                                                                                            <div onClick={() => selectUserData(searchInput)}>
                                                                                                <div className='d-flex align-items-center item'>
                                                                                                    <div className='symbol symbol-40px me-2 symbol-circle'>
                                                                                                        {
                                                                                                            <>
                                                                                                                <span className={'symbol-label text-uppercase bg-' + searchInput.charAt(0)}>
                                                                                                                    <span className='text-white fs-6 fw-bolder'>
                                                                                                                        {searchInput.charAt(0)}
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <span className='text-dark text-hover-primary fs-5 d-block'>
                                                                                                            {
                                                                                                                <>
                                                                                                                    {/* <div className='fw-bolder'>
                                                {searchInput}
                                            </div> */}
                                                                                                                    <div>
                                                                                                                        {searchInput}
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : ('')
                                                                            )
                                                                    }
                                                                </div>
                                                            ) : ('')
                                                    }

                                                </div>
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className='fv-plugins-message-container'>
                                                        <span role='alert'>{formik.errors.email}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* listhere */}

                                        {
                                            getCloseList ?
                                                (
                                                    // !getShowUserInvite &&
                                                    !getFilteredResponse.passwordSet &&
                                                        getFilteredResponse.userExist &&
                                                        !getFilteredResponse.userInWorkspace ?
                                                        (
                                                            <>
                                                                <div className="alert alert-dismissible bg-success d-flex flex-column flex-sm-row p-5 mb-10">
                                                                    <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                                                                        <h5 className="mb-2 text-white">{getFilteredUserData.full_name ? getFilteredUserData.full_name : ''}</h5>
                                                                        <span>
                                                                            {getFilteredUserData.email} {getFilteredResponse.message}
                                                                            {/* already exists in our system. We don't need to send them a new invitation. */}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : getFilteredResponse.passwordSet &&
                                                            getFilteredResponse.userExist &&
                                                            !getFilteredResponse.userInWorkspace ?
                                                            (
                                                                <>
                                                                    <div className="alert alert-dismissible bg-success d-flex flex-column flex-sm-row p-5 mb-10">
                                                                        <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                                                                            <h5 className="mb-2 text-white">{getFilteredUserData.full_name ? getFilteredUserData.full_name : ''}</h5>
                                                                            <span>
                                                                                {getFilteredUserData.email} {getFilteredResponse.message}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : getFilteredResponse.passwordSet &&
                                                                getFilteredResponse.userExist &&
                                                                getFilteredResponse.userInWorkspace ?
                                                                (
                                                                    <>
                                                                        <div className="alert alert-dismissible bg-success d-flex flex-column flex-sm-row p-5 mb-10">
                                                                            <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                                                                                <h5 className="mb-2 text-white">{getFilteredUserData.full_name ? getFilteredUserData.full_name : ''}</h5>
                                                                                <span>
                                                                                    {getFilteredUserData.email} {getFilteredResponse.message}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : ('')
                                                ) : ('')
                                        }

                                        {
                                            getCloseList && searchInput.length > 0 && getDisableBtn == false ?
                                                (
                                                    // getShowUserInvite?
                                                    !getFilteredResponse.passwordSet &&
                                                        !getFilteredResponse.userExist &&
                                                        !getFilteredResponse.userInWorkspace ?
                                                        (
                                                            <>
                                                                {
                                                                    // !activeInactive && (
                                                                    <div className='row'>
                                                                        <div className='col-sm-6 mb-7'>
                                                                            <label className='fw-bolder fs-6 mb-2'>First Name </label>
                                                                            <input
                                                                                placeholder='First name'
                                                                                type='text'
                                                                                {...formik.getFieldProps('first_name')}
                                                                                name='first_name'
                                                                                // value={initialFormValues.first_name}
                                                                                className={clsx(
                                                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                                                    { 'is-invalid': formik.touched.first_name && formik.errors.first_name },
                                                                                    {
                                                                                        'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                                                                    }
                                                                                )}
                                                                                // onChange={handleFNameChange}
                                                                                autoComplete='off'
                                                                            />
                                                                        </div>
                                                                        <div className='col-sm-6 mb-7'>
                                                                            <label className='fw-bolder fs-6 mb-2'>Last Name </label>
                                                                            <input
                                                                                placeholder='Last name'
                                                                                type='text'
                                                                                {...formik.getFieldProps('last_name')}
                                                                                name='last_name'
                                                                                // value={initialFormValues.last_name}
                                                                                className={clsx(
                                                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                                                    { 'is-invalid': formik.touched.last_name && formik.errors.last_name },
                                                                                    {
                                                                                        'is-valid': formik.touched.last_name && !formik.errors.last_name,
                                                                                    }
                                                                                )}
                                                                                // onChange={handleLNameChange}
                                                                                autoComplete='off'
                                                                            />
                                                                        </div>
                                                                        <div className='col-sm-6 mb-7'>
                                                                            <label className='fw-bolder fs-6 mb-2'>Password </label>
                                                                            <input
                                                                                placeholder='Password'
                                                                                {...formik.getFieldProps('password')}
                                                                                type='password'
                                                                                name='password'
                                                                                className={clsx(
                                                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                                                    { 'is-invalid': formik.touched.password && formik.errors.password },
                                                                                    {
                                                                                        'is-valid': formik.touched.password && !formik.errors.password,
                                                                                    }
                                                                                )}
                                                                                autoComplete='off'
                                                                                disabled={formik.isSubmitting}
                                                                            />
                                                                            {formik.touched.password && formik.errors.password && (
                                                                                <div className='fv-plugins-message-container'>
                                                                                    <div className='fv-help-block'>
                                                                                        <span role='alert'>{formik.errors.password}</span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className='col-sm-6 mb-7'>
                                                                            <label className='fw-bolder fs-6 mb-2'>Confirm Password </label>
                                                                            <input
                                                                                placeholder='Password'
                                                                                {...formik.getFieldProps('confirm_password')}
                                                                                type='password'
                                                                                name='confirm_password'
                                                                                className={clsx(
                                                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                                                    { 'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password },
                                                                                    {
                                                                                        'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                                                                                    }
                                                                                )}
                                                                                autoComplete='off'
                                                                                disabled={formik.isSubmitting}
                                                                            />
                                                                            {formik.touched.confirm_password && formik.errors.confirm_password && (
                                                                                <div className='fv-plugins-message-container'>
                                                                                    <div className='fv-help-block'>
                                                                                        <span role='alert'>{formik.errors.confirm_password}</span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className='col-sm-12 mb-7'>
                                                                            <label className='fw-bolder fs-6 mb-2'>Job Title</label>
                                                                            <input
                                                                                placeholder='Job Title'

                                                                                type='text'
                                                                                {...formik.getFieldProps('job_title')}
                                                                                name='job_title'
                                                                                // value={initialFormValues.job_title}
                                                                                className={clsx(
                                                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                                                    { 'is-invalid': formik.touched.job_title && formik.errors.job_title },
                                                                                    {
                                                                                        'is-valid': formik.touched.job_title && !formik.errors.job_title,
                                                                                    }
                                                                                )}
                                                                                // onChange={handleFNameChange}
                                                                                autoComplete='off'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    // )
                                                                }
                                                                <div className='fv-row mb-3'>
                                                                    <div className='d-flex flex-stack'>
                                                                        <div className='me-5'>
                                                                            <p className='fs-6 fw-bolder text-gray-900 mb-0'>Invite User</p>
                                                                            <p className="fs-7 fw-bolder text-muted "> By sending email notification </p>
                                                                        </div>
                                                                        <div className='form-check form-switch form-check-custom form-check-solid'>
                                                                            <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                                                                <span className='form-check-label text-muted me-2'>No</span>
                                                                                <input
                                                                                    className='form-check-input'
                                                                                    type='checkbox'
                                                                                    name='invite'
                                                                                    checked={activeInactive}
                                                                                    onClick={() =>
                                                                                        setActiveInactive(!activeInactive)
                                                                                    }
                                                                                />
                                                                                <span className='form-check-label text-muted'>Yes</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    // !getFilteredUserData.password?
                                                                    !getFilteredResponse.passwordSet &&
                                                                        getFilteredResponse.userExist &&
                                                                        !getFilteredResponse.userInWorkspace ?
                                                                        (
                                                                            <div className='fv-row mb-3'>
                                                                                <div className='d-flex flex-stack'>
                                                                                    <div className='me-5'>
                                                                                        <p className='fs-6 fw-bolder text-gray-900 mb-0'>Resend Invite</p>
                                                                                        <p className="fs-7 fw-bolder text-muted "> By sending email notification </p>
                                                                                    </div>
                                                                                    <div className='form-check form-switch form-check-custom form-check-solid'>
                                                                                        <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                                                                            <span className='form-check-label text-muted me-2'>No</span>
                                                                                            <input
                                                                                                className='form-check-input'
                                                                                                type='checkbox'
                                                                                                name='resend_invite'
                                                                                                checked={getResendInvite}
                                                                                                onClick={() =>
                                                                                                    setResendInvite(!getResendInvite)
                                                                                                }
                                                                                            />
                                                                                            <span className='form-check-label text-muted'>Yes</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : ('')
                                                                }
                                                            </>
                                                        )
                                                ) : ('')
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        onClick={() => notify()}
                                        disabled={getDisableBtn}
                                    >{!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button
                                        type='button' // {()=>{showHideModel(cancelModel)}}
                                        onClick={() => showHideModel()}
                                        className='btn btn-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                            {/* end::Modal body */}
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
            </Modal>

        </div>

    )

}

// export { ExternalWorkspaceAddUserModal }
export default connect(mapStateToProps)(ExternalWorkspaceAddUserModal);