import { Types } from '../constants/actionTypes';

const initialState = {
  roles: {
    name: '',
  },
  formSubmitted: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.INTERNAL_USER_ROLES:
    // console.log('userRolesData=', action.payload.userRolesData)
      return {
        ...state,
        roles: action.payload.userRolesData,
        formSubmitted: false // after update roles formsubmition reset
      }
    default:
      return state;
  }
}

export default reducer;
