import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      requestPassword(values.email)
        .then(({data: {result}}) => {
          // console.log('result=',result)
          setHasErrors(false)
          setLoading(false)
          setStatus('Reset link sent. Please check your email')
        })
        .catch((error) => {
          // console.log('error=',error.response)
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          if(error.response){
            if(error.response.data){
              if(error.response.data['errors'] && error.response.data['errors'].length > 0){
                setStatus(error.response.data['errors'][0]['msg'])
              }
            }
          } else {
            setStatus('The login detail is incorrect')
          }
        })
    },
  })

  return (
    <>
    <div className='loginPage'>
      <div className="utilities-form-column">
        <div className="utilities-form-wrap">
          <h4 className="h4-title xs-margin">Forgot Your Password?</h4>
          <p className="m-paragraph l-margin">Already a member? <Link to="/auth/login" className="link-text">Log in</Link>.</p>
          <div className="utilities-form w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get"
              onSubmit={formik.handleSubmit}
              noValidate
            >

              {hasErrors === false? (
                <div className="success-message w-form-fail mb-5">
                  <div className="center-content">
                    <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf70c956a3e7_check-circle.svg" loading="lazy" alt="" className="s-icon" />
                      <div>{formik.status}</div>
                    </div>
                  </div>
                </div>
              ):('')}

              {hasErrors === true? (
                formik.status ? (
                  <div className="error-message w-form-fail mb-5">
                    <div className="center-content">
                      <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf360756a3e8_alert-triangle.svg" loading="lazy" alt="" className="s-icon" />
                        <div>{formik.status}</div>
                      </div>
                    </div>
                  </div>
                ):('')
              ):('')}
              
              {/* <input type="email" className="large-field email w-input" name="Email" data-name="Email" placeholder="Your email" id="Email" /> */}
              <div className='inputMarginBottom'>
                <input
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control large-field email w-input',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  data-name="Email" 
                  placeholder="Your email" 
                  id="Email" 
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
            
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='utilities-button w-button'
                disabled={loading}
              >
                {!loading && <span className='indicator-label fw-bolder'>Send Reset Password Email</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* <input type="submit" value="Send Reset Password Email" data-wait="Please wait..." className="utilities-button w-button" /> */}
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
