/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { KTSVG } from '../../../_metronic/helpers'
import axios from 'axios'
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import { ComponentLoading } from '../../modules/ComponentLoading'
import { ToastContainer, toast } from 'react-toastify';

import { OrganizationDeleteAllModal } from '../customer-organizations/OrganizationDeleteAllModal'

// Redux Apply
import { store } from '../../../redux/store/index';
import * as _ from "lodash";
        
var xData : any  = []

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

const OrganizationListUrl = `${API_URL}/internal/organizations/list`
type Props = {
  className: string
}
let rowsData: any = [];
const OrganizationsList: React.FC<Props> = ({ className }) => {

  const [OrganizationList, setOrganizationList] = useState<any[]>([]);
  const [OrganizationListAll, setOrganizationListAll] = useState<any[]>([]);
  const [showHideModal, setshowHideModal] = useState(false)

  const [editorgData, seteditorgData] = useState<any>({});

  const [getComponentLoading, setComponentLoading] = useState(true)

  const navigate = useNavigate();

  const showHideModelFunction: any = () => {
    setshowHideModal(!setshowHideModal)
  }

  const editOrgModal: any = (data: any) => {
    seteditorgData(data);
  }

  const [isCheckAll, setIsCheckAll]: any = useState(false);
  const [isCheck, setIsCheck]: any = useState([]);
  const [listData, setListData]: any = useState([]);

  const [getDeletedMessages, setDeletedMessages]: any = useState([]);

  
  const [getOrganizationId, setOrganizationId]: any = useState('');
  const [showHidePasswordModal, setshowHidePasswordModal]: any = useState(false)
  const showHideSetPasswordModal: any = () => {
    setshowHidePasswordModal(!setshowHidePasswordModal)
  }

  // const navigate = useNavigate();

  let inputArray: any = [];
  const fetchOrganizationsList: any = () => {

    let objSelect: any;
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const OrganizationListList = axios.get(
      OrganizationListUrl,
      config
    ).then(async (res: any) => {
      const { data: OrganizationListData } = res
      OrganizationListData.forEach((element: any) => {
        element[`checked`] = false;
        objSelect = { 'id': element._id, 'name': element._id }
        inputArray.push(objSelect);
      });
      setListData(inputArray)
      rowsData = OrganizationListData;
      await setOrganizationListAll(OrganizationListData)
      await setOrganizationList(OrganizationListData)
      setComponentLoading(false)
      return [OrganizationListAll, OrganizationList]
    }
    ).catch((error) => {
      setComponentLoading(false)
      console.log(error)
    });
    return OrganizationListList
  }

  function GlobalFilter(props: any) {
    const { setGlobalFilter } = props
    const count = props.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(props.globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)

    return (

      <div className="border-0 pt-6 mb-5">

        <div className="card-title" ></div>
        <div className="card-toolbar" >
          <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base" >
            <div className="d-flex align-items-center position-relative my-1" >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={value || ""}
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />

            </div>
          </div>

        </div>
      </div>


    )
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length

    return (
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='customTableSearch form-control form-control-solid w-250px ps-14'
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  function Table(props: any) {
    const { columns, data } = props

    const defaultColumn: any = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
    const setLocalStorage = (pageSize: any) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem('pageSize', pageSize)
    }
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
    )

    let currentPageStartIndex: any = 2;
    let previousIndexPage: any = 0;

    return (
      <div>

        <ToastContainer />

        {
          getComponentLoading ?
            (
              <ComponentLoading></ComponentLoading>
            ) : ('')
        }

        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        <div className='table-responsive'>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer organizationTableList" {...getTableProps()}>
            <thead>

              {headerGroups.map((headerGroup: any) => (
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th className={" "} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {/* {console.log('column=',column)} */}

                      {column.isSorted
                        ? column.isSortedDesc
                          ? (<span className='table-sort-asc'></span>)
                          : (<span className='table-sort-desc'></span>)
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='fw-bold' {...getTableBodyProps()}>
              {
                page.length > 0 ?
                  (
                    <>
                      {page.map((row: any, i: any) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                              return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                              </td>
                            })}
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                      </td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="dataTables_length" id="kt_customers_table_length">
              <select name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                value={pageSize}
                onChange={e => {
                  { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                }}
                style={{ width: '120px', height: '38px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div id="kt_table_users_paginate">
              <ul className="pagination">
                <li className="page-item" onClick={() => previousPage()} >
                  <a className="page-link prev-angle">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </li>
                {pageOptions.map((pageSize: any, rowIndex: any) => {
                  if (currentPageStartIndex <= rowIndex) {
                    currentPageStartIndex = currentPageStartIndex + 1
                    previousIndexPage = rowIndex - 1
                  }
                  return (
                    previousIndexPage <= currentPageStartIndex
                      ?
                      <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                        <a className="page-link">{pageSize + 1} </a>
                      </li>
                      :
                      null
                  )

                })}

                <li className="page-item" onClick={() => nextPage()} >
                  <a className="page-link next-angle">
                    {/* {'Next »'} */}
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }

  useEffect(() => {
    fetchOrganizationsList()

    window.addEventListener('organizationListRefresh', (event: any) => {
      if (event['detail'].value) {
        setshowHidePasswordModal(false)
        fetchOrganizationsList()
      }
    })

  }, [])



  async function handleSelectAll(e: any) {
    let trueFalse: any = !e.target.checked;
    rowsData.forEach((element: any) => {
      element[`checked`] = !element.checked;
    });

    await setListData(rowsData);
    await setOrganizationListAll(rowsData)
    await setOrganizationList(rowsData)

    await setIsCheckAll(trueFalse);
  };

  const handleClick = (e: any) => {
  };
  const chartFunc = (value: any) => {
    console.log('value=', value.charAt(0))
    let chatAt_value = value.charAt(0);
    return chatAt_value;
  }
  const columns: any = React.useMemo(
    () => [

      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              <div className='d-flex align-items-center'>
                {
                  data.avatar_id ?
                    (
                      data.avatar_id.file ?
                        (
                          <>
                            <span className={"symbol-label symbol symbol-circle symbol-35px overflow-hidden me-3"}>

                              <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(data.name) + " img-alt-35"} src={getUserInfo.domainUrl() + data.avatar_id.file_path} alt={chartFunc(data.name)} />


                            </span>
                          </>
                        ) : (
                          data.name ?
                            (
                              <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                                <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                                  {data.name.charAt(0)}
                                </span>
                              </div>
                            ) : ('N/A')
                        )
                    ) : data.name ? (
                      <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                        <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                          {data.name.charAt(0)}
                        </span>
                      </div>
                    ) : ('N/A')
                }
                <div className='d-flex flex-column'>
                  <span key={data._id} className="text-capitalize fw-bolder " >{data.name}</span>
                </div>
              </div>
            </>
          )
        }
      },


      {
        Header: 'Industry',
        accessor: 'industry_id',
        disableSortBy: false,
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                data.industry_id ?
                  (
                    data.industry_id.name ?
                      (
                        <span className="text-capitalize badge m-1 bg-info">{data.industry_id.name}</span>
                      ) : ('N/A')
                  ) : ('N/A')
              }
            </>
          )
        }
      },

      {
        Header: 'Workspaces',
        accessor: 'workspaces',
        disableSortBy: false,
        Cell: ({ row }: any) => {
          const data = row.original
          const workspaces = data.workspaces && data.workspaces.length > 0 ? data.workspaces : [];

          const showTotals = 5
          const org_data = getHelpers.WorkspacesAvatarsDataArray(showTotals, data.workspaces)['users']
          const totalUsers = getHelpers.WorkspacesAvatarsDataArray(showTotals, data.workspaces)['total']
          return (
            <div className="symbol-group symbol-hover flex-nowrap">
              {
                org_data.length > 0 ?
                  (
                    <>

                      {org_data.map((item: any) =>
                        <Link to={"/internal/workspace/" + item._id} className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={item._id}>
                          {
                            item.avatar_id ?
                              (
                                item.avatar_id.file ?
                                  (
                                    <>
                                      <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{item.name ? (item.name) : ('N/A')}</Tooltip>}>
                                          <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(item.name) + " img-alt-35"} src={getUserInfo.domainUrl() + item.avatar_id.file_path} alt={chartFunc(item.name)} />
                                      </OverlayTrigger>
                                    </>
                                  ) : (
                                    item.name ?
                                      (
                                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{item.name ? (item.name) : ('N/A')}</Tooltip>}>
                                          <span className={'symbol-label text-inverse-warning fw-bolder bg-' + item.name.charAt(0)}>
                                            {item.name.charAt(0)}
                                          </span>
                                        </OverlayTrigger>
                                      ) : ('N/A')
                                  )
                              ) : (
                                item.name ?
                                  (
                                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{item.name ? (item.name) : ('N/A')}</Tooltip>}>
                                      <span className={"symbol-label text-inverse-warning fw-bolder bg-" + item.name.charAt(0)}>
                                        {item.name.charAt(0)}
                                      </span>
                                    </OverlayTrigger>
                                  ) : ('N/A')
                              )
                          }
                        </Link>
                      )}

                      {
                        totalUsers > 0 ?
                          (
                            <div className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                              <span className="symbol-label bg-primary text-inverse-dark fs-8 fw-bold" data-bs-toggle="tooltip" data-bs-trigger="hover" data-kt-initialized="1">+{totalUsers}</span>
                            </div>
                          ) : ('')
                      }
                    </>
                  ) : ('N/A')
              }
            </div>
          )
        }
      },

      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                data.status == 'active' ?
                  (
                    <span key={data._id} className="text-capitalize badge-success badge " >{data.status}</span>
                  ) : (
                    <span key={data._id} className="text-capitalize badge-danger badge " >{data.status}</span>
                  )
              }
            </>
          )
        }
      },

      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const data = row.original
          const org_id: any = data._id;
          return (
            <>
              <div className='customActionButtons'>
                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Basic Details</Tooltip>}>
                  <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/organizations/" + org_id}>
                    {/* <i className="bi bi-eye"></i> */}
                    <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor" />
                      <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor" />
                    </svg>
                    </span>
                  </Link>
                </OverlayTrigger>
                {
                  _.includes(store.getState().userData.permissions, 'manage_organizations') ?
                    (
                      <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                        <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/organizations/" + org_id + "/edit"}>
                          {/* <i className="bi bi-pencil-square"></i> */}
                          <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                            <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                            <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                          </svg>
                          </span>
                        </Link>
                      </OverlayTrigger>
                    ) : ('')
                }
                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Contacts</Tooltip>}>
                  <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/organizations/" + org_id + "/contacts"}>
                    {/* <i className="bi bi-people"></i> */}
                    <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor" />
                      <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor" />
                      <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor" />
                      <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor" />
                    </svg>
                    </span>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Specialities</Tooltip>}>
                  <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/organizations/" + org_id + "/specialities"}>
                    {/* <i className="bi bi-people"></i> */}
                    <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M22 12C22 13.8 21.5 15.5 20.7 17L14.9 7H20.7C21.5 8.5 22 10.2 22 12ZM3.3 7L6.2 12L12 2C8.3 2 5.1 4 3.3 7ZM3.3 17C5 20 8.3 22 12 22L14.9 17H3.3Z" fill="currentColor"/><path d="M20.7 7H9.2L12.1 2C15.7 2 18.9 4 20.7 7ZM3.3 7C2.4 8.5 2 10.2 2 12C2 13.8 2.5 15.5 3.3 17H9.10001L3.3 7ZM17.8 12L12 22C15.7 22 18.9 20 20.7 17L17.8 12Z" fill="currentColor"/></svg></span>
                  </Link>
                </OverlayTrigger>
                {
                  _.includes(store.getState().userData.permissions, 'view_workspaces') ?
                    (
                      <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Workspaces</Tooltip>}>
                        <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/organizations/" + org_id + "/workspaces"}>
                          {/* <i className="bi bi-diagram-3-fill"></i> */}
                          <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor" />
                            <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor" /></svg>
                          </span>
                        </Link>
                      </OverlayTrigger>
                    ) : ('')
                }

                {
                  _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
                    (
                      <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Delete</Tooltip>}>
                        <>
                          <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' 
                            // onClick={() => { deleteWorkspace(org_id) }}
                            onClick={() => { setshowHidePasswordModal(true); setOrganizationId(data._id) }}
                          >
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                              <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                              <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                            </svg>
                            </span>
                          </span>
                        </>
                      </OverlayTrigger>
                    ) : ('')
                }
              </div>
            </>
          )
        }
      },
    ],
    []
  )

  const data: any = OrganizationList

  return (
    <div className={`card ${className} card-border-radius min-height-400 `}>
      <div className="card-body pt-0 bg-white" >
        <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
          <Table columns={columns} data={data} />
        </div>
      </div>
      <OrganizationDeleteAllModal showHideSetPasswordModal={showHideSetPasswordModal} showHidePasswordModal={showHidePasswordModal} organizationId={getOrganizationId} />
    </div>
  )
}

export { OrganizationsList }