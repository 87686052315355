/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import * as getHelpers from '../../modules/auth/GetHelpers'
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ComponentLoading } from '../../modules/ComponentLoading'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToken = `Bearer ${ApiToken}`
interface PageProps {
    //   organisationUsersId: any;
}

// Redux Apply
const mapStateToProps = (state: any) => {
    return {
        profile: state.user.profile,
        userData: state.userData.permissions,
        userRolesData: state.userRolesData.roles
    }
}

let OrgId: any;
const OrganizationDetailHeader: FC<PageProps> = () => {

    const [getOrganizationData, setOrganizationData]: any = useState<any>({});
    const [getTotalContacts, setTotalContacts] = useState(0)
    const [getTotalWorkspaces, setTotalWorkspaces] = useState(0)
    let { organizationId }: any = useParams();
    const [getComponentLoading, setComponentLoading] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false)

    const [getspinner, setspinner] = useState(false)
    const [getIndustries, setIndustries] = useState<any[]>([]);
    const [getIndustry, setIndustry] = useState({});

    const [getInitialValues, setInitialValues] = useState<any>()

    const ApiToken = localStorage.getItem('api_token')
    const BearerToeken = `Bearer ${ApiToken}`

    const organizationURL = `${API_URL}/internal/organizations/` + organizationId

    const OrganizationsModalSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(50, 'Maximum 100 characters')
            .required('Name is required'),
        address: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 200 characters')
            .required('Address is required'),
        industry_id: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        })
            .required('Industry is required')
    })

    const formik: any = useFormik({
        initialValues: {
            name: '',
            address: '',
            industry_id: {}
        },
        // initialValues: getInitialValues,
        validationSchema: OrganizationsModalSchema,
        onSubmit: async (values) => {

            try {

                let formData: any = await {
                    name: values.name,
                    address: values.address,
                    industry_id: getIndustry,
                }

                const config = {
                    headers: { Authorization: BearerToeken }
                };
                await axios.put(
                    process.env.REACT_APP_API_URL + '/internal/organizations/' + organizationId,
                    formData,
                    config
                )
                    .then(async (res) => {
                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                            setspinner(false)
                        } else {
                            setspinner(false)
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    })
                    .catch((error) => {
                        setspinner(false)
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });

                    });

            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)

            }
        },
    })

    const fetchOrganization: any = () => {
        const config = {
            headers: { Authorization: BearerToken }
        };
        const organizationsList = axios.get(
            organizationURL,
            config
        ).then(async (res: any) => {

            const organizationData = res.data.organization
            const totalContacts = res.data.totalContacts
            const totalWorkspaces = res.data.totalWorkspaces
            // alert(totalContacts)
            if (organizationData.name) {
                formik.initialValues.name = organizationData.name
            }
            if (organizationData.address) {
                formik.initialValues.address = organizationData.address
            }
            if (organizationData.industry_id) {
                var orgObj = {
                    label: organizationData.industry_id['name'],
                    value: organizationData.industry_id['_id'],
                    _id: organizationData.industry_id['_id'],
                }
                setIndustry(orgObj)
            }
            setOrganizationData(organizationData)
            setTotalContacts(totalContacts)
            setTotalWorkspaces(totalWorkspaces)
            setComponentLoading(false)
            return setOrganizationData;
        }
        ).catch((error) => {
            console.log('error=', error)
        });
        return organizationsList
    }

    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        // console.group();
        setIndustry(newValue)
        // console.log('handleChange getIndustry=',getIndustry)
        // console.groupEnd();
    }

    const submitErrorCheck = () => {
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    const getIndustriesData = () => {
        axios.get(process.env.REACT_APP_BASE_API_URL + 'internal/industries/list')
            .then(async (res) => {
                setspinner(false)
                setIndustries(res.data)
                // optionsOrganizations = res.data
            })
            .catch(error => {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });

                setspinner(false)
                // fetchorganizationsList();
            })
    }

    const getListnerData = () => {
        window.addEventListener('dispatchOrganization', (event: any) => {
            if (event['detail'].value) {
                // setOrganizationData(event['detail'].data)
                fetchOrganization()
            }
        })
    }

    useEffect(() => {
        const config = {
            headers: { Authorization: BearerToken }
        };
        axios.get(
            GET_USER_BY_ACCESSTOKEN_URL,
            config
        )
            .then(async (res) => {
                if (res.data.status) {
                    if (res.data.userType == 'external') {
                        navigate('/error/404')
                    }
                } else if (res.data.userType == 'User type not found') {
                    if (getUserInfo.userType() == 'external') {
                        navigate('/error/404')
                    }
                }
            })
            .catch((error) => {
                console.clear()
                navigate('/error/404')
            });
        getListnerData();
        getIndustriesData();
        fetchOrganization()
    }, [])
    const chartFunc = (value: any) => {
        console.log('value=', value.charAt(0))
        let chatAt_value = value.charAt(0);
        return chatAt_value;
    }
    return (
        <>

            <div className="card mb-5 mb-xl-7 overflow-hidden internalWorkspaceHeader">

                {
                    getComponentLoading ?
                        (
                            <ComponentLoading></ComponentLoading>
                        ) : ('')
                }

                <div className="card-body pt-9 pb-0">
                    <div className="mb-3 internalWorkspaceContainer">
                        <div className="me-7 mb-4 colLeft">
                            {
                                getOrganizationData.avatar_id ?
                                    (
                                        getOrganizationData.avatar_id.file ?
                                            (
                                                <>
                                                    <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative text-uppercase">
                                                        <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase img-alt-100 bg-img-" + chartFunc(getOrganizationData.name) } src={getUserInfo.domainUrl() + getOrganizationData.avatar_id.file_path} alt={chartFunc(getOrganizationData.name)} />
                                                        {/* <img src={getUserInfo.domainUrl()+getOrganizationData.avatar_id.file_path} alt='Metornic' /> */}
                                                    </div>
                                                </>
                                            ) : (
                                                getOrganizationData.name ?
                                                    (
                                                        <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative text-uppercase">
                                                            <span className={'symbol-label bg-light-danger text-white fs-30 fw-bolder bg-' + getOrganizationData.name.charAt(0)}>
                                                                {getOrganizationData.name.charAt(0)}
                                                            </span>
                                                        </div>
                                                    ) : ('N/A')
                                            )
                                    ) : getOrganizationData.name ? (
                                        <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative text-uppercase">
                                            <span className={'symbol-label bg-light-danger text-white fs-30 fw-bolder bg-' + getOrganizationData.name.charAt(0)}>
                                                {getOrganizationData.name.charAt(0)}
                                            </span>
                                        </div>
                                    ) : ('N/A')
                            }
                        </div>
                        <div className="colRight">
                            <div className="hButtons d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2"><a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{getOrganizationData.name}</a></div>
                                </div>
                                <div className="d-flex my-1">
                                    {/* <a className="btn btn-sm btn-light fw-bolder me-2">
                                    <i className="bi bi-trash me-1"></i> Archive
                                </a> */}
                                    {
                                        location.pathname === '/internal/organizations/' + organizationId + '/edit' ?
                                            (
                                                <Link
                                                    to={'/internal/organizations/' + organizationId + ''}
                                                    className='btn btn-sm btn-primary fw-bolder'
                                                >
                                                    {/* <i className="bi bi-pencil-square me-1"></i>  */}
                                                    <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
                                                        <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor" />
                                                        <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor" />
                                                        <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor" />
                                                        <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
                                                    </svg>
                                                    </span>
                                                    View
                                                </Link>
                                            ) : (
                                                _.includes(store.getState().userData.permissions, 'manage_organizations') ?
                                                    (
                                                        <Link
                                                            to={'/internal/organizations/' + organizationId + '/edit'}
                                                            className='btn btn-sm btn-primary fw-bolder'
                                                        >
                                                            {/* <i className="bi bi-pencil-square me-1"></i>  */}
                                                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                                                                <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                                                                <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                                                            </svg>
                                                            </span>
                                                            Edit
                                                        </Link>
                                                    ) : ('')
                                            )
                                    }
                                    {/* <a className="btn btn-sm btn-primary fw-bolder">
                                    <i className="bi bi-pencil-square me-1"></i> Edit
                                </a> */}
                                </div>
                            </div>
                            <div className="countContainer d-flex flex-wrap flex-stack">
                                <div className="d-flex flex-column flex-grow-1">
                                    <div className="d-flex flex-wrap">
                                        <div className="countCol border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-2 fw-bolder">{getTotalContacts}</div>
                                            </div>
                                            <div className="fw-bold fs-6 text-gray-400">Contacts</div>
                                        </div>
                                        {
                                            _.includes(store.getState().userData.permissions, 'view_workspaces') ?
                                                (
                                                    <div className="countCol border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <div className="fs-2 fw-bolder">{getTotalWorkspaces}</div>
                                                        </div>
                                                        <div className="fw-bold fs-6 text-gray-400">Workspaces</div>
                                                    </div>
                                                ) : ('')
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="customNavTabs">
                        <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary ` +
                                        ((location.pathname === '/internal/organizations/' + organizationId + '' || location.pathname === '/internal/organizations/' + organizationId + '/edit') && 'active')
                                    }
                                    to={'/internal/organizations/' + organizationId + ''}
                                >
                                    Basic Details
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary ` +
                                        (location.pathname === '/internal/organizations/' + organizationId + '/contacts' && 'active')
                                    }
                                    to={'/internal/organizations/' + organizationId + '/contacts'}
                                >
                                    Contacts
                                </Link>
                            </li>
                            {
                                _.includes(store.getState().userData.permissions, 'view_workspaces') ?
                                    (
                                        <li className='nav-item'>
                                            <Link
                                                className={
                                                    `nav-link text-active-primary ` +
                                                    (location.pathname === '/internal/organizations/' + organizationId + '/workspaces' && 'active')
                                                }
                                                to={'/internal/organizations/' + organizationId + '/workspaces'}
                                            >
                                                Workspaces
                                            </Link>
                                        </li>
                                    ) : ('')
                            }
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary ` +
                                        (location.pathname === '/internal/organizations/' + organizationId + '/specialities' && 'active')
                                    }
                                    to={'/internal/organizations/' + organizationId + '/specialities'}
                                >
                                    Specialities
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    )

}

// export { OrganizationDetailHeader }
export default connect(mapStateToProps)(OrganizationDetailHeader);