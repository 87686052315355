/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import Multiselect from 'multiselect-react-dropdown';

type Props = {
  className: string
}

const emailsData = [];

const multiselectEmailsData: any = {
  options: []
};

const DashboardCreate: React.FC<Props> = ({ className }) => {
  // import { json } from 'stream/consumers'
  const navigate = useNavigate()
  const API_URL = process.env.REACT_APP_API_URL
  const ApiToken = localStorage.getItem('api_token')
  const BearerToken = `Bearer ${ApiToken}`
  let { workspaceId } = useParams();
  const workspaceDashboardsUrl = `${API_URL}/workspace/standard/users/` + workspaceId

  const [orgData, setOrgState] = useState<any[]>([]);
  const [dashboardName, setdashboardName] = useState("untitled");
  const [workspaceUsersData, setWorkspaceUsersState] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false)
  const [getLoading, setLoading] = useState(false)
  const [getStandardUsers, setStandardUsers] = useState<any[]>([]);
  const [getSelectedEmails, setSelectedEmails] = useState<any[]>([]);
  const showHideModel = () => {
    setShowModal(!setShowModal)
  }
  const fetchWorkspacesList: any = () => {
    const config = {
      headers: { Authorization: BearerToken }
    };
    const workspacesListList = axios.get(workspaceDashboardsUrl, config)
      .then(async (res: any) => {
        // console.log('res data', JSON.stringify(res.data))
        setOrgState(res.data.workspace)
        setWorkspaceUsersState(res.data.users)
      }).catch();
    return workspacesListList
  }

  useEffect(() => {

    // console.log('formik',formik.values)
    fetchWorkspacesList()
    standardUsersRequest()

  }, [])
  // console.log("orgData = ",orgData)

  const standardUsersRequest = () => {
    // console.log("createDashboard request")
    multiselectEmailsData.options.length = 0;
    axios.get(process.env.REACT_APP_BASE_API_URL + 'workspace/standard/users/' + workspaceId)
      .then((res) => {
        // console.log(res.data)
        setStandardUsers(res.data.users);
        for (const key in res.data.users) {
          if (!multiselectEmailsData.options.includes(res.data.users[key]['user_id']['email'])) {
            multiselectEmailsData.options.push(res.data.users[key]['user_id'])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const createDashboard = (formData: any) => {
    setLoading(true)
    // console.log("createDashboard request")
    axios.post(process.env.REACT_APP_BASE_API_URL + 'dashboards/add', formData)
      .then((res) => {
        // console.log(res.data)
        createGoodDataDashboard({
          dashboard_name: formData.dashboard_name,
          workspaceId: res.data.workspace._id,
          gd_workspaceId: res.data.workspace.gd_workspace_ids[0],
          dashboardId: res.data.dashboard ? res.data.dashboard._id : '',
          emails: formData.userEmails
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const createGoodDataDashboard = (data: any) => {
    // console.log("createDashboard request")
    axios.post(process.env.REACT_APP_BASE_API_URL + 'gooddata/dashboard/save', data)
      .then((res) => {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        setLoading(false)
        navigate('/internal/workspace/' + workspaceId + '/dashboards')
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSubmit = (e: any) => {

    e.preventDefault();

    const formData = {
      dashboard_name: e.target.dashboard_name.value,
      workspaceId: workspaceId,
      userEmails: getSelectedEmails//[e.target.emails.value],//['david@yopmail.com']
    }

    createDashboard(formData)

    // console.log(`Form submitted`, JSON.stringify(formData));

  }

  const onSelect = (selectedList: any, selectedItem: any) => {
    // console.log('selectedList remove=', selectedList)
    setSelectedEmails(selectedList)
  }

  const onRemove = (selectedList: any, removedItem: any) => {
    // console.log('selectedList remove=', selectedList)
    setSelectedEmails(selectedList)
  }
  const changeDashboardText = (event: any) => {
    // console.log('e.target.dashboard_name.value', event.target.name)
    // setdashboardName(e.target.name);
  }

  return (
    <>
      <ToastContainer />
      <div className={`card ${className}`}>

        {/* begin::Body */}
        <div className='card-body py-3 pt-8'>
          <form id='kt_modal_add_user_form' className='form' onSubmit={handleSubmit} noValidate>
            <div
              className='d-flex flex-column me-n7 pe-7'
            >

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Dashboard Name</label>
                <input
                  className=
                  'form-control form-control-solid mb-3 mb-lg-0'
                  // value={dashboardName}
                  type='text'
                  // onChange={(event)=>changeDashboardText(event)}
                  name='dashboard_name'
                  autoComplete='off'
                />
              </div>

              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Share with</label>
                {/* <select name='emails' className='form-control'>
                      {getStandardUsers.map((item) => (  
                        <option value={item.user_id.email}>{item.user_id.email}</option>
                      ))}
                    </select> */}

                <Multiselect
                  placeholder={"Select or enter new email"}
                  options={multiselectEmailsData.options} // Options to display in the dropdown
                  selectedValues={''} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="email" // Property name to display in the dropdown options
                  isObject={true}
                />

              </div>


            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}
            <div className='pt-5 pb-5'>
              <button
                type='submit'
                className='btn btn-primary'
              >
                {
                  getLoading ?
                    (
                      <span>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <span className='indicator-label'>Save</span>
                    )
                }
              </button>
            </div>
            {/* end::Actions */}
          </form>
        </div>
        {/* begin::Body */}

      </div>
    </>
  )
}

export { DashboardCreate }
