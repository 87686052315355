/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import axios from 'axios'
import { Link } from 'react-router-dom'
import * as getUserInfo from '../../../app/modules/auth/GetUserInfo'
const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const GET_WIDGET_COUNT = `${API_URL}/internal/widget-dashboard-count`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}
const StatsCard: React.FC<Props> = ({ className, chartColor, chartHeight, strokeColor }) => {
  const [allUsers, setallUsers] = useState<any[]>([]);
  const [allExternalUser, setallExternalUser] = useState<any[]>([]);

  const [allOrganizationsdata, setallOrganizationsdata] = useState<any>('');
  const [allWorkspacedata, setAllWorkspacedata] = useState<any[]>([]);
  const navigate = useNavigate();
  const getInternalDashbaordWidgetData = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_WIDGET_COUNT,
      config
    )
      .then(async (res) => {
        if (res.data.users) {
          setAllWorkspacedata(res.data.workspace);
          setallOrganizationsdata(res.data.organization);
          setallUsers(res.data.users)
          setallExternalUser(res.data.external)
        }
      })
      .catch((error) => {
        console.clear()
      });
  }
  useEffect(() => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {

            navigate('/error/404')
          } else {
            getInternalDashbaordWidgetData();
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {

            navigate('/error/404')
          } else {
            getInternalDashbaordWidgetData();
          }
        }
      })
      .catch((error) => {
        console.clear()
        navigate('/error/404')
      });
  }, [])
  return (
    <div className={`card ${className}`}>
      <div className={`card-header border-0 py-5 pt-7 bg-${chartColor}`} style={{ height: '150px' }}>
        <div>
          <h3 className='card-title fw-bolder text-white fs-24 heading_f'>
            {
              getUserInfo.fullName() ?
                (
                  getUserInfo.fullName()
                ) : (
                  getUserInfo.email()
                )
            }
          </h3>
          <span className='card-title fw-bold text-white'>Welcome to Internal Dashboard</span>
        </div>
        <div className='card-toolbar'>
        </div>
      </div>
      <div className='card-body p-0'>
        <div className='card-p mt-n20 position-relative customColBox'>
          <div className='rowx g-0 customBoxsCol'>
            <Link to='/internal/organizations' className='customBoxCol colx bg-gray-200 px-6 py-8 rounded-2 me-7x mb-7x'>
              {/* <i className="fa fa-users text-primary fs-2x d-block my-2"></i> */}
              <div>
                {/* <img height='35px' src='/media/icons/duotune/finance/fin001.svg' /> */}
                <span className='svg-icon svg-icon-muted svg-icon-2hx'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"/>
                    <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"/>
                  </svg>
                </span>
              </div>
              <a className='count fs-1 fw-bolder text-gray-700' >
                {allOrganizationsdata}
              </a><br />
              <span className='text-gray-600 fw-bolder fs-4 countText'>
                  Organizations
              </span>
            </Link>
            <Link to='/internal/workspaces/list' className='customBoxCol colx bg-gray-200 px-6 py-8 rounded-2 mb-7x'>
              {/* <i className="fa fa-university text-primary fs-2x d-block my-2"></i> */}
              <div>
                {/* <img height='35px' src='/media/icons/duotune/abstract/abs027.svg' /> */}
                <span className='svg-icon svg-icon-muted svg-icon-2hx'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"/><path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"/></svg>
                </span>
              </div>
              <a className='count fs-1 fw-bolder text-gray-700'>
                {allWorkspacedata}
              </a><br />
              <span className='text-gray-600 fw-bolder fs-4 countText'>
                  Workspaces
              </span>
            </Link>
          </div>
          <div className='rowx g-0 customBoxsCol'>
            <Link to='/internal/users/list' className='customBoxCol colx bg-gray-200 px-6 py-8 rounded-2 me-7x mb-7x'>
              {/* <i className="fa fa-user text-primary fs-2x d-block my-2"></i> */}
              <div>
                {/* <img height='35px' src='/media/icons/duotune/communication/com015.svg' /> */}
                <span className='svg-icon svg-icon-muted svg-icon-2hx'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/><rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/><path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/><rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/></svg>
                </span>
              </div>
              <a className='count fs-1 fw-bolder text-gray-700 mt-2'>
                {allUsers}
              </a><br />
              <span className='text-gray-600 fw-bolder fs-4 countText'>
                  Internal Users
              </span>
            </Link>
            <div className='customBoxCol colx bg-gray-200 px-6 py-8 rounded-2 me-7x mb-7x'>
              {/* <i className="fa fa-user text-primary fs-2x d-block my-2"></i> */}
              <div>
                {/* <img height='35px' src='/media/icons/duotune/communication/com015.svg' /> */}
                <span className='svg-icon svg-icon-muted svg-icon-2hx'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/>
                    <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/>
                    <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/>
                    <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/>
                  </svg>
                </span>
              </div>
              <a className='count fs-1 fw-bolder text-gray-700 mt-2'>
                {allExternalUser}
              </a><br />
              <span className='text-gray-600 fw-bolder fs-4 countText'>
                External Users
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const color = getCSSVariableValue('--kt-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  }
}

export { StatsCard }
