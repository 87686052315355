/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {useNavigate, Link, useParams} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
// import { DefaultTitle } from '../../../../_metronic/layout/components/header/page-title/DefaultTitle'
import Dropdown from 'react-bootstrap/Dropdown';
import { ToastContainer, toast } from 'react-toastify';
import GdDashboardShareEditButtons from '../../../layout/components/header/GdDashboardShareEditButtons'
import axios from 'axios'
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

let currentActiveOrgName: any = ''
let currentActiveOrgId: any = ''

const HeaderUserMenuDashboard: FC = () => {
  const {currentUser, logout} = useAuth()

  let params: any = useParams();
  const dashboardID = params.dashboardId;
  const [getWorkspaceId, setWorkspaceId] = useState('')
  const [getDashboard, setDashboard] = useState<any>({})
  
  // const { config } = useLayout()
  const [getAllWorkspace, setAllWorkspace] = useState<any[]>([]);
  const navigate = useNavigate();
  const findOrgId = async (data: any) => {
    let userData: any = await localStorage.getItem('userinfo');
    userData = JSON.parse(userData);
    let currentOrgId = ''
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id == userData.workspace_id) {
        currentOrgId = data[i].name;
        currentActiveOrgId = data[i]._id
      }
    }
    return currentOrgId;
  }
  const fetchAllOrg = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/workspaces",
    )
      .then(async (response) => {
        if (response.data) {
          currentActiveOrgName = await findOrgId(response.data.workspaces);
          setAllWorkspace(response.data.workspaces)
        }
      })
      .catch((error) => {
        console.log(error)
      });

  };

  const getDashboardById = async () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    await axios.get(
      process.env.REACT_APP_API_URL + '/dashboard/' + dashboardID,
      config
    )
      .then(async (res) => {
        setWorkspaceId(res.data.workspace_id)
        setDashboard(res.data)
      })
      .catch((error) => {
        console.log(error)
      });
  }

  useEffect(() => {

    fetchAllOrg()
    getDashboardById()

  }, []);
  
  const switchWorkspace = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/workspace/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
          window.location.reload();
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }

      // }, 2000);
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }
  const createDashboard = () =>{
    // console.log('data[i].organisation_id.name',currentActiveOrgId)
    let routePath = `external/dashboard/create/${currentActiveOrgId}`
    navigate(routePath)
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >

    {/* Start */}
    <div className='shareEditButtonsInDropdown'>
        <GdDashboardShareEditButtons></GdDashboardShareEditButtons>
    </div>
    {/* End */}



    </div>
  )
}

export {HeaderUserMenuDashboard}
