import { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { ExternalOrganizationAddModalFormWrapper } from './ExternalOrganizationAddModalFormWrapper' // src/app/

const ExternalOrganizationAddModal = (props: any) => {
  const [showModal, setShowModal] = useState(false)

  const modelHideshow = () => {
    setShowModal(!setShowModal)
  }

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='false'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* <UserEditModalHeader /> */}
            <div className='modal-header bg-primary'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder text-white'>Add Organization</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => { props.showHideModel() }}
                style={{ cursor: 'pointer' }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            {/* <div className='modal-body scroll-y mx-5'> */}
            <ExternalOrganizationAddModalFormWrapper modelHideshow={props.showHideModel} fetchorganizationsList={props.fetchorganizationsList} />
            {/* </div> */}
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export { ExternalOrganizationAddModal }
