import { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { WorkspaceEditModalFormWrapper } from './WorkspaceEditModalWrapper' // src/app/modules/apps/user-management/users-list/user-edit-modal/WorkspaceEditModalFormWrapper

const WorkspaceUserEditModal = (props: any) => {
  const [showModal, setShowModal] = useState(false)

  const modelHideshow = () => {
    setShowModal(!setShowModal)
  }

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='false'
      >
        <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header bg-primary'>
              <h2 className='fw-bolder text-white'>Create Workspace</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => { props.showHideModel() }}
                style={{ cursor: 'pointer' }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <WorkspaceEditModalFormWrapper modelHideshow={props.showHideModel} fetchorganizationsList={props.fetchorganizationsList} />
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export { WorkspaceUserEditModal }
