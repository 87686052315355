/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {useNavigate, Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
import Dropdown from 'react-bootstrap/Dropdown';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

let currentActiveOrgName: any = ''
let currentActiveOrgId: any = ''

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  
  // const { config } = useLayout()
  const [getAllWorkspace, setAllWorkspace] = useState<any[]>([]);
  const navigate = useNavigate();
  const findOrgId = async (data: any) => {
    let userData: any = await localStorage.getItem('userinfo');
    userData = JSON.parse(userData);
    let currentOrgId = ''
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id == userData.workspace_id) {
        currentOrgId = data[i].name;
        currentActiveOrgId = data[i]._id
      }
    }
    return currentOrgId;
  }
  const fetchAllOrg = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/workspaces",
    )
      .then(async (response) => {
        if (response.data) {
          currentActiveOrgName = await findOrgId(response.data.workspaces);
          setAllWorkspace(response.data.workspaces)
        }
      })
      .catch((error) => {
        console.log(error)
      });

  };

  useEffect(() => {

    fetchAllOrg()

  }, []);
  
  const switchWorkspace = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/workspace/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }

      // }, 2000);
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }
  const createDashboard = () =>{
    // console.log('data[i].organisation_id.name',currentActiveOrgId)
    let routePath = `external/dashboard/create/${currentActiveOrgId}`
    navigate(routePath)
  }
  const chartFunc = (value: any) => {
    // console.log('value=', value.charAt(0))
    let chatAt_value = value.charAt(0);
    return chatAt_value;
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>

            {
              getUserInfo.userAvatar()?
              (
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-2" data-bs-toggle="tooltip" data-kt-initialized="1">
                  <div>
                    <span className="dropdownProfileAvatar symbol-label text-inverse-warning fw-bolder text-uppercase">
                    <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + chartFunc(getUserInfo.fullName()) + " img-alt"} src={getUserInfo.domainUrl() + getUserInfo.userAvatar()} alt={chartFunc(getUserInfo.fullName())} />
                      {/* <img className='w-100' alt='Logo' src={getUserInfo.domainUrl()+getUserInfo.userAvatar()} /> */}
                    </span>
                  </div>
                </div>
              ):getUserInfo.fullName()?(
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-2" data-bs-toggle="tooltip" data-kt-initialized="1">
                  <div>
                    <span className="dropdownProfileAvatar symbol-label text-inverse-warning fw-bolder text-uppercase bg-primary fs-2">
                      { getUserInfo.fullName().charAt(0) }
                    </span>
                  </div>
                </div>
              ):(
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-2" data-bs-toggle="tooltip" data-kt-initialized="1">
                  <div>
                    <span className="dropdownProfileAvatar symbol-label text-inverse-warning fw-bolder text-uppercase bg-primary fs-2">
                      { getUserInfo.email().charAt(0) }
                    </span>
                  </div>
                </div>
              )
            }

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {getUserInfo.fullName()}
            </div>
            <a className='fw-bold fs-7'>
              <span className='fw-bold'>{getUserInfo.email()}</span>
              {/* <div className='text-capitalize'>{getUserInfo.roleLabel()}</div> */}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          {/* <i className="bi bi-person me-4 fs-1"></i> */}
          <span className="svg-icon svg-icon-muted svg-icon-18 me-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="currentColor"/>
            <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor"/>
            </svg>
          </span>
          My Profile
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {/* <i className="bi bi-box-arrow-right me-4 fs-2"></i> */}
          <span className="svg-icon svg-icon-muted svg-icon-18 me-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M21 22H12C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2H21C21.6 2 22 2.4 22 3V21C22 21.6 21.6 22 21 22Z" fill="currentColor"/>
            <path d="M19 11H6.60001V13H19C19.6 13 20 12.6 20 12C20 11.4 19.6 11 19 11Z" fill="currentColor"/>
            <path opacity="0.3" d="M6.6 17L2.3 12.7C1.9 12.3 1.9 11.7 2.3 11.3L6.6 7V17Z" fill="currentColor"/>
            </svg>
          </span>
          Sign Out
        </a>
      </div>


      {/* Start */}
      {/* <div className='hideShowButtons'>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
      {getUserInfo.userType() == 'external'?
          <Link to={`external/dashboard/create/${currentActiveOrgId}`} className='menu-link text-white btn btn-md fw-bolder btn-primary d-flex align-items-center w-100'>
            <i className="fa fa-plus fs-18 me-2"></i> 
            Create Dashboard
          </Link>
        :
        ('')
      }
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
      {getUserInfo.userType() == 'external'
        ?
        <Dropdown className='w-100'>
          {currentActiveOrgName != ''
            ?
            <Dropdown.Toggle id="dropdown-basic" className='d-flex align-items-center w-100'>
                <i className="fa fa-university fs-18 me-2"></i> 
                <span className='fw-bolder'>{currentActiveOrgName}</span>
            </Dropdown.Toggle>
            :
            <Dropdown.Toggle id="dropdown-basic">
              Select Organizations
            </Dropdown.Toggle>
          }
          <Dropdown.Menu>
            <Dropdown.Item href="/workspaces">View All Workspaces</Dropdown.Item>
            {getAllWorkspace.map((objWorkspace: any, index:any) => (
              <Dropdown.Item key={index} onClick={() => switchWorkspace(objWorkspace._id)} 
                className={
                  `nav-link text-active-primary me-6  ` +
                  (currentActiveOrgId == objWorkspace._id && 'active')
                }
              >
                {objWorkspace.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        :
        ('')
      }
      </div>
      </div> */}
      {/* End */}



    </div>
  )
}

export {HeaderUserMenu}
