import React, {useState,useEffect} from 'react'
import {isNotEmpty, toAbsoluteUrl} from '../../../../_metronic/helpers'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from './../../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import {ProfileChangeEmail} from './ProfileChangeEmail'
import {ProfileChangePassword} from './ProfileChangePassword'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'

const LoginMethodCard: React.FC = () => {

  const [loading, setLoading] = useState(false)

  const [getchangeEmail, setchangeEmail] = useState(false)
  const [getchangePassword, setchangePassword] = useState(false)
  //  console.log()
  const [getEmail, setEmail] = useState(getUserInfo.email())
  const [getPassword, setPassword] = useState('')

  const editProfileSchema = Yup.object().shape({
    email: Yup.string()
      .min(3, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('First Name is required'),
    password: Yup.string()
      .min(3, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('First Name is required'),
  })

  const formik = useFormik({
    initialValues: {
      email: getEmail,
      password: getPassword,
    },
    validationSchema: editProfileSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        setLoading(true)
        updateProfile(values)
      } catch (ex) {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        // console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
    useEffect(() => {
    // alert(0)
    // console.log('getchangeEmail',getchangeEmail)
    // getDashboardInsight();
  },[])
  const updateProfile = (data:any) => {
    // console.log('updateProfile=',data)
    // return;
    axios.put(process.env.REACT_APP_BASE_API_URL+'me',data)
      .then(async (res) => {
        setLoading(false)
        if(res.data.status){
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        } else {
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        // console.log();
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        setLoading(false)
        // fetchorganizationsList();
      })
  }

  const changeEmailBtn : any = () => {
    setchangeEmail(!getchangeEmail)
  }
  // const changeEmailCancelBtn = () => {
  //   setchangeEmail(false)
  // }
  const changePasswordBtn = () => {
    setchangePassword(!getchangePassword)
  }

  return (
    <>
      {/* <ToastContainer /> */}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_signin_method'
          aria-expanded='true'
          aria-controls='kt_account_profile_signin_method'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Sign-in Method</h3>
          </div>
        </div>

        <div id='kt_account_profile_signin_method' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {
                getchangeEmail == false?
                (
                  <>
                    <div className='row mb-6'>
                      <div className='col-lg-8 col-form-label fw-bold fs-6'>
                        <h5>Email Address</h5>
                        <p>{getUserInfo.email()}</p>
                      </div>
                      <div className='col-lg-4 text-right'>
                        <button className='btn btn-primary' onClick={changeEmailBtn}>Change Email</button>
                      </div>
                    </div>
                  </>
                ):(
                  <>
                    <div className='mb-10'>
                      <ProfileChangeEmail getchangeEmail={changeEmailBtn}/>
                    </div>
                  </>
                )
              }
              {
                getchangePassword == false?
                (
                  <>
                    <div className='row mb-6'>
                      <div className='col-lg-8 col-form-label fw-bold fs-6'>
                        <h5>Password</h5>
                        <p>********</p>
                      </div>
                      <div className='col-lg-4 text-right'>
                        <button className='btn btn-primary' onClick={changePasswordBtn}>Change Password</button>
                      </div>
                    </div>
                  </>
                ):(
                  <>
                    <ProfileChangePassword getchangePasswordProps={changePasswordBtn} />
                  </>
                )
              }
            </div>

          </form>
        </div>
      </div>
    </>
  )
}

export {LoginMethodCard}