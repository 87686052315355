/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { ActionMeta, OnChangeValue } from 'react-select';
import { ComponentLoading } from '../../modules/ComponentLoading'
import OrganizationDetailHeader from '../../pages/customer-organizations/OrganizationDetailHeader'

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToken = `Bearer ${ApiToken}`
interface PageProps {
//   organisationUsersId: any;
}
let OrgId: any;
const OrganizationDetailPage: FC<PageProps> = () => {

    const [getOrganizationData, setOrganizationData]: any = useState<any>({});
    let { organizationId }: any = useParams();
    const [getComponentLoading, setComponentLoading] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false)

    const [getspinner, setspinner] = useState(false)
    const [getIndustries, setIndustries] = useState<any[]>([]);
    const [getIndustry, setIndustry] = useState({});
    
    const [getInitialValues, setInitialValues] = useState<any>()

    const ApiToken = localStorage.getItem('api_token')
    const BearerToeken = `Bearer ${ApiToken}`

    const organizationURL = `${API_URL}/internal/organizations/` + organizationId

    const OrganizationsModalSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(50, 'Maximum 100 characters')
            .required('Name is required'),
        // address: Yup.string()
        //     .min(2, 'Minimum 2 characters')
        //     .max(100, 'Maximum 200 characters')
        //     .required('Address is required'),
        industry_id: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
            })
            .required('Industry is required')
    })

    const formik : any = useFormik({
        initialValues: {
            name: '',
            address: '',
            industry_id: {}
        },
        // initialValues: getInitialValues,
        validationSchema: OrganizationsModalSchema,
        onSubmit: async (values) => {

            try {

                let formData: any = await {
                    name: values.name,
                    address: values.address,
                    industry_id: getIndustry,
                }

                const config = {
                    headers: { Authorization: BearerToeken }
                };
                await axios.put(
                    process.env.REACT_APP_API_URL + '/internal/organizations/' + organizationId,
                    formData,
                    config
                )
                    .then(async (res) => {
                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                            setspinner(false)
                        } else {
                            setspinner(false)
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    })
                    .catch((error) => {
                        setspinner(false)
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });

                    });

            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)

            }
        },
    })

    const fetchOrganization: any = () => {
        const config = {
            headers: { Authorization: BearerToken }
        };
        const organizationsList = axios.get(
            organizationURL,
            config
        ).then(async (res: any) => {
            const organizationRes = res.data.organization
            if(organizationRes.name){
                formik.initialValues.name = organizationRes.name
            }
            if(organizationRes.address){
                formik.initialValues.address = organizationRes.address
            }
            if(organizationRes.industry_id){
                var orgObj = {
                    label: organizationRes.industry_id['name'],
                    value: organizationRes.industry_id['_id'],
                    _id: organizationRes.industry_id['_id'],
                }
                setIndustry(orgObj)
            }
            setOrganizationData(organizationRes)
            setComponentLoading(false)
            return setOrganizationData;
        }
        ).catch((error) => {
            console.log('error=',error)
        });
        return organizationsList
    }

    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        // console.group();
        setIndustry(newValue)
        // console.log('handleChange getIndustry=',getIndustry)
        // console.groupEnd();
    }

    const submitErrorCheck = () => {
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    const getIndustriesData = () => {
      axios.get(process.env.REACT_APP_BASE_API_URL + 'internal/industries/list')
        .then(async (res) => {
          setspinner(false)
          setIndustries(res.data)
          // optionsOrganizations = res.data
        })
        .catch(error => {
          
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
  
          setspinner(false)
          // fetchorganizationsList();
        })
    }

    // const verifyAccess = () => {
    //   getHelpers.getVerifyAccess()
    // }

    useEffect(() => {
        // verifyAccess()

        const config = {
            headers: { Authorization: BearerToken }
        };
        axios.get(
            GET_USER_BY_ACCESSTOKEN_URL,
            config
        )
        .then(async (res) => {
            if (res.data.status) {
                if (res.data.userType == 'external') {
                    navigate('/error/404')
                }
            } else if (res.data.userType == 'User type not found') {
                if (getUserInfo.userType() == 'external') {
                    navigate('/error/404')
                }
            }
        })
        .catch((error) => {
            console.clear()
            navigate('/error/404')
        });

        getIndustriesData();
        fetchOrganization()
    }, [])

  return (
    <>

        <ToastContainer />
        
        <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">{getOrganizationData.name} Organization</h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                    <li className="breadcrumb-item text-muted">
                        <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                    </li>
                    <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                    <li className="breadcrumb-item text-muted">
                        <Link to={'/internal/organizations'} className="text-muted text-hover-primary">Organizations</Link>
                    </li>
                    <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                    <li className="breadcrumb-item text-muted">Basic Details</li>
                </ul>
            </div>
        </div>

        <OrganizationDetailHeader></OrganizationDetailHeader>

        <div className='card mb-5 mb-xl-10'>

            {
                getComponentLoading ?
                (
                    <ComponentLoading></ComponentLoading>
                ) : ('')
            }

            <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_profile_details'
            aria-expanded='true'
            aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Basic Details</h3>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='pb-2'>
                    
                    <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body pb-3 border-top p-9'>
                            <div className='mb-5'>
                            
                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">Organization Name</label>
                                    <div className="col-lg-8">
                                    {
                                        getOrganizationData.name?
                                        (
                                        <span className="fw-bolder fs-6">{getOrganizationData.name}</span>
                                        ):(
                                        <span className="fw-bolder fs-6">N/A</span>
                                        )
                                    }
                                    </div>
                                </div>
                                
                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">Industry</label>
                                    <div className="col-lg-8">
                                    {
                                        getOrganizationData.industry_id?
                                        (
                                            <>
                                            {
                                                getOrganizationData.industry_id.name?
                                                (
                                                    <span className="fw-bolder fs-6">{getOrganizationData.industry_id.name}</span>
                                                ):('N/A')
                                            }
                                            </>
                                        ):(<span className="fw-bolder fs-6">N/A</span>)
                                    }
                                    </div>
                                </div>
                                
                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">Address</label>
                                    <div className="col-lg-8">
                                    {
                                        getOrganizationData.address?
                                        (
                                        <span className="fw-bolder fs-6">{getOrganizationData.address}</span>
                                        ):(<span className="fw-bolder fs-6">N/A</span>)
                                    }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    </>
  )

}

export { OrganizationDetailPage }
