import React, { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { GoodDataDashboardPage } from './GoodDataDashboardPage'
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import * as _ from "lodash";
import axios from 'axios';

const GoodDataDashboardPageWrapper: FC = () => {
  let params = useParams();
  const navigate = useNavigate()
  const dashboardID = params.dashboardId;
  // var workspaceId = params.workspaceId;
  // const [splashScreen, setsplashScreen] = useState<boolean>(false)
  // console.log('dashboardID',dashboardID)
  const ApiToken = localStorage.getItem('api_token')
  const BearerToeken = `Bearer ${ApiToken}`
  const [getDashboards, setdashboards] = useState<any[]>([]);
  useEffect(() => {
    const getDashboardsData = async () => {
      const config = {
        headers: { Authorization: BearerToeken }
      };
      await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/dashboard/' + dashboardID,
        config
      )
        .then(async (res) => {
          // console.log('res.data=',res.data)
          setdashboards(res.data.name)
          if(res.data.status != 'active'){
            navigate('/')
          }
        })
        .catch((error) => {
          console.log('error=',error.response.data)
          if(error.response){
            if(error.response.data){
              if(error.response.data.workspace && error.response.data.workspace == 'inactive'){
                  navigate('/')
              }
            }
          }
        });
    };

    getDashboardsData()


  }, []);
  return (
    <>
      <div>
        <PageTitle breadcrumbs={[]}>{getDashboards}</PageTitle>
        <GoodDataDashboardPage />
      </div>
    </>
  )
}

export default GoodDataDashboardPageWrapper
