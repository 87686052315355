import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from './components/Footer'
import HeaderWrapperDashboard from './components/header/HeaderWrapperDashboard'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/DashboardContent'
import { DashboardTitle } from '../layout/components/header/page-title/DashboardTitle'
import { PageDataProvider } from './core'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuComponent } from '../assets/ts/components'
import {toAbsoluteUrl} from '../../_metronic/helpers'
const MasterDashboardLayout = () => {
  const location = useLocation();
  const [splashScreen, setsplashScreen] = useState<boolean>(false)
  useEffect(() => {
    // alert('step 1 in master layout');
    // console.log('step 1 in master layout');
    setsplashScreen(true)
    document.body.classList.remove('aside-enabled');
    document.body.classList.remove('header-fixed');
    document.body.classList.remove('aside-fixed');
    document.body.classList.add('header-fixed');

      setsplashScreen(false)
      MenuComponent.reinitialization()
  }, [location.key])


  return (
    <div>
      {
        splashScreen == true ?
          <div id="splash-screen" className="splash-screen" style={{ zIndex: 2000 }}>
            {/* <img src="%PUBLIC_URL%/media/logos/loading-icon.svg" className='loadingIcon' alt="Stringo Media | Analytics Platform"  /> */}
            <img src={toAbsoluteUrl('/media/logos/loading-icon.gif')} className='loadingIcon' alt="Stringo Media | Analytics Platform" />
            {/* <div className="rotating loadingIcon">
              <img className="front" src={toAbsoluteUrl('/media/black-icon-front.svg')} />
              <img className="back" src={toAbsoluteUrl('/media/black-icon-back.svg')} />
            </div> */}
            <span>Loading...</span>
          </div>
          :
          ('')
      }
      <PageDataProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          {/* <AsideDefault /> */}
          <div className='wrapper d-flex flex-column flex-row-fluid dashboardWrapper' id='kt_wrapper'>
            <div className='container dashboardWrapperTitle'>
              <div className='ml-10 fs-3 pt-4 pb-4'>
                <DashboardTitle />
              </div>
            </div>
            <HeaderWrapperDashboard />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid' style={{padding:0 }}>
              {/* <Toolbar2 /> */}
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        <ScrollTop />
      </PageDataProvider>
    </div>
  )
}

export { MasterDashboardLayout }
