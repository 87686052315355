/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, lazy } from 'react'
import {HashRouter , Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { NoAuthPage } from '../modules/auth/indexNoAuth'
import * as getUserInfo from '../../app/modules/auth/GetUserInfo'
// import {ResetPasswordPage} from '../modules/ResetPasswordPage'
import { App } from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const DashboardPageWrapper = lazy(() => import('../pages/layout-builder/DashboardPageWrapper'));
  return (
    // <BrowserRouter basename={PUBLIC_URL}>
    <HashRouter >
     {/* <BrowserRouter basename={'/'}>  */}
      <Routes>
        <Route element={<App />}>
          <Route path='dashboard/public/:generalAccessLink' element={<DashboardPageWrapper />} />

          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              {
                getUserInfo.userType() == 'external'
                  ?
                  <Route index element={<Navigate to='/overview' />} />
                  :
                  <Route index element={<Navigate to='/internal/dashboard' />} />
              }
              
              <Route path='/auth/*' element={<NoAuthPage />} />

            </>
          ) : (
            <>
              <Route path='/auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    {/* </BrowserRouter> */}
    </HashRouter>
  )
}

export { AppRoutes }
