const info : any = () => {
    let data : any = localStorage.getItem('userinfo');
    if(!data){
        return '';
    }
    return JSON.parse(data)
}

const domainUrl = () => {
    return process.env.REACT_APP_DOMAIN_URL
}

const firstName = () => {
    const data = info()
    let firstName = data.first_name?data.first_name:''
    if(firstName){
        return firstName
    }
    return '';
}

const lastName = () => {
    const data = info()
    let lastName = data.last_name?data.last_name:''
    if(lastName){
        return lastName
    }
    return '';
}

const fullName = () => {
    const data = info()
    let firstName = data.first_name?data.first_name:''
    let lastName = data.last_name?data.last_name:''
    if(firstName && lastName){
        return firstName+' '+lastName
    }
    return '';
}

const email = () => {
    const data = info()
    return data.email?data.email:''
}

const userAvatar = () => {
    const data = info()
    return data.file_path?data.file_path:''
}

const userType = () => {
    const data = info()
    return data.user_type?data.user_type:''
}

const company = () => {
    const data = info()
    return data.company?data.company:''
}

const jobTitle = () => {
    const data = info()
    return data.job_title?data.job_title:''
}

const roleLabel = () => {
    const data = info()
    if(data.role){
        return data.role.label?data.role.label:''
    } else {
        return ''
    }
}
const workspaceId = () => {
    const data = info()
    return data.workspace_id?data.workspace_id:''
}
const _id = () => {
    const data = info()
    return data._id?data._id:''
}
export {
    info,
    firstName,
    lastName,
    fullName,
    email,
    domainUrl,
    userAvatar,
    userType,
    roleLabel,
    company,
    jobTitle,
    workspaceId,
    _id
}