/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../modules/auth/GetHelpers'
import ExternalWorkspaceHeaderSection from './ExternalWorkspaceHeaderSection';
import { useLocation } from 'react-router'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../redux/actions/externalPermissions';
import { ActionExternalWorkspacePermissions } from '../../../redux/actions/externalWorkspacePermissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const ExternalWorkspacesBasicDetailPage: FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [workspaceData, setworkspaceData]: any = useState<any[]>([]);
  const [workspaceUsersData, setworkspaceUsersData]: any = useState<any[]>([]);
  const [getDashboardsCount, setDashboardsCount]: any = useState<any>(0);
  const [getspinner, setspinner] = useState(false)
  const [roleEditProps, setroleEditProps] = useState<any[]>([]);
  const [showEditUserModal, setshowEditUserModal]: any = useState(false)
  const [showHidePasswordModal, setshowHidePasswordModal]: any = useState(false)
  const [workspaceUserId, setworkspaceUserId]: any = useState('');
  const [tabDashboar, settabDashboar] = useState(false)
  const [rowprops, setrowprops] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(true)
  const [getPageKey, setPageKey] = useState(0)
  const location = useLocation();
  const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
  const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
  const [getExternalWorkspacePermissions, setUserAllExternalPermissions] = useState<any[]>([]);
  const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);
  const [getKey, setKey] = useState<any>(0);

  const showHideModel: any = () => {
    setShowModal(!setShowModal)
  }

  const showHideEditUserModal: any = () => {
    setshowEditUserModal(!setshowEditUserModal)
  }

  const showHideSetPasswordModal: any = () => {
    setshowHidePasswordModal(!setshowHidePasswordModal)
  }

  const rowPropData: any = (rowData: any) => {
    setrowprops(rowData);
  }

  const navigate = useNavigate();
  let { organisationUsersId }: any = useParams();

  const WorkspaceUsersURL = `${API_URL}/external/workspace/` + organisationUsersId

  const fetchWorkspaceUsersList: any = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const organizationsList = axios.get(
      WorkspaceUsersURL,
      config
    ).then(async (res: any) => {
      setDashboardsCount(res.data.dashboardsCount);
      setworkspaceUsersData(res.data.users);
      setworkspaceData(res.data.workspace)

      setComponentLoading(false)
      setPageKey(getPageKey+1)
      return [setworkspaceUsersData, setworkspaceData];
    }
    ).catch(console.log);
    return organizationsList
  }

  const dispatch = useDispatch();
  
  const fetchVerifyAccess = async () => {
    const API_URL = process.env.REACT_APP_API_URL
    const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
    const ApiToken = localStorage.getItem('api_token')
    const BearerToken = `Bearer ${ApiToken}`
    const config = {
      headers: { Authorization: BearerToken }
    };
    axios.get(
        GET_USER_BY_ACCESSTOKEN_URL,
        config
    )
      .then(async (res) => {
        if (res.data.status) {
          if(res.data.internalPermissions){
            if(res.data.internalPermissions.permissions){
              dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
              setUserInternalPermissions(res.data.internalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.externalPermissions){
            // console.log('res.data.externalPermissions=',res.data.externalPermissions)
            if(res.data.externalPermissions.permissions){
              // getWPermissions = res.data.externalPermissions.permissions
              var data = getHelpers.doesHasPermissions(res.data.externalPermissions.permissions,organisationUsersId)
              dispatch(ActionExternalPermissions.externalUserPermissions(data))
              dispatch(ActionExternalWorkspacePermissions.externalWorkspacePermissions(res.data.externalPermissions.permissions))
              setUserExternalPermissions(data)
              setUserAllExternalPermissions(res.data.externalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.internalRoles){
            if(res.data.internalRoles.roles){
              dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
              setUserInternalRoles(res.data.internalRoles.roles)
              setKey(getKey+1)
            }
          }
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchVerifyAccess()
      window.addEventListener('loadBasicDetails', (event:any) => {
        if(event['detail'].value){
          setPageKey(getPageKey+1)
          console.log('addEventListener loadBasicDetails')
          fetchWorkspaceUsersList()
        }
      })
  
      fetchWorkspaceUsersList()

    // }

  }, [organisationUsersId])

  const data: any = workspaceUsersData;
  return (
    <>
      {
        _.includes(store.getState().userExternalData.externalPermissions,'view_workspaces')?
        (
          <div key={getPageKey}>
        
            <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">{workspaceData.name} Workspace</h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <Link to={'/'} className="text-muted text-hover-primary">Home</Link>
                  </li>
                  <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                  <li className="breadcrumb-item text-muted">
                    {workspaceData.name}
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center gap-2 gap-lg-3">
              </div>
            </div>

            <ExternalWorkspaceHeaderSection organizationId={organisationUsersId} fetchWorkspaceUsersList={fetchWorkspaceUsersList} workspaceData={workspaceData} workspaceUsersData={workspaceUsersData} getDashboardsCount={getDashboardsCount} getComponentLoading={getComponentLoading} />

            <div className='card mb-5 mb-xl-10'>

                {
                    getComponentLoading ?
                    (
                        <ComponentLoading></ComponentLoading>
                    ) : ('')
                }

                <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Workspace Details</h3>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    <div className='pb-2'>
                        
                        <div id='kt_account_profile_details' className='collapse show'>
                            <div className='card-body pb-3 border-top p-9'>
                                <div className='mb-5'>
                                
                                    <div className="row mb-7">
                                        <label className="col-lg-4 fw-bold text-muted">Workspace Name</label>
                                        <div className="col-lg-8">
                                        {
                                            workspaceData.name?
                                            (
                                            <span className="fw-bolder fs-6">{workspaceData.name}</span>
                                            ):(
                                            <span className="fw-bolder fs-6">N/A</span>
                                            )
                                        }
                                        </div>
                                    </div>
                                
                                <div className="row mb-7">
                                    <label className="col-lg-4 fw-bold text-muted">Organization</label>
                                    <div className="col-lg-8">
                                    {
                                        workspaceData.organization_ids?
                                        (
                                          workspaceData.organization_ids.length > 0?
                                          (
                                            workspaceData.organization_ids[0]?
                                            (
                                              workspaceData.organization_ids[0].name?
                                              (
                                                <span className="fw-bolder fs-6">{workspaceData.organization_ids[0].name}</span>
                                              ):(
                                                <span className="fw-bolder fs-6">N/A</span>
                                              )
                                            ):(
                                              <span className="fw-bolder fs-6">N/A</span>
                                            )
                                          ):(
                                            <span className="fw-bolder fs-6">N/A</span>
                                          )
                                        ):(
                                          <span className="fw-bolder fs-6">N/A</span>
                                        )
                                    }
                                    </div>
                                </div>

                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
          </div>
        ):(
          <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
      )
      }
    </>
  )
}

// export { ExternalWorkspacesBasicDetailPage }
export default connect(mapStateToProps)(ExternalWorkspacesBasicDetailPage);