import React, { useState } from 'react'
import { isNotEmpty, toAbsoluteUrl } from '../../../../_metronic/helpers'
// import { IProfileDetails, profileDetailsInitValues as initialValues } from './../../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
import { useNavigate } from "react-router-dom";
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
export type Props = {
  getchangeEmail() : any,
  // required : true
}
{/* <Props>/ */}
const ProfileChangeEmail: React.FC<Props> = ({getchangeEmail}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  // const [getchangeEmail, setchangeEmail] = useState(false)
  // const [getchangePassword, setchangePassword] = useState(false)

  const [getEmail, setEmail] = useState(getUserInfo.email())
  const [getPassword, setPassword] = useState('')

  const editProfileSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    confirmemailpassword: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(50, 'Maximum 50 characters')
      // .matches(
      //   /^(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   'Need one special character'
      // )
      .required('Password is required'),
  })

  const formik = useFormik({
    initialValues: {
      email: getEmail,
      confirmemailpassword: getPassword,
    },
    validationSchema: editProfileSchema,
    onSubmit: async (values,) => {
      // setSubmitting(true)
      // setLoading(true)
      // alert(0)
      try {
        // alert(0);
        // setLoading(true)
        // updateProfile(values)
      } catch (ex) {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        console.error(ex)
      } finally {
        // setLoading(false)
        // setSubmitting(true)
      }
    },
  })

  const updateProfile = (data: any) => {
    // console.log('updateProfile=',data)
    // return;
    axios.put(process.env.REACT_APP_BASE_API_URL + 'me', data)
      .then(async (res) => {
        setLoading(false)
        if (res.data.status) {
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        } else {
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        // console.log();
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        setLoading(false)
        // fetchorganizationsList();
      })
  }

  const updateEmailBtn = () => {
    // alert('updateEmailBtn')s
  }
  const submitErrorCheck = () => {
    if (JSON.stringify(formik.errors) !== '{}') {
      return true;
    } else {
      if (formik.values.email.length == 0 || formik.values.confirmemailpassword.length == 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  const checkFunction = (ev: any) => {
    ev.preventDefault();
    setLoading(true)
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.put(process.env.REACT_APP_BASE_API_URL + 'me', formik.values, config)
      .then(async (res) => {
        if (res.data.status) {
          let localStorageUserData: any = await localStorage.getItem('userinfo');
          localStorageUserData = await JSON.parse(localStorageUserData);
          let resUserdata = res.data.user;
          localStorageUserData.email = resUserdata.email;
          localStorageUserData = await JSON.stringify(localStorageUserData)
          await localStorage.setItem('userinfo', localStorageUserData)

          setLoading(false)
          // console.log(res.data)
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
          getchangeEmail();

          navigate('/profile/settings');
          // getchangeEmail = true 
          // 
        } else {
          toast.error(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
          setLoading(false)
        }
      })
      .catch(error => {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        setLoading(false)
        // fetchorganizationsList();
      })
  }
  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='row'>
          <div className='col-lg-6 fv-row'>
            <label htmlFor="">Enter New Email Address</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
              placeholder='Email'
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.email}</div>
              </div>
            )}
          </div>
          <div className='col-lg-6 fv-row'>
            <label htmlFor="">Current Password</label>
            <input
              type='password'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
              placeholder='********'
              {...formik.getFieldProps('confirmemailpassword')}
            />
            {formik.touched.confirmemailpassword && formik.errors.confirmemailpassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.confirmemailpassword}</div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-6 mt-5'>
          <div className='col-lg-12'>

            <button
              // type='submit'
              className='btn btn-primary'
              disabled={submitErrorCheck()}
              onClick={(event) => checkFunction(event)}
            // onClick={(event)=>{checkfunction(event)}}
            >
              {!loading && 'Update Email'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {/* <button className='btn btn-primary'>Cancel</button> */}
          </div>
        </div>
      </form>
    </>
  )
}

export { ProfileChangeEmail }