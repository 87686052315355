
import { FC, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { useParams, useNavigate } from "react-router-dom";
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';

const API_URL      = process.env.REACT_APP_API_URL
const ApiToken     = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

interface PostsProps {
    showHideSetPasswordModal: any;
    showHidePasswordModal: any;
    organizationId: any;
    userId: any;
    fetchorganizationsUsersList(): any;
}

const OrganizationUserSetPassModal: FC<PostsProps> = ({ showHideSetPasswordModal, showHidePasswordModal, organizationId, userId, fetchorganizationsUsersList }) => {

    let { organisationUsersId }: any = useParams();
    const [getspinner, setspinner] = useState(false)
    const [singleUser, setsingleuser]: any = useState<any[]>([]);


    const setPasswordSchema = Yup.object().shape({

        password: Yup.string()
            .min(8, 'Minimum 8 characters')
            .max(50, 'Maximum 8 characters')
            // .matches(
            //     /^(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            //     'Need one special character'
            // )
            .required('Password is required'),

        confirm_password: Yup.string()
            .min(8, 'Minimum 8 characters')
            .max(50, 'Maximum 8 characters')
            .required('Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    })


  

    const fetchOrganizationUserForPassword: any = () => {
        // console.log("inn function")
        const fetchOrgUserAPI = `${API_URL}/users/` + userId
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const singleuserData = axios.get(
            fetchOrgUserAPI,
            config
        ).then(async (res: any) => {

            await setsingleuser(res.data);
            return setsingleuser;

        }
        ).catch(console.log);
        return singleuserData

    }



    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: setPasswordSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {

                const formDataObj = {
                    password: values.password,
                    confirm_password:values.confirm_password

                }
                
                setspinner(true)
                updateOrganizationPassword(formDataObj)
                // }
            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });


                console.error(ex)
            } finally {

                setSubmitting(true)
                // cancel(true)
            }
        },
    })

    const updateOrganizationPassword = (data: any) => {
        // organizationId,userId
        axios.put(process.env.REACT_APP_BASE_API_URL + 'internal/users/'+ userId +'/password', data)
            .then((res) => {
                // console.log("response=",res);
                setspinner(false)
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                        showHideSetPasswordModal();

                    window.dispatchEvent(new CustomEvent('dispatchWorkspaceUsers',{
                        detail: {
                            value: true
                        }
                    }))

                } else {

                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                        showHideSetPasswordModal();
                }
            })
            .catch(error => {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)
                // fetchorganizationsUsersList();
            })
    }


    const notify = () => {

        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });

        }
    }


    useEffect(() => {
        // internalRolePermissionList()
        fetchOrganizationUserForPassword()

    }, [])




    return (
        <div>
            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showHidePasswordModal}
                animation={false}
            >
                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        <div className='modal-content'>
                            <div className='modal-header bg-primary'>
                                <h2 className='fw-bolder text-white'>Update Password </h2>
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => { showHideSetPasswordModal() }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                                <div className='modal-body scroll-y pb-2'>
                                    <div
                                        className='d-flex flex-column scroll-y me-n7 pe-7'
                                        id='kt_modal_add_user_scroll'
                                        data-kt-scroll='true'
                                        data-kt-scroll-activate='{default: false, lg: true}'
                                        data-kt-scroll-max-height='auto'
                                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                        data-kt-scroll-offset='300px'
                                    >
                                        <div className="row">                                            
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Password </label>
                                                <input
                                                    placeholder='Password'
                                                    {...formik.getFieldProps('password')}
                                                    type='password'
                                                    name='password'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.password && formik.errors.password },
                                                        {
                                                            'is-valid': formik.touched.password && !formik.errors.password,
                                                        }
                                                    )}
                                                    autoComplete='off'
                                                    disabled={formik.isSubmitting}
                                                />
                                                {formik.touched.password && formik.errors.password && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.password}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Confirm Password </label>
                                                <input
                                                    placeholder='Password'
                                                    {...formik.getFieldProps('confirm_password')}
                                                    type='password'
                                                    name='confirm_password'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password },
                                                        {
                                                            'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                                                        }
                                                    )}
                                                    autoComplete='off'
                                                    disabled={formik.isSubmitting}
                                                />
                                                {formik.touched.confirm_password && formik.errors.confirm_password && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.confirm_password}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        onClick={() => notify()}
                                    >{!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button
                                        type='button' // {()=>{showHideModel(cancelModel)}}
                                        onClick={() => showHideSetPasswordModal()}
                                        className='btn btn-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
            </Modal>

        </div>

    )

}

export { OrganizationUserSetPassModal }