/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link , useNavigate } from "react-router-dom";
// import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ExternalRolesAddModel } from './ExternalRolesAddModel'
import { ExternalRoleEditModel } from './ExternalRolesEditModel'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';

import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import * as getUserInfo from '../../../app/modules/auth/GetUserInfo'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// import withReactContent from 'sweetalert2-react-content'
// import '@sweetalert2/src/sweetalert2.scss'
// const MySwal = withReactContent(Swal)
const API_URL = process.env.REACT_APP_API_URL
const enternalRolesPermissionsUrl = `${API_URL}/external/roles-permissions`
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
type Props = {
  className: string
}

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const ExternalRolesTable: React.FC<Props> = ({ className }) => {

  const [enternalRolesPermissionsListResult, setenternalRolesPermissionsListResult] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setshowEditModal] = useState(false)
  const [roleEditProps, setroleEditProps] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(true)
  
  const [getStorePermissions, setStorePermissions] = useState<any>({})
  const [getStoreRoles, setStoreRoles] = useState<any>({})

  const navigate = useNavigate();
  const showHideModel = () => {
    setShowModal(!setShowModal)
    enternalRolePermissionList();
  }
  const showHideEditModal = () => {
    setshowEditModal(!setshowEditModal)
    enternalRolePermissionList();
  }
  const enternalRolePermissionList = () => {

    const config = {
      headers: { Authorization: BearerToeken }
    };
    const enternalRolesList = axios.get(
      enternalRolesPermissionsUrl,
      config
    ).then(async (res) => {
      const { data: rolespermissionsDataResult } = res
      setComponentLoading(false)
      return await setenternalRolesPermissionsListResult(rolespermissionsDataResult)
    }
    ).catch((error) => {
      setComponentLoading(false)
      console.log(error)
    });
    return enternalRolesList

  }

  useEffect(() => {
    
    setTimeout(() => {
      setStorePermissions(store.getState().userData.permissions)
      setStoreRoles(store.getState().userRolesData.roles)

      // setTimeout(() => {
      //   if(!_.includes(store.getState().userData.permissions,'manage_settings')){
      //     navigate("/");
      //   }
      // }, 200);

    }, 100);

    // const config = {
    //   headers: { Authorization: BearerToeken }
    // };
    // axios.get(
    //   GET_USER_BY_ACCESSTOKEN_URL,
    //   config
    // )
    // .then(async (res) => {
    // if (res.data.status) {
    //     if (res.data.userType == 'external') {

    //     navigate('/error/404')
    //     }
    // } else if (res.data.userType == 'User type not found') {
    //     if (getUserInfo.userType() == 'external') {

    //     navigate('/error/404')
    //     }
    // }
    // })
    // .catch((error) => {
    // console.clear()
    // navigate('/error/404')
    // });
    enternalRolePermissionList();
  }, [getStorePermissions,getStoreRoles]);

  const deleteEnternalRole = async (id: any) => {
    Swal.fire({
      title: getHelpers.ConfirmDeleteMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/roles/' + id,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
              enternalRolePermissionList();
            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
            // console.log(res.data)
          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          });
      }
    })
  }

  const sendRoleEditProps = (editprop: any) => {
    // console.log('editprop',editprop)
    // console.log('editprop',editprop)
    setroleEditProps(editprop)
  }
  return (

    <div >
      <ToastContainer />

      <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Roles</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
            </li>
            <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
            <li className="breadcrumb-item text-muted">Roles</li>
          </ul>
        </div>
        <div className="d-flex align-items-center gap-2 gap-lg-3">
          {
            _.includes(getStorePermissions,'manage_settings')?
            (
              <a className="btn btn-sm fw-bolder btn-primary customIconButton" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => { setShowModal(true) }}>
                  <i className="bi bi-person-plus-fill btnIcon"></i>
                  <span className='btnTxt'>Add Role</span> 
              </a>
            ):('')
          }
        </div>
      </div>

      {
        getComponentLoading ?
          (
            <div className="card overflow-hidden">
              <div className="card-body set_scroll">
                <ComponentLoading></ComponentLoading>
              </div>
            </div>
          ) : ('')
      }

      {
        enternalRolesPermissionsListResult.length > 0 ?
          (
            <div className='row'>
              {
                enternalRolesPermissionsListResult.map((role, index) => {
                  return (
                    <div className='col-md-6 col-lg-4'>

                      <div className="card role_card_setting mb-6">
                        <div className="card-header bg-primary">
                          <div className="card-title mb-0">
                            <div>
                              <h3 className="card-label text-white"><span className='fw-bolder'>{role.label}</span> ({role.name})</h3>
                              <span className='text-white mt-1 fw-semibold fs-7'>Total permisions with this role </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-body set_scroll">
                          {role.permissions.map((externalPermissions: any) => (
                            <div>
                              <ul>
                                <li key={externalPermissions._id}>
                                  <strong>{externalPermissions.label} </strong>
                                  ({externalPermissions.name})
                                </li>
                              </ul>
                            </div>
                          )
                          )}
                        </div>
                        {
                          _.includes(getStorePermissions,'manage_settings')?
                          (
                            <div className="card-footer d-flex flex-fill">
                              <button type="button" className="btn btn-light w-100 btn-sm fw-bolder m-1 btn-primary" onClick={() => { setshowEditModal(true); sendRoleEditProps(enternalRolesPermissionsListResult[index]) }} ><i className='fa fa-edit'></i> Edit</button>
                              <button type="button"disabled className="btn btn-light w-100 btn-sm fw-bolder m-1 btn-light" onClick={() => { deleteEnternalRole(role._id) }}  ><i className='fa fa-trash'></i> Delete</button>
                            </div>
                          ):('')
                        }
                      </div>

                      {/* <div className={`card ${className}`} style={{ padding: '20px' }}>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1' >
                          <span style={{ textTransform: 'capitalize' }}>{role.label}</span> ({role.name})</span><br />
                        <span className='text-muted mt-1 fw-semibold fs-7'>Total permisions with this role </span>
                      </h3>
                      {role.permissions.map((externalPermissions: any) => (
                        <div>
                          <ul>
                            <li key={externalPermissions._id}>{externalPermissions.label} ({externalPermissions.name})</li>
                          </ul>
                        </div>
                      )
                      )}
                      <button type="button" className="btn btn-light btn-sm fw-bolder m-1 btn-light" onClick={() => { setshowEditModal(true); sendRoleEditProps(enternalRolesPermissionsListResult[index]) }} >Edit Role</button>
                      <button type="button" className="btn btn-light btn-sm fw-bolder m-1 btn-light" onClick={() => { deleteEnternalRole(role._id) }}  >Delete Role</button>
                    </div> */}

                    </div>
                  )
                })
              }
            </div>
          ) : (
            ''
            // <div dangerouslySetInnerHTML={{ __html: getHelpers.NoDataFound() }}></div>
          )
      }


      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_modal_add_role'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={showModal}
        animation={false}
      >
        <ExternalRolesAddModel showModal={showHideModel} />
      </Modal>
      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_modal_edit_role'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={showEditModal}
        animation={false}
      >
        <ExternalRoleEditModel showEditModal={showHideEditModal} editProps={roleEditProps} />
      </Modal>
    </div>

  )
}

// export { ExternalRolesTable }
export default connect(mapStateToProps)(ExternalRolesTable);
