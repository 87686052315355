/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { getLayout, ILayout, LayoutSetup, useLayout } from '../../../_metronic/layout/core'
import { toPng } from 'html-to-image';
import { Routes, Route, useParams } from "react-router-dom";
//////////////////////////////////////////////////////////
//Begin: GoodData Dashboard///////////////////////////////
//////////////////////////////////////////////////////////
import "@gooddata/sdk-ui-filters/styles/css/main.css";
import "@gooddata/sdk-ui-charts/styles/css/main.css";
import "@gooddata/sdk-ui-geo/styles/css/main.css";
import "@gooddata/sdk-ui-pivot/styles/css/main.css";
import "@gooddata/sdk-ui-kit/styles/css/main.css";
import "@gooddata/sdk-ui-ext/styles/css/main.css";
import bearFactory, { ContextDeferredAuthProvider } from "@gooddata/sdk-backend-bear";
import { BackendProvider, WorkspaceProvider } from "@gooddata/sdk-ui";
import "@gooddata/sdk-ui-dashboard/styles/css/main.css";
import axios from 'axios';
import { Dashboard, DashboardConfig, HiddenTopBar, DefaultDashboardInsight, IDashboardInsightProps } from "@gooddata/sdk-ui-dashboard";
import { idRef, insightId } from "@gooddata/sdk-model";
import { setTimeout } from 'timers';
import { getFactory } from "@gooddata/api-client-bear";
import { ITopBarProps } from "@gooddata/sdk-ui-dashboard/esm/presentation/topBar/topBar/types";
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
let backendUrl: any = process.env.REACT_APP_GD_BACKEND_URL
const backend = bearFactory()
  .onHostname(backendUrl)
  .withAuthentication(new ContextDeferredAuthProvider());
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
var dashboard_id = '';
var workspaceId = '';
const GoodDataDashboardTestPage: React.FC = () => {
  const { setLayout } = useLayout()
  const ref = useRef<HTMLDivElement>(null)
  const [tab, setTab] = useState('Header')
  const [checkSsoLogin, setcheckSsoLogin] = useState<boolean>(false)
  // const [splashScreen, setsplashScreen] = useState<boolean>(true)
  const [setHeight, setsetHeight] = useState(550);
  const [loadingMessage, setloadingMessage] = useState<any>('');
  const [loadingMessageError, setloadingMessageError] = useState<boolean>(false)
  const [hyperlinkText, sethyperlinkText] = useState<boolean>(false)


  let params = useParams();
  const Config: DashboardConfig = {
    isReadOnly: false,
    // hideSaveAsNewButton: true
    // disableDefaultDrills : false
    // initialRenderMode: "edit"
  };
  const dashboardParamId = params.dashboardId;
  const getDashboardById = async () => {
    // alert('step 3 fetch dashboard req started');
    setloadingMessage('Step 3 loading...')
    const config = {
      headers: { Authorization: BearerToeken }
    };
    await axios.get(
      process.env.REACT_APP_API_URL + '/dashboard/' + dashboardParamId,
      config
    )
      .then(async (res) => {
        if (res.data.gd_dashboard) {
          if (res.data.gd_dashboard[0]) {
            dashboard_id = res.data.gd_dashboard[0].identifier
            workspaceId = res.data.gd_dashboard[0].workspaceId
          }
          // alert('step 3 fetch dashboard req completed')
          // setloadingMessage('Step 3 loading...')
          // return true;
        } else {
          // alert('step 3 fetch issue problem')
          setloadingMessage('There is some problem with the dashboard.')
          // return false;
          setloadingMessageError(true);

        }
      })
      .catch((error) => {
        setloadingMessage('There is some problem with the dashboard.')
        setloadingMessageError(true);
        // return false;
      });
  }
  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return
    }
    toPng(ref.current, { cacheBust: true, })
      .then((screenShotImageBase64) => {
        let captureObj = {
          dashboard_id: dashboardParamId,
          base64Image: screenShotImageBase64
        }
        axios.post(
          process.env.REACT_APP_BASE_API_URL + 'dashboard/screenShotSave', captureObj
        )
          .then(async (res) => {
          })
          .catch((error) => {
          });
      })
      .catch((err) => {
        //console.log(err)
      })


  }, [ref])
  useEffect(() => {
    // alert('Step 2 view dashboard component')
    setsetHeight(window.innerHeight - 110.5);
    setloadingMessage('Step 2 loading...')
    const fetchDashboardData = async () => {
      const config = {
        headers: { Authorization: BearerToeken }
      };
      await axios.get(
        process.env.REACT_APP_API_URL + '/dashboard/' + dashboardParamId,
        config
      )
        .then(async (res) => {
          // setsplashScreen(false)
          if (res.data.gd_dashboard) {
            if (res.data.gd_dashboard[0]) {
              dashboard_id = res.data.gd_dashboard[0].identifier
              workspaceId = res.data.gd_dashboard[0].workspaceId
              setloadingMessage('Step 3 loading...')
              if (getUserInfo.userType() == 'external') {
                setloadingMessage('Step 4 loading...')
                axios.get(
                  process.env.REACT_APP_BASE_API_URL + 'gooddata/checking-user/'+workspaceId,
                )
                  .then(async (res) => {
                    let resData = await res.data.newUser;
                    let resSplit = resData.split('/')
                    // setloadingMessage('Step 4 loading...')
                    ssoEnvironment(resSplit[4])
                  })
                  .catch((error) => {
                    console.log('check user error problem', error);
                    setloadingMessage('There is some problem with the dashboard.')
                    setloadingMessageError(true);
                  });
              } else {
                setloadingMessage('Step 4 loading ...')
                axios.get(
                  process.env.REACT_APP_BASE_API_URL + `gooddata/internal/${dashboardParamId}/check-user`,
                )
                  .then(async (res) => {
                    // console.log('res',res.data);
                    let resData = await res.data.newUser;
                    let resSplit = resData.split('/')
                    // console.log('res',resSplit);
                    // setloadingMessage('Step 4 loading...')
                    ssoEnvironment(resSplit[4])
                  })
                  .catch((error) => {
                    console.log('check user error problem', error);
                    setloadingMessage('There is some problem with the dashboard.')
                    setloadingMessageError(true);
                  });
              }
            } else {
              console.log('Cannot find gd_dashboard Object');
              setloadingMessageError(true);
            }
          } else {
            console.log('Cannot find gd_dashboard');
            setloadingMessage('There is some problem with the dashboard loading ...')
            setloadingMessageError(true);
          }
        })
        .catch((error) => {
          console.log('gd_dashboard problem', error);
          setloadingMessage('There is some problem with the dashboard.')
          setloadingMessageError(true);
        });
    }
    fetchDashboardData();

  }, []);
  const dashboardID = idRef(dashboard_id);
  const ssoEnvironment = (userId: any) => {
    setloadingMessage('Step 5 loading ...');
    axios.post(process.env.REACT_APP_BASE_API_URL + 'openpgp/sso-environment', {
      userId: userId
    })
      .then((res) => {
        // setloadingMessage('Step 5 SSO login req 1 completed');
        getEncryptedClaims(res.data)
      }).catch((error) => {
        console.log('There are some problem with sso enviroment', error);
        setloadingMessage('There is some problem with the dashboard.')
        setloadingMessageError(true);
      });
  }

  var ssoLoginData = ''

  const getEncryptedClaims = async (resData: any) => {
    setloadingMessage('Step 5 loading...');
    axios.post(process.env.REACT_APP_BASE_API_URL + 'openpgp/encrypted-claims', resData.data)
      .then(async (res) => {
        await localStorage.setItem("encryptedClaims", JSON.stringify(res.data.encryptedClaim))
        await localStorage.setItem("ssoProvider", JSON.stringify(res.data.userId))
        const domain = await process.env.REACT_APP_GD_BACKEND_URL;
        const sdk = await getFactory({ domain });
        // console.log('res.data.encryptedClaim=',res.data.encryptedClaim)
        const encryptedClaims = await JSON.parse(res.data.encryptedClaim)
        const ssoProvider = await res.data.userId;
        const targetUrl = "/gdc/account/token";
        sdk.user
          .loginSso(encryptedClaims, ssoProvider, targetUrl)
          .then((response) => {
            ssoLoginData = response.responseBody
            setcheckSsoLogin(true)
            // setloadingMessage('Step 5 SSO login req 2 completed');
            setTimeout(() => {
              onButtonClick();
            }, 5000);
          })
          .catch(error => {
            console.log('There are some problem in customer login', error);
            // setloadingMessage('This  dashboard could not be loaded. One of this reason could be that your browser did not allow third party cookies.')
            setloadingMessage('The dashboard could not be loaded.')
            sethyperlinkText(true)
            // setloadingMessageError(true);
          });
      }).catch(error => {
        console.log('There are some problem in encrypted claims', error);
        setloadingMessage('There is some problem with the dashboard')
        setloadingMessageError(true);
      });
  }
  const screenReload = () => {
    window.location.reload();
  }
  const ClickableInsight = (props: IDashboardInsightProps) => {
    console.log('props', props);
    return (
      <div
        style={{ height: "100%" }}
        onClick={() => {
          //insightId(props.insight)
          console.log("insight clicked, perform the eventing",);
        }}
      >
        <DefaultDashboardInsight {...props} />
      </div>
    );
  };
  return (
    <>
      {/* {
        splashScreen == true ?
          <div id="splash-screen" className="splash-screen" style={{ zIndex: 2000 }}>
            <span style={{ fontSize: '1.75rem', fontWeight: '600', fontFamily: 'Averia Serif Libre' }}>stringo media</span>
            <span>Loading ...</span>
          </div>
          :
          ('')
      } */}
      <div className='gdDashboardView' style={{ 'height': setHeight }} ref={ref}>

        {
          checkSsoLogin ?
            <>
              {
                workspaceId != ''
                  ?
                  <BackendProvider backend={backend} >
                    <WorkspaceProvider workspace={workspaceId} >
                      <Dashboard dashboard={dashboardID}
                        TopBarComponent={HiddenTopBar}
                        config={Config}
                        InsightComponentProvider={() => {
                          return ClickableInsight;
                        }}
                      />
                    </WorkspaceProvider>
                  </BackendProvider>
                  :
                  ('')
              }
            </>
            :
            (
              <div className="componentLoading bg-white">
                <div id="splash-screen" className="splash-screen" style={{ zIndex: 2000 }}>
                  {
                    hyperlinkText || loadingMessageError?
                    (
                      <>
                        <img src="/media/logos/loading-icon.svg" className='loadingIcon width-30' alt="Stringo Media | Analytics Platform" />
                      </>
                    ):(
                      <>
                        <img src={toAbsoluteUrl('/media/logos/loading-icon.gif')} className='loadingIcon' alt="Stringo Media | Analytics Platform" />
                        {/* <div className="rotating loadingIcon">
                          <img className="front" src={toAbsoluteUrl('/media/black-icon-front.svg')} />
                          <img className="back" src={toAbsoluteUrl('/media/black-icon-back.svg')} />
                        </div> */}
                      </>
                    )
                  }
                  {/* <span style={{ fontSize: '1.75rem', fontWeight: '600', fontFamily: 'Averia Serif Libre' }}>stringo media</span> */}
                  <span >{loadingMessage} { hyperlinkText == true? <><a className='link cursor-pointer' onClick={screenReload}>Please reload</a>.</> : ('') }</span>
                  {
                    loadingMessageError == true

                      ?
                      <div style={{ marginTop: '5px' }}><button onClick={screenReload} className='btn btn-sm btn-primary'>Try again</button></div>
                      :
                      ('')

                  } 
                  {/* {
                    hyperlinkText == true
                      ?
                      <div style={{ marginTop: '5px' }}><a href='https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Callow-or-block-cookies' target="_blank"> Try this  </a> and relod this page.</div>
                      :
                      ('')

                  } */}
                </div>
              </div>
            )
        }

      </div>
    </>
  )
}
export { GoodDataDashboardTestPage }
