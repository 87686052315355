/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';

import { KTSVG } from '../../../_metronic/helpers'

import { useLocation } from 'react-router'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../modules/auth/GetHelpers'
import * as getUserInfo from '../../modules/auth/GetUserInfo'

import * as Yup from 'yup'
import clsx from 'clsx'
import { FastField, useFormik } from 'formik'
import { any } from 'lodash/fp';

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../redux/actions/externalPermissions';
import { ActionExternalWorkspacePermissions } from '../../../redux/actions/externalWorkspacePermissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}


const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

interface SectionProps {
    organizationId: any
    fetchWorkspaceUsersList(): any;
    workspaceData: any;
    workspaceUsersData: any;
    getDashboardsCount: any;
    getComponentLoading: any
}
const ExternalWorkspaceHeaderSection: FC<SectionProps> = ({ organizationId, fetchWorkspaceUsersList, workspaceData, workspaceUsersData, getDashboardsCount, getComponentLoading }) => {

    const navigate = useNavigate();
    let { organisationUsersId }: any = useParams();

    const [showModal, setShowModal] = useState(false)
    const [getspinner, setspinner] = useState(false)
    const [roleEditProps, setroleEditProps] = useState<any[]>([]);

    const [showEditUserModal, setshowEditUserModal]: any = useState(false)
    const [showHidePasswordModal, setshowHidePasswordModal]: any = useState(false)


    const [orgUserId, setorgUserId]: any = useState('');
    const [tabDashboar, settabDashboar] = useState(false)
    const [rowprops, setrowprops] = useState<any[]>([]);

    const location = useLocation();
    const [getComponentCustomLoading, setComponentLoading] = useState(true)
    const [orgDashboardsListState, setOrgDashboardsListState] = useState<any[]>([]);
    const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
    const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
    const [getExternalWorkspacePermissions, setUserAllExternalPermissions] = useState<any[]>([]);
    const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);
    const [getKey, setKey] = useState<any>(0);

    ////////////
    const orgDashboardsUrl = `${API_URL}/external/workspace/dashboards/` + organizationId+ '/active'

    const fetchWorkspaceList: any = () => {
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const organizationsListList = axios.get(
            orgDashboardsUrl,
            config
        )
            .then(async (res: any) => {
                setComponentLoading(false)
                return setOrgDashboardsListState(res.data)
            }
            )
            .catch((error) => {
                // console.log(error)
                setComponentLoading(false)
            }
            );
        return organizationsListList
    }
    const showHideModel: any = () => {
        setShowModal(!setShowModal)
    }
    const showHideEditUserModal: any = () => {
        setshowEditUserModal(!setshowEditUserModal)
    }

    const showHideSetPasswordModal: any = () => {
        setshowHidePasswordModal(!setshowHidePasswordModal)
    }

    const sendRoleEditProps = (editprop: any) => {
        formik.values.name = editprop.name
    }

    const rowPropData: any = (rowData: any) => {
        setrowprops(rowData);
    }

    // console.log("id=",organizationId)

  const dispatch = useDispatch();
  
  const fetchVerifyAccess = async () => {
    const API_URL = process.env.REACT_APP_API_URL
    const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
    const ApiToken = localStorage.getItem('api_token')
    const BearerToken = `Bearer ${ApiToken}`
    const config = {
      headers: { Authorization: BearerToken }
    };
    axios.get(
        GET_USER_BY_ACCESSTOKEN_URL,
        config
    )
      .then(async (res) => {
        if (res.data.status) {
          if(res.data.internalPermissions){
            if(res.data.internalPermissions.permissions){
              dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
              setUserInternalPermissions(res.data.internalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.externalPermissions){
            // console.log('res.data.externalPermissions=',res.data.externalPermissions)
            if(res.data.externalPermissions.permissions){
              // getWPermissions = res.data.externalPermissions.permissions
              var data = getHelpers.doesHasPermissions(res.data.externalPermissions.permissions,organisationUsersId)
              dispatch(ActionExternalPermissions.externalUserPermissions(data))
              dispatch(ActionExternalWorkspacePermissions.externalWorkspacePermissions(res.data.externalPermissions.permissions))
              setUserExternalPermissions(data)
              setUserAllExternalPermissions(res.data.externalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.internalRoles){
            if(res.data.internalRoles.roles){
              dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
              setUserInternalRoles(res.data.internalRoles.roles)
              setKey(getKey+1)
            }
          }
        }
      })
      .catch((error) => {});
  }

    useEffect(() => {
        fetchVerifyAccess()
        // fetchWorkspaceUsersList()
        fetchWorkspaceList()
    }, [])


    const deleteOrganization = async () => {

        Swal.fire({
            title: getHelpers.ConfirmArchiveMsg(),
            icon: getHelpers.ConfirmDeleteIcon().toString(),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Archive'
        }).then((result) => {
            if (result.isConfirmed) {
                const config = {
                    headers: { Authorization: BearerToeken }
                };
                axios.delete(
                    process.env.REACT_APP_API_URL + '/internal/workspace/' + organizationId,
                    config
                )
                    .then(async (res) => {
                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });

                            setTimeout(() => {                                
                                navigate('/internal/workspaces/list');
                            }, 1000);

                        } else {
                            toast.error(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }

                    })
                    .catch((error) => {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    });
            }
        })
    }

    const editOrganizationsModalSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .required('Name is required'),
    })
    const formik = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: editOrganizationsModalSchema,
        onSubmit: async (values) => {

            try {

                let formData: any = await {
                    name: values.name,
                }

                const config = {
                    headers: { Authorization: BearerToeken }
                };
                await axios.put(
                    process.env.REACT_APP_API_URL + '/workspace/' + organizationId,
                    formData,
                    config
                )
                    .then(async (res) => {

                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                            setspinner(false)
                            showHideModel()
                            fetchWorkspaceUsersList()
                        } else {
                            setspinner(false)
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    })
                    .catch((error) => {
                        setspinner(false)
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });

                    });

            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)

            }
        },
    })
    const submitErrorCheck = () => {
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }
    const chartFunc = (value: any) => {
        // console.log('value=', value.charAt(0))
        let chatAt_value = value.charAt(0);
        return chatAt_value;
      }
    return (
        <>
        {
            _.includes(store.getState().userExternalData.externalPermissions,'view_workspaces')?
            (
                
            <>
                <div className="card mb-5 mb-xl-7 overflow-hidden internalWorkspaceHeader">
                    {
                        getComponentCustomLoading ?
                            (
                                <ComponentLoading></ComponentLoading>
                            ) : ('')
                    }
                    <div className="card-body pt-9 pb-0">
                        <div className="mb-3 internalWorkspaceContainer">
                            <div className="me-7 mb-4 colLeft">
                                {/* {
                                    workspaceData.name ?
                                        (
                                            <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative">
                                                <span className={'symbol-label bg-light-danger text-white fs-30 fw-bolder bg-' + workspaceData.name.charAt(0)}>
                                                    {workspaceData.name.charAt(0)}
                                                </span>
                                            </div>
                                        ) : (
                                            'N/A'
                                        )
                                } */}

                                {
                                    workspaceData.avatar_id ?
                                    (
                                        workspaceData.avatar_id.file?
                                        (
                                            <>
                                            <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative text-uppercase">
                                                {/* <img src={getUserInfo.domainUrl()+workspaceData.avatar_id.file_path} alt='Avatar' /> */}
                                                <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase img-alt-100 bg-img-" + chartFunc(workspaceData.name)} src={getUserInfo.domainUrl()+workspaceData.avatar_id.file_path} alt={chartFunc(workspaceData.name)} />
                                            </div>
                                            </>
                                        ):(
                                            workspaceData.name ?
                                            (
                                                <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative text-uppercase">
                                                    <span className={'symbol-label bg-light-danger text-white fs-30 fw-bolder bg-' + workspaceData.name.charAt(0)}>
                                                        {workspaceData.name.charAt(0)}
                                                    </span>
                                                </div>
                                            ):('N/A')
                                        )
                                    ) : workspaceData.name ? (
                                        <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative text-uppercase">
                                            <span className={'symbol-label bg-light-danger text-white fs-30 fw-bolder bg-' + workspaceData.name.charAt(0)}>
                                                {workspaceData.name.charAt(0)}
                                            </span>
                                        </div>
                                    ):('N/A')
                                }

                            </div>
                            <div className="colRight">
                                <div className="hButtons d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center mb-2"><a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{workspaceData.name}</a></div>
                                    </div>
                                    {
                                        _.includes(store.getState().userExternalData.externalPermissions,'manage_workspaces')?
                                        (
                                            <div className="d-flex my-1">
                                                {/* <a className="btn btn-sm btn-light fw-bolder me-2" onClick={() => { deleteOrganization() }} >
                                                    <i className="bi bi-trash me-1"></i> Archive
                                                </a> */}
                                                {
                                                    location.pathname === '/workspace/' + organizationId + '/edit'?
                                                    (
                                                        <Link
                                                        to={'/workspace/'+organizationId+''}
                                                        className='btn btn-sm btn-primary fw-bolder'
                                                        >
                                                            {/* <i className="bi bi-eye me-1"></i>  */}
                                                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"/>
                                                                <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            View
                                                        </Link>
                                                    ):(
                                                        <Link
                                                        to={'/workspace/'+organizationId+'/edit'}
                                                        className='btn btn-sm btn-primary fw-bolder'
                                                        >
                                                            {/* <i className="bi bi-pencil-square me-1"></i>  */}
                                                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"/>
                                                                <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"/>
                                                                <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            Edit
                                                        </Link>
                                                    )
                                                }
                                                {/* <a className="btn btn-sm btn-primary fw-bolder" onClick={() => { setShowModal(true); sendRoleEditProps(workspaceData) }}>
                                                    <i className="bi bi-pencil-square me-1"></i> Edit
                                                </a> */}
                                            </div>
                                        ):('')
                                    }
                                </div>
                                <div className="countContainer d-flex flex-wrap flex-stack">
                                    <div className="d-flex flex-column flex-grow-1">
                                        <div className="d-flex flex-wrap">
                                            <div className="countCol border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bolder">{workspaceUsersData.filter((item: any) => item._id != '').length}</div>
                                                </div>
                                                <div className="fw-bold fs-6 text-gray-400">Users</div>
                                            </div>
                                            {/* <div className="countCol border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bolder">{orgDashboardsListState.length}</div>
                                                </div>
                                                <div className="fw-bold fs-6 text-gray-400">Dashboards</div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            _.includes(store.getState().userExternalData.externalPermissions,'view_workspaces') || _.includes(store.getState().userExternalData.externalPermissions,'view_users')?
                            (
                                <div className="customNavTabs">
                                    <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                                        {
                                            _.includes(store.getState().userExternalData.externalPermissions,'view_workspaces')?
                                            (
                                                <li className='nav-item' onClick={() => { settabDashboar(false) }}>
                                                    <Link
                                                        className={
                                                            `nav-link text-active-primary ` +
                                                            ((location.pathname === '/workspace/' + organizationId + '' || location.pathname === '/workspace/' + organizationId + '/edit') && 'active')
                                                        }
                                                        to={'/workspace/' + organizationId + ''}
                                                    >
                                                        Workspace Details
                                                    </Link>
                                                </li>
                                            ):('')
                                        }
                                        {
                                            _.includes(store.getState().userExternalData.externalPermissions,'view_users')?
                                            (
                                                <li className='nav-item' onClick={() => { settabDashboar(false) }}>
                                                    <Link
                                                        className={
                                                            `nav-link text-active-primary ` +
                                                            (location.pathname === '/users/' + organizationId && 'active')
                                                        }
                                                        to={'/users/' + organizationId}
                                                    >
                                                        Workspace Users
                                                    </Link>
                                                </li>
                                            ):('')
                                        }
                                        {/* <li className='nav-item' onClick={() => { settabDashboar(true) }}>
                                            <Link
                                                className={
                                                    `nav-link text-active-primary ` +
                                                    (location.pathname === '/internal/workspace/' + organizationId + '/dashboards' && 'active')
                                                }
                                                to={'/internal/workspace/' + organizationId + '/dashboards'}
                                            >
                                                Dashboards
                                            </Link>
                                        </li>
                                        <li className='nav-item' onClick={() => { settabDashboar(true) }}>
                                            <Link
                                                className={
                                                    `nav-link text-active-primary ` +
                                                    (location.pathname === '/internal/workspace/' + organizationId + '/dashboards/archive' && 'active')
                                                }
                                                to={'/internal/workspace/' + organizationId + '/dashboards/archive'}
                                            >
                                                Archived Dashboards
                                            </Link>
                                        </li> */}

                                    </ul>
                                </div>
                            ):('')
                        }
                    </div>
                </div>
                <div>
                    <Modal
                        className='modal-sticky custom_modal modal-sticky-lg modal-sticky-bottom-right'
                        id='kt_inbox_compose'
                        role='dialog'
                        data-backdrop='false'
                        aria-hidden='true'
                        tabIndex='-1'
                        show={showModal}
                        animation={false}
                    >
                        <div className="modal-content bg-white">
                            <div className="modal-header bg-primary">
                                <h2 className="fw-bolder text-white"> Edit Workspace </h2>
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => { showHideModel() }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <form id="kt_modal_edit_organization_form" className="form" onSubmit={formik.handleSubmit} noValidate >
                                <div className='modal-body scroll-y pb-2'>
                                    <div className="d-flex flex-column mb-7 fv-row">
                                        <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2 text-gray-900">
                                            <span className="required fw-bolder fs-7">Workspace Name</span>
                                        </label>
                                        <input
                                            placeholder='Name'
                                            {...formik.getFieldProps('name')}
                                            type='text'
                                            name='name'
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                { 'is-invalid': formik.touched.name && formik.errors.name },
                                                {
                                                    'is-valid': formik.touched.name && !formik.errors.name,
                                                }
                                            )}

                                            autoComplete='off'
                                            disabled={formik.isSubmitting}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.name}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block fs-8">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button type="submit" className="btn btn-primary btn-primary" onClick={() => submitErrorCheck()}>
                                        {!getspinner && (
                                            <span className='indicator-label'>Save</span>
                                        )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button type="reset" className="btn btn-white" data-bs-dismiss="modal" onClick={() => { showHideModel() }}> Cancel </button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </div>
            </>
            ):('')
        }
      </>
    )
}

// export { ExternalWorkspaceHeaderSection }
export default connect(mapStateToProps)(ExternalWorkspaceHeaderSection);