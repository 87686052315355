/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
// import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

import axios from 'axios'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import { Link } from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const recentCustomersUrl = `${API_URL}/external/recent-dashboard`

type Props = {
  className: string
}

const RecentDashboardsTable: React.FC<Props> = ({ className }) => {

  const [recentCustomers, setrecentCustomers] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(true)

  const fetchRecentCustomers: any = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const recentCustomersList = axios.get(
      recentCustomersUrl,
      config
    ).then(async (res: any) => {
      const { data: recentCustomersData } = res
      setComponentLoading(false)
      return await setrecentCustomers(recentCustomersData)
    }
    ).catch((error) => {
      console.log(error)
      setComponentLoading(false)
    });
    return recentCustomersList

  }

  useEffect(() => {

    fetchRecentCustomers()

  }, [])
  return (
    <div className={`card ${className}`}>

      {
        getComponentLoading ?
          (
            <ComponentLoading></ComponentLoading>
          ) : ('')
      }

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1 heading_f'>Recently Viewed Dashboards</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
        </div>
      </div>

      {
        recentCustomers.length > 0 ?
          (
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <div className="row">
                  {recentCustomers.map((recent, index) => (
                    <div className='col-md-6 p-2'>
                      <Link to={'/dashboard/' + recent.dashboard_id}>
                        <div className="card shadow-sm w-100 h-100">
                          <img className="card-img-top" alt="Recent Dashboard Image" src={process.env.REACT_APP_DOMAIN_URL + '/dashboardCapture/' + recent.fileName} style={{ width: '100%', height: "150px", objectFit: "cover" }} />
                          <div className="card-body p-4">
                            <h4 className="card-title text-gray-700 fs-6 text-capitalize" >{recent.dashboardName}</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                  )}
                </div>

              </div>
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: getHelpers.NoDataFound() }}></div>
          )
      }
    </div>
  )
}

export { RecentDashboardsTable }
