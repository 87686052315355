/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from "react-router-dom";
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import { ToastContainer, toast } from 'react-toastify';
import { ComponentLoading } from '../../modules/ComponentLoading'
import Swal from 'sweetalert2'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import { InternalRoleAddModel } from './internalRolesAddModel'
import InternalRoleEditModel from './internalRolesEditModel'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionCreators } from '../../../redux/actions/profile';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../redux/actions/externalPermissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const InternalRoles: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false)
  const [getStorePermissions, setStorePermissions] = useState<any>({})
  const [getStoreRoles, setStoreRoles] = useState<any>({})
  const [getComponentLoading, setComponentLoading] = useState(true)
  const [showEditModal, setshowEditModal] = useState(false)
  const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);
  const [roleEditProps, setroleEditProps] = useState<any[]>([]);
  const API_URL = process.env.REACT_APP_API_URL
  const internalRolesPermissionsUrl = `${API_URL}/internal/roles-permissions`
  const showHideModel = () => {
    setShowModal(!setShowModal)
    internalRolePermissionList();
  }
  const showHideEditModal = () => {
    setshowEditModal(!setshowEditModal)
    internalRolePermissionList();
  }
  const internalRolePermissionList = () => {

    const config = {
      headers: { Authorization: BearerToeken }
    };
    const internalRolesList = axios.get(
      internalRolesPermissionsUrl,
      config
    ).then(async (res) => {
      const { data: rolespermissionsDataResult } = res
      // console.log('rolespermissionsDataResult', rolespermissionsDataResult)
      // setTimeout(() => {
        setComponentLoading(false)
      // }, 500);
      return await setinternalRolesPermissionsListResult(rolespermissionsDataResult)
    }
    ).catch((error) => {
      // setTimeout(() => {
        setComponentLoading(false)
      // }, 500);
      console.log(error)
    });
    return internalRolesList

  }

  
  const deleteInternalRole = async (id: any) => {
    Swal.fire({
      title: getHelpers.ConfirmDeleteMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/internal/roles/' + id,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
              internalRolePermissionList();
            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
            // console.log(res.data)
          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          });
      }
    })
  }

  const sendRoleEditProps = (editprop: any) => {
    // console.log('editprop',editprop)
    setroleEditProps(editprop)
  }
  
  useEffect(() => {
    setTimeout(() => {
      setStorePermissions(store.getState().userData.permissions)
      setStoreRoles(store.getState().userRolesData.roles)

      // setTimeout(() => {
      //   if(!_.includes(store.getState().userData.permissions,'view_settings')){
      //     navigate("/");
      //   }
      // }, 200);

    }, 100);
    internalRolePermissionList();
  }, [getStorePermissions,getStoreRoles]);

  return (
    <>
    <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Roles</h1>
        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
          <li className="breadcrumb-item text-muted">
            <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
          </li>
          <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
          <li className="breadcrumb-item text-muted">Roles</li>
        </ul>
      </div>
      <div className="d-flex align-items-center gap-2 gap-lg-3">
        {
          _.includes(store.getState().userData.permissions,'manage_settings')?
          (
            <a className="btn btn-sm fw-bolder btn-primary customIconButton" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => { setShowModal(true) }}>
              {/* <i className="bi bi-person-plus-fill btnIcon"></i> */}
              <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
              <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
              <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
              </svg>
              </span>
              <span className='btnTxt'>Role</span>
            </a>
          ):('')
        }
      </div>
    </div>
    {
      getComponentLoading ?
        (
          <div className="card overflow-hidden">
            <div className="card-body set_scroll">
              <ComponentLoading></ComponentLoading>
            </div>
          </div>
        ) : (
          <>
          {
            _.includes(store.getState().userData.permissions,'view_settings')?
            (
              <>
      
      
              <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>
                  {/* <InternalRolesTable className='card-xxl-stretch mb-5 mb-xl-12' /> */}
                  <div >
                    <ToastContainer />
      
                    <div className='row'>
                      {
                        internalRolesPermissionsListResult.map((role, index) => {
                          return (
                            <>
                              <div className='col-md-6 col-lg-4' >
      
                                <div className="card role_card_setting mb-6">
                                  <div className="card-header bg-primary">
                                    <div className="card-title mb-0">
                                      <div>
                                        <h3 className="card-label text-white"><span className='fw-bolder'>{role.label}</span> ({role.name})</h3>
                                        <span className='text-white mt-1 fw-semibold fs-7'>Total permisions with this role </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-body set_scroll">
                                    {role.permissions.map((superAdminPermission: any) => (
                                      <div >
                                        <ul>
                                          <li key={superAdminPermission._id}>
                                            <strong>{superAdminPermission.label} </strong>
                                            ({superAdminPermission.name})
                                          </li>
                                        </ul>
                                      </div>
                                    )
                                    )}
                                  </div>
                                  {
                                    _.includes(store.getState().userData.permissions,'manage_settings')
                                    ?
                                    (
                                    <div className="card-footer d-flex flex-fill">
                                      <button type="button" className="btn btn-light w-100 btn-sm fw-bolder m-1 btn-primary" onClick={() => { setshowEditModal(true); sendRoleEditProps(internalRolesPermissionsListResult[index]) }}>
                                        {/* <i className="bi bi-pencil-square me-2"></i>  */}
                                        <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"/>
                                          <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"/>
                                          <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"/>
                                          </svg>
                                        </span>
                                        Edit
                                      </button>
                                      {/* {role.permissions.map((superAdminPermission: any) => ( */}
                                      {
                                        role.name == 'super_admin' || role.name == 'admin'
      
                                          ?
                                          //onClick={() => { deleteInternalRole(role._id) }}
                                          <button type="button" disabled className="btn btn-light w-100 btn-sm fw-bolder m-1 btn-light" >
                                            {/* <i className="bi bi-trash me-2"></i>  */}
                                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                                              <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                                              <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                                              </svg>
                                            </span>
                                            Delete
                                          </button>
      
                                          :
                                          <button type="button" className="btn btn-light w-100 btn-sm fw-bolder m-1 btn-light" onClick={() => { deleteInternalRole(role._id) }}>
                                            {/* <i className="bi bi-trash me-2"></i>  */}
                                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                                              <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                                              <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                                              </svg>
                                            </span>
                                            Delete
                                          </button>
      
      
                                      }
                                      {/* ) */}
                                      {/* )} */}
      
                                      {/* <button type="button" className="btn btn-light w-100 btn-sm fw-bolder m-1 btn-light" onClick={() => { deleteInternalRole(role._id) }}><i className="bi bi-trash me-2"></i> Delete</button> */}
                                    </div>
                                    ):('')
                                  }
                                </div>
      
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                    <Modal
                      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                      id='kt_modal_add_role'
                      role='dialog'
                      data-backdrop='false'
                      aria-hidden='true'
                      tabIndex='-1'
                      show={showModal}
                      animation={false}
                    >
                      <InternalRoleAddModel showModal={showHideModel} />
                    </Modal>
                    <Modal
                      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                      id='kt_modal_edit_role'
                      role='dialog'
                      data-backdrop='false'
                      aria-hidden='true'
                      tabIndex='-1'
                      show={showEditModal}
                      animation={false}
                    >
                      <InternalRoleEditModel showEditModal={showHideEditModal} editProps={roleEditProps} />
                    </Modal>
                  </div>
                </div>
              </div>
              </>
            ):(
              <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
            )
          }
          </>
        )
    }
    </>
  )
}

// export { InternalRoles }
export default connect(mapStateToProps)(InternalRoles);