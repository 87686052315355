/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import { KTSVG } from '../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import { ComponentLoading } from '../../modules/ComponentLoading'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const internalAdminUrl = `${API_URL}/internal/admins`
const ApiToken = localStorage.getItem('api_token')
const BearerToken = `Bearer ${ApiToken}`

// Redux Apply
const mapStateToProps = (state: any) => {
    return {
        profile: state.user.profile,
        userData: state.userData.permissions,
        userRolesData: state.userRolesData.roles
    }
}

const InternalUtilities: FC = () => {
    useEffect(() => {
        // internalAdminList();
    }, []);

    const intl = useIntl()

    const [getComponentLoading, setComponentLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setComponentLoading(false)
        }, 400);
    }, []);

    return (
        <>
            <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Utilities</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                        </li>
                        <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                        <li className="breadcrumb-item text-muted">Utilities</li>
                    </ul>
                </div>
                <div className="d-flex align-items-center gap-2 gap-lg-3"></div>
            </div>

            {
                getComponentLoading ?
                    (
                        <div className="card overflow-hidden">
                            <div className="card-body set_scroll">
                                <ComponentLoading></ComponentLoading>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="utilitiesBoxes">
                                <div className="row">
                                    <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-10'>
                                        <div className="card card-flush mb-5 mb-xl-10">
                                            <Link className="utilityBox card-body pt-4 pb-4 text-center" to={'/internal/gd-users'}>
                                                <div className="">
                                                    <div className='pb-4'>
                                                        <span className="svg-icon svg-icon-muted svg-icon-2hx">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/><rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/><path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/><rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/></svg>
                                                        </span>
                                                    </div>
                                                    <span className="text-gray-600 fw-bolder fs-7">Delete Unused GD Users</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-10'>
                                        <div className="card card-flush mb-5 mb-xl-10">
                                            <Link className="utilityBox card-body pt-4 pb-4 text-center" to={'/internal/gd-workspaces'}>
                                                <div className="">
                                                    <div className='pb-4'>
                                                        <span className="svg-icon svg-icon-muted svg-icon-2hx">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"/><path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"/></svg>
                                                        </span>
                                                    </div>
                                                    <span className="text-gray-600 fw-bolder fs-7">Delete Unused GD Workspaces</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
            }

        </>
    )
}

// export {InternalUtilities}
export default connect(mapStateToProps)(InternalUtilities);
