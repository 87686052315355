/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { ToastContainer, toast } from 'react-toastify';
import * as getHelpers from '../../../modules/auth/GetHelpers'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser, logout } = useAuth()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    // toast.success('asdfsdfsafsad', {
    //   position: getHelpers.toastPosition(),
    //   theme: 'colored'
    // });
  },[])

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: loginSchema,
  //   onSubmit: async (values, {setStatus, setSubmitting}) => {
  //     setLoading(true)
  //     try {
  //       const {data: auth} = await login(values.email, values.password)
  //       saveAuth(auth)
  //       const {data: user} = await getUserByToken(auth.api_token)
  //       setCurrentUser(user)
  //     } catch (error) {
  //       console.error(error)
  //       saveAuth(undefined)
  //       setStatus('The login detail is incorrect')
  //       setSubmitting(false)
  //       setLoading(false)
  //     }
  //   },
  // })

  let fullName;
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      let resquestData : any;
      setLoading(true)
      try {
        resquestData = await login(values);
        if(resquestData.status){
          logout()
          setHasErrors(false)
        } else {
          setHasErrors(true)
          setStatus(resquestData.message)
        }
        setLoading(false)
        if(resquestData.status){
          const { data: auth } = resquestData
          fullName = resquestData.data.first_name + ' ' + resquestData.data.last_name
          localStorage.setItem('full_name', fullName);
          localStorage.setItem('api_token', resquestData.data.api_token);
          saveAuth(auth)
          // localStorage.setItem('api_token', auth.api_token);
          // const { data: user } = await getUserByToken(auth.api_token)
          setCurrentUser(resquestData)
          navigate('/')
        }
      } catch (error) {
        setLoading(false)
        setHasErrors(true)
        let errorMessage: any;
        if (resquestData.message) {
          errorMessage = resquestData.message
        } else {
          errorMessage = resquestData['errors'][0].msg
        }
        saveAuth(undefined)
        setStatus(errorMessage)
        setSubmitting(false)
      }
    },
  })

  return (
    <>
    {/* <ToastContainer /> */}
    <div className='loginPage'>
      <div className="utilities-form-column">
        <div className="utilities-form-wrap">
          <h4 className="h4-title xs-margin">Client Login.</h4>
          <p className="m-paragraph l-margin">Forgot password? <Link to="/auth/forgot-password" className="link-text">Reset</Link>.</p>
          <div className="utilities-form w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get"
              onSubmit={formik.handleSubmit}
              noValidate
            >

              {/* {formik.status ? (
                <div className="error-message w-form-fail mb-5">
                  <div className="center-content">
                    <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf360756a3e8_alert-triangle.svg" loading="lazy" alt="" className="s-icon" />
                      <div>{formik.status}</div>
                    </div>
                  </div>
                </div>
                // <div className='mb-lg-5 alert alert-danger'>
                //   <div className='alert-text font-weight-bold'>{formik.status}</div>
                // </div>
              ) : ('')} */}

              {hasErrors === false? (
                <div className="success-message w-form-fail mb-5">
                  <div className="center-content">
                    <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf70c956a3e7_check-circle.svg" loading="lazy" alt="" className="s-icon" />
                      <div>Login successful, redirecting you to dashboard</div>
                    </div>
                  </div>
                </div>
              ):('')}

              {hasErrors === true? (
                  <div className="error-message w-form-fail mb-5">
                    <div className="center-content">
                      <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf360756a3e8_alert-triangle.svg" loading="lazy" alt="" className="s-icon" />
                        <div>Invalid email or password!</div>
                      </div>
                    </div>
                  </div>
              ):('')}
              
              {/* <input type="email" className="large-field email w-input" name="Email" data-name="Email" placeholder="Your email" id="Email" /> */}
              <div className='inputMarginBottom'>
                <input
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control large-field email w-input',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  data-name="Email" 
                  placeholder="Your email" 
                  id="Email" 
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
              
              {/* <input type="password" className="large-field password w-input" name="Password" data-name="Password" placeholder="Your password" id="Password" /> */}
              <div className='inputMarginBottom'>
                <input
                  data-name="Password" 
                  placeholder="Your password" 
                  id="Password"
                  type='password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control large-field password w-input',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='utilities-button w-button'
                disabled={loading}
              >
                {!loading && <span className='indicator-label fw-bolder'>Login To Your Account</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* <input type="submit" value="Login To Your Account" data-wait="Please wait..." className="utilities-button w-button" /> */}
              {/* <div className="or-sign-up-with-wrap">
                <div className="sign-up-divider"></div>
                <div className="extra-small-text">or log in with</div>
                <div className="sign-up-divider"></div>
              </div>
              <a href="#" className="social-sign-up w-inline-block"><img src="https://assets.website-files.com/634ed62e7678c43e9f8c8877/634ed62e7678c4e2258c8924_search.png" loading="lazy" width="24" srcSet="https://assets.website-files.com/634ed62e7678c43e9f8c8877/634ed62e7678c4e2258c8924_search-p-500.png 500w, https://assets.website-files.com/634ed62e7678c43e9f8c8877/634ed62e7678c4e2258c8924_search.png 512w" sizes="24px" alt="" className="social-sign-up-icon" />
                <div className="social-sign-up-text">Login With Google</div>
              </a> */}
            </form>
            {/* <div className="success-message w-form-done">
              <div className="center-content">
                <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf70c956a3e7_check-circle.svg" loading="lazy" alt="" className="s-icon" />
                  <div>Thank you! Your submission has been received!</div>
                </div>
              </div>
            </div>
            <div className="error-message w-form-fail">
              <div className="center-content">
                <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf360756a3e8_alert-triangle.svg" loading="lazy" alt="" className="s-icon" />
                  <div>Oops! Something went wrong!</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
