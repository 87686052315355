import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import axios from 'axios'
import { useNavigate } from "react-router-dom";

import { getFactory } from "@gooddata/api-client-bear";


export function MenuInner() {
  const intl = useIntl()

  const navigate = useNavigate();

  /*
  const ssoEnvironment = (userId:any) => {
    axios.post(process.env.REACT_APP_BASE_API_URL+'openpgp/sso-environment',{
      userId: userId
    })
      .then((res) => {
        // console.log('res=',res.data)
        getEncryptedClaims(res.data)
      })
  }

  var ssoLoginData = ''

  const getEncryptedClaims = (resData:any) => {
    axios.post(process.env.REACT_APP_BASE_API_URL+'openpgp/encrypted-claims',resData.data)
      .then((res) => {
        // console.log('res=',res.data)

        localStorage.setItem("encryptedClaims", JSON.stringify(res.data.encryptedClaim))
        localStorage.setItem("ssoProvider", JSON.stringify(res.data.userId))

        /////////////
        
        const domain = process.env.REACT_APP_GD_BACKEND_URL;
        const sdk = getFactory({ domain });
        const encryptedClaims = JSON.parse(res.data.encryptedClaim)
        const ssoProvider = res.data.userId;
        const targetUrl = "/gdc/account/token"; // keep this URL set to /gdc/account/token

        // console.log('encryptedClaims=',encryptedClaims)
        
        sdk.user
        .loginSso(encryptedClaims, ssoProvider, targetUrl)
        .then((response) => {

            // console.log('sdk.user.getCurrentProfile()=',sdk.user.getCurrentProfile())
            // console.log('response.responseBody=',response.responseBody)
            
            ssoLoginData = response.responseBody

        })
        .catch(error => {
          // console.log('error=');
          console.error(error);
        });
          
        /////////////
      })
  }
  */

  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      {/* <MenuItem title='Dashboard 1' to='/gooddata/abqoZR6DoDfK' />
      <MenuItem title='Dashboard 2' to='/gooddata/aaXo0Yq6TZ9R' /> */}
      {/* <MenuInnerWithSub
        title='Users'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <div className="menu-item me-lg-1">
          <a className='menu-link py-3' onClick={() => ssoEnvironment("085e45d41ee02c395a5ae5bef14e24b9")}>
            <span className="menu-bullet"><span className="bullet bullet-dot"></span></span>
            jhonsmith@yopmail.com
          </a>
        </div>
        <div className="menu-item me-lg-1">
          <a className='menu-link py-3' onClick={() => ssoEnvironment("bc82c40d98db9a8a150f14dc37405570")}>
            <span className="menu-bullet"><span className="bullet bullet-dot"></span></span>
            david@yopmail.com
          </a>
        </div>
        <div className="menu-item me-lg-1">
          <a className='menu-link py-3' onClick={() => ssoEnvironment("ed6e2c78de4a50ac375dfb2ad9b97333")}>
            <span className="menu-bullet"><span className="bullet bullet-dot"></span></span>
            arlinda@stringomedia.com
          </a>
        </div>
        <div className="menu-item me-lg-1">
          <a className='menu-link py-3' onClick={() => ssoEnvironment("8a232875a5fa28d6cd783c08b246068e")}>
            <span className="menu-bullet"><span className="bullet bullet-dot"></span></span>
            access@stringomedia.com
          </a>
        </div>
      </MenuInnerWithSub> */}
      {/* <MenuItem title='Home' to='/' /> */}
      {/* <MenuItem title='Dashboard' to='/dashboard-page' /> */}
      {/* <div dangerouslySetInnerHTML={{ __html: ssoLoginData }} /> */}
        {/* <MenuItem title='access@stringomedia.com' to='/user/8a232875a5fa28d6cd783c08b246068e' hasBullet={true} />
        <MenuItem title='david@yopmail.com' to='/user/bc82c40d98db9a8a150f14dc37405570' hasBullet={true} /> */}
    </>
  )
}
