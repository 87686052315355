/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import PermissionsRolesTables from '../../pages/customer-permissions/PermissionsRolesTables'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

import { Link, useNavigate } from "react-router-dom";
import { KTSVG, toAbsoluteUrl, initialQueryState, useDebounce } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ExternalPermissionAddModel } from './ExternalPermissionAddModel'
import { ExternalPermissionEditModel } from './ExternalPermissionEditModel'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
// import { useQueryRequest } from '../../../app/modules/apps/user-management/users-list/core/QueryRequestProvider'
import { useTable, useFilters, useGlobalFilter, useSortBy, useAsyncDebounce, usePagination } from 'react-table'
import { count } from 'console';
import Dropdown from 'react-bootstrap/Dropdown';
import { ComponentLoading } from '../../modules/ComponentLoading'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

import * as Yup from 'yup'
import { useFormik } from 'formik'

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const permissionsUrl = `${API_URL}/roles`
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const internalPermissionsListUrl = `${API_URL}/permissions-roles`
const PermissionsRole: FC = (props: any) => {

  // const intl = useIntl()
  const [permissionsRoles, setpermissionsRoles] = useState<any[]>([]);
  const [ExternalPermissionList, setExternalPermissionList] = useState<any[]>([]);
  const [ExternalPermissionListAll, setExternalPermissionListAll] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showEditModal, setshowEditModal] = useState(false)
  const [roleEditProps, setroleEditProps] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(true)
  const navigate = useNavigate();

  const [getStorePermissions, setStorePermissions] = useState<any>({})
  const [getStoreRoles, setStoreRoles] = useState<any>({})

  // const showHideModel = () => {
  //   setShowModal(!setShowModal)
  // }

  const rolesList = () => {

    const config = {
      headers: { Authorization: BearerToeken }
    };
    const permissionsRolesList = axios.get(
      permissionsUrl,
      config
    ).then(async (res) => {
      // console.log("response=",res);
      const { data: rolePermissionData } = res
      return await setpermissionsRoles(rolePermissionData)
    }
    ).catch(console.log);
    return permissionsRolesList

  }

  
    const showHideModel = () => {
        setShowModal(!setShowModal)
        fetchEnternalPermissionList();
    }
    const showHideEditModal = () => {
        setshowEditModal(!setshowEditModal)
        fetchEnternalPermissionList();
    }
    const setSearchTermFilter: any = () => {
        if (searchTerm.trim().length > 0) {
            let finaldata = ExternalPermissionListAll.filter((item) => {
                if (
                    item.name
                ) {
                    return searchTerm
                        .toLowerCase()
                        .split(" ")
                        .every(
                            (v: any) =>
                                item.name.toString().toLowerCase().includes(v)
                        );
                }
            });
        } else {
            setExternalPermissionList(ExternalPermissionListAll)
        }
    }
    const fetchEnternalPermissionList: any = () => {
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const organizationsListList = axios.get(
            internalPermissionsListUrl,
            config
        ).then(async (res: any) => {
            const { data: InternalDataListData } = res
            await setExternalPermissionListAll(InternalDataListData)
            // setTimeout(() => {
              setComponentLoading(false)
            // }, 200);
            return await setExternalPermissionList(InternalDataListData)

        }
        ).catch((error) => {
          // setTimeout(() => {
            setComponentLoading(false)
          // }, 200);
            console.log(error)
        });
        return organizationsListList

    }

    function GlobalFilter(props: any) {
        const { setGlobalFilter } = props
        const count = props.preGlobalFilteredRows.length
        const [value, setValue] = React.useState(props.globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (

            <div className="border-0 pt-6 mb-5">
                <div className="card-title" ></div>
                <div className="card-toolbar" >
                    <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base" >
                        <div className="d-flex align-items-center position-relative my-1" >
                            <KTSVG
                                path='/media/icons/duotune/general/gen021.svg'
                                className='svg-icon-1 position-absolute ms-6'
                            />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='customTableSearch form-control form-control-solid w-250px ps-14'
                                placeholder='Search'
                                value={value || ""}
                                onChange={e => {
                                    setValue(e.target.value);
                                    onChange(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>


        )
    }

    function CustomUsersRoles(data: any) {
        {
            data.map((roles: any) => {
                let v = JSON.parse(JSON.stringify(roles));
                let userName = '';
                let roleName = '';
                if (v.user_id != null) {
                    if (v.user_id.role != null) {
                        roleName = v.user_id.role.name;
                        userName = v.user_id.full_name
                    }
                }
                if (userName && roleName) {
                    return <>
                        <span className="text-capitalize badge m-1 bg-primary" >
                            <span>
                                <span >{userName} <span > ( {roleName} ) </span>
                                </span>
                            </span>
                        </span>
                    </>
                }
            })
        }
    }
    const sendRoleEditProps = (editprop: any) => {
        // console.log('editprop',editprop.original)
        setroleEditProps(editprop.original)
    }
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }: any) {
        const count = preFilteredRows.length
        return (
            <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }
    const deleteInternalPermission = async (id: any) => {
        // console.log(id.original._id, '_id')
        Swal.fire({
            title: getHelpers.ConfirmDeleteMsg(),
            icon: getHelpers.ConfirmDeleteIcon().toString(),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                const config = {
                    headers: { Authorization: BearerToeken }
                };
                axios.delete(
                    process.env.REACT_APP_API_URL + '/permissions/' + id.original._id,
                    config
                )
                    .then(async (res) => {
                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                            fetchEnternalPermissionList();
                        } else {
                            toast.error(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                        // console.log(res.data)
                    })
                    .catch((error) => {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    });
            }
        })
    }
    function Table(props: any) {
        const { columns, data } = props
        const defaultColumn: any = React.useMemo(
            () => ({
                // Default Filter UI
                Filter: DefaultColumnFilter,
            }),
            []
        )
        const setLocalStorage = (pageSize: any) => {
            // console.log('pageSize',pageSize)
            localStorage.setItem('pageSize', pageSize)
        }

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            // state,
            preGlobalFilteredRows,
            setGlobalFilter,
            page,
            // canPreviousPage,
            // canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize, globalFilter },
        }: any = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination,

        )
        let currentPageStartIndex: any = 2;
        let previousIndexPage: any = 0;
        return (
            <div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />

                <div className='table-responsive'>
                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer internalOrganizationPermissionTableList" {...getTableProps()}>
                        {headerGroups.map((headerGroup: any) => (
                            <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th className="" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? (<span className='table-sort-asc'></span>)
                                                    : (<span className='table-sort-desc'></span>)
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {/* <tbody {...getTableBodyProps()}>
                            {page.map((row: any, i: any) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell: any) => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}
                                            </td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody> */}
                        <tbody className='fw-bold' {...getTableBodyProps()}>
                            {
                                page.length > 0?
                                (
                                <>
                                {page.map((row: any, i: any) => {
                                    prepareRow(row)
                                    return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell: any) => {
                                        return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                                        </td>
                                        })}
                                    </tr>
                                    )
                                })}
                                </>
                                ):(
                                <tr>
                                    <td colSpan={4}>
                                    <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                                    </td>
                                </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                <div className="row">
                    <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
                        <div className="dataTables_length" id="kt_customers_table_length">
                            <select name="kt_customers_table_length"
                                className="form-select form-select-sm form-select-solid"
                                value={pageSize}
                                onChange={e => {
                                    { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                                }}
                                style={{ width: '120px', height: '38px' }}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
                        <div id="kt_table_users_paginate">
                            <ul className="pagination">
                                {/* <li className="page-item" onClick={() => gotoPage(0)} >
                            <a className="page-link">First</a>
                            </li> */}
                                <li className="page-item" onClick={() => previousPage()} >
                                    <a className="page-link prev-angle">
                                        <i className="fa fa-chevron-left"></i>
                                    </a>
                                </li>
                                {/* <div>{pageIndex}{pageSize}</div> */}
                                {/* if ($x != 0 && $x % 3 == 0 && $x < count($rows)) */}
                                {pageOptions.map((pageSize: any, rowIndex: any) => {
                                    if (currentPageStartIndex <= rowIndex) {
                                        currentPageStartIndex = currentPageStartIndex + 1
                                        previousIndexPage = rowIndex - 1
                                    }
                                    return (

                                        // rowIndex != 0 && rowIndex % 3 !=0  && rowIndex < pageCount

                                        previousIndexPage <= currentPageStartIndex
                                            ?
                                            <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                                                <a className="page-link">{pageSize + 1} </a>
                                                {/* {pageIndex + 1} */}
                                            </li>
                                            :
                                            null
                                    )

                                })}

                                <li className="page-item" onClick={() => nextPage()} >
                                    <a className="page-link next-angle">
                                        {/* {'Next »'} */}
                                        <i className="fa fa-chevron-right"></i>
                                    </a>
                                </li>




                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

  useEffect(() => {
    rolesList();
    setTimeout(() => {
      setStorePermissions(store.getState().userData.permissions)
      setStoreRoles(store.getState().userRolesData.roles)

      // setTimeout(() => {
      //   if(!_.includes(store.getState().userData.permissions,'view_settings')){
      //     navigate("/");
      //   }
      // }, 200);

    }, 100);
    fetchEnternalPermissionList()
  }, [getStorePermissions,getStoreRoles]);
  
    const columns: any = React.useMemo(
        () => [
            {
                Header: 'Label',
                accessor: 'label',
                Cell: ({ row }: any) => {
                    const data = row.original

                    return (
                        <>
                            {
                                data ?
                                    (
                                        <>
                                            <span className='fw-bolder'>
                                                {data.label}
                                            </span>
                                        </>
                                    ) : ('')
                            }
                        </>
                    )
                }
            },
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Assign To',
                accessor: 'Assign To',
                disableSortBy: false,
                Cell: ({ row }: any) => {
                    const data = row.original
                    const org_data = data.roles;
                    return (

                        <>{org_data.map((roles: any) =>
                            <span>
                                {
                                    roles._id ?
                                        (
                                            <span>
                                                {
                                                    roles.name ?
                                                        (
                                                            <span className="text-capitalize badge m-1 bg-primary">{roles.label}</span>
                                                        ) : ('')
                                                }
                                            </span>
                                        ) : ('')
                                }
                            </span>

                        )}</>

                    )
                }
            },

            {
                Header: 'Action',
                accessor: 'action',
                disableSortBy: true,
                Cell: ({ row }: any) => {
                    const data = row.original
                    const org_id: any = data._id;
                    return (
                        <>
                            {
                                _.includes(store.getState().userData.permissions,'manage_settings')?
                                (
                                    <div className='customActionButtons'>
                                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                                            <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { setshowEditModal(true); sendRoleEditProps(row) }}>
                                                {/* <i className="bi bi-pencil-square"></i> */}
                                                <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"/>
                                                    <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"/>
                                                    <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Delete</Tooltip>}>
                                            <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { deleteInternalPermission(row) }}>
                                                {/* <i className="bi bi-trash"></i> */}
                                                <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                                                    <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                                                    <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </a>
                                        </OverlayTrigger>
                                    </div>
                                ):('N/A')
                            }
                        </>
                    )
                }

            },

        ],
        []
    )

    const data: any = ExternalPermissionList

  // console.log("roles=",permissionsRoles);
  return (
    <>

    <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Permissions</h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                    <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">Permissions</li>
            </ul>
        </div>
        <div className="d-flex align-items-center gap-2 gap-lg-3">
            {
                _.includes(store.getState().userData.permissions,'manage_settings')?
                (
                    <button type="button" className="btn btn-sm fw-bolder btn-primary customIconButton" onClick={() => { setShowModal(true) }}>
                        {/* <i className="bi bi-person-check-fill btnIcon"></i> */}
                        <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                        </svg>
                        </span>
                        <span className='btnTxt'>Permission</span>
                    </button>
                ):('')
            }
        </div>
    </div>

    {
        getComponentLoading ?
        (
          <div className="card overflow-hidden">
            <div className="card-body set_scroll">
              <ComponentLoading></ComponentLoading>
            </div>
          </div>
        ) : (
          <>
            {
              _.includes(getStorePermissions,'view_settings')?
              (
                <>
                  {/* <PageTitle breadcrumbs={[]}>Permissions  </PageTitle><br/> */}
                  {/* <PermissionsRolePage /> */}
                  <div className='row gy-5 g-xl-8'>
                    <div className='col-xxl-12'>
                      {/* <PermissionsRolesTables className='card-xxl-stretch mb-5 mb-xl-12' /> */}
                      <div>
                        <ToastContainer />

                        <div className={`card overflow-hidden`}>

                            <div className="card-body pt-0 bg-white" >
                                <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
                                    <div className="table-responsivex" >
                                        <Table columns={columns} data={data} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal
                            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                            id='kt_modal_add_internal_permission'
                            role='dialog'
                            data-backdrop='false'
                            aria-hidden='true'
                            tabIndex='-1'
                            show={showModal}
                            animation={false}
                        >
                            <ExternalPermissionAddModel showModal={showHideModel} />
                        </Modal>
                        <Modal
                            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                            id='kt_modal_internal_edit_permission'
                            role='dialog'
                            data-backdrop='false'
                            aria-hidden='true'
                            tabIndex='-1'
                            show={showEditModal}
                            animation={false}
                        >
                            <ExternalPermissionEditModel showEditModal={showHideEditModal} editProps={roleEditProps} />
                        </Modal>
                      </div>
                    </div>
                  </div>
                </>
              ):(
                <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
              )
            }
          </>
        )
    }

    </>
  )
}

// export { PermissionsRole }
export default connect(mapStateToProps)(PermissionsRole);
