import { useQuery } from 'react-query'
// import {UserEditModalForm} from '../../../app/modules/apps/user-management/users-list/user-edit-modal/UserEditModalForm' 
import { AddWorkspaceModalFormAsideMenu } from './AddWorkspaceModalFormAsideMenu'
import { isNotEmpty, QUERIES } from '../../../_metronic/helpers'
// import { useListView } from '../../modules/apps/user-management/users-list/core/ListViewProvider'
// import { getUserById } from '../../modules/apps/user-management/users-list/core/_requests'
// import { User} from './OrganizationValidationModal'
// type Props = {
//   modelHideshow: boolean
// }

const AddWorkspaceModalWrapperAsideMenu = (props: any) => {
  // const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  // const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  // const {
  //   isLoading,
  //   data: user,
  //   error,
  // } = useQuery(
  //   `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
  //   () => {
  //     return getUserById(itemIdForUpdate)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setItemIdForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  // if (!itemIdForUpdate) {

    // return <AddWorkspaceModalFormAsideMenu modelHideshow={props.modelHideshow} fetchorganizationsList={props.fetchorganizationsList} isUserLoading={isLoading} user={{ id: undefined }} />
  // }

  // if (!isLoading && !error && user) {
    return <AddWorkspaceModalFormAsideMenu modelHideshow={props.modelHideshow} fetchorganizationsList={props.fetchorganizationsList} />
  // }

  // return null
}

export { AddWorkspaceModalWrapperAsideMenu }
