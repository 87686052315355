/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import clsx from 'clsx'
import { FastField, useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import axios from 'axios'
import { props } from 'lodash/fp'
import * as _ from "lodash";
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
export type Props = {
    showEditModal(): any,
    editProps: Object
}
const ExternalRoleEditModel: React.FC<Props> = ({ showEditModal, editProps }) => {
    const [getspinner, setspinner] = useState(false)
    const [permissions, setPermissions] = useState<any[]>([]);
    const [roleName, setRoleName] = useState("");
    const [checked, setChecked] = useState<any[]>([]);
    const editUserSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .required('Name is required'),
    })
    const formik = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: editUserSchema,
        onSubmit: async (values) => {
            setspinner(true)
            try {
                let formData: any = await {
                    name: values.name,
                    permissions: checked
                }
                let editValue: any = await editProps;
                const config = {
                    headers: { Authorization: BearerToeken }
                };
                await axios.put(
                    process.env.REACT_APP_API_URL + '/roles/' + editValue._id,
                    formData,
                    config
                )
                    .then(async (res) => {
                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                                setspinner(false)
                                showEditModal()

                        } else {
                            setspinner(false)
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    })
                    .catch((error) => {
                        setspinner(false)
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                        // console.log(error)
                    });

            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)
                // console.error(ex)
            }
        },
    })
    useEffect(() => {
        // console.log('12',)

        const getAllPermissions = async () => {
            const config = {
                headers: { Authorization: BearerToeken }
            };
            await axios.get(
                process.env.REACT_APP_API_URL + '/permissions',
                config
            )
                .then(async (res) => {
                    let list = await res.data;
                    let editValue: any = await editProps;
                    formik.initialValues.name = editValue.label;
                    // if(formik.initialValues.name){
                    //     console.log('formik.initialValues.name',formik.initialValues.name);
                    // }
                    let inputValue = formik.initialValues.name.replaceAll(' ', '_').toLowerCase()
                    setRoleName(inputValue);

                    let permissionIds = [];
                    for (let i = 0; i < editValue.permissions.length; i++) {
                        // const element = array[i];
                        permissionIds.push(editValue.permissions[i]['_id'])

                    }
                    // console.log('permissionIds',permissionIds)
                    // console.log(permissionIds, 'permissionIds')
                    setChecked(permissionIds)

                    setPermissions(list)

                })
                .catch((error) => {
                    console.log(error)
                });
        };

        getAllPermissions()
    }, []);
    const submitErrorCheck = () => {
        //   console.log("formik",formik.errors)
        // alert(0)
        // let check_error = Object.keys(formik.errors).length  ;
        if (JSON.stringify(formik.errors) !== '{}') {
            // alert(0)
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });

        }
    }
    const statusChange = (index: any) => {
        permissions[index]['status'] = !permissions[index]['status']
    }
    const roleNameInput = async (e: any) => {
        let inputValue = await e.target.value.replaceAll(' ', '_').toLowerCase()
        setRoleName(inputValue);
    }
    const handleCheck = (event: any) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        // console.log('handle', updatedList)
        setChecked(updatedList);
    };
    const isChecked = (item: any) =>
        checked.includes(item) ? true : false;
    return (
        <div>
            <ToastContainer />
            <div
                className='modal fade show d-block'
                id='kt_modal_add_role'
                role='dialog'
                tabIndex={-1}
                aria-modal='false'
            >
                <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                    <div className='modal-content'>
                        <div className='modal-header bg-primary'>
                            <h2 className='fw-bolder text-white'>Edit Role</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                style={{ cursor: 'pointer' }}
                                onClick={() => { showEditModal() }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                        </div>
                        <div className=''>
                            <form id="kt_modal_add_role" className="form" onSubmit={formik.handleSubmit} noValidate >
                                <div className='modal-body scroll-y pb-2'>
                                    <div className="row" >
                                        <div className="col-sm-6 mb-7 fv-row" >
                                            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2 text-gray-900" >
                                                <span className="fw-bolder fs-7" >Label</span>
                                            </label>
                                            <input
                                                placeholder='Label'
                                                onInput={roleNameInput}
                                                {...formik.getFieldProps('name')}
                                                type='text'
                                                name='name'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    { 'is-invalid': formik.touched.name && formik.errors.name },
                                                    {
                                                        'is-valid': formik.touched.name && !formik.errors.name,
                                                    }
                                                )}
                                                autoComplete='off'
                                                disabled
                                                // disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.name}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {roleName.length > 0 ?
                                            <div className="col-sm-6 mb-7 fv-row" >
                                                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2 text-gray-900" >
                                                    <span className="fw-bolder fs-7" >Name</span>
                                                </label>
                                                <input
                                                    placeholder='Name'
                                                    type='text'
                                                    name='name'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                    )}
                                                    value={roleName}
                                                    disabled
                                                    autoComplete='off'
                                                />
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                    <h2 className="fs-6 fw-bolder text-gray-900" >Role Permissions</h2>
                                    <div className="permission-model-list table-responsivex" >
                                        <table className="table" >
                                            <tbody >
                                                {permissions.map((permission, index) => (
                                                    <tr key={permission._id}>
                                                        <td className="align-middle fw-bolder text-capitalize fs-7" >{permission.label}
                                                        </td>
                                                        <td width="60">
                                                            <div key={index} className="form-check      form-check-sm form-check-custom" >
                                                                <input className="form-check-input form-check-solid" type="checkbox"
                                                                    value={permission._id}
                                                                    onChange={handleCheck}
                                                                    checked={isChecked(permission._id)}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-modal-add-role-action='submit'
                                        onClick={() => submitErrorCheck()}
                                    >
                                        {!getspinner && (
                                            <span className='indicator-label'>Save</span>
                                        )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => { showEditModal() }}
                                        className='btn btn-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* </Modal>
         */}
        </div>
    )
}

export { ExternalRoleEditModel }
