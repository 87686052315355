import React from 'react'
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'

export function Overview() {
  return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body pb-3 border-top p-9'>
            <div className='mb-5'>
              
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Full Name</label>
                <div className="col-lg-8">
                  {
                    getUserInfo.fullName()?
                    (
                      <span className="fw-bolder fs-6">{getUserInfo.fullName()}</span>
                    ):(
                      <span className="fw-bolder fs-6">N/A</span>
                    )
                  }
                </div>
              </div>
              
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Company</label>
                <div className="col-lg-8">
                  {
                    getUserInfo.company()?
                    (
                      <span className="fw-bolder fs-6">{getUserInfo.company()}</span>
                    ):(<span className="fw-bolder fs-6">N/A</span>)
                  }
                </div>
              </div>
              
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Email</label>
                <div className="col-lg-8">
                  {
                    getUserInfo.email()?
                    (
                      <span className="fw-bolder fs-6">{getUserInfo.email()}</span>
                    ):(<span className="fw-bolder fs-6">N/A</span>)
                  }
                </div>
              </div>
              
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Job Title</label>
                <div className="col-lg-8">
                  {
                    getUserInfo.jobTitle()?
                    (
                      <span className="fw-bolder fs-6">{getUserInfo.jobTitle()}</span>
                    ):(<span className="fw-bolder fs-6">N/A</span>)
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
          
  )
}
