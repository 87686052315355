/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { ExternalOrganizationsList } from './ExternalOrganizationsList'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { ExternalOrganizationAddModal } from './ExternalOrganizationAddModel'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const OrganizationsListUrl = `${API_URL}/external/organizations`

const OrganizationsPage: FC = () => (
  <>
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-12'>
        <ExternalOrganizationsList className='card-xxl-stretch mb-5 mb-xl-12' />
      </div>
    </div>
  </>
)

const ExternalOrganizations: FC = () => {

  const [showModal, setShowModal] = useState(false)

  const [organizationList, setOrganizationList] = useState<any[]>([]);
  const showHideModel: any = () => {
    setShowModal(!setShowModal)
  }
  const fetchOrganizationsList: any = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const organizationListList = axios.get(
      OrganizationsListUrl,
      config
    ).then(async (res: any) => {
      const { data: organizationListData } = res
      return await setOrganizationList(organizationListData)
    }
    ).catch(console.log);
    return organizationListList
  }
  
  return (
    <>
      {
        _.includes(store.getState().userExternalData.externalPermissions,'view_organizations')?
        (
          <>
          <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Organizations</h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">Organizations</li>
              </ul>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              {
                _.includes(store.getState().userExternalData.externalPermissions,'manage_organizations')?
                (
                  <a className="btn btn-sm fw-bolder btn-primary customIconButton" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => { setShowModal(true) }}>
                      <i className="bi bi-person-workspace btnIcon"></i>
                      <span className='btnTxt'>Add Organization</span> 
                  </a>
                ):('')
              }
            </div>
          </div>
          <div>
            <Modal
              className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
              id='kt_inbox_compose'
              role='dialog'
              data-backdrop='false'
              aria-hidden='true'
              tabIndex='-1'
              show={showModal}
              animation={false}
            >
              <ExternalOrganizationAddModal showHideModel={showHideModel} fetchOrganizationsList={fetchOrganizationsList} />
            </Modal>
          </div>
          <OrganizationsPage />
          </>
        ):(
          <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
        )
      }
    </>
  )
}

// export { Organizations }
export default connect(mapStateToProps)(ExternalOrganizations);
