/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
// import ExternalRoleTable from './ExternalRoleTable'
import { Link, useNavigate } from "react-router-dom";
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import { KTSVG } from '../../../_metronic/helpers'

import axios from 'axios'
import { ExternalRolesAddModel } from './ExternalRolesAddModel'
import { ExternalRoleEditModel } from './ExternalRolesEditModel'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';

import { ComponentLoading } from '../../modules/ComponentLoading'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const enternalRolesPermissionsUrl = `${API_URL}/roles-permissions`
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const Roles: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const [getStorePermissions, setStorePermissions] = useState<any>({})
  const [getStoreRoles, setStoreRoles] = useState<any>({})
  

  const [enternalRolesPermissionsListResult, setenternalRolesPermissionsListResult] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setshowEditModal] = useState(false)
  const [roleEditProps, setroleEditProps] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(true)

  

  // const [getDeleteRoleModal, setDeleteRoleModal]: any = useState(false)
  // const showHideWPDeleteModal: any = () => {
  //   setDeleteRoleModal(!setDeleteRoleModal)
  // }
  const [getspinner, setspinner] = useState(false)
  const [getRoleId, setRoleId] = useState(null);
  const [getDeleteRoleModal, setDeleteRoleModal] = useState(false);
  const openDeleteRoleModal = () => { setDeleteRoleModal(true); };
  const closeDeleteRoleModal = () => { setDeleteRoleModal(false); };


  const showHideModel = () => {
    setShowModal(!setShowModal)
    enternalRolePermissionList();
  }
  const showHideEditModal = () => {
    setshowEditModal(!setshowEditModal)
    enternalRolePermissionList();
  }
  const enternalRolePermissionList = () => {

    const config = {
      headers: { Authorization: BearerToeken }
    };
    const enternalRolesList = axios.get(
      enternalRolesPermissionsUrl,
      config
    ).then(async (res) => {
      const { data: rolespermissionsDataResult } = res
      // setTimeout(() => {
        setComponentLoading(false)
      // }, 500);
      return await setenternalRolesPermissionsListResult(rolespermissionsDataResult)
    }
    ).catch((error) => {
      // setTimeout(() => {
        setComponentLoading(false)
      // }, 500);
      console.log(error)
    });
    return enternalRolesList

  }
  
  useEffect(() => {
    setTimeout(() => {
      setStorePermissions(store.getState().userData.permissions)
      setStoreRoles(store.getState().userRolesData.roles)

      // setTimeout(() => {
      //   if(!_.includes(store.getState().userData.permissions,'view_settings')){
      //     navigate("/");
      //   }
      // }, 200);

    }, 100);
    enternalRolePermissionList();
  }, [getStorePermissions,getStoreRoles]);

  const deleteEnternalRole = async (id: any) => {
    setspinner(true)
    // Swal.fire({
    //   title: getHelpers.ConfirmDeleteMsg(),
    //   icon: getHelpers.ConfirmDeleteIcon().toString(),
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Delete'
    // }).then((result) => {
    //   if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/roles/' + id,
          config
        )
          .then(async (res) => {
            closeDeleteRoleModal()
            setspinner(false)
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
              enternalRolePermissionList();
            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
            // console.log(res.data)
          })
          .catch((error) => {
            setspinner(false)
            console.log('error.response=',error.response.data.message)
            if(error.response.data.message){
              toast.error(error.response.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          });
    //   }
    // })
  }

  const sendRoleEditProps = (editprop: any) => {
    // console.log('editprop',editprop)
    // console.log('editprop',editprop)
    setroleEditProps(editprop)
  }

  return (
    <>
    <div style={{position:'relative'}}>
      <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Roles</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
            </li>
            <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
            <li className="breadcrumb-item text-muted">Roles</li>
          </ul>
        </div>
        <div className="d-flex align-items-center gap-2 gap-lg-3">
          {
            _.includes(store.getState().userData.permissions, 'manage_settings') ?
              (
                <a className="btn btn-sm fw-bolder btn-primary customIconButton" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => { setShowModal(true) }}>
                  {/* <i className="bi bi-person-plus-fill btnIcon"></i> */}
                  <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
                  </svg>
                  </span>
                  <span className='btnTxt'>Role</span>
                </a>
              ) : ('')
          }
        </div>
      </div>

      {
        getComponentLoading ?
          (
            <div className="card overflow-hidden">
              <div className="card-body set_scroll">
                <ComponentLoading></ComponentLoading>
              </div>
            </div>
          ) : (
            <>
              {
                _.includes(store.getState().userData.permissions,'view_settings')?
                (
                  <>
        
                    {/* <PageTitle breadcrumbs={[]}>Roles  </PageTitle> */}
                    {/* <RolePage /> */}
                    <div className='row gy-5 g-xl-8'>
                      <div className='col-xxl-12'>
                        {/* <ExternalRoleTable className='card-xxl-stretch mb-5 mb-xl-12' /> */}
                        <div >
                          <ToastContainer />
        
                          {
                            enternalRolesPermissionsListResult.length > 0 ?
                              (
                                <div className='row'>
                                  {
                                    enternalRolesPermissionsListResult.map((role, index) => {
                                      return (
                                        <div className='col-md-6 col-lg-4'>
        
                                          <div className="card role_card_setting mb-6">
                                            <div className="card-header bg-primary">
                                              <div className="card-title mb-0">
                                                <div>
                                                  <h3 className="card-label text-white"><span className='fw-bolder'>{role.label}</span> ({role.name})</h3>
                                                  <span className='text-white mt-1 fw-semibold fs-7'>Total permisions with this role </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="card-body set_scroll">
                                              {role.permissions.map((externalPermissions: any) => (
                                                <div>
                                                  <ul>
                                                    <li key={externalPermissions._id}>
                                                      <strong>{externalPermissions.label} </strong>
                                                      ({externalPermissions.name})
                                                    </li>
                                                  </ul>
                                                </div>
                                              )
                                              )}
                                            </div>
                                            {
                                              _.includes(store.getState().userData.permissions, 'manage_settings') ?
                                                (
                                                  <div className="card-footer d-flex flex-fill">
                                                    <button type="button" className="btn btn-light w-100 btn-sm fw-bolder m-1 btn-primary" onClick={() => { setshowEditModal(true); sendRoleEditProps(enternalRolesPermissionsListResult[index]) }} >
                                                      {/* <i className='fa fa-edit'></i>  */}
                                                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                                                        <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                                                        <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                                                      </svg>
                                                      </span>
                                                      Edit
                                                    </button>
                                                    <button type="button" className="btn btn-light w-100 btn-sm fw-bolder m-1 btn-light" 
                                                      // onClick={() => { deleteEnternalRole(role._id) }}
                                                      onClick={() => { openDeleteRoleModal();setRoleId(role._id) }}
                                                      disabled={
                                                        role.name == 'guest' ? true : false
                                                      }
                                                    >
                                                      {/* <i className='fa fa-trash'></i>  */}
                                                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                                                      </svg>
                                                      </span>
                                                      Delete
                                                    </button>
                                                  </div>
                                                ) : ('')
                                            }
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              ) : (
                                ''
                                // <div dangerouslySetInnerHTML={{ __html: getHelpers.NoDataFound() }}></div>
                              )
                          }
        
        
                          <Modal
                            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                            id='kt_modal_add_role'
                            role='dialog'
                            data-backdrop='false'
                            aria-hidden='true'
                            tabIndex='-1'
                            show={showModal}
                            animation={false}
                          >
                            <ExternalRolesAddModel showModal={showHideModel} />
                          </Modal>
                          <Modal
                            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                            id='kt_modal_edit_role'
                            role='dialog'
                            data-backdrop='false'
                            aria-hidden='true'
                            tabIndex='-1'
                            show={showEditModal}
                            animation={false}
                          >
                            <ExternalRoleEditModel showEditModal={showHideEditModal} editProps={roleEditProps} />
                          </Modal>

                          <Modal
                              className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                              id='kt_inbox_compose'
                              role='dialog'
                              data-backdrop='false'
                              aria-hidden='true'
                              tabIndex='-1'
                              show={getDeleteRoleModal}
                              animation={false}
                          >

                              <div
                                  className='modal fade show d-block'
                                  id='kt_modal_add_user'
                                  role='dialog'
                                  tabIndex={-1}
                                  aria-modal='false'
                              >
                                  {/* begin::Modal dialog */}
                                  <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                                      <div className='modal-content'>
                                          <div className='modal-header bg-primary'>
                                              <h2 className='fw-bolder text-white'>Delete </h2>
                                              <div
                                                  className='btn btn-icon btn-sm btn-active-icon-primary'
                                                  data-kt-users-modal-action='close'
                                                  onClick={() => { closeDeleteRoleModal() }}
                                                  style={{ cursor: 'pointer' }}
                                              >
                                                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                              </div>
                                          </div>
                                          <form id='kt_modal_add_user_form' className='form'>
                                              <div className='modal-body scroll-y pb-2 minHeight200'>
                                                <h2 className='text-center mt-10'>Are you sure want to delete?</h2>
                                              </div>
                                              <div className="modal-footer bg-light flex-row-reverse">
                                                  <button
                                                      type='submit'
                                                      className='btn btn-primary'
                                                      data-kt-users-modal-action='submit'
                                                      onClick={() => { deleteEnternalRole(getRoleId) }}
                                                  >{!getspinner && (
                                                      <span className='indicator-label'>Delete</span>
                                                  )}
                                                      {getspinner && (
                                                          <span >
                                                              Please wait...
                                                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                          </span>
                                                      )}
                                                  </button>
                                                  <button
                                                      type='button'
                                                      onClick={() => closeDeleteRoleModal()}
                                                      className='btn btn-white'
                                                  >
                                                      Cancel
                                                  </button>
                                              </div>
                                          </form>
                                      </div>
                                      {/* end::Modal content */}
                                  </div>
                                  {/* end::Modal dialog */}
                              </div>
                          </Modal>

                        </div>
                      </div>
                    </div>
                  </>
                ):(
                  <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
                )
              }
            </>
          )
      }

    </div>
    </>
  )
}

// export {Roles}
export default connect(mapStateToProps)(Roles);
