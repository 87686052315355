/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios'
import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import Swal from 'sweetalert2'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../modules/auth/GetHelpers'
import ExternalOrganizationContactAddUserModal from './ExternalOrganizationContactAddUserModal'
import { ExternalOrganizationContactEditUserModal } from './ExternalOrganizationContactEditUserModal'
import { ExternalOrganizationUserSetPassModal } from './ExternalOrganizationUserSetPassModal'
import ExternalOrganizationDetailHeader from '../../pages/customer-organizations/ExternalOrganizationDetailHeader'
import { KTSVG } from '../../../_metronic/helpers'
import { useLocation } from 'react-router'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const BearerToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${BearerToken}`

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const ExternalOrganizationDetailContactsPage: FC = () => {
  const [getOrganizationData, setOrganizationData]: any = useState<any>({});

  const [showModal, setShowModal] = useState(false)
  // const [workspaceData, setworkspaceData]: any = useState<any[]>([]);
  const [organizationContactsData, setOrganizationContactsData]: any = useState<any[]>([]);
  const [getDashboardsCount, setDashboardsCount]: any = useState<any>(0);
  const [getspinner, setspinner] = useState(false)
  const [roleEditProps, setroleEditProps] = useState<any[]>([]);
  const [showEditUserModal, setshowEditUserModal]: any = useState(false)
  const [showHidePasswordModal, setshowHidePasswordModal]: any = useState(false)
  const [getOrgContactId, setOrgContactUserId]: any = useState('');
  const [tabDashboar, settabDashboar] = useState(false)
  const [rowprops, setrowprops] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(true)
  const location = useLocation();

  const [getContactData, setContactData] = useState({})

  const showHideModel: any = () => {
    setShowModal(!setShowModal)
  }

  const showHideEditUserModal: any = () => {
    setshowEditUserModal(!setshowEditUserModal)
  }

  const showHideSetPasswordModal: any = () => {
    setshowHidePasswordModal(!setshowHidePasswordModal)
  }

  const rowPropData: any = (rowData: any) => {
    setrowprops(rowData);
    fetchOrganizationContactById(rowData._id)
  }

  const navigate = useNavigate();
  let { organizationId }: any = useParams();

  const organizationContactsURL = `${API_URL}/external/organizations/` + organizationId + '/contacts'
  const organizationURL = `${API_URL}/external/organizations/` + organizationId

  const fetchOrganizationContactById = (id: any) => {
    const singleuserData = axios.get(
      `${API_URL}/external/contact/` + organizationId + '/' + id,
      {
        headers: { Authorization: BearerToken }
      }
    ).then((res: any) => {
      var data = {
        email: res.data.userDocument.email,
        user_id: res.data.userDocument._id,
        job_title: res.data.profileDoc.job_title,
        first_name: res.data.profileDoc.first_name,
        last_name: res.data.profileDoc.last_name,
        phone_number: res.data.profileDoc.phone_number,
        // role: res.data.userDocument.role,
        specialty_id: res.data.Specialities,
      }
      setContactData(data)
    }).catch((err) => {
      console.log('err=', err)
    });
    // return singleuserData
  }

  const fetchOrganization: any = () => {
    const config = {
      headers: { Authorization: BearerToken }
    };
    const organizationsList = axios.get(
      organizationURL,
      config
    ).then(async (res: any) => {
      setOrganizationData(res.data)
      setComponentLoading(false)
      return setOrganizationData;
    }
    ).catch((error) => {
      console.log('error=', error)
    });
    return organizationsList
  }

  const fetchOrganizationContactsList: any = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const organizationsList = axios.get(
      organizationContactsURL,
      config
    ).then(async (res: any) => {
      await setOrganizationContactsData(res.data.users);
      setComponentLoading(false)
      return [setOrganizationContactsData];


    }
    ).catch(console.log);
    return organizationsList

  }


  function SearchInPut(props: any) {
    const { setGlobalFilter } = props
    const count = props.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(props.globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
    return (
      <div className="border-0 pt-6 mb-5" >
        {
          getComponentLoading ?
            (
              <ComponentLoading></ComponentLoading>
            ) : ('')
        }
        <div className="card-title" ></div>
        <div className="card-toolbar">
          <div className="search_btn" data-kt-customer-table-toolbar="base" >
            <div className="d-flex align-items-center position-relative my-1 mx-2" >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={value || ""}
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />
            </div>
            <ExternalOrganizationContactAddUserModal fetchOrganizationContactsList={fetchOrganizationContactsList} />
          </div>
        </div>
      </div>
    )
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length

    return (
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='customTableSearch form-control form-control-solid w-250px ps-14'
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  function OrganizationContactsTable(props: any) {
    const { columns, data } = props

    const defaultColumn: any = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
    const setLocalStorage = (pageSize: any) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem('pageSize', pageSize)
    }
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,

    )

    // console.log("rows data", rows);

    return (
      <div>
        <SearchInPut
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className='table-responsive'>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer organizationContactsTableList " {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th className="" {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (<span className='table-sort-asc'></span>)
                          : (<span className='table-sort-desc'></span>)
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* <tbody className='fw-bold' {...getTableBodyProps()}>
              {page.map((row: any, i: any) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                      </td>
                    })}
                  </tr>
                )
              })}
            </tbody> */}
            <tbody className='fw-bold' {...getTableBodyProps()}>
              {
                page.length > 0 ?
                  (
                    <>
                      {page.map((row: any, i: any) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                              return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                              </td>
                            })}
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                      </td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="dataTables_length" id="kt_customers_table_length">
              <select name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                value={pageSize}
                onChange={e => {
                  { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                }}
                style={{ width: '120px', height: '38px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div id="kt_table_users_paginate">
              <ul className="pagination">
                <li className="page-item" onClick={() => previousPage()} >
                  <a className="page-link prev-angle">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </li>
                {pageOptions.map((pageSize: any, rowIndex: any) => {

                  return (
                    <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                      <a className="page-link">{pageSize + 1} </a>
                    </li>
                  )

                })}
                <li className="page-item" onClick={() => nextPage()} >
                  <a className="page-link next-angle">
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }

  useEffect(() => {
    fetchOrganization()
    fetchOrganizationContactsList()
  }, [])

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'full_name',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              <div className='d-flex align-items-center'>
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3" data-bs-toggle="tooltip" data-kt-initialized="1">

                  {/* {console.log('data=',data)} */}

                  <div key={data._id} >
                    {
                      data.avatar ?
                        (
                          data.avatar[0] ?
                            (
                              <span className={"symbol-label"}>
                                <img width="100%" src={getUserInfo.domainUrl() + 'avatars/' + data.avatar[0]['file']['filename']} />
                              </span>
                            ) : (
                              <>
                                {
                                  data.profile ?
                                    (
                                      data.profile.full_name ?
                                        (
                                          <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase text-uppercase bg-" + data.profile.full_name.charAt(0)}>
                                            {data.profile.full_name.charAt(0)}
                                          </span>
                                        ) : (
                                          <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase text-uppercase bg-" + data.email.charAt(0)}>
                                            {data.email.charAt(0)}
                                          </span>
                                        )
                                    ) : (
                                      data.full_name ?
                                        (
                                          <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase text-uppercase bg-" + data.full_name.charAt(0)}>
                                            {data.full_name.charAt(0)}
                                          </span>
                                        ) : (
                                          <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase text-uppercase bg-" + data.email.charAt(0)}>
                                            {data.email.charAt(0)}
                                          </span>
                                        )
                                    )
                                }
                              </>
                            )
                        ) : (
                          data.profile ?
                            (
                              data.profile.full_name ?
                                (
                                  <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.profile.full_name.charAt(0)}>
                                    {data.profile.full_name.charAt(0)}
                                  </span>
                                ) : (
                                  <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.email.charAt(0)}>
                                    {data.email.charAt(0)}
                                  </span>
                                )
                            ) : (
                              data.full_name ?
                                (
                                  <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.full_name.charAt(0)}>
                                    {data.full_name.charAt(0)}
                                  </span>
                                ) : (
                                  <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.email.charAt(0)}>
                                    {data.email.charAt(0)}
                                  </span>
                                )
                            )
                        )
                    }
                  </div>

                </div>
                <div className='d-flex flex-column'>
                  <span className="text-capitalize fw-bolder">
                    {
                      data.profile ?
                        (
                          data.profile.full_name ? (
                            data.profile.full_name
                          ) : (
                            data.full_name ? (data.full_name) : ('')
                          )
                        ) : (
                          data.full_name ? (data.full_name) : ('')
                        )
                    }
                  </span>
                  <div>
                    {data.email ? (data.email) : ('')}
                  </div>
                </div>
              </div>
            </>
          )
        }
      },
      {
        Header: 'Specialties',
        accessor: 'user_specialties',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                data.user_specialties.length > 0 ?
                  (
                    data.user_specialties.map((item: any) => (
                      <>
                        {
                          item.specialty_id ?
                            (
                              item.specialty_id.name ?
                                (
                                  <span key={item._id} className="text-capitalize badge bg-info m-1 " >
                                    {item.specialty_id.name}
                                  </span>
                                ) : ('N/A')
                            ) : ('N/A')
                        }
                      </>
                    ))
                  ) : ('N/A')
              }
            </>
          )
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {data.status == 'active' &&
                <span key={data._id} className="text-capitalize badge badge-success" >{data.status}</span>
              }

              {data.status == 'inactive' &&
                <span key={data._id} className="text-capitalize badge badge-danger" >{data.status}</span>
              }
            </>
          )
        }

      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                _.includes(store.getState().userExternalData.externalPermissions,'manage_organizations')?
                (
                  <div className='customActionButtons'>
                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                      <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { setshowEditUserModal(true); setOrgContactUserId(data._id); rowPropData(data) }}><i className="bi bi-pencil-square"></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Set Password</Tooltip>}>
                      <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { setshowHidePasswordModal(true); setOrgContactUserId(data._id) }}><i className="bi bi-lock"></i></a>
                    </OverlayTrigger>
                  </div>
                ):('')
              }
            </>
          )
        }
      },
    ],
    []
  )
  const deleteOrgContactUser = async (rowData: any) => {
    Swal.fire({
      title: getHelpers.ConfirmDeleteMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/external/users/workspace/' + rowData._id + '/' + organizationId,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
              fetchOrganizationContactsList();
            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          });
      }
    })
  }
  const data: any = organizationContactsData;
  return (
    <>
      
    {
      _.includes(store.getState().userExternalData.externalPermissions,'view_organizations')?
      (
        <>
          <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">{getOrganizationData.name} Organization</h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <Link to={'/'} className="text-muted text-hover-primary">Home</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">
                  <Link to={'/organizations'} className="text-muted text-hover-primary">Organizations</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">Contacts</li>
              </ul>
            </div>
          </div>

          <ExternalOrganizationDetailHeader></ExternalOrganizationDetailHeader>

          <div className='row gy-5 g-xl-8'>
            <div className='col-xxl-12'>
              <ToastContainer />
              {!tabDashboar &&
                <div className="card card-xxl-stretch mb-5 mb-xl-12 card-border-radius min-height-400" >
                  <div className="card-body pt-0 bg-white" >
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
                      <OrganizationContactsTable columns={columns} data={data} />
                    </div>
                  </div>
                </div>
              }
              {/* {tabDashboar &&
                <WorkspaceDashboardsPage organisationUsersId={organizationId} />
              } */}
            </div>
            {getOrgContactId &&
              <ExternalOrganizationContactEditUserModal getContactData={getContactData} showEditUserModal={showEditUserModal} showHideEditUserModal={showHideEditUserModal} getOrgContactId={getOrgContactId} fetchorganizationsUsersList={fetchOrganizationContactsList} rowprops={rowprops} />
            }
            {getOrgContactId &&
              <ExternalOrganizationUserSetPassModal showHideSetPasswordModal={showHideSetPasswordModal} showHidePasswordModal={showHidePasswordModal} organizationId={organizationId} userId={getOrgContactId} fetchorganizationsUsersList={fetchOrganizationContactsList} />
            }
          </div>
        </>
      ):(
        <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
      )
    }


    </>
  )
}

// export { ExternalOrganizationDetailContactsPage }
export default connect(mapStateToProps)(ExternalOrganizationDetailContactsPage);