/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios'
import { OrgWorkspaceAddModel } from './OrgWorkspaceAddModel'
import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import Swal from 'sweetalert2'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../modules/auth/GetHelpers'
import OrganizationDetailHeader from '../../pages/customer-organizations/OrganizationDetailHeader'
import { KTSVG } from '../../../_metronic/helpers'
import { useLocation } from 'react-router'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import { WorkspaceDeleteAllModal } from '../customer-organizations/WorkspaceDeleteAllModal'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const BearerToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${BearerToken}`

// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const OrganizationDetailWorkspacesPage: FC = () => {
  const [getOrganizationData, setOrganizationData]: any = useState<any>({});

  const [showModal, setShowModal] = useState(false)
  // const [workspaceData, setworkspaceData]: any = useState<any[]>([]);
  const [organizationWorkspacesData, setOrganizationWorkspacesData]: any = useState<any[]>([]);
  const [getDashboardsCount, setDashboardsCount]: any = useState<any>(0);
  const [getspinner, setspinner] = useState(false)
  const [roleEditProps, setroleEditProps] = useState<any[]>([]);
  const [showEditUserModal, setshowEditUserModal]: any = useState(false)
  // const [showHidePasswordModal, setshowHidePasswordModal]: any = useState(false)
  const [getOrgContactId, setOrgContactUserId]: any = useState('');
  const [tabDashboar, settabDashboar] = useState(false)
  const [rowprops, setrowprops] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(true)
  const location = useLocation();

  const [getContactData, setContactData] = useState({})
  
  const [workspaceId, setworkspaceId]: any = useState('')
  const [showHidePasswordModal, setshowHideWPDeleteModal]: any = useState(false)
  const showHideWPDeleteModal: any = () => {
    setshowHideWPDeleteModal(!setshowHideWPDeleteModal)
  }

  const showHideModel: any = () => {
    setShowModal(!setShowModal)
  }

  const showHideEditUserModal: any = () => {
    setshowEditUserModal(!setshowEditUserModal)
  }

  const setCopied = () => {
    toast.success('GD link copied', {
      position: getHelpers.toastPosition(),
      autoClose: getHelpers.toastAutoClose(),
      theme: 'colored'
    });
  }

  const rowPropData: any = (rowData: any) => {
    setrowprops(rowData);
    fetchOrganizationContactById(rowData._id)
  }

  const navigate = useNavigate();
  let { organizationId }: any = useParams();

  const organizationWorkspacesURL = `${API_URL}/internal/organizations/` + organizationId + '/workspaces'
  const organizationURL = `${API_URL}/internal/organizations/` + organizationId

  const fetchOrganizationContactById = (id: any) => {
    const singleuserData = axios.get(
      `${API_URL}/internal/contact/` + organizationId + '/' + id,
      {
        headers: { Authorization: BearerToken }
      }
    ).then((res: any) => {
      var data = {
        email: res.data.userDocument.email,
        user_id: res.data.userDocument._id,
        job_title: res.data.profileDoc.job_title,
        first_name: res.data.profileDoc.first_name,
        last_name: res.data.profileDoc.last_name,
        phone_number: res.data.profileDoc.phone_number,
        // role: res.data.userDocument.role,
        specialty_id: res.data.Specialities,
      }
      setContactData(data)
    }).catch((err) => {
      console.log('err=', err)
    });
    // return singleuserData
  }

  const fetchOrganization: any = () => {
    const config = {
      headers: { Authorization: BearerToken }
    };
    const organizationsList = axios.get(
      organizationURL,
      config
    ).then(async (res: any) => {
      const organizationRes = res.data.organization
      setOrganizationData(organizationRes)
      setComponentLoading(false)
      return setOrganizationData;
    }
    ).catch((error) => {
      console.log('error=', error)
    });
    return organizationsList
  }

  const fetchOrganizationWorkspacesList: any = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const organizationsList = axios.get(
      organizationWorkspacesURL,
      config
    ).then(async (res: any) => {
      await setOrganizationWorkspacesData(res.data.workspaces);
      setComponentLoading(false)
      return [setOrganizationWorkspacesData];


    }
    ).catch(console.log);
    return organizationsList

  }


  const deleteWorkspace = async (workspaceId: any) => {

    Swal.fire({
      title: getHelpers.ConfirmArchiveMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Archive'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/internal/workspace/' + workspaceId,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });

              fetchOrganizationWorkspacesList();

            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }

          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          });
      }
    })
  }


  function SearchInPut(props: any) {
    const { setGlobalFilter } = props
    const count = props.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(props.globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
    return (
      <div className="border-0 pt-6 mb-5" >
        {
          getComponentLoading ?
            (
              <ComponentLoading></ComponentLoading>
            ) : ('')
        }
        <div className="card-title" ></div>
        <div className="card-toolbar">
          <div className="search_btn" data-kt-customer-table-toolbar="base" >
            <div className="d-flex align-items-center position-relative my-1 mx-2" >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={value || ""}
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />
            </div>
            {/* <OrganizationContactAddUserModal fetchOrganizationWorkspacesList={fetchOrganizationWorkspacesList} /> */}
            {
              _.includes(store.getState().userData.permissions,'manage_workspaces')?
              (
                <a className="btn btn-sm fw-bolder btn-primary customIconButton" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => { setShowModal(true) }}>
                    {/* <i className="bi bi-person-workspace btnIcon"></i> */}
                    <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/><rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/><rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/></svg></span>
                    <span className='btnTxt'>Workspace</span> 
                </a>
              ):('')
            }
            <Modal
              className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
              id='kt_inbox_compose'
              role='dialog'
              data-backdrop='false'
              aria-hidden='true'
              tabIndex='-1'
              show={showModal}
              animation={false}
            >
              <OrgWorkspaceAddModel 
                showHideModel={showHideModel} 
                organizationId={organizationId} 
                organization={getOrganizationData}
                fetchWorkspacesList={fetchOrganizationWorkspacesList} 
              />
            </Modal>
          </div>
        </div>
      </div>
    )
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length

    return (
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='customTableSearch form-control form-control-solid w-250px ps-14'
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  function OrganizationContactsTable(props: any) {
    const { columns, data } = props

    const defaultColumn: any = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
    const setLocalStorage = (pageSize: any) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem('pageSize', pageSize)
    }
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,

    )

    // console.log("rows data", rows);

    return (
      <div>
        <SearchInPut
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className='table-responsive'>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer organizationContactsTableList " {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th className="" {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (<span className='table-sort-asc'></span>)
                          : (<span className='table-sort-desc'></span>)
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* <tbody className='fw-bold' {...getTableBodyProps()}>
              {page.map((row: any, i: any) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                      </td>
                    })}
                  </tr>
                )
              })}
            </tbody> */}
            <tbody className='fw-bold' {...getTableBodyProps()}>
              {
                page.length > 0 ?
                  (
                    <>
                      {page.map((row: any, i: any) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                              return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                              </td>
                            })}
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                      </td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="dataTables_length" id="kt_customers_table_length">
              <select name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                value={pageSize}
                onChange={e => {
                  { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                }}
                style={{ width: '120px', height: '38px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div id="kt_table_users_paginate">
              <ul className="pagination">
                <li className="page-item" onClick={() => previousPage()} >
                  <a className="page-link prev-angle">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </li>
                {pageOptions.map((pageSize: any, rowIndex: any) => {

                  return (
                    <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                      <a className="page-link">{pageSize + 1} </a>
                    </li>
                  )

                })}
                <li className="page-item" onClick={() => nextPage()} >
                  <a className="page-link next-angle">
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }

  useEffect(() => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {

            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {

            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        console.clear()
        navigate('/error/404')
      });

    fetchOrganization()
    fetchOrganizationWorkspacesList()

    window.addEventListener('workspaceListRefresh', (event: any) => {
      if (event['detail'].value) {
        setshowHideWPDeleteModal(false)
        fetchOrganizationWorkspacesList()
      }
    })

  }, [])
  const chartFunc = (value: any) => {
    console.log('value=', value.charAt(0))
    let chatAt_value = value.charAt(0);
    return chatAt_value;
  }
  const columns: any = React.useMemo(
    () => [

      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              <div className='d-flex align-items-center'>
                {
                  data.avatar_id ?
                    (
                      data.avatar_id.file ?
                        (
                          data.name ?
                            (
                              <span className={"symbol-label symbol symbol-circle symbol-35px overflow-hidden me-3"}>
                                <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(data.name) + " img-alt-35"} src={getUserInfo.domainUrl() + data.avatar_id.file_path} alt={chartFunc(data.name)} />
                              </span>
                            ) : ('')
                        ) : (
                          data.name ?
                            (
                              <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                                <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                                  {data.name.charAt(0)}
                                </span>
                              </div>
                            ) : ('N/A')
                        )
                    ) : data.name ? (
                      <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                        <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                          {data.name.charAt(0)}
                        </span>
                      </div>
                    ) : ('N/A')
                }
                <div className='d-flex flex-column'>
                  <span key={data._id} className="text-capitalize fw-bolder " >{data.name}</span>
                </div>
              </div>
            </>
          )
        }
      },

      {
        Header: 'Organization',
        accessor: 'organizations',
        Cell: ({ row }: any) => {
          const data = row.original
          const organizations = data.organizations && data.organizations.length > 0 ? data.organizations : [];
          return (

            <>
              {
                organizations.length > 0 ?
                  (
                    organizations.map((org: any) =>
                      <span>
                        {
                          org._id ?
                            (
                              <span>
                                {
                                  org.name ?
                                    (
                                      <span className="text-capitalize badge m-1 bg-primary">{org.name}</span>
                                    ) : ('')
                                }
                              </span>
                            ) : ('')
                        }
                      </span>

                    )
                  ) : ('N/A')
              }
            </>

          )
        }
      },

      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                data.status == 'active' ?
                  (
                    <span key={data._id} className="text-capitalize badge-success badge " >{data.status}</span>
                  ) : (
                    <span key={data._id} className="text-capitalize badge-danger badge " >{data.status}</span>
                  )
              }
            </>
          )
        }

      },
      {
        Header: 'Users',
        accessor: 'users',
        Cell: ({ row }: any) => {
          const data = row.original
          const org_id: any = data._id;
          // const org_data = data.external_users_roles;
          const showTotals = 5
          const org_data = getHelpers.AvatarsDataArray(showTotals, data.user_id)['users']
          const totalUsers = getHelpers.AvatarsDataArray(showTotals, data.user_id)['total']
          // console.log('org_data=',getHelpers.AvatarsDataArray(showTotals,data.user_id))
          return (
            <>

              <div className="symbol-group symbol-hover flex-nowrap">
                {
                  org_data.length > 0 ?
                    (
                      <>

                        {org_data.map((roles: any) =>
                          roles ?
                            (
                              <>
                                {
                                  roles.role ?
                                    (
                                      <>
                                        <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={roles._id}>
                                          {
                                            roles.avatar_id ?
                                              (
                                                <>
                                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.first_name ? (roles.first_name + ' ' + roles.last_name) : (roles.email)}</Tooltip>}>
                                                    {
                                                      roles.avatar_id.file ?
                                                        (
                                                          <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(roles.first_name ? roles.first_name : roles.email) + " img-alt-35"} src={getUserInfo.domainUrl() + 'avatars/' + roles.avatar_id.file.filename} alt={chartFunc(roles.first_name ? roles.first_name : roles.email)} />
                                                          // <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/' + roles.avatar_id.file.filename} />
                                                        ) : (
                                                          <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(roles.first_name ? roles.first_name : roles.email) + " img-alt-35"} src={getUserInfo.domainUrl() + 'avatars/default.png'} alt={chartFunc(roles.first_name ? roles.first_name : roles.email)} />
                                                          // <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/default.png'} />
                                                        )
                                                    }
                                                  </OverlayTrigger>
                                                </>
                                              ) : roles.first_name && roles.last_name ? (
                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.first_name ? (roles.first_name + ' ' + roles.last_name) : (roles.email)}</Tooltip>}>
                                                  <span className={"symbol-label text-inverse-warning fw-bolder bg-" + roles.first_name.charAt(0)}>
                                                    {roles.first_name.charAt(0)}
                                                  </span>
                                                </OverlayTrigger>
                                              ) : (
                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.first_name ? (roles.first_name + ' ' + roles.last_name) : (roles.email)}</Tooltip>}>
                                                  <span className={"symbol-label text-inverse-warning fw-bolder bg-" + roles.email.charAt(0)}>
                                                    {roles.email.charAt(0)}
                                                  </span>
                                                </OverlayTrigger>
                                              )
                                          }
                                        </div>
                                      </>
                                    ) : ('N/A')
                                }
                              </>
                            ) : ('N/A')
                        )}

                        {
                          totalUsers > 0 ?
                            (
                              <Link to={"/internal/workspace/" + org_id + "/users"} className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                                <span className="symbol-label bg-primary text-inverse-dark fs-8 fw-bold" data-bs-toggle="tooltip" data-bs-trigger="hover" data-kt-initialized="1">+{totalUsers}</span>
                              </Link>
                            ) : ('')
                        }
                      </>
                    ) : ('N/A')
                }
              </div>

            </>

          )
        }
      },

      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const data = row.original
          const org_id: any = data._id;
          return (
            <>
              {
                // _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
                //   (
                <div className='customActionButtons'>
                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Basic Details</Tooltip>}>
                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/workspace/" + org_id}>
                      {/* <i className="bi bi-eye"></i> */}
                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor" />
                        <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor" />
                      </svg>
                      </span>
                    </Link>
                  </OverlayTrigger>
                  {
                    _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
                      (
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                          <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/workspace/" + org_id + "/edit"}>
                            {/* <i className="bi bi-pencil-square"></i> */}
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                              <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                              <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                            </svg>
                            </span>
                          </Link>
                        </OverlayTrigger>
                      ) : ('')
                  }
                  {
                    _.includes(store.getState().userData.permissions, 'view_users') ?
                      (
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Users</Tooltip>}>
                          <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/workspace/" + org_id + "/users"}>
                            {/* <i className="bi bi-people"></i> */}
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor" />
                              <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor" />
                              <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor" />
                              <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor" />
                            </svg>
                            </span>
                          </Link>
                        </OverlayTrigger>
                      ) : ('')
                  }
                  {
                    _.includes(store.getState().userData.permissions, 'manage_organizations_dashboards') ?
                      (
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Dashboards</Tooltip>}>
                          <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/workspace/" + org_id + "/dashboards"}>
                            {/* <i className="bi bi-stack"></i> */}
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M17 10H11C10.4 10 10 9.6 10 9V8C10 7.4 10.4 7 11 7H17C17.6 7 18 7.4 18 8V9C18 9.6 17.6 10 17 10ZM22 4V3C22 2.4 21.6 2 21 2H11C10.4 2 10 2.4 10 3V4C10 4.6 10.4 5 11 5H21C21.6 5 22 4.6 22 4ZM22 15V14C22 13.4 21.6 13 21 13H11C10.4 13 10 13.4 10 14V15C10 15.6 10.4 16 11 16H21C21.6 16 22 15.6 22 15ZM18 20V19C18 18.4 17.6 18 17 18H11C10.4 18 10 18.4 10 19V20C10 20.6 10.4 21 11 21H17C17.6 21 18 20.6 18 20Z" fill="currentColor" />
                              <path d="M8 5C8 6.7 6.7 8 5 8C3.3 8 2 6.7 2 5C2 3.3 3.3 2 5 2C6.7 2 8 3.3 8 5ZM5 4C4.4 4 4 4.4 4 5C4 5.6 4.4 6 5 6C5.6 6 6 5.6 6 5C6 4.4 5.6 4 5 4ZM8 16C8 17.7 6.7 19 5 19C3.3 19 2 17.7 2 16C2 14.3 3.3 13 5 13C6.7 13 8 14.3 8 16ZM5 15C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17C5.6 17 6 16.6 6 16C6 15.4 5.6 15 5 15Z" fill="currentColor" />
                            </svg>
                            </span>
                          </Link>
                        </OverlayTrigger>
                      ) : ('')
                  }
                  {
                    data.gd_status && _.includes(store.getState().userData.permissions, 'manage_workspaces')
                      ?
                      <>
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Copy GD Link</Tooltip>}>
                          <CopyToClipboard text={`${process.env.REACT_APP_GD_BACKEND_URL}/dashboards/#/workspace/${data.gd_workspace_ids[0]}`} onCopy={() => setCopied()}>
                            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'>GD</span>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </>
                      :
                      <></>
                  }

                  {
                    _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
                      (
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Delete</Tooltip>}>
                          <>
                            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' 
                              // onClick={() => { deleteWorkspace(org_id) }}
                              onClick={() => { setshowHideWPDeleteModal(true); setworkspaceId(data._id) }}
                            >
                              {/* <i className="bi bi-trash"></i> */}
                              <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                              </svg>
                              </span>
                            </span>
                          </>
                        </OverlayTrigger>
                      ) : ('')
                  }
                </div>
                // ) : ('N/A')
              }

            </>
          )
        }

      },
    ],
    []
  )

  const deleteOrgContactUser = async (rowData: any) => {
    Swal.fire({
      title: getHelpers.ConfirmDeleteMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/internal/users/workspace/' + rowData._id + '/' + organizationId,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
              fetchOrganizationWorkspacesList();
            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          });
      }
    })
  }
  const data: any = organizationWorkspacesData;
  return (
    <>
    {/* <ToastContainer></ToastContainer> */}
      {
        _.includes(store.getState().userData.permissions, 'view_workspaces') ?
          (
            <>
              <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                  <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">{getOrganizationData.name} Organization</h1>
                  <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                    <li className="breadcrumb-item text-muted">
                      <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                    </li>
                    <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                    <li className="breadcrumb-item text-muted">
                      <Link to={'/internal/organizations'} className="text-muted text-hover-primary">Organizations</Link>
                    </li>
                    <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                    <li className="breadcrumb-item text-muted">Workspaces</li>
                  </ul>
                </div>
              </div>

              <OrganizationDetailHeader></OrganizationDetailHeader>

              <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>
                  {!tabDashboar &&
                    <div className="card card-xxl-stretch mb-5 mb-xl-12 card-border-radius min-height-400" >
                      <div className="card-body pt-0 bg-white" >
                        <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
                          <OrganizationContactsTable columns={columns} data={data} />
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <WorkspaceDeleteAllModal showHideWPDeleteModal={showHideWPDeleteModal} showHidePasswordModal={showHidePasswordModal} workspaceId={workspaceId} />

              </div>
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
          )
      }


    </>
  )
}

// export { OrganizationDetailWorkspacesPage }
export default connect(mapStateToProps)(OrganizationDetailWorkspacesPage);