/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios'
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2'
import * as getUserInfo from '../../../modules/auth/GetUserInfo'
import * as getHelpers from '../../../modules/auth/GetHelpers'
import { ComponentLoading } from '../../../modules/ComponentLoading'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import { AddWorkspaceModel } from '../../customer-organizations/AddWorkspaceModel'

import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'

import { ToastContainer, toast } from 'react-toastify';

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../../redux/actions/externalPermissions';
import { ActionRoles } from '../../../../redux/actions/roles';
import {store} from '../../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const allUsersUrl = `${API_URL}/internal/all/users`
const organizationsUrl = `${API_URL}/internal/customers`
const internalUsersUrl = `${API_URL}/internal/admins`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const loggedUserName = localStorage.getItem('full_name')
const WorkspaceListUrl = `${API_URL}/internal/customers`
let currentActiveWorkspaceId: any = ''

type Props = {
  className: string
}
const SwitchWorkspacePage: React.FC<Props> = ({ className }) => {
  const [showModal, setShowModal] = useState(false)

  const [getComponentLoading, setComponentLoading] = useState(true)

  const [getAllWorkspace, setAllWorkspace] = useState<any[]>([]);
  const [getUserRole, setUserRole] = useState<any>({});
  const navigate = useNavigate();
  const [workspacesList, setWorkspacesList] = useState<any[]>([]);

  const showHideModel: any = () => {
    setShowModal(!setShowModal)
  }
  const fetchWorkspacesList: any = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const workspacesListList = axios.get(
      WorkspaceListUrl,
      config
    ).then(async (res: any) => {
      const { data: workspacesListData } = res
      return await setWorkspacesList(workspacesListData)
    }
    ).catch(console.log);
    return workspacesListList
  }

  const findWorkspaceId = async (data: any) => {
    let userData: any = await localStorage.getItem('userinfo');
    userData = JSON.parse(userData);
    // console.log()
    let currentWorkspaceId = ''
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id == userData.workspace_id) {
        currentWorkspaceId = data[i]._id;
      }
    }
    return currentWorkspaceId;
  }
  const fetchAllWorkspace = async () => {


    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/workspaces",
    )
      .then(async (response) => {

        if (response.data) {

          // console.log('response.data.userRole=',response.data.userRole)
          currentActiveWorkspaceId = await findWorkspaceId(response.data.workspaces);
          setUserRole(response.data.userRole)
          setAllWorkspace(response.data.workspaces)
          setComponentLoading(false)
          // console.log('currentWorkspaceId',currentWorkspaceId)
        }
      })
      .catch((error) => {
        console.log(error)
        setComponentLoading(false)
      });

  };

  // console.log('getDashboards=',getDashboards)

  const columns: any = React.useMemo(
    () => [

      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const data = row.original
          // console.log('data', data)
          return (
            <>
              {
                data.name ?
                  (
                    <div className='d-flex align-items-center'>
                      {/* <div className="symbol symbol-35px symbol-circle me-3" data-bs-toggle="tooltip" data-kt-initialized="1">
                        <span className={"symbol-label text-inverse-warning fw-bolder bg-" + data.name.charAt(0)}>
                          {data.name.charAt(0).toUpperCase()}
                        </span>
                      </div> */}
                      {
                        data.avatar_id ?
                          (
                            data.avatar_id.file ?
                              (
                                <>
                                  <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                                    <img src={getUserInfo.domainUrl() + data.avatar_id.file_path} alt='Metornic' />
                                  </div>
                                </>
                              ) : (
                                data.name ?
                                  (
                                    <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                                      <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                                        {data.name.charAt(0)}
                                      </span>
                                    </div>
                                  ) : ('N/A')
                              )
                          ) : data.name ? (
                            <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                              <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                                {data.name.charAt(0)}
                              </span>
                            </div>
                          ) : ('N/A')
                      }
                      <div className='d-flex flex-column'>
                        <span key={data._id} className="text-capitalize fw-bolder " >{data.name}</span>
                        {
                          currentActiveWorkspaceId == data._id ?
                            <span className='badge badge-success mt-1'>Current</span>
                            :
                            ('')
                        }
                      </div>
                    </div>
                  ) : ('')
              }
            </>

          )
        }
      },

      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                data.role ?
                  (
                    <span key={data._id} className="text-capitalize badge bg-info" >{data.role.name}</span>
                  ) : ('')
              }
            </>
          )
        }

      },

      {
        Header: 'Shared With',
        accessor: 'user_id',
        Cell: ({ row }: any) => {
          const data = row.original
          const org_id: any = data._id;
          // const org_data = data.external_users_roles;
          const showTotals = 5
          const org_data = getHelpers.AvatarsDataArray(showTotals, data.user_id)['users']
          const totalUsers = getHelpers.AvatarsDataArray(showTotals, data.user_id)['total']
          // console.log('org_data=',getHelpers.AvatarsDataArray(showTotals,data.user_id))
          return (
            <>

              <div className="symbol-group symbol-hover flex-nowrap">
                {
                  org_data.length > 0 ?
                    (
                      <>

                        {org_data.map((roles: any) =>
                          roles ?
                            (
                              <>
                                {
                                  roles.role ?
                                    (
                                      <>
                                        <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={roles._id}>
                                          {
                                            roles.avatar_id ?
                                              (
                                                <>
                                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.first_name ? (roles.first_name + ' ' + roles.last_name) : (roles.email)}</Tooltip>}>
                                                    {
                                                      roles.avatar_id.file ?
                                                        (
                                                          <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/' + roles.avatar_id.file.filename} />
                                                        ) : (
                                                          <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/default.png'} />
                                                        )
                                                    }
                                                  </OverlayTrigger>
                                                </>
                                              ) : roles.first_name && roles.last_name ? (
                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.first_name ? (roles.first_name + ' ' + roles.last_name) : (roles.email)}</Tooltip>}>
                                                  <span className={"symbol-label text-inverse-warning fw-bolder bg-" + roles.first_name.charAt(0)}>
                                                    {roles.first_name.charAt(0)}
                                                  </span>
                                                </OverlayTrigger>
                                              ) : (
                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.first_name ? (roles.first_name + ' ' + roles.last_name) : (roles.email)}</Tooltip>}>
                                                  <span className={"symbol-label text-inverse-warning fw-bolder bg-" + roles.email.charAt(0)}>
                                                    {roles.email.charAt(0)}
                                                  </span>
                                                </OverlayTrigger>
                                              )
                                          }
                                        </div>
                                      </>
                                    ) : ('')
                                }
                              </>
                            ) : ('')
                        )}

                        {
                          totalUsers > 0 ?
                            (
                              data.role && data.role.name != 'admin' ?
                                (
                                  <div className="symbol symbol-35px symbol-circle">
                                    <span className="symbol-label bg-primary text-inverse-dark fs-8 fw-bold" data-bs-toggle="tooltip" data-bs-trigger="hover" data-kt-initialized="1">+{totalUsers}</span>
                                  </div>
                                ) : (
                                  <Link to={"/workspace/" + org_id + "/users"} className="symbol symbol-35px symbol-circle">
                                    <span className="symbol-label bg-primary text-inverse-dark fs-8 fw-bold" data-bs-toggle="tooltip" data-bs-trigger="hover" data-kt-initialized="1">+{totalUsers}</span>
                                  </Link>
                                )
                            ) : ('')
                        }
                      </>
                    ) : ('')
                }
              </div>

            </>

          )
        }
      },

      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const data = row.original
          // const org_id: any = data._id;
          return (
            <>
              {/* {console.log('getUserRole1=', getUserRole)} */}
              {
                data.role && data.role.name != 'admin' ?
                  <div className='customActionButtons'>
                    {
                      currentActiveWorkspaceId == data._id ?
                        (
                          <>
                          {
                            _.includes(store.getState().userExternalData.externalPermissions,'manage_workspaces')?
                            (
                              <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Leave</Tooltip>}>
                                <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => leaveWorkspace(data._id)} ><i className="bi bi-box-arrow-right"></i></a>
                              </OverlayTrigger>
                            ):('')
                          }
                          </>
                        ) : (
                          <>
                            {
                              _.includes(store.getState().userExternalData.externalPermissions,'manage_workspaces')?
                              (
                                <>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Switch</Tooltip>}>
                                  <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => switchWorkspace(data._id)}><i className='bi bi-toggles'></i></a>
                                </OverlayTrigger>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Leave</Tooltip>}>
                                  <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => leaveWorkspace(data._id)} ><i className="bi bi-box-arrow-right"></i></a>
                                </OverlayTrigger>
                                </>
                              ):('')
                            }
                          </>
                        )
                    }
                  </div>
                  :
                  <div className='customActionButtons'>
                    {
                      currentActiveWorkspaceId == data._id ?
                        (
                          <>
                            <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Users</Tooltip>}>
                              <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' href={"#/workspace/" + data._id + "/users"}><i className='bi bi-people'></i></a>
                            </OverlayTrigger>
                            {
                              _.includes(store.getState().userExternalData.externalPermissions,'manage_workspaces')?
                              (
                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Leave</Tooltip>}>
                                  <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => leaveWorkspace(data._id)} ><i className="bi bi-box-arrow-right"></i></a>
                                </OverlayTrigger>
                              ):('')
                            }
                          </>
                        ) : (
                          <>
                            <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Users</Tooltip>}>
                              <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' href={"#/workspace/" + data._id + "/users"}><i className='bi bi-people'></i></a>
                            </OverlayTrigger>
                            {
                              _.includes(store.getState().userExternalData.externalPermissions,'manage_workspaces')?
                              (
                                <>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Switch</Tooltip>}>
                                  <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => switchWorkspace(data._id)}><i className='bi bi-toggles'></i></a>
                                </OverlayTrigger>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Leave</Tooltip>}>
                                  <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => leaveWorkspace(data._id)} ><i className="bi bi-box-arrow-right"></i></a>
                                </OverlayTrigger>
                                </>
                              ):('')
                            }
                          </>
                        )
                    }
                  </div>
              }
            </>
          )
        }
      },
    ],
    []
  )
  const data: any = getAllWorkspace && getAllWorkspace.length > 0 ? getAllWorkspace : []
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length

    return (
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='customTableSearch form-control form-control-solid w-250px ps-14'
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }
  const setLocalStorage = (pageSize: any) => {
    // console.log('pageSize',pageSize)
    localStorage.setItem('pageSize', pageSize)
  }
  function WorkspaceDashboardListTable(props: any) {
    const { columns, data } = props

    const defaultColumn: any = React.useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,

    )

    // console.log("rows data", rows);
    let currentPageStartIndex: any = 2;
    let previousIndexPage: any = 0;

    return (
      <div>

        {/* <ToastContainer /> */}

        <div className='table-responsive'>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer externalWorkspacesTableList" {...getTableProps()}>
            <thead>

              {headerGroups.map((headerGroup: any) => (
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th className={" "} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {/* {console.log('column=',column)} */}

                      {column.isSorted
                        ? column.isSortedDesc
                          ? (<span className='table-sort-asc'></span>)
                          : (<span className='table-sort-desc'></span>)
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row: any, i: any) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>

                    {row.cells.map((cell: any) => {
                      return <td  {...cell.getCellProps()}>{cell.render('Cell')}

                      </td>
                    })}



                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="dataTables_length" id="kt_customers_table_length">
              <select name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                value={pageSize}
                onChange={e => {
                  { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                }}
                style={{ width: '120px', height: '38px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div id="kt_table_users_paginate">
              <ul className="pagination">
                <li className="page-item" onClick={() => previousPage()} >
                  <a className="page-link prev-angle">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </li>
                {pageOptions.map((pageSize: any, rowIndex: any) => {
                  if (currentPageStartIndex <= rowIndex) {
                    currentPageStartIndex = currentPageStartIndex + 1
                    previousIndexPage = rowIndex - 1
                  }
                  return (

                    previousIndexPage <= currentPageStartIndex
                      ?
                      <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                        <a className="page-link">{pageSize + 1} </a>

                      </li>
                      :
                      null
                  )
                })}
                <li className="page-item" onClick={() => nextPage()} >
                  <a className="page-link next-angle">

                    <i className="fa fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }


  useEffect(() => {
    fetchAllWorkspace()
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {
          } else {
            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {

          } else {
            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        console.clear()
        navigate('/error/404')
      });

  }, []);
  const switchWorkspace = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/workspace/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
        window.location.reload();
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }
  const leaveWorkspace = async (id: any) => {
    Swal.fire({
      title: 'Want to Leave?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Leave'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(process.env.REACT_APP_BASE_API_URL + "workspace/leave/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
          let status = await res.data.status
          if (status) {
            fetchAllWorkspace();
            toast.success(res.data.message, {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
            window.location.reload();
          } else {
            toast.error(res.data.message, {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        }).catch(error => {
          toast.error(error.response.data.errors, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });

        });
      }
    })
  }
  return (
    <>
      {/* <ToastContainer /> */}
      {
        _.includes(store.getState().userExternalData.externalPermissions,'view_workspaces')?
        (
          <>
          <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Workspaces</h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <Link to={'/overview'} className="text-muted text-hover-primary">Home</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">Workspaces</li>
              </ul>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              {
                _.includes(store.getState().userExternalData.externalPermissions,'manage_workspaces')?
                (
                  <a className="btn btn-sm fw-bolder btn-primary customIconButton" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => { setShowModal(true) }}>
                    <i className="bi bi-person-workspace btnIcon"></i>
                    <span className='btnTxt'>Create Workspace</span>
                  </a>
                ):('')
              }
            </div>
          </div>
          <div>
            <Modal
              className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
              id='kt_inbox_compose'
              role='dialog'
              data-backdrop='false'
              aria-hidden='true'
              tabIndex='-1'
              show={showModal}
              animation={false}
            >
              <AddWorkspaceModel showHideModel={showHideModel} fetchWorkspacesList={fetchAllWorkspace} />
            </Modal>
          </div>
          <div className={`card ${className} card-border-radius min-height-300`}>
            {
              getComponentLoading ?
                (
                  <ComponentLoading></ComponentLoading>
                ) : ('')
            }
            {data.length > 0 ?
              <div className="card-body pt-0 bg-white  min-height-400" >
                <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
                  <WorkspaceDashboardListTable columns={columns} data={data} />
                </div>
              </div>
              :
              <div className='card'><div className='card-body text-center bg-light m-5 fs-5'>No data found</div></div>
            }
          </div>
          </>
        ):(
          <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
        )
      }
    </>
  )
}
// export { SwitchWorkspacePage }
export default connect(mapStateToProps)(SwitchWorkspacePage);