/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { ActionMeta, OnChangeValue } from 'react-select';
import { ComponentLoading } from '../../modules/ComponentLoading'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToken = `Bearer ${ApiToken}`
interface PageProps {
//   organisationUsersId: any;
}

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

let OrgId: any;
const ExternalOrganizationDetailHeader: FC<PageProps> = () => {

    const [getOrganizationData, setOrganizationData]: any = useState<any>({});
    const [getTotalContacts, setTotalContacts] = useState(0)
    let { organizationId }: any = useParams();
    const [getComponentLoading, setComponentLoading] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false)

    const [getspinner, setspinner] = useState(false)
    const [getIndustries, setIndustries] = useState<any[]>([]);
    const [getIndustry, setIndustry] = useState({});
    
    const [getInitialValues, setInitialValues] = useState<any>()

    const ApiToken = localStorage.getItem('api_token')
    const BearerToeken = `Bearer ${ApiToken}`

    const organizationURL = `${API_URL}/external/organizations/` + organizationId

    const OrganizationsModalSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(50, 'Maximum 100 characters')
            .required('Name is required'),
        address: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 200 characters')
            .required('Address is required'),
        industry_id: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
            })
            .required('Industry is required')
    })

    const formik : any = useFormik({
        initialValues: {
            name: '',
            address: '',
            industry_id: {}
        },
        // initialValues: getInitialValues,
        validationSchema: OrganizationsModalSchema,
        onSubmit: async (values) => {

            try {

                let formData: any = await {
                    name: values.name,
                    address: values.address,
                    industry_id: getIndustry,
                }

                const config = {
                    headers: { Authorization: BearerToeken }
                };
                await axios.put(
                    process.env.REACT_APP_API_URL + '/external/organizations/' + organizationId,
                    formData,
                    config
                )
                    .then(async (res) => {
                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                            setspinner(false)
                        } else {
                            setspinner(false)
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    })
                    .catch((error) => {
                        setspinner(false)
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });

                    });

            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)

            }
        },
    })

    const fetchOrganization: any = () => {
        const config = {
            headers: { Authorization: BearerToken }
        };
        const organizationsList = axios.get(
            organizationURL,
            config
        ).then(async (res: any) => {

            const organizationData = res.data.organization
            const totalContacts = res.data.totalContacts
            // alert(totalContacts)
            if(organizationData.name){
                formik.initialValues.name = organizationData.name
            }
            if(organizationData.address){
                formik.initialValues.address = organizationData.address
            }
            if(organizationData.industry_id){
                var orgObj = {
                    label: organizationData.industry_id['name'],
                    value: organizationData.industry_id['_id'],
                    _id: organizationData.industry_id['_id'],
                }
                setIndustry(orgObj)
            }
            setOrganizationData(organizationData)
            setTotalContacts(totalContacts)
            setComponentLoading(false)
            return setOrganizationData;
        }
        ).catch((error) => {
            console.log('error=',error)
        });
        return organizationsList
    }

    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        // console.group();
        setIndustry(newValue)
        // console.log('handleChange getIndustry=',getIndustry)
        // console.groupEnd();
    }

    const submitErrorCheck = () => {
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    const getIndustriesData = () => {
      axios.get(process.env.REACT_APP_BASE_API_URL + 'external/industries/list')
        .then(async (res) => {
          setspinner(false)
          setIndustries(res.data)
          // optionsOrganizations = res.data
        })
        .catch(error => {
          
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
  
          setspinner(false)
          // fetchorganizationsList();
        })
    }

    const getListnerData = () => {
        window.addEventListener('dispatchOrganization', (event:any) => {
            if (event['detail'].value) {
                // setOrganizationData(event['detail'].data)
                fetchOrganization()
            }
        })
    }

    useEffect(() => {
        getListnerData();
        getIndustriesData();
        fetchOrganization()
    }, [])

  return (
    <>

        <ToastContainer />
    
        <div className="card mb-5 mb-xl-7 overflow-hidden internalWorkspaceHeader">

            {
                getComponentLoading ?
                (
                    <ComponentLoading></ComponentLoading>
                ) : ('')
            }

            <div className="card-body pt-9 pb-0">
                <div className="mb-3 internalWorkspaceContainer">
                    <div className="me-7 mb-4 colLeft">
                        {
                            getOrganizationData.avatar_id ?
                                (
                                    getOrganizationData.avatar_id.file?
                                    (
                                        <>
                                        <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative text-uppercase">
                                            <img src={getUserInfo.domainUrl()+getOrganizationData.avatar_id.file_path} alt='Metornic' />
                                        </div>
                                        </>
                                    ):(
                                        getOrganizationData.name ?
                                        (
                                            <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative text-uppercase">
                                                <span className={'symbol-label bg-light-danger text-white fs-30 fw-bolder bg-' + getOrganizationData.name.charAt(0)}>
                                                    {getOrganizationData.name.charAt(0)}
                                                </span>
                                            </div>
                                        ):('N/A')
                                    )
                                ) : getOrganizationData.name ? (
                                    <div className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative text-uppercase">
                                        <span className={'symbol-label bg-light-danger text-white fs-30 fw-bolder bg-' + getOrganizationData.name.charAt(0)}>
                                            {getOrganizationData.name.charAt(0)}
                                        </span>
                                    </div>
                                ):('N/A')
                        }
                    </div>
                    <div className="colRight">
                        <div className="hButtons d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2"><a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{getOrganizationData.name}</a></div>
                            </div>
                            <div className="d-flex my-1">
                                {
                                    location.pathname === '/organizations/' + organizationId + '/edit'?
                                    (
                                        <Link
                                        to={'/organizations/'+organizationId+''}
                                        className='btn btn-sm btn-primary fw-bolder'
                                        >
                                        <i className="bi bi-eye me-1"></i> View
                                        </Link>
                                    ):(
                                        _.includes(store.getState().userExternalData.externalPermissions,'manage_organizations')?
                                        (
                                            <Link
                                            to={'/organizations/'+organizationId+'/edit'}
                                            className='btn btn-sm btn-primary fw-bolder'
                                            >
                                            <i className="bi bi-pencil-square me-1"></i> Edit
                                            </Link>
                                        ):('')
                                    )
                                }
                            </div>
                        </div>
                        <div className="countContainer d-flex flex-wrap flex-stack">
                            <div className="d-flex flex-column flex-grow-1">
                                <div className="d-flex flex-wrap">
                                    <div className="countCol border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="fs-2 fw-bolder">{getTotalContacts}</div>
                                        </div>
                                        <div className="fw-bold fs-6 text-gray-400">Contacts</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="customNavTabs">
                    <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary ` +
                                    ((location.pathname === '/organizations/' + organizationId + '' || location.pathname === '/organizations/' + organizationId + '/edit') && 'active')
                                }
                                to={'/organizations/' + organizationId + ''}
                            >
                                Basic Details
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary ` +
                                    (location.pathname === '/organizations/' + organizationId + '/contacts' && 'active')
                                }
                                to={'/organizations/' + organizationId + '/contacts'}
                            >
                                Contacts
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </>
  )

}

export default connect(mapStateToProps)(ExternalOrganizationDetailHeader);