/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { ComponentLoading } from '../../modules/ComponentLoading'
// import { store } from '../../../redux/store/index';
const API_URL = process.env.REACT_APP_API_URL
const WorkspacesStatusGDCheck = `${API_URL}/workspace/gd/status/customers`
const WorkspacesRemoveGDCheck = `${API_URL}/workspace/user/not-synched`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
interface EditModalProps {
    hideShowGdStatusModel(): any;
}

const GdCheckModel: FC<EditModalProps> = ({ hideShowGdStatusModel }) => {
    const [getComponentLoading, setComponentLoading] = useState(true)
    const [getGdNonExistWorkspaces, setGdNonExistWorkspaces] = useState([])
    const [getspinner, setspinner] = useState(false)
    const [getResponseMessage, setResponseMessage] = useState('')
    useEffect(() => {
        checkGdStatus()
    }, [])
    const checkGdStatus = () => {
        setComponentLoading(true);
        let objSelect: any;
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const WorkspacesListList = axios.get(
            WorkspacesStatusGDCheck,
            config
        ).then(async (res: any) => {
            // console.log('res.data.Workspaces-----', res.data.Workspaces);
            let finalTableArray: any = [];

            if(res.data.Workspaces && res.data.Workspaces.length > 0) {
                for (let i = 0; i < res.data.Workspaces.length; i++) {
                    if (res.data.Workspaces[i]['gd_status']) {
                    } else {
                        finalTableArray.push(res.data.Workspaces[i]);
                    }
                }
            }
            setGdNonExistWorkspaces(finalTableArray);
            setComponentLoading(false);
            setResponseMessage(res.data.message)
        }
        ).catch((error) => {
            console.log('error=',error)
            setResponseMessage(error.response.data.message)
        });
    }
    const removeAllWorkspace = (e: any) => {
        e.preventDefault();
        setspinner(true);
        axios.delete(
            WorkspacesRemoveGDCheck
        ).then(async (res: any) => {
            // console.log('res', res.data);
            if (res.data.status) {
                setTimeout(() => {
                    setspinner(false);
                    hideShowGdStatusModel()
                }, 2000);
            }
        }
        ).catch((error) => {
            console.log(error)
        });
    }
    const checkgetGdNonExistWorkspaceFunc = () => {
        if (getGdNonExistWorkspaces.length > 0) {
            return false
        } else {
            return true
        }
    }
    return (
        <>
            <div className="modal-content bg-white">
                <div className="modal-header bg-primary">
                    <h2 className="fw-bolder text-white">GD Status</h2>
                    <div
                        className='btn btn-icon btn-sm btn-active-icon-primary'
                        data-kt-users-modal-action='close'
                        style={{ cursor: 'pointer' }}
                        onClick={() => { hideShowGdStatusModel() }}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                </div>
                <div>

                    <div className=''>
                        <form id="kt_modal_add_role" className="form" noValidate >
                            <div className='modal-body scroll-y pb-2'>
                                {getGdNonExistWorkspaces.length > 0 ?
                                    <div className="gd-status-model-list table-responsivex" >
                                        <table className="table" >
                                            <tbody >
                                                {getGdNonExistWorkspaces.map((workspace: any, index) => (
                                                    <tr key={workspace._id}>
                                                        <td className="align-middle fw-bolder text-capitalize fs-7" >
                                                            <div>

                                                                {workspace.name}
                                                            </div>
                                                            {
                                                                workspace.gd_status
                                                                    ?
                                                                    <></>
                                                                    :
                                                                    <div className='badge bg-danger color_2'>Not synched with GD</div>
                                                            }

                                                        </td>
                                                        {/* <td width="60">
                                                            <div key={index} className="form-check      form-check-sm form-check-custom" >
                                                                <input className="form-check-input form-check-solid" type="checkbox"
                                                                // onClick={() => { statusChange(index) }}
                                                                />
                                                            </div>
                                                        </td> */}
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <>
                                        <div className=" table-responsivex" >
                                            {
                                                getComponentLoading ?
                                                    (
                                                        <div className='loader-min-h'>
                                                            <ComponentLoading></ComponentLoading>

                                                        </div>
                                                    ) : <div className='card'><div className='card-body text-center bg-light m-5 fs-5'>
                                                        {/* No unsynched workspaces found. */}
                                                        {getResponseMessage}
                                                    </div></div>
                                            }

                                        </div>

                                    </>
                                }

                            </div>
                            <div className="modal-footer bg-light flex-row-reverse">
                                <button
                                    // type='submit'

                                    disabled={checkgetGdNonExistWorkspaceFunc()}
                                    className='btn btn-primary'
                                    data-kt-modal-add-role-action='submit'
                                    onClick={(event) => removeAllWorkspace(event)}
                                >
                                    {!getspinner && (
                                        <span className='indicator-label'>Delete all</span>
                                    )}
                                    {getspinner && (
                                        <span >
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                <button
                                    type='button'
                                    onClick={() => { hideShowGdStatusModel() }}
                                    className='btn btn-white'
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>



                </div>
            </div>

        </>
    )
}

export { GdCheckModel }
