
import { FC, useState, useEffect } from 'react'


import { Modal } from 'react-bootstrap'

import axios from 'axios'
import { WorkspaceUserEditModal } from './WorkspaceUserEditModel'

import { useParams, useNavigate } from "react-router-dom";

import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../modules/auth/GetHelpers'
import Multiselect from 'multiselect-react-dropdown';

import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import * as Yup from 'yup'
import { useFormik } from 'formik'

// import {initialUser, User} from '../../../app/modules/apps/user-management/users-list/core/_models' 
import { initialUser, User } from './OrganizationValidationModal'
import clsx from 'clsx'
// import { useListView } from '../../modules/apps/user-management/users-list/core/ListViewProvider'
// import {UsersListLoading} from '../../../app/modules/apps/user-management/users-list/components/loading/UsersListLoading' 
// import { createUser, updateUser } from '../../modules/apps/user-management/users-list/core/_requests'
// import { useQueryResponse } from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import { ToastContainer, toast } from 'react-toastify';

import '../../../../node_modules/react-toastify/dist/ReactToastify.css';

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}


const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const OrganizationsListUrl = `${API_URL}/internal/customers`

interface ModalProps {
    fetchWorkspacesList(): any
}

// const multiselectEmailsData: any = {
//   options: []
// };

const shareWithData: any = {
  options: []
};

// var getAllSelectedEmails : any = []

const AddDashboardModal: FC<ModalProps> = ({ fetchWorkspacesList }) => {

    let { organisationUsersId }: any = useParams();
    let { workspaceId } = useParams();

    const [showModal, setShowModal] = useState(false)
    // const { setItemIdForUpdate } = useListView()
    // const { refetch } = useQueryResponse()
    // const [activeInactive, setActiveInactive] = useState(true)
    // const [getfname, setfname] = useState('')
    // const [getlname, setlname] = useState('')

    const [getLoading, setLoading] = useState(false)
    const [getStandardUsers, setStandardUsers] = useState<any[]>([]);
    const [getAllSelectedEmails, setAllSelectedEmails] = useState<any>([]);
    const [getAllowToExternal, setAllowToExternal] = useState(true)
    // const [getSelectedEmails, setSelectedEmails] = useState<any[]>([]);
    // const [getUserEmails, setUserEmails] = useState<any[]>([]);

    const [getspinner, setspinner] = useState(false)
    const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);

    const showHideModel: any = () => {
        setShowModal(!setShowModal)
    }
    
    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            // refetch()
        }
        // setItemIdForUpdate(undefined)
    }
    
    const createDashboard = (formData: any) => {
        setLoading(true)
        setspinner(true)
        // console.log("createDashboard request")
        axios.post(process.env.REACT_APP_BASE_API_URL + 'internal/dashboard', formData)
          .then((res) => {
            setspinner(false)
            // console.log(res.data)

            if (res.data.status) {

                createGoodDataDashboard({
                    dashboard_name: formData.dashboard_name,
                    workspaceId: res.data.workspace._id,
                    gd_workspaceId: res.data.workspace.gd_workspace_ids[0],
                    dashboardId: res.data.dashboard ? res.data.dashboard._id : '',
                    getAllSelectedEmails: formData.getAllSelectedEmails
                })

                standardUsersRequest()
                
                // toast.success(res.data.message, {
                //   position: getHelpers.toastPosition(),
                // autoClose: getHelpers.toastAutoClose(),
                //   theme: 'colored'
                // });
              } else {
                
                if(res.data.message){
                    toast.error(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored',
                    });
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
      
                setspinner(false)
                
            }

          })
          .catch(error => {
            setspinner(false)
            console.log(error)
        
            if(error.response){
                if(error.response.data){
                    if(error.response.data.message){
                        toast.error(error.response.data.message, {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored',
                        });
                    } else {
                        if(error.response.data.errors && error.response.data.errors.length > 0){
                            if(error.response.data.errors[0] && error.response.data.errors[0]['msg']){
                                toast.error(error.response.data.errors[0]['msg'], {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            }
                        } else {
                          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                              position: getHelpers.toastPosition(),
                              autoClose: getHelpers.toastAutoClose(),
                              theme: 'colored'
                          });
                        }
                    }
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
            } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
            }

          })
    }

    const createGoodDataDashboard = (data: any) => {
      // console.log("createDashboard request")
      axios.post(process.env.REACT_APP_BASE_API_URL + 'gooddata/dashboard/save', data)
        .then((res) => {
            // console.log('res.send(-',res.data.message);
       
            
            toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });

            setTimeout(() => {
                setLoading(false)
                setspinner(false)
                showHideModel()
                fetchWorkspacesList()
                standardUsersRequest()
            }, 3000);
           
        })
        .catch(error => {
            setspinner(false)
            console.log(error)
            
            if(error.response){
                if(error.response.data){
                    if(error.response.data.message){
                        toast.error(error.response.data.message, {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        if(error.response.data.errors && error.response.data.errors.length > 0){
                            if(error.response.data.errors[0] && error.response.data.errors[0]['msg']){
                                toast.error(error.response.data.errors[0]['msg'], {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            }
                        } else {
                          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                              position: getHelpers.toastPosition(),
                              autoClose: getHelpers.toastAutoClose(),
                              theme: 'colored'
                          });
                        }
                    }
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
            } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
            }
        })
    }

    const handleSubmit = (e: any) => {
  
        e.preventDefault();

        const formData = {
            dashboard_name: e.target.dashboard_name.value,
            workspaceId: workspaceId,
            allow_to_external: getAllowToExternal,
            // userEmails: getSelectedEmails,//[e.target.emails.value],//['david@yopmail.com']
            getAllSelectedEmails: getAllSelectedEmails,
        }

        createDashboard(formData)

        // console.log(`Form submitted`, JSON.stringify(formData));
  
    }

    const standardUsersRequest = () => {
      // console.log("createDashboard request")
    //   multiselectEmailsData.options.length = 0;
      axios.get(process.env.REACT_APP_BASE_API_URL + 'workspace/standard/users/' + workspaceId)
        .then((res) => {
            if(res.data.users && res.data.users.length > 0){
                res.data.users.forEach((userData:any,index:any) => {
                    shareWithData.options.push({
                        email: userData['email'],
                        label: userData['email'],
                        value: userData['_id'],
                        user_id: userData['_id'],
                        first_name: userData['first_name']?userData['first_name']:'untitled',
                        last_name: userData['last_name']?userData['last_name']:'untitled',
                    })
                });
            }
            shareWithData.options = _.uniqBy(shareWithData.options,'email')
        })
        .catch(error => {
          console.log(error)
        })
    }

    // const onSelect = (selectedList: any, selectedItem: any) => {
    //   console.log('selectedList remove=', selectedList)
    //   setSelectedEmails(selectedList)
    // }
  
    // const onRemove = (selectedList: any, removedItem: any) => {
    //   console.log('selectedList remove=', selectedList)
    //   setSelectedEmails(selectedList)
    // }

    const handleChange = (
      newValue: any,
      actionMeta: ActionMeta<any>
    ) => {
        // console.group();
        // console.log('Value Changed=',newValue);
        
        // setUserEmails(newValue)

        // getAllSelectedEmails = newValue
        setAllSelectedEmails(newValue)

        // console.log('getAllSelectedEmails=',getAllSelectedEmails)

        // console.groupEnd();
    }

    // const handleInputChange = (inputValue: string) => {
    //     console.log('inputValue=',inputValue);
    // }

    const allowToExternalHandle = (e:any) => {
        console.log('e=',e.target.checked)
    }

    const changePermission = (eventValue:any,valueId:any) => {
        var selectedEmail = _.find(getAllSelectedEmails, {value:valueId});
        selectedEmail['access_permission'] = eventValue
        // console.log("selectedEmail=",selectedEmail,eventValue)

        var newArray = []
        for (const key in getAllSelectedEmails) {
            const element = getAllSelectedEmails[key];
            if(element['value'] == valueId){
                element['access_permission'] = eventValue
            }
            newArray.push(element)
        }
        setAllSelectedEmails(newArray)
        // console.log('newArray=',newArray)
    }

    const removeItem = (key:any) => {
        // console.log('removeItem=',key)
        // var newArray = getAllSelectedEmails
        getAllSelectedEmails.splice(key, 1)

        var newArray = []
        for (const key in getAllSelectedEmails) {
            const element = getAllSelectedEmails[key];
            newArray.push(element)
        }
        // console.log('newArray=2',newArray)

        setAllSelectedEmails(newArray)
    }
    
    useEffect(() => {
        // fetchWorkspacesList()
        standardUsersRequest()
    }, [])
    return (
        <>
        {/* <ToastContainer></ToastContainer> */}
        <div className='btn_col'>

            {
              _.includes(store.getState().userData.permissions,'manage_organizations_dashboards')?
              (
                <>
                    <button type="button" className="btn btn-primary btn-md fw-bolder btn-z-1500 btn-primary  my-1" onClick={() => { setShowModal(true) }}>
                        {/* <i className="bi bi-plus"></i> */}
                        <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                        </svg>
                        </span>
                        <span className='btnTxt'>Dashboard</span>
                    </button>
                </>
              ):('')
            }

            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showModal}
                animation={false}
            >
                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        {/* begin::Modal content */}
                        <div className='modal-content'>
                            {/* <UserEditModalHeader /> */}
                            <div className='modal-header bg-primary'>
                                <h2 className='fw-bolder text-white'>Create Dashboard</h2>
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => { showHideModel() }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={handleSubmit} noValidate>

                                <div className="modal-body scroll-yx pb-2">
                                    <div className='row me-n7 pe-7'>

                                        {/* begin::Input group */}
                                        <div className='col-sm-12 mb-7'>
                                            <label className='required fw-bold fs-6 mb-2'>Dashboard Name</label>
                                            <input
                                            className=
                                            'form-control form-control-solid mb-3 mb-lg-0'
                                            // value={dashboardName}
                                            type='text'
                                            // onChange={(event)=>changeDashboardText(event)}
                                            name='dashboard_name'
                                            autoComplete='off'
                                            />
                                        </div>

                                        <div className='col-sm-12 mb-2'>

                                            <div className='d-flex flex-stack'>
                                                <div className='me-5'>
                                                    {/* <p className='fs-6 fw-bolder text-gray-900 mb-0'>Allow to external?</p> */}
                                                    <p className="fs-7 fw-bolder text-muted "> Allow to external? </p>
                                                </div>
                                                <div className='form-check form-switch form-check-custom form-check-solid'>
                                                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                                        <span className='form-check-label text-muted me-2'>No</span>
                                                            <input
                                                                className='form-check-input'
                                                                type='checkbox'
                                                                name='allow_to_external'
                                                                checked={getAllowToExternal}
                                                                onClick={() =>
                                                                    setAllowToExternal(!getAllowToExternal)
                                                                }
                                                            />
                                                        <span className='form-check-label text-muted'>Yes</span>
                                                    </label>
                                                </div>
                                            </div>

                                            {/* <div className="form-check form-check-custom form-check-solid">
                                                <input onChange={e => allowToExternalHandle(e)} className="form-check-input" type="checkbox" value="1" id="flexCheckChecked" />
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                Allow to external
                                                </label>
                                            </div> */}

                                        </div>

                                        <div className='col-sm-12 mb-7'>
                                            <label className='fw-bold fs-6 mb-2'>Share with</label>

                                            {/* <Multiselect
                                                options={multiselectEmailsData.options} // Options to display in the dropdown
                                                selectedValues={''} // Preselected value to persist in dropdown
                                                onSelect={onSelect} // Function will trigger on select event
                                                onRemove={onRemove} // Function will trigger on remove event
                                                displayValue="email" // Property name to display in the dropdown options
                                                isObject={true}
                                            /> */}

                                            <CreatableSelect
                                                placeholder={"Select or enter new email"}
                                                className='customSelectDropdown'
                                                isMulti
                                                isClearable
                                                onChange={handleChange}
                                                options={shareWithData.options}
                                                name='userEmails'
                                                // onInputChange={handleInputChange}
                                            />

                                        </div>

                                        <div className='col-sm-12 mb-7'>

                                        <div className='table-responsivex'>
                                            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                                <thead>
                                                <tr className='border-0'>
                                                    <th className='p-0 min-w-150px'></th>
                                                    <th className='p-0 min-w-50px'></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {getAllSelectedEmails.map((item: any, index:any) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='symbol symbol-45px me-2 symbol-circle'>
                                                                    {
                                                                        item.__isNew__?
                                                                        (
                                                                            <span className={'symbol-label text-uppercase bg-' + item.label.charAt(0)}>
                                                                                <span className='text-white fs-3 fw-bolder'>{item.label.charAt(0)}</span>
                                                                            </span>
                                                                        ):(
                                                                            item.first_name?
                                                                            (
                                                                                <span className={'symbol-label text-uppercase bg-' + item.first_name.charAt(0)}>
                                                                                    <span className='text-white fs-3 fw-bolder'>{item.first_name.charAt(0)}</span>
                                                                                </span>
                                                                            ):(
                                                                                <span className={'symbol-label text-uppercase bg-' + item.label.charAt(0)}>
                                                                                    <span className='text-white fs-3 fw-bolder'>{item.label.charAt(0)}</span>
                                                                                </span>
                                                                            )
                                                                        )
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span className='text-dark text-hover-primary fs-5 d-block'>
                                                                    {
                                                                        item.first_name && item.last_name?
                                                                        (
                                                                            <>
                                                                            <div className='fw-bolder'>
                                                                                {item.first_name+' '+item.last_name}
                                                                            </div>
                                                                            <div>
                                                                                {item.label}
                                                                            </div>
                                                                            </>
                                                                        ):(
                                                                            item.label
                                                                        )
                                                                    }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='text-end'>
                                                            <select className='form-select' onChange={(event) => changePermission(event.target.value,item.value)}>
                                                                <option value="can_view">Can View</option>
                                                                <option value="can_edit">Can Edit</option>
                                                            </select>
                                                        </td>
                                                        {/* <td className='text-end'>
                                                            <a
                                                                 onClick={(event) => removeItem(index)}
                                                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                                            >
                                                                <KTSVG
                                                                path='/media/icons/duotune/arrows/arr064.svg'
                                                                className='svg-icon-2'
                                                                />
                                                            </a>
                                                        </td> */}
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* {JSON.stringify(getAllSelectedEmails)} */}
                                        {/* 
                                        {
                                            getAllSelectedEmails.map((object:any, i:any) => 
                                                <>
                                                <div key={i}>
                                                    asdf
                                                </div>
                                                </>
                                            )
                                        } */}
                                        </div>

                                    </div>
                                </div>

                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button type="submit" className="btn btn-primary btn-primary">
                                        {!getspinner && (
                                            <span className='indicator-label'>Save</span>
                                        )}
                                        {/* Submit */}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button type="reset" className="btn btn-white" data-bs-dismiss="modal" onClick={() => { showHideModel() }}> Cancel </button>
                                </div>

                            </form>
                            {/* end::Modal body */}
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
            </Modal>

        </div>
        </>
    )

}

// export { AddDashboardModal }
export default connect(mapStateToProps)(AddDashboardModal);