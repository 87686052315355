export const Types = {
    EXTERNAL_WORKSPACE_PERMISSIONS: 'EXTERNAL_WORKSPACE_PERMISSIONS',
    EXTERNAL_USER_PERMISSIONS: 'EXTERNAL_USER_PERMISSIONS',
    INTERNAL_USER_PERMISSIONS: 'INTERNAL_USER_PERMISSIONS',
    INTERNAL_USER_ROLES: 'INTERNAL_USER_ROLES',
    LOGIN: 'LOGIN',
    ADD_USER: 'ADD_USER',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
    FORM_SUBMITION_STATUS: 'FORM_SUBMITION_STATUS'
}