import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import profile from '../reducers/profile';
import externalWorkspacePermissions from '../reducers/externalWorkspacePermissions';
import externalPermissions from '../reducers/externalPermissions';
import permissions from '../reducers/permissions';
import roles from '../reducers/roles';

const rootReducer = combineReducers({
  user: profile,
  userData: permissions,
  userExternalData: externalPermissions,
  userExternalWorkspaceData: externalWorkspacePermissions,
  userRolesData: roles,
});

const configureStore = () => {
  return createStore(
    rootReducer,
    compose(applyMiddleware(thunk))
  );
};

const store = configureStore();

export {store};
