/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, setPasswordRequest} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import PasswordStrengthBar from 'react-password-strength-bar';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import * as getHelpers from '../../../modules/auth/GetHelpers'

const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToken = `Bearer ${ApiToken}`

const setPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(12, 'Password too short. Minimum required length is 12.')
    .max(20, 'Maximum 20 characters')
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must contain o lharacters,cOne Uppercase, One Lowercase, One Number and One Special Case Character"
    // )
    // .matches(
    //   /^(?=.*[a-z])/,
    //   "Must contain one lowercase character"
    // )
    // .matches(
    //   /^(?=.*[A-Z])/,
    //   "Must contain one uppercase character"
    // )
    // .matches(
    //   /^(?=.*[0-9])/,
    //   "Must contain one number character"
    // )
    // .matches(
    //   /^(?=.*[!@#\$%\^&\*])/,
    //   "Must contain one special case character"
    // )
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Must match "password" field value'),
})

const initialValues = {
  password: '',
  confirm_password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function SetPassword() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [getCheckTokenExpiry, setCheckTokenExpiry] : any = useState({})

  const {id,token} = useParams()

  const navigate = useNavigate()


  const reqTokenExpiry = () => {
    const config = { headers: { Authorization: BearerToken } };
    axios.get(`${API_URL}/forgot-password/${id}/${token}`,config)
    .then(async (res) => {
      console.log('res.data=',res.data)
      setCheckTokenExpiry(res.data)
    })
    .catch(async (error) => {
      toast.error(error.response.data.error, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
      });
      setTimeout(() => {
        navigate('/auth/login')
      }, getHelpers.toastAutoClose());
    });
  }

  const goToPage = () => {
    navigate("/auth/forgot-password");
  }

  useEffect(() => {
    reqTokenExpiry()
    // this.refs.someName.value = '';
  },[])

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: setPasswordSchema,
  //   onSubmit: async (values, {setStatus, setSubmitting}) => {
  //     setLoading(true)
  //     try {
  //       const {data: auth} = await login(values.email, values.password)
  //       saveAuth(auth)
  //       const {data: user} = await getUserByToken(auth.api_token)
  //       setCurrentUser(user)
  //     } catch (error) {
  //       console.error(error)
  //       saveAuth(undefined)
  //       setStatus('The login detail is incorrect')
  //       setSubmitting(false)
  //       setLoading(false)
  //     }
  //   },
  // })

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: setPasswordSchema,
  //   onSubmit: async (values, {setStatus, setSubmitting}) => {
  //     let resquestData;
  //     setLoading(true)
  //     try {
  //         var idAndToken = '/'+id+'/'+token
  //         resquestData =await setPasswordRequest(values,idAndToken);
  //       // fullName = resquestData.data.first_name +' '+ resquestData.data.last_name
  //       // const {data: auth} = resquestData
  //       // localStorage.setItem('full_name', fullName);
  //       // saveAuth(auth)
  //       //  localStorage.setItem('api_token', auth.api_token);
  //       // const {data: user} = await getUserByToken(auth.api_token)
  //       // setCurrentUser(user)
  //       setLoading(false)
  //       setStatus(resquestData.message)
  //       setSubmitting(false)
        
  //     } catch (error) {
  //       saveAuth(undefined)
  //       setStatus(resquestData.message)
  //       setSubmitting(false)
  //       setLoading(false)
  //     }
  //   },
  // })

  const [getPassword, setPassword]: any = useState('');
  const [getConfirmPassword, setConfirmPassword]: any = useState('');

  const valueChangePassword = (e:any) => {
    setPassword(e.target.value)
    initialValues.password = e.target.value
  }
  const valueChangeConfirmPassword = (e:any) => {
    setConfirmPassword(e.target.value)
    initialValues.confirm_password = e.target.value
  }

  var idAndToken = '/'+id+'/'+token
  const formik = useFormik({
    initialValues,
    validationSchema: setPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // setHasErrors(undefined)
      setPasswordRequest(values,idAndToken)
        .then(({data: {result}}) => {
          setHasErrors(false)
          setLoading(false)
          setSubmitting(false)
          setStatus('Password reset successfully')
          setTimeout(() => {
            navigate('/auth/login')
          }, 2000);
        })
        .catch(() => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus('Passwords are mismatch')
        })
    },
  })

  return (
    <>
    <ToastContainer></ToastContainer>
    {
      getCheckTokenExpiry.expired?
      (
        <div className='loginPage'>
          <div className="utilities-form-column">
            <div className="utilities-form-wrap">
              <h4 className="h4-title xs-margin">Token Expired.</h4>
              <p className="m-paragraph xs-margin">Already a member? <Link to="/auth/login" className="link-text">Log in</Link>.</p>
              <p className=' l-margin' style={{fontSize: '14px'}}>Your forgot password token has expired. You can get a new token at your email using below button.</p>
              <div className="utilities-form w-form">
                {/* <form id="email-form" name="email-form" data-name="Email Form" method="get"
                  onSubmit={formik.handleSubmit}
                  noValidate
                > */}

                  {getCheckTokenExpiry.status? (
                    <div className="success-message w-form-fail mb-5">
                      <div className="center-content">
                        <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf70c956a3e7_check-circle.svg" loading="lazy" alt="" className="s-icon" />
                          <div>{getCheckTokenExpiry.message}</div>
                        </div>
                      </div>
                    </div>
                  ):('')}

                  {hasErrors === true? (
                    formik.status ? (
                      <div className="error-message w-form-fail mb-5">
                        <div className="center-content">
                          <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf360756a3e8_alert-triangle.svg" loading="lazy" alt="" className="s-icon" />
                            <div>{formik.status}</div>
                          </div>
                        </div>
                      </div>
                    ):('')
                  ):('')}
                  
                  {/* <div className='inputMarginBottom'>
                    <span style={{fontSize:'16px'}}>{getCheckTokenExpiry.email}</span>
                  </div> */}
                  
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='utilities-button w-button'
                    disabled={loading}
                    onClick={() => goToPage()}
                  >
                    {!loading && <span className='indicator-label fw-bolder'>Forgot Password</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  
                {/* </form> */}
                
              </div>
            </div>
          </div>
        </div>
      ):(
        <div className='loginPage'>
          <div className="utilities-form-column">
            <div className="utilities-form-wrap">
              <h4 className="h4-title l-margin">Reset Your Password.</h4>
              <div className="utilities-form w-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get"
                  onSubmit={formik.handleSubmit}
                  noValidate
                >

                  {hasErrors === false? (
                    <div className="success-message w-form-fail mb-5">
                      <div className="center-content">
                        <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf70c956a3e7_check-circle.svg" loading="lazy" alt="" className="s-icon" />
                          <div>Password reset successfully, redirecting you to login screen</div>
                        </div>
                      </div>
                    </div>
                  ):('')}

                  {hasErrors === true? (
                    formik.status ? (
                      <div className="error-message w-form-fail mb-5">
                        <div className="center-content">
                          <div className="xs-badge-grid"><img src="https://assets.website-files.com/6262a3e80c60bf573256a3c0/6262a3e80c60bf360756a3e8_alert-triangle.svg" loading="lazy" alt="" className="s-icon" />
                            <div>{formik.status}</div>
                          </div>
                        </div>
                      </div>
                    ):('')
                  ):('')}
                  
                  <div className='inputMarginBottom'>
                    <input
                      data-name="Password" 
                      placeholder="Your password" 
                      id="Password"
                      type='password'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      value={getPassword}
                      onChange={e => valueChangePassword(e)}
                      className={clsx(
                        'form-control large-field password w-input',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                    <PasswordStrengthBar password={getPassword} minLength={12} scoreWords={['Password too weak. Use a combination of lower, upper and special characters.', 'Password too weak. Use a combination of lower, upper and special characters.', 'Password is ok', 'Password is ok', 'Password is ok']} barColors={['#ddd', '#ef4836', '#25c281', '#25c281', '#25c281']} />
                  </div>
                  
                  <div className='inputMarginBottom'>
                    <input
                      data-name="Confirm_Password" 
                      placeholder="Your confirm password" 
                      id="ConfirmPassword"
                      type='password'
                      autoComplete='off'
                      {...formik.getFieldProps('confirm_password')}
                      value={getConfirmPassword}
                      onChange={e => valueChangeConfirmPassword(e)}
                      className={clsx(
                        'form-control large-field password w-input',
                        {
                          'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password,
                        },
                        {
                          'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                        }
                      )}
                    />
                    {formik.touched.confirm_password && formik.errors.confirm_password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.confirm_password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='utilities-button w-button'
                    disabled={loading}
                  >
                    {!loading && <span className='indicator-label fw-bolder'>Reset Your Password</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      )
    }
    </>
  )
}
