/* eslint-disable react/jsx-no-target-blank */
// import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, Link, useParams } from 'react-router-dom'
// import {KTSVG} from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
// import { AsideMenuItemWithSubWorkspaceSettings } from './AsideMenuItemWithSubWorkspaceSettings'
import { AsideMenuItem } from './AsideMenuItem'
// import { useEffect } from 'react'
import { useState, useEffect } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { AsideComponentLoading } from '../../../../app/modules/AsideComponentLoading'
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
import { useAuth } from '../../../../../src/app/modules/auth/core/Auth'
import { getUserByToken, login } from '../../../../../src/app/modules/auth/core/_requests'
import * as _ from "lodash";
import { Modal } from 'react-bootstrap'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionCreators } from '../../../../redux/actions/profile';
import { ActionPermissions } from '../../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../../redux/actions/externalPermissions';
import { ActionExternalWorkspacePermissions } from '../../../../redux/actions/externalWorkspacePermissions';
import { ActionRoles } from '../../../../redux/actions/roles';
import {store} from '../../../../redux/store/index';

import { AddWorkspaceModelAsideMenu } from '../../../../app/pages/customer-organizations/AddWorkspaceModelAsideMenu'

import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useLayout} from '../../core'

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
let currentActiveOrgName: any = ''
let currentActiveOrgId: any = ''

var globalKey : any = 0

// var getUserInternalPermissions : any = []

const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions
  }
}

// var getSelectedWorkspaceId = ''

var getWPermissions : any = []

const AsideMenuMain: React.FC = () => {

  let { organisationUsersId } = useParams();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false)
// export function AsideMenuMain() {

  // console.log("recent_url",window.location.href);
  // alert(window.location.href);
  // const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [getMyDashboards, setMyDashboards] = useState<any[]>([]);
  const [getMySharedDashboards, setMySharedDashboards] = useState<any[]>([]);
  const [getAsideComponentLoading, setAsideComponentLoading] = useState(false)
  const [checkExternalRole, setCheckExternalRole] = useState(getUserInfo.roleLabel())

  const [getKey, setKey] = useState<any>(0);
  const [getWorkspaceId, setWorkspaceId] = useState<any[]>([]);
  const [getAllWorkspace, setAllWorkspace] = useState<any[]>([]);
  const [getOrgWorkspaces, setOrgWorkspaces] = useState<any[]>([]);
  const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
  const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
  const [getExternalWorkspacePermissions, setUserAllExternalPermissions] = useState<any[]>([]);
  const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);
  
  const [getAllOrganizations, setAllOrganizations] = useState<any[]>([]);
  const [getActiveOrganizationId, setActiveOrganizationId] = useState<any>('');
  const [getActiveOrganizationName, setActiveOrganizationName] = useState<any>('');
  
  const [getSelectedWorkspaceId, setSelectedWorkspaceId] = useState<any>('');

  const intl = useIntl()
  
  const showHideModel: any = () => {
    setShowModal(!setShowModal)
  }

  

  const selectingWorkspace = (workspaceId:any) => {
    console.log('workspaceId=',workspaceId)
    setSelectedWorkspaceId(workspaceId)
  }

  const selectWorkspace = (workspaceId:any) => {
    console.log('workspaceId=',workspaceId)
    // getSelectedWorkspaceId = workspaceId
    // setTimeout(() => {
      // setSelectedWorkspaceId(workspaceId)
    // }, 500);
    
    window.addEventListener('dispatchWorkspaceId', (event:any) => {
      if (event['detail'].value) {
        console.log('dispatchWorkspaceId=',event['detail'].workspaceId)
        setSelectedWorkspaceId(event['detail'].workspaceId)
      }
    })

  }

  /////////////////// SubMenu
  type Props = {
    to: string
    title: string
    icon?: string
    fontIcon?: string
    hasBullet?: boolean
    workspaceId?: string
  }
  const AsideMenuItemWithSubWorkspaceSettings: React.FC<Props> = ({
    children,
    to,
    title,
    icon,
    fontIcon,
    hasBullet,
    workspaceId,
  }) => {
    const {pathname} = useLocation()
    const isActive = checkIsActive(pathname, to)
    const {config} = useLayout()
    const {aside} = config

    const clickOnWorkspace = (data:any) => {
      window.dispatchEvent(new CustomEvent(
        'dispatchWorkspaceId', 
        {
            detail: {
                value: true,
                workspaceId: data
            }
        }
      ))
    }
  
    return (
      <div
        className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
        data-kt-menu-trigger='click'
        onClick={() => {
          selectWorkspace(workspaceId);
          clickOnWorkspace(workspaceId)
          // setSelectedWorkspaceId(workspaceId)
        }}
      >
        <span className='menu-link'>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && aside.menuIcon === 'svg' && (
            <span className='menu-icon'>
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
          <span className='menu-title'>{title}</span>
          <span className='menu-arrow'></span>
        </span>
        <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
          {children}
        </div>
      </div>
    )
  }
  ///////////////////

  const findOrgId = async (data: any) => {
    let userData: any = await localStorage.getItem('userinfo');
    userData = JSON.parse(userData);
    let currentOrgId = ''
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id == userData.workspace_id) {
        currentOrgId = data[i].name;
        currentActiveOrgId = data[i]._id
      }
    }
    return currentOrgId;
  }
  // useEffect(() => {

  // }, [])
  const switchWorkspace = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/workspace/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
        window.location.reload();
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }

      // }, 2000);
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }

  const getData = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + 'gooddata/dashboards',
    )
      .then((res) => {
        console.log('res=',res.data)
        setMyDashboards(res.data.myDashboards)
        setMySharedDashboards(res.data.mySharedDashboards)
        setWorkspaceId(res.data.workspaceId)
        // setAsideComponentLoading(false)
      })
      .catch((error) => {
        // console.log(error)
        // setAsideComponentLoading(false)
      });
  };

  // console.log('getMyDashboards=',getMyDashboards)
  const fetchAllOrg = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/workspaces",
    )
      .then(async (response) => {
        if (response.data) {
          currentActiveOrgName = await findOrgId(response.data.workspaces);
          setAllWorkspace(response.data.workspaces)
        }
      })
      .catch((error) => {
        // console.log(error)
      });

  };

  const getMe = () => {
    axios.get(process.env.REACT_APP_BASE_API_URL + "me").then(async (res) => {
      let resData = await res.data;
      setCheckExternalRole(resData.role.name)

      ///////////////// updating workspace
      let userInfo : any = localStorage.getItem('userinfo');
      userInfo = JSON.parse(userInfo)
      if(userInfo.workspace_id && !_.includes(res.data.activeWorkspaces,userInfo.workspace_id)){
        userInfo.workspace_id = await res.data.activeWorkspaces[0];
        // userInfo.user_type = await res.data.user_type;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
      }
      ///////////////// updating workspace

    }).catch(error => {
      // console.log(error)
    });
  };

  const dispatch = useDispatch();
  
  const fetchVerifyAccess = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
    const ApiToken = localStorage.getItem('api_token')
    const BearerToken = `Bearer ${ApiToken}`
    const config = {
      headers: { Authorization: BearerToken }
    };
    axios.get(
        GET_USER_BY_ACCESSTOKEN_URL,
        config
    )
      .then(async (res) => {
        if (res.data.status) {
          if(res.data.internalPermissions){
            if(res.data.internalPermissions.permissions){
              dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
              setUserInternalPermissions(res.data.internalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.externalPermissions){
            // console.log('res.data.externalPermissions.permissions=',res.data.externalPermissions.permissions)
            if(res.data.externalPermissions.permissions){
              // getWPermissions = res.data.externalPermissions.permissions
              var data = getHelpers.doesHasPermissions(res.data.externalPermissions.permissions,organisationUsersId)
              dispatch(ActionExternalPermissions.externalUserPermissions(data))
              dispatch(ActionExternalWorkspacePermissions.externalWorkspacePermissions(res.data.externalPermissions.permissions))
              setUserExternalPermissions(data)
              setUserAllExternalPermissions(res.data.externalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.internalRoles){
            if(res.data.internalRoles.roles){
              dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
              setUserInternalRoles(res.data.internalRoles.roles)
              setKey(getKey+1)
              globalKey = globalKey + 1
            }
          }
        }
        globalKey = globalKey + 1
      })
      .catch((error) => {});
  }

  /////////////// organization workspaces
  const fetchOrgWorkspaces = async () => {
    setAsideComponentLoading(true)
    // alert('fetchOrgWorkspaces')
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/org/workspaces",
    )
      .then(async (response) => {
        setAsideComponentLoading(false)
        if (response.data) {
          currentActiveOrgName = await findOrgId(response.data.workspaces);
          setOrgWorkspaces(response.data.workspaces)
        }
      })
      .catch((error) => {
        setAsideComponentLoading(false)
        // console.log(error)
      });

  };
  ///////////////

  
  const fetchAllOrganizations = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/organizations",
    )
      .then(async (response) => {
        if (response.data) {
          // currentActiveOrgName = await findOrgId(response.data.workspaces);
          setAllOrganizations(response.data.organizations)
          if(response.data.activeOrganization){
            setActiveOrganizationId(response.data.activeOrganization._id)
            setActiveOrganizationName(response.data.activeOrganization.name)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const switchOrganization = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/organization/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
          window.location.reload();
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }

      // }, 2000);
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }

  useEffect(() =>{
    fetchVerifyAccess()
  },[])

  useEffect(() => {

    window.addEventListener('workspaceAddExternalUser', (event:any) => {
      if(event['detail'].value){
        fetchVerifyAccess()
        getMe();
        fetchAllOrganizations()  
        fetchOrgWorkspaces()
        fetchAllOrg()
      }
    })

    getMe();
    // console.log('=====',getExternalWorkspacePermissions)

    // if(_.includes(store.getState().userExternalData.externalPermissions,'view_workspaces')){

      fetchAllOrganizations()  
      // console.log('store.getState().userData.permissions1=',store.getState().userData.permissions)
      fetchOrgWorkspaces()
      fetchAllOrg()
      if (getUserInfo.userType() == 'external') {
        getData()
      }

    // }

  }, []);

  return (
    <>
    
    <div key={globalKey}>
      {
        getUserInfo.userType() == 'external' ?
        // _.includes(getUserExternalPermissions,'manage_organizations_dashboards')?
          (
            <>

            {
              getAsideComponentLoading ?
                (
                  <AsideComponentLoading></AsideComponentLoading>
                ) : ('')
            }

            {/* Start */}
            <div className='hideShowButtons'>                

              <AsideMenuItemWithSub
                to='#CustomersWorkspaces'
                title={
                  getActiveOrganizationName?
                  (
                    getActiveOrganizationName
                  ):('Select Organization')
                }
                // icon='/media/icons/duotune/coding/cod001.svg'
                icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"/><path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"/></svg>'
                fontIcon='bi-person'
              >
                {getAllOrganizations.map((object: any, index) => (
                  <>
                  <div className="menu-item" key={index}>
                    <a className="menu-link without-sub" key={index} onClick={() => switchOrganization(object._id)}>
                      <span className="menu-title">
                          {object.name}
                      </span>
                    </a>
                  </div>
                  </>
                ))}
              </AsideMenuItemWithSub>
                  
              <AsideMenuItem
                to={'/external/dashboard/create'}
                // icon='/media/icons/duotune/abstract/abs011.svg'
                icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/><rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/><rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/></svg>'
                title='Create Dashboard'
                // fontIcon='bi-layers'
              />

              <div className='separator my-5'></div>

            </div>

            <AsideMenuItem
              to='/overview'
              // icon='/media/icons/duotune/general/gen008.svg'
              icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z" fill="currentColor"/><path opacity="0.3" d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z" fill="currentColor"/><path opacity="0.3" d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z" fill="currentColor"/><path opacity="0.3" d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z" fill="currentColor"/></svg>'
              title='Overview'
              // fontIcon='bi-layers'
            />

            {
              // _.includes(store.getState().userExternalData.externalPermissions,'manage_workspaces')?
              getHelpers.hasAccessInAnyWorkspace(getExternalWorkspacePermissions,'manage_workspaces')?
              (
                <>
                  <span className="sharedDashboardx">
                    <div className="menu-item">
                      <a className="menu-link without-sub" onClick={() => { setShowModal(true) }}>
                        {/* <span className="menu-bullet"><span className="bullet bullet-dot"></span></span> */}
                        <span className="menu-title">
                          <span className="menu-icon">
                            <span className="svg-icon svg-icon-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                            </span>
                            {/* <i className="bi bi-box"></i> */}
                          </span>
                          Create Workspace
                        </span>
                      </a>
                    </div>
                  </span>

                  <div>
                    <Modal
                      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                      id='kt_inbox_compose'
                      role='dialog'
                      data-backdrop='false'
                      aria-hidden='true'
                      tabIndex='-1'
                      show={showModal}
                      animation={false}
                    >
                      <AddWorkspaceModelAsideMenu showHideModel={showHideModel} />
                    </Modal>
                  </div>

                  {/* <a className="btn btn-sm fw-bolder btn-primary customIconButton" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => { setShowModal(true) }}>
                    <i className="bi bi-person-workspace btnIcon"></i>
                    <span className='btnTxt'>Create Workspace</span>
                  </a> */}
                </>
              ):('')
            }

            {
              getHelpers.hasAccessInAnyWorkspace(getExternalWorkspacePermissions,'manage_organizations_dashboards')?
              (
                <>
                <AsideMenuItem
                  to={'/external/dashboard/create/'+getSelectedWorkspaceId}
                  // icon='/media/icons/duotune/communication/com015.svg'
                  icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" /><rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" /><rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" /></svg>'
                  title='Create Dashboard'
                />
                </>
              ):('')
            }
            
            {/* Begin: Workspaces dropdown */}
            {
              getOrgWorkspaces.length > 0?
              (
                <>
                  <AsideMenuItemWithSub
                    to='#MYdashboard2'
                    title={'Workspaces'}
                    // icon='/media/icons/duotune/abstract/abs027.svg'
                    icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"/><path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"/></svg>'
                  >
                  {
                    getOrgWorkspaces.map((workspace: any, index: any) => (
                      <>
                        <div
                            className={clsx('menu-item', 'menu-accordion')}
                            data-kt-menu-trigger='click'
                            onClick={() => {
                              selectingWorkspace(workspace._id);
                              // clickOnWorkspace(workspace._id)
                            }}
                          >
                            <span className='menu-link'>
                              <span className='menu-icon'>
                                <KTSVG path='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"/><path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"/></svg>' className='svg-icon-2' />
                              </span>
                              <span className='menu-title'>{workspace.name}</span>
                              <span className='menu-arrow'></span>
                            </span>
                            <div className={clsx('menu-sub menu-sub-accordion menu-active-bg')}>
                              {
                                workspace.myDashboards && workspace.myDashboards.length > 0 ?
                                (
                                  <>
                                    {workspace.myDashboards.map((object:any, i:any) =>
                                    (
                                      <>
                                        {
                                          object.gd_dashboard && object.gd_dashboard.length > 0 ?
                                          (
                                            object.gd_dashboard[0] ?
                                              (
                                                object.gd_dashboard[0].identifier ?
                                                (
                                                  <>
                                                  
                                                    <div className="menu-item" key={i}>
                                                      <a className="menu-link without-sub pl-15"
                                                        href={'/#/dashboard/' + object._id}
                                                      >
                                                        <span className="menu-title">
                                                          <span className="menu-icon">
                                                            <span className="svg-icon svg-icon-2">
                                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"/>
                                                                <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"/>
                                                                <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"/>
                                                                <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"/>
                                                                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"/>
                                                              </svg>
                                                            </span>
                                                          </span>
                                                          {object.name}
                                                        </span>
                                                      </a>
                                                    </div>
                                                  </>
                                                ) : (<><span></span></>)
                                              ) : (<><span></span></>)
                                          ) : (<><span></span></>)
                                        }
                                      </>
                                    )
                                    )}
                                  </>
                                ) : ('')
                              }
                              {
                                workspace.mySharedDashboards && workspace.mySharedDashboards.length > 0 ?
                                (
                                  <>
                                    {workspace.mySharedDashboards.map((object:any, i:any) =>
                                    (
                                      <>
                                        {
                                          object.gd_dashboard && object.gd_dashboard.length > 0 ?
                                          (
                                            object.gd_dashboard[0] ?
                                              (
                                                object.gd_dashboard[0].identifier ?
                                                (
                                                  <>
                                                    <div className="menu-item" key={i}>
                                                      <a className="menu-link without-sub pl-15"
                                                        href={'/#/dashboard/' + object._id}
                                                      >
                                                        <span className="menu-title">
                                                          <span className="menu-icon">
                                                            <span className="svg-icon svg-icon-2">
                                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"/>
                                                              <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"/>
                                                              <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"/>
                                                              </svg>
                                                            </span>
                                                          </span>
                                                          {object.name}
                                                        </span>
                                                      </a>
                                                    </div>
                                                  </>
                                                ) : (<><span></span></>)
                                              ) : (<><span></span></>)
                                          ) : (<><span></span></>)
                                        }
                                      </>
                                    )
                                    )}
                                  </>
                                ) : ('')
                              }
                            </div>
                          </div>
                          {/* <AsideMenuItemWithSubWorkspaceSettings
                            to='#MYdashboard2'
                            title={workspace.name}
                            workspaceId={workspace._id}
                            key={index}
                            // icon='/media/icons/duotune/abstract/abs027.svg'
                            icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"/><path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"/></svg>'
                          >
                            <>
                              {
                                <>
                                {
                                  workspace.myDashboards && workspace.myDashboards.length > 0 ?
                                  (
                                    <>
                                      {workspace.myDashboards.map((object:any, i:any) =>
                                      (
                                        <>
                                          {
                                            object.gd_dashboard && object.gd_dashboard.length > 0 ?
                                            (
                                              object.gd_dashboard[0] ?
                                                (
                                                  object.gd_dashboard[0].identifier ?
                                                  (
                                                    <>
                                                    
                                                      <div className="menu-item" key={i}>
                                                        <a className="menu-link without-sub pl-15"
                                                          href={'/#/dashboard/' + object._id}
                                                        >
                                                          <span className="menu-title">
                                                            <span className="menu-icon">
                                                              <span className="svg-icon svg-icon-2">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"/>
                                                                  <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"/>
                                                                  <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"/>
                                                                  <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"/>
                                                                  <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"/>
                                                                </svg>
                                                              </span>
                                                            </span>
                                                            {object.name}
                                                          </span>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (<><span></span></>)
                                                ) : (<><span></span></>)
                                            ) : (<><span></span></>)
                                          }
                                        </>
                                      )
                                      )}
                                    </>
                                  ) : ('')
                                }
                                {
                                  workspace.mySharedDashboards && workspace.mySharedDashboards.length > 0 ?
                                  (
                                    <>
                                      {workspace.mySharedDashboards.map((object:any, i:any) =>
                                      (
                                        <>
                                          {
                                            object.gd_dashboard && object.gd_dashboard.length > 0 ?
                                            (
                                              object.gd_dashboard[0] ?
                                                (
                                                  object.gd_dashboard[0].identifier ?
                                                  (
                                                    <>
                                                      <div className="menu-item" key={i}>
                                                        <a className="menu-link without-sub pl-15"
                                                          href={'/#/dashboard/' + object._id}
                                                        >
                                                          <span className="menu-title">
                                                            <span className="menu-icon">
                                                              <span className="svg-icon svg-icon-2">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"/>
                                                                <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"/>
                                                                <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"/>
                                                                </svg>
                                                              </span>
                                                            </span>
                                                            {object.name}
                                                          </span>
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (<><span></span></>)
                                                ) : (<><span></span></>)
                                            ) : (<><span></span></>)
                                          }
                                        </>
                                      )
                                      )}
                                    </>
                                  ) : ('')
                                }
                                </>
                              }
                            </>
                          </AsideMenuItemWithSubWorkspaceSettings> */}
                      </>
                    ))
                  }  
                  </AsideMenuItemWithSub>
                </>
              ):('')
            }
            
            {
              getOrgWorkspaces.length > 0 && getSelectedWorkspaceId != ''?
              (
                <>
                  <AsideMenuItemWithSub
                    to='#MYdashboard'
                    title={'Workspace Settings'}
                    // icon='/media/icons/duotune/abstract/abs027.svg'
                    icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"/><path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"/></svg>'
                  >
                  {
                    <>
                    <span className="sharedDashboardx svg-icon-dander">
                      {
                        _.includes(getHelpers.doesHasPermissions(getExternalWorkspacePermissions,getSelectedWorkspaceId),'view_workspaces')?
                        (
                          <>
                          <AsideMenuItem
                            to={'/workspace/'+getSelectedWorkspaceId}
                            // icon='/media/icons/duotune/communication/com015.svg'
                            icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"/>
                            <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"/>
                            </svg>'
                            title='Workspace Details'
                          />
                          </>
                        ):('')
                      }
                    </span>
                    {
                      _.includes(getHelpers.doesHasPermissions(getExternalWorkspacePermissions,getSelectedWorkspaceId),'view_users')?
                      (
                        <>
                        <span className="sharedDashboardx">
                          <AsideMenuItem
                            to={'/users/'+getSelectedWorkspaceId}
                            // icon='/media/icons/duotune/communication/com015.svg'
                            icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/><rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/><path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/><rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/></svg>'
                            title='Workspace Users'
                          />
                        </span>
                        </>
                      ):('')
                    }
                    </>
                  }  
                  </AsideMenuItemWithSub>
                </>
              ):('')
            }

            {
              getHelpers.hasManageSettings(getExternalWorkspacePermissions,'view_settings')?
              // _.includes(store.getState().userExternalData.externalPermissions,'view_settings')?
              (
                <>
                <AsideMenuItemWithSub
                  to='#CustomersWorkspaces'
                  title='Roles & Permissions'
                  // icon='/media/icons/duotune/coding/cod001.svg'
                  icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/><path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/></svg>'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='/roles'
                    // icon='/media/icons/duotune/general/gen051.svg'
                    title='Roles'
                    fontIcon='bi-layers'
                    hasBullet={true}
                  />
                  <AsideMenuItem
                    to='/permissions'
                    // icon='/media/icons/duotune/general/gen051.svg'
                    title='Permissions'
                    fontIcon='bi-layers'
                    hasBullet={true}
                  />
                </AsideMenuItemWithSub>
                </>
              ):('')
            }

          </>
        ) : ('')
      }

      {/* <div
        className={clsx('menu-item', 'menu-accordion')}
        data-kt-menu-trigger='click'
      >
        <span className='menu-link'>
          <span className='menu-icon'>
            <KTSVG path='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"/><path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"/></svg>' className='svg-icon-2' />
          </span>
          <span className='menu-title'>asdf</span>
          <span className='menu-arrow'></span>
        </span>
        <div className={clsx('menu-sub menu-sub-accordion menu-active-bg')}>
          <div className="menu-item">
            <a className="menu-link without-sub pl-15"
              href={'/#/dashboard/1'}
            >
              <span className="menu-title">
                <span className="menu-icon">
                  <span className="svg-icon svg-icon-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"/>
                      <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"/>
                      <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"/>
                      <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"/>
                      <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"/>
                    </svg>
                  </span>
                </span>
                Dash 1
              </span>
            </a>
          </div>
        </div>
      </div> */}




    
      {
        
        <>
          {
            getUserInfo.userType() == 'internal' ?
            <AsideMenuItem
              to='/internal/dashboard'
              // icon='/media/icons/duotune/general/gen008.svg'
              icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z" fill="currentColor"/><path opacity="0.3" d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z" fill="currentColor"/><path opacity="0.3" d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z" fill="currentColor"/><path opacity="0.3" d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z" fill="currentColor"/></svg>'
              title='Overview'
              fontIcon='bi-layers'
            />
            :''
          }
          {
            _.includes(getUserInternalPermissions,'view_admins')?
            (
              <AsideMenuItem
                to='/internal/users/list'
                // icon='/media/icons/duotune/communication/com015.svg'
                icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/><rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/><path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/><rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/></svg>'
                title='Users'
                fontIcon='bi-layers'
              />
            ):('')
          }
          
          {
            _.includes(getUserInternalPermissions,'view_organizations') || 
            _.includes(getUserInternalPermissions,'view_workspaces')?
            (
              <AsideMenuItemWithSub
                to='#CustomersWorkspaces'
                title='Customers'
                // icon='/media/icons/duotune/communication/com015.svg'
                icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/><rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/><path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/><rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/></svg>'
                fontIcon='bi-person'
              >
                {
                  _.includes(getUserInternalPermissions,'view_organizations')?
                  (
                    <AsideMenuItem to='/internal/organizations' title='Organizations'
                    // icon='/media/icons/duotune/finance/fin001.svg'
                    icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"/><path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"/></svg>'
                    hasBullet={false} />
                  ):('')
                }
                {
                  _.includes(getUserExternalPermissions,'view_organizations')?
                  (
                    <AsideMenuItem to='/organizations' title='Organizations' 
                      // icon='/media/icons/duotune/finance/fin001.svg'
                      icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"/><path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"/></svg>'
                      hasBullet={false}
                    />
                  ):('')
                }
                {
                  _.includes(getUserInternalPermissions,'view_workspaces') ||
                  _.includes(getUserExternalPermissions,'view_workspaces')?
                  (
                    <AsideMenuItem to='/internal/workspaces/list' title='Workspaces' 
                      hasBullet={false}
                      // icon="/media/icons/duotune/abstract/abs027.svg"
                      icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"/><path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"/></svg>'
                    />
                  ):('')
                }
              </AsideMenuItemWithSub>
            ):('')
          }

          {
            _.includes(getUserInternalPermissions,'view_settings')?
            (
              <AsideMenuItemWithSub
                to='#CustomersWorkspaces'
                title='Settings'
                // icon='/media/icons/duotune/coding/cod001.svg'
                icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/><path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/></svg>'
                fontIcon='bi-person'
              >
                <AsideMenuItem to='/internal/users/role' title='Internal Roles' hasBullet={true} />
                <AsideMenuItem to='/internal/users/permission' title='Internal Permissions' hasBullet={true} />
                <AsideMenuItem to='/internal/workspaces/role' title='External Roles' hasBullet={true} />
                <AsideMenuItem to='/internal/workspaces/permission' title='External Permissions' hasBullet={true} />

                {
                  _.includes(getUserInternalRoles,'super_admin')?
                  (
                    <AsideMenuItem to='/internal/utilities' title='Utilities' hasBullet={true} />
                  ):('')
                }

                {/* {
                  _.includes(getUserInternalRoles,'super_admin')?
                  (
                    <AsideMenuItemWithSub
                      to='#Utilities'
                      title='Utilities'
                      // icon='/media/icons/duotune/coding/cod001.svg'
                      icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                      <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                      </svg>'
                      fontIcon='bi-person'
                    >
                      <AsideMenuItem to='/internal/gd-users' title='Delete GD Users' hasBullet={true} />
                    </AsideMenuItemWithSub>
                  ):('')
                } */}
              </AsideMenuItemWithSub>
            ):('')
          }

        </>
      }
    </div>

    </>
  )
}

export default connect(mapStateToProps)(AsideMenuMain);