/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../modules/auth/GetHelpers'

import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import * as Yup from 'yup'
import clsx from 'clsx'
import { FastField, useFormik } from 'formik'

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

interface EditModalProps {
    organizationId: any
    fetchWorkspacesList(): any;
    showHideModal: any;
    showHideModelFunction(): any;
    editorgData: any;
}

const WorkspaceEditModal: FC<EditModalProps> = ({ organizationId, fetchWorkspacesList, showHideModal, showHideModelFunction, editorgData }) => {

    const [getspinner, setspinner] = useState(false)
    const [getOrganization, setOrganization] = useState({})
    const [getOrganizations, setOrganizations] = useState<any[]>([]);
    const [disableOnOrgExist, setDisableOnOrgExist] = useState(false)

    const editOrganizationsModalSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .required('Name is required'),
        organization: Yup.object().shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required('Organization Name is required'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            organization: {}
        },
        validationSchema: editOrganizationsModalSchema,
        onSubmit: async (values) => {

            try {

                let formData: any = {
                    name: values.name,
                    organization: getOrganization,
                }

                const config = {
                    headers: { Authorization: BearerToeken }
                };

                await axios.put(
                    process.env.REACT_APP_API_URL + '/workspace/' + organizationId,
                    formData,
                    config
                )
                    .then(async (res) => {

                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                            setspinner(false)
                            showHideModelFunction()
                            fetchWorkspacesList()
                        } else {
          
                            if(res.data.errors && res.data.errors.length > 0){
                                if(res.data.errors[0] && res.data.errors[0]['msg']){
                                    toast.error(res.data.errors[0]['msg'], {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }
                            } else {
                                if(res.data.message){
                                    toast.error(res.data.message, {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }
                            }

                            setspinner(false)
                            
                        }
                    })
                    .catch((error) => {
        
                        if(error.response){
                          if(error.response.data){
                            if(error.response.data.errors && error.response.data.errors.length > 0){
                                if(error.response.data.errors[0] && error.response.data.errors[0]['msg']){
                                    toast.error(error.response.data.errors[0]['msg'], {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }
                            } else {
                                if(error.response.data.message){
                                    toast.error(error.response.data.message, {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }
                            }
                          } else {
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                          }
                        } else {
                          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                              position: getHelpers.toastPosition(),
                              autoClose: getHelpers.toastAutoClose(),
                              theme: 'colored'
                          });
                        }
                
                        setspinner(false)
                        // fetchorganizationsList();
                    })

            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)

            }
        },
    })

    const getOrganizationsData = () => {

        const config = {
            headers: { Authorization: BearerToeken }
        };
        
        axios.get(process.env.REACT_APP_BASE_API_URL + 'internal/organizations',
        config)
            .then(async (res) => {
                setspinner(false)
                setOrganizations(res.data)
                loadData()
                // optionsOrganizations = res.data
            })
            .catch(error => {
            
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
        
                setspinner(false)
                // fetchorganizationsList();
            })
    }

    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        // console.group();
        // console.log('Value Changed=',JSON.stringify(newValue));
        // userForEdit.organization = newValue
        setOrganization(newValue)
        // console.log('handleChange getOrganization=',getOrganization)
        // console.groupEnd();
    };


    const submitErrorCheck = () => {
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }
    
    
    const loadData = () => {
        if (editorgData) {
            formik.values.name = editorgData.name;
            if(editorgData.organizations && editorgData.organizations.length > 0){
                if(editorgData.organizations[0]){
                    if(editorgData.organizations[0]['name'] && editorgData.organizations[0]['_id']){
                        var orgObj = {
                            label: editorgData.organizations[0]['name'],
                            value: editorgData.organizations[0]['_id'],
                        }
                        // console.log('orgObj=',orgObj,JSON.stringify(orgObj))
                        formik.values.organization = orgObj;
                        setOrganization(orgObj)
                    }
                }
            } else {
                setOrganization({})
            }
            checkOrganizationExistInWorkspace(editorgData)
            // console.log('editorgData=',editorgData)
        }
    }

    const checkOrganizationExistInWorkspace = (data:any) => {
        if(data.organizations && data.organizations.length > 0){
            // console.log('data=',data.organizations)
            setDisableOnOrgExist(true)
        } else {
            setDisableOnOrgExist(false)
        }
    }

    useEffect(() => {
        getOrganizationsData();
        loadData();
    }, [editorgData])

    return (
        <>
            <div>

                <Modal
                    className='modal-sticky custom_modal modal-sticky-lg modal-sticky-bottom-right '
                    id='kt_inbox_compose'
                    role='dialog'
                    data-backdrop='false'
                    aria-hidden='true'
                    tabIndex='-1'
                    show={showHideModal}
                    animation={false}
                >

                    <div className="modal-content bg-white">
                        <div className="modal-header bg-primary">
                            <h2 className="fw-bolder text-white">Edit Workspace</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                style={{ cursor: 'pointer' }}
                                onClick={() => { showHideModelFunction() }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                        </div>
                        <form id="kt_modal_edit_organization_form" className="form" onSubmit={formik.handleSubmit} noValidate >
                            <div className='modal-body scroll-yx pb-2'>
                                <div className='row'>
                                    <div className='col-sm-12 mb-7'>
                                        <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2 text-gray-900">
                                            <span className="fw-bolder fs-7">Organization</span>
                                        </label>
                                        <CreatableSelect
                                            isClearable
                                            onChange={handleChange}
                                            // onInputChange={handleInputChange}
                                            isDisabled={disableOnOrgExist}
                                            options={getOrganizations}
                                            name='organization'
                                            value={getOrganization}
                                        />
                                    </div>
                                    <div className='col-sm-12 mb-7'>
                                        <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2 text-gray-900">
                                            <span className="fw-bolder fs-7">Workspace Name</span>
                                        </label>
                                        <input
                                            placeholder='Name'
                                            {...formik.getFieldProps('name')}
                                            type='text'
                                            name='name'
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                { 'is-invalid': formik.touched.name && formik.errors.name },
                                                {
                                                    'is-valid': formik.touched.name && !formik.errors.name,
                                                }
                                            )}

                                            autoComplete='off'
                                            disabled={formik.isSubmitting}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.name}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block fs-8">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer bg-light flex-row-reverse">
                                <button type="submit" className="btn btn-primary btn-primary" onClick={() => submitErrorCheck()}>
                                    {!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                    {/* Submit */}
                                    {getspinner && (
                                        <span >
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                <button type="reset" className="btn btn-white" data-bs-dismiss="modal" onClick={() => { showHideModelFunction() }}> Cancel </button>
                            </div>
                        </form>
                    </div>

                </Modal>

            </div>

        </>
    )
}

export { WorkspaceEditModal }
