
import { FC, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { useParams, useNavigate } from "react-router-dom";
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';

const API_URL      = process.env.REACT_APP_API_URL
const ApiToken     = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

interface PostsProps {
    showHideWPDeleteModal: any;
    showHidePasswordModal: any;
    workspaceId: any;
}

const WorkspaceDeleteAllModal: FC<PostsProps> = ({
    showHideWPDeleteModal, 
    showHidePasswordModal, 
    workspaceId
}) => {

    let { organisationUsersId }: any = useParams();
    const [getspinner, setspinner] = useState(false)
    const [getDeletedMessages, setDeletedMessages]: any = useState([]);
    
    const [getDashboardsDocs, setDashboardsDocs] : any = useState([]);
    const [getUsersDocs, setUsersDocs] : any = useState([]);
    
    const [getComponentLoading, setComponentLoading]: any = useState(true)
    const [checked, setChecked]: any = useState([]);

    const deleteCountAll = async () => {
        setChecked([])
        const config = {
            headers: { Authorization: BearerToeken }
        };
        axios.delete(process.env.REACT_APP_API_URL + '/internal/ws/' + workspaceId + '/all', config).then(async (res) => {
            if (res.data.status) {
                setDashboardsDocs(res.data.getDashboardsDocs)
                setUsersDocs(res.data.getUsersDocs)
            }
            setTimeout(() => {
                setComponentLoading(false)
            }, 500);
        }).catch((error) => {
            setTimeout(() => {
                setComponentLoading(false)
            }, 500);
            setspinner(false)
            console.log('error=11',error)
            if(error.response.data.message != undefined){
                toast.error(error.response.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
            } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
            }
        });

    }

    const deleteAll = async (e:any) => {
        e.preventDefault()
        setspinner(true)
        var xData : any  = []

        // Swal.fire({
        //   title: getHelpers.ConfirmDeleteMsg(),
        //   icon: getHelpers.ConfirmDeleteIcon().toString(),
        //   showCancelButton: true,
        //   confirmButtonColor: '#3085d6',
        //   cancelButtonColor: '#d33',
        //   confirmButtonText: 'Delete'
        // }).then((result) => {
        //   if (result.isConfirmed) {
            const config = {
              headers: { Authorization: BearerToeken }
            };
            axios.delete(process.env.REACT_APP_API_URL + '/internal/ws/' + workspaceId + '/users', config).then(async (res) => {
                if (res.data.status) {
                    await setDeletedMessages([])
                    xData.push(res.data.deletedData)
                    await setDeletedMessages(xData)
                    ///////////
                    setTimeout(() => {
                        axios.delete(process.env.REACT_APP_API_URL + '/internal/ws/' + workspaceId + '/dashboards', config).then(async (res) => {
                            if (res.data.status) {
                                await setDeletedMessages([])
                                xData.push(res.data.deletedData)
                                await setDeletedMessages(xData)
                    
                                toast.success(res.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
                                    
                                setTimeout(() => {
                                    window.dispatchEvent(new CustomEvent(
                                        'workspaceListRefresh', 
                                        {
                                            detail: {
                                                value:true
                                            }
                                        }
                                    ))
                                    setspinner(false)
                                }, 700);

                            }
                        }).catch((error) => {
                            setspinner(false)
                            console.log('error=',error)
                            if(error.response.data.message != undefined){
                                toast.error(error.response.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
                            }
                        });
                    }, 500);
                    ///////////
                } else {
                    
                    toast.error(res.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
                                            
                    setTimeout(() => {
                        setspinner(false)
                        window.dispatchEvent(new CustomEvent(
                            'organizationListRefresh', 
                            {
                                detail: {
                                    value:true
                                }
                            }
                        ))
                        setspinner(false)
                    }, 1000);
                }
            }).catch((error) => {
                setspinner(false)
                console.log('error=11',error)
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", { position: getHelpers.toastPosition(), autoClose: getHelpers.toastAutoClose(), theme: 'colored' });
                }
            });
        //   }
        // })

    }

    // Add/Remove checked item from list
    const handleCheck = (event:any) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    // Return classes based on whether item is checked
    var isChecked = (item:any) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";

    useEffect(() => {
        // if(workspaceId){
        //     setComponentLoading(true)
        //     deleteCountAll()
        // }
        setDeletedMessages([])
    }, [workspaceId])

    return (
        <div>

            <ToastContainer></ToastContainer>

            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showHidePasswordModal}
                animation={false}
            >

                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        <div className='modal-content'>
                            <div className='modal-header bg-primary'>
                                <h2 className='fw-bolder text-white'>Delete </h2>
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => { showHideWPDeleteModal() }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <form id='kt_modal_add_user_form' className='form'>
                                <div className='modal-body scroll-y pb-2 minHeight200'>
                                    {
                                        getDeletedMessages.length > 0?
                                        (
                                            getDeletedMessages.map((item:any,index:any) => {
                                                return (<>
                                                    <div>{item.message}</div>
                                                </>)
                                            })
                                        ):(
                                            <>
                                                <h2 className='text-center mt-10'>Are you sure want to delete?</h2>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        onClick={(e) => { deleteAll(e) }}
                                    >{!getspinner && (
                                        <span className='indicator-label'>Delete</span>
                                    )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => showHideWPDeleteModal()}
                                        className='btn btn-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
            </Modal>
        </div>
    )
}

export { WorkspaceDeleteAllModal }