/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { KTSVG, toAbsoluteUrl, initialQueryState, useDebounce } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ExternalPermissionAddModel } from './ExternalPermissionAddModel'
import { ExternalPermissionEditModel } from './ExternalPermissionEditModel'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { useTable, useFilters, useGlobalFilter, useSortBy, useAsyncDebounce, usePagination } from 'react-table'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const internalPermissionsListUrl = `${API_URL}/external/permissions-roles`

type Props = {
    className: string
}

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const ExternalPermissionsRolesTables: React.FC<Props> = ({ className }) => {
    // const { updateState } = useQueryRequest()
    const [ExternalPermissionList, setExternalPermissionList] = useState<any[]>([]);
    const [ExternalPermissionListAll, setExternalPermissionListAll] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [showEditModal, setshowEditModal] = useState(false)
    const [roleEditProps, setroleEditProps] = useState<any[]>([]);
    const [getComponentLoading, setComponentLoading] = useState(true)
    const navigate = useNavigate();
  
    const [getStorePermissions, setStorePermissions] = useState<any>({})
    const [getStoreRoles, setStoreRoles] = useState<any>({})

    // const showHideModel = ()=>{
    //   setShowModal(!setShowModal)
    // }
    const showHideModel = () => {
        setShowModal(!setShowModal)
        fetchEnternalPermissionList();
    }
    const showHideEditModal = () => {
        setshowEditModal(!setshowEditModal)
        fetchEnternalPermissionList();
    }
    const setSearchTermFilter: any = () => {
        if (searchTerm.trim().length > 0) {
            let finaldata = ExternalPermissionListAll.filter((item) => {
                if (
                    item.name
                ) {
                    return searchTerm
                        .toLowerCase()
                        .split(" ")
                        .every(
                            (v: any) =>
                                item.name.toString().toLowerCase().includes(v)
                        );
                }
            });
        } else {
            setExternalPermissionList(ExternalPermissionListAll)
        }
    }
    const fetchEnternalPermissionList: any = () => {
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const organizationsListList = axios.get(
            internalPermissionsListUrl,
            config
        ).then(async (res: any) => {
            const { data: InternalDataListData } = res
            await setExternalPermissionListAll(InternalDataListData)
            setComponentLoading(false)
            return await setExternalPermissionList(InternalDataListData)

        }
        ).catch((error) => {
            setComponentLoading(false)
            console.log(error)
        });
        return organizationsListList

    }

    function GlobalFilter(props: any) {
        const { setGlobalFilter } = props
        const count = props.preGlobalFilteredRows.length
        const [value, setValue] = React.useState(props.globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (

            <div className="border-0 pt-6 mb-5">
                <div className="card-title" ></div>
                <div className="card-toolbar" >
                    <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base" >
                        <div className="d-flex align-items-center position-relative my-1" >
                            <KTSVG
                                path='/media/icons/duotune/general/gen021.svg'
                                className='svg-icon-1 position-absolute ms-6'
                            />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='customTableSearch form-control form-control-solid w-250px ps-14'
                                placeholder='Search'
                                value={value || ""}
                                onChange={e => {
                                    setValue(e.target.value);
                                    onChange(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>


        )
    }

    function CustomUsersRoles(data: any) {
        {
            data.map((roles: any) => {
                let v = JSON.parse(JSON.stringify(roles));
                let userName = '';
                let roleName = '';
                if (v.user_id != null) {
                    if (v.user_id.role != null) {
                        roleName = v.user_id.role.name;
                        userName = v.user_id.full_name
                    }
                }
                if (userName && roleName) {
                    return <>
                        <span className="text-capitalize badge m-1 bg-primary" >
                            <span>
                                <span >{userName} <span > ( {roleName} ) </span>
                                </span>
                            </span>
                        </span>
                    </>
                }
            })
        }
    }
    const sendRoleEditProps = (editprop: any) => {
        // console.log('editprop',editprop.original)
        setroleEditProps(editprop.original)
    }
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }: any) {
        const count = preFilteredRows.length
        return (
            <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }
    const deleteInternalPermission = async (id: any) => {
        // console.log(id.original._id, '_id')
        Swal.fire({
            title: getHelpers.ConfirmDeleteMsg(),
            icon: getHelpers.ConfirmDeleteIcon().toString(),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                const config = {
                    headers: { Authorization: BearerToeken }
                };
                axios.delete(
                    process.env.REACT_APP_API_URL + '/permissions/' + id.original._id,
                    config
                )
                    .then(async (res) => {
                        if (res.data.status) {
                            toast.success(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                            fetchEnternalPermissionList();
                        } else {
                            toast.error(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                        // console.log(res.data)
                    })
                    .catch((error) => {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    });
            }
        })
    }
    function Table(props: any) {
        const { columns, data } = props
        const defaultColumn: any = React.useMemo(
            () => ({
                // Default Filter UI
                Filter: DefaultColumnFilter,
            }),
            []
        )
        const setLocalStorage = (pageSize: any) => {
            // console.log('pageSize',pageSize)
            localStorage.setItem('pageSize', pageSize)
        }

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            // state,
            preGlobalFilteredRows,
            setGlobalFilter,
            page,
            // canPreviousPage,
            // canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize, globalFilter },
        }: any = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination,

        )
        let currentPageStartIndex: any = 2;
        let previousIndexPage: any = 0;
        return (
            <div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />

                <div className='table-responsive'>
                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer internalOrganizationPermissionTableList" {...getTableProps()}>
                        {headerGroups.map((headerGroup: any) => (
                            <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th className="" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? (<span className='table-sort-asc'></span>)
                                                    : (<span className='table-sort-desc'></span>)
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {/* <tbody {...getTableBodyProps()}>
                            {page.map((row: any, i: any) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell: any) => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}
                                            </td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody> */}
                        <tbody className='fw-bold' {...getTableBodyProps()}>
                            {
                                page.length > 0?
                                (
                                <>
                                {page.map((row: any, i: any) => {
                                    prepareRow(row)
                                    return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell: any) => {
                                        return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                                        </td>
                                        })}
                                    </tr>
                                    )
                                })}
                                </>
                                ):(
                                <tr>
                                    <td colSpan={4}>
                                    <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                                    </td>
                                </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                <div className="row">
                    <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
                        <div className="dataTables_length" id="kt_customers_table_length">
                            <select name="kt_customers_table_length"
                                className="form-select form-select-sm form-select-solid"
                                value={pageSize}
                                onChange={e => {
                                    { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                                }}
                                style={{ width: '120px', height: '38px' }}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
                        <div id="kt_table_users_paginate">
                            <ul className="pagination">
                                {/* <li className="page-item" onClick={() => gotoPage(0)} >
                            <a className="page-link">First</a>
                            </li> */}
                                <li className="page-item" onClick={() => previousPage()} >
                                    <a className="page-link prev-angle">
                                        <i className="fa fa-chevron-left"></i>
                                    </a>
                                </li>
                                {/* <div>{pageIndex}{pageSize}</div> */}
                                {/* if ($x != 0 && $x % 3 == 0 && $x < count($rows)) */}
                                {pageOptions.map((pageSize: any, rowIndex: any) => {

                                    if (currentPageStartIndex <= rowIndex) {
                                        currentPageStartIndex = currentPageStartIndex + 1
                                        previousIndexPage = rowIndex - 1
                                    }
                                    return (

                                        // rowIndex != 0 && rowIndex % 3 !=0  && rowIndex < pageCount

                                        previousIndexPage <= currentPageStartIndex
                                            ?
                                            <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                                                <a className="page-link">{pageSize + 1} </a>
                                                {/* {pageIndex + 1} */}
                                            </li>
                                            :
                                            null
                                    )

                                })}

                                <li className="page-item" onClick={() => nextPage()} >
                                    <a className="page-link next-angle">
                                        {/* {'Next »'} */}
                                        <i className="fa fa-chevron-right"></i>
                                    </a>
                                </li>




                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    useEffect(() => {

        setTimeout(() => {
          setStorePermissions(store.getState().userExternalData.externalPermissions)
          setStoreRoles(store.getState().userRolesData.roles)
    
        }, 100);

        fetchEnternalPermissionList()
    }, [getStorePermissions,getStoreRoles]);
    const columns: any = React.useMemo(
        () => [
            {
                Header: 'Label',
                accessor: 'label',
                Cell: ({ row }: any) => {
                    const data = row.original

                    return (
                        <>
                            {
                                data ?
                                    (
                                        <>
                                            <span className='fw-bolder'>
                                                {data.label}
                                            </span>
                                        </>
                                    ) : ('')
                            }
                        </>
                    )
                }
            },
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Assign To',
                accessor: 'Assign To',
                disableSortBy: false,
                Cell: ({ row }: any) => {
                    const data = row.original
                    const org_data = data.roles;
                    return (

                        <>{org_data.map((roles: any) =>
                            <span>
                                {
                                    roles._id ?
                                        (
                                            <span>
                                                {
                                                    roles.name ?
                                                        (
                                                            <span className="text-capitalize badge m-1 bg-primary">{roles.label}</span>
                                                        ) : ('')
                                                }
                                            </span>
                                        ) : ('')
                                }
                            </span>

                        )}</>

                    )
                }
            },

            {
                Header: 'Action',
                accessor: 'action',
                disableSortBy: true,
                Cell: ({ row }: any) => {
                    const data = row.original
                    const org_id: any = data._id;
                    return (
                        <>
                            {
                                _.includes(store.getState().userData.permissions,'manage_settings')?
                                (
                                    <div className='customActionButtons'>
                                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                                            <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { setshowEditModal(true); sendRoleEditProps(row) }}><i className="bi bi-pencil-square"></i></a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Delete</Tooltip>}>
                                            <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { deleteInternalPermission(row) }}><i className="bi bi-trash"></i></a>
                                        </OverlayTrigger>
                                    </div>
                                ):('N/A')
                            }
                        </>
                    )
                }

            },

        ],
        []
    )

    const data: any = ExternalPermissionList
    return (
        <div>
            <ToastContainer />

            <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Permissions</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                        </li>
                        <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                        <li className="breadcrumb-item text-muted">Permissions</li>
                    </ul>
                </div>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    {
                        _.includes(store.getState().userData.permissions,'manage_settings')?
                        (
                            <button type="button" className="btn btn-sm fw-bolder btn-primary customIconButton" onClick={() => { setShowModal(true) }}>
                                <i className="bi bi-person-check-fill btnIcon"></i>
                                <span className='btnTxt'>Add Permission</span>
                            </button>
                        ):('')
                    }
                </div>
            </div>

            <div className={`card ${className} overflow-hidden`}>

                {
                    getComponentLoading ?
                        (
                            <ComponentLoading></ComponentLoading>
                        ) : ('')
                }

                <div className="card-body pt-0 bg-white" >
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
                        <div className="table-responsivex" >
                            <Table columns={columns} data={data} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_modal_add_internal_permission'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showModal}
                animation={false}
            >
                <ExternalPermissionAddModel showModal={showHideModel} />
            </Modal>
            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_modal_internal_edit_permission'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showEditModal}
                animation={false}
            >
                <ExternalPermissionEditModel showEditModal={showHideEditModal} editProps={roleEditProps} />
            </Modal>
        </div>
    )
}

// export { ExternalPermissionsRolesTables }
export default connect(mapStateToProps)(ExternalPermissionsRolesTables);