/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'

import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'

import { InternalEditUserModal } from './InternalEditUserModal';
import { InternalUsersSetPasswordModal } from './InternalUsersSetPasswordModal';
import * as getUserInfo from '../../../app/modules/auth/GetUserInfo'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import axios from 'axios'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

// import axios from 'axios'
// const API_URL = process.env.REACT_APP_API_URL
// const ApiToken = localStorage.getItem('api_token')
// const BearerToeken = `Bearer ${ApiToken}`

const API_URL = process.env.REACT_APP_API_URL

const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const internalAdminUrl = `${API_URL}/internal/admins`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

type Props = {
  className: string;
  // internalAdminsListResult:any
}

const InternalUsersTable: React.FC<Props> = ({ className }) => {
  const [internalAdminsListResult, setinternalAdminsListResult] = useState<any[]>([]);

  const [showEditUserModal, setshowEditUserModal]: any = useState(false)

  const [showHidePasswordModal, setshowHidePasswordModal]: any = useState(false)
  const [getComponentLoading, setComponentLoading] = useState(true)



  const [intertUserId, setintertUserId]: any = useState('');
  const [rowprops, setrowprops] = useState<any[]>([]);
  const [changepassword, setchangepassword] = useState<any[]>([]);
  const navigate = useNavigate();



  const internalAdminList = () => {

    const internalAdminsURL = `${API_URL}/internal/admins`

    const config = {
      headers: { Authorization: BearerToeken }
    };
    const internalAdminsList = axios.get(
      internalAdminsURL,
      config
    ).then(async (res) => {
      const { data: internalAdminsresultData } = res
      setComponentLoading(false)
      await setinternalAdminsListResult(internalAdminsresultData)
      // return  res.data
    }
    ).catch((error) => {
      setComponentLoading(false)
      console.log(error)
    });
    return internalAdminsList

  }


  const showHideEditUserModal: any = () => {
    setshowEditUserModal(!setshowEditUserModal)
  }
  const rowPropData: any = (rowData: any) => {
    setrowprops(rowData);
  }

  const showHideSetPasswordModal: any = () => {
    setshowHidePasswordModal(!setshowHidePasswordModal)
  }



  // const internalAdminData = internalAdminList() ;

  // console.log("adminData=",internalAdminData);
  useEffect(() => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {
            // console.clear()
            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {
            // console.clear()
            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        navigate('/error/404')
      });
    internalAdminList();

    window.addEventListener('internalUsersListRefresh', (event: any) => {
      if (event['detail'].value) {
        internalAdminList()
      }
    })

  }, []);

  const deleteInternalUser = async (id: any) => {
    // console.log(id.original._id, '_id')
    Swal.fire({
      title: getHelpers.ConfirmDeleteMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/users/' + id,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
              internalAdminList();
            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
            // console.log(res.data)
          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          });
      }
    })
  }


  function InternalUserSearchInput(props: any) {
    const { setGlobalFilter } = props
    const count = props.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(props.globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)




    return (
      <div className='card-header border-0 pt-5'>
        <div className="card-title"></div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base"  >

            <div className="d-flex align-items-center position-relative my-1" >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={value || ""}
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />
            </div>

          </div>

        </div>
      </div>



    )
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length

    return (
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='customTableSearch form-control form-control-solid w-250px ps-14'
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  const setLocalStorage = (pageSize: any) => {
    // console.log('pageSize',pageSize)
    localStorage.setItem('pageSize', pageSize)
  }

  function UsersTable(props: any) {
    const { columns, data } = props

    const defaultColumn: any = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,

    )

    // console.log("rows data", rows);
    let currentPageStartIndex: any = 2;
    let previousIndexPage: any = 0;

    return (
      <div>
        <InternalUserSearchInput
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className='card-body pt-0 bg-white'>
          <div className='table-responsive'>
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer internalUsersTableList" {...getTableProps()}>
              <thead>

                {headerGroups.map((headerGroup: any) => (
                  <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th className="" {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}

                        {column.isSorted
                          ? column.isSortedDesc
                            ? (<span className='table-sort-asc'></span>)
                            : (<span className='table-sort-desc'></span>)
                          : ''}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {/* <tbody {...getTableBodyProps()}>
                {page.map((row: any, i: any) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>

                      {row.cells.map((cell: any) => {
                        return <td  {...cell.getCellProps()}>{cell.render('Cell')}

                        </td>
                      })}
                    </tr>
                  )
                })}
              </tbody> */}
              <tbody className='fw-bold' {...getTableBodyProps()}>
                {
                  page.length > 0 ?
                    (
                      <>
                        {page.map((row: any, i: any) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell: any) => {
                                return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                                </td>
                              })}
                            </tr>
                          )
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                        </td>
                      </tr>
                    )
                }
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
              <div className="dataTables_length" id="kt_customers_table_length">
                <select name="kt_customers_table_length"
                  className="form-select form-select-sm form-select-solid"
                  value={pageSize}
                  onChange={e => {
                    { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                  }}
                  style={{ width: '120px', height: '38px' }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination">
                  <li className="page-item" onClick={() => previousPage()} >
                    <a className="page-link prev-angle">
                      <i className="fa fa-chevron-left"></i>
                    </a>
                  </li>
                  {pageOptions.map((pageSize: any, rowIndex: any) => {
                    if (currentPageStartIndex <= rowIndex) {
                      currentPageStartIndex = currentPageStartIndex + 1
                      previousIndexPage = rowIndex - 1
                    }
                    return (
                      // rowIndex != 0 && rowIndex % 3 !=0  && rowIndex < pageCount
                      previousIndexPage <= currentPageStartIndex
                        ?
                        <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                          <a className="page-link">{pageSize + 1} </a>

                        </li>
                        :
                        null
                    )
                  })}
                  <li className="page-item" onClick={() => nextPage()} >
                    <a className="page-link next-angle">
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>


      </div>
    )
  }
  const chartFunc = (value: any) => {
    console.log('value=', value.charAt(0))
    let chatAt_value = value.charAt(0);
    return chatAt_value;
  }

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'full_name',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              <div className='d-flex align-items-center'>
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3" data-bs-toggle="tooltip" data-kt-initialized="1">

                  {/* {console.log('data=',data)} */}

                  <div key={data._id} >
                    {
                      data.avatar ?
                        (
                          data.avatar[0] ?
                            (
                              <span className={"symbol-label symbol symbol-circle symbol-50px overflow-hidden me-3"}>
                                {
                                  data.full_name ?
                                    (
                                      <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + chartFunc(data.full_name) + " img-alt"} src={getUserInfo.domainUrl() + 'avatars/' + data.avatar[0]['file']['filename']} alt={chartFunc(data.full_name)} />
                                    ) : (
                                      <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + chartFunc(data.email)} src={getUserInfo.domainUrl() + 'avatars/' + data.avatar[0]['file']['filename']} alt={chartFunc(data.email)} />
                                    )
                                }
                              </span>
                            ) : (
                              <>
                                {
                                  data.full_name ?
                                    (
                                      <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.full_name.charAt(0)}>
                                        {data.full_name.charAt(0)}
                                      </span>
                                    ) : (
                                      <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.email.charAt(0)}>
                                        {data.email.charAt(0)}
                                      </span>
                                    )
                                }
                              </>
                            )
                        ) : (
                          <>
                            {
                              data.full_name ?
                                (
                                  <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.full_name.charAt(0)}>
                                    {data.full_name.charAt(0)}
                                  </span>
                                ) : (
                                  <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.email.charAt(0)}>
                                    {data.email.charAt(0)}
                                  </span>
                                )
                            }
                          </>
                        )
                    }
                  </div>

                </div>
                <div className='d-flex flex-column'>
                  <span className="text-capitalize fw-bolder">
                    {data.full_name ? (data.full_name) : ('')}
                  </span>
                  <div>
                    {data.email ? (data.email) : ('')}
                  </div>
                </div>
              </div>
            </>
          )
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {data.status == "active"

                ?
                <span className="text-capitalize badge bg-success color_1" >{data.status}</span>
                :
                <span className="text-capitalize badge bg-danger color_2" >{data.status}</span>
              }
            </>
          )
        }
      },

      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              <span className='text-capitalize badge bg-info m-1' >{data.role ? data.role.label : 'N/A'}</span>
            </>
          )
        }
      },

      {
        Header: 'Actions',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                _.includes(store.getState().userData.permissions, 'manage_admins') ?
                  (
                    <div className='customActionButtons'>
                      <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                        <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { setshowEditUserModal(true); setintertUserId(data._id); rowPropData(data) }}>
                          {/* <i className="bi bi-pencil-square"></i> */}
                          <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                            <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                            <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                          </svg>
                          </span>
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Set Password</Tooltip>}>
                        <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { setshowHidePasswordModal(true); setintertUserId(data._id) }}>
                          {/* <i className="bi bi-lock"></i> */}
                          <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor" />
                            <path d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z" fill="currentColor" />
                          </svg>
                          </span>
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Delete</Tooltip>}>
                        <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { deleteInternalUser(data._id) }}>
                          {/* <i className="bi bi-trash"></i> */}
                          <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                          </svg>
                          </span>
                        </a>
                      </OverlayTrigger>
                    </div>
                  ) : ('N/A')
              }
            </>
          )
        }
      },
    ],
    []
  )


  const data: any = internalAdminsListResult
  return (
    <div className={`card ${className} overflow-hidden`}>

      {/* <ToastContainer /> */}

      {
        getComponentLoading ?
          (
            <ComponentLoading></ComponentLoading>
          ) : ('')
      }

      <UsersTable data={data} columns={columns} />

      {intertUserId &&
        <InternalEditUserModal showEditUserModal={showEditUserModal} showHideEditUserModal={showHideEditUserModal} internalUserId={intertUserId} internalAdminList={internalAdminList} rowprops={rowprops} />
      }
      {intertUserId &&
        <InternalUsersSetPasswordModal showHideSetPasswordModal={showHideSetPasswordModal} showHidePasswordModal={showHidePasswordModal} internalUserId={intertUserId} internalAdminList={internalAdminList} />
      }
    </div>
  )
}

// export { InternalUsersTable }
export default connect(mapStateToProps)(InternalUsersTable);