import React, { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { GoodDataDashboardEdit } from './GoodDataDashboardEdit'
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import * as getUserInfo from '../../modules/auth/GetUserInfo'
// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../redux/actions/externalPermissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const GoodDataPageEditWrapper: FC = () => {
  let params = useParams();
  const navigate = useNavigate()

  const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
  const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
  const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);

  const dashboardID = params.dashboardId;
  var workspaceId = params.workspaceId;
  const ApiToken = localStorage.getItem('api_token')
  const BearerToeken = `Bearer ${ApiToken}`
  const [getDashboards, setdashboards] = useState<any[]>([]);

  const dispatch = useDispatch();

  const fetchVerifyAccess = async () => {
    const API_URL = process.env.REACT_APP_API_URL
    const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
    const ApiToken = localStorage.getItem('api_token')
    const BearerToken = `Bearer ${ApiToken}`
    const config = {
      headers: { Authorization: BearerToken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.internalPermissions) {
            if (res.data.internalPermissions.permissions) {
              dispatch(ActionExternalPermissions.externalUserPermissions(res.data.externalPermissions.permissions))
              dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
              setUserInternalPermissions(res.data.internalPermissions.permissions)
              setUserExternalPermissions(res.data.externalPermissions.permissions)
              // setKey(getKey+1)
            }
          }
          if (res.data.internalRoles) {
            if (res.data.internalRoles.roles) {
              dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
              setUserInternalRoles(res.data.internalRoles.roles)
              // setKey(getKey+1)
            }
          }
        }
      })
      .catch((error) => { });
  }

  useEffect(() => {
    fetchVerifyAccess()
    const getDashboardsData = async () => {
      const config = {
        headers: { Authorization: BearerToeken }
      };
      await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/dashboard/' + dashboardID,
        config
      )
        .then(async (res) => {
          setdashboards(res.data.name)
          if (res.data.status != 'active') {
            navigate('/')
          }
        })
        .catch((error) => {
          console.log(error)
        });
    };
    getDashboardsData()
  }, []);
  return (
    <>


      {
        getUserInfo.userType() == 'internal' ?
          <>
            {
              _.includes(store.getState().userData.permissions, 'manage_organizations_dashboards') ||
                _.includes(store.getState().userExternalData.externalPermissions, 'manage_organizations_dashboards') ?
                (
                  <>
                    {/* <PageTitle breadcrumbs={[]}>{getDashboards}</PageTitle> */}
                    <GoodDataDashboardEdit />
                  </>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
                )
            }
          </>
          :
          <GoodDataDashboardEdit />
      }

    </>
  )
}

// export default GoodDataPageEditWrapper
export default connect(mapStateToProps)(GoodDataPageEditWrapper);