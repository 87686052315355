/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
// import { KTSVG, toAbsoluteUrl, initialQueryState, useDebounce } from '../../../_metronic/helpers'
// import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import axios from 'axios'
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import * as getHelpers from '../../modules/auth/GetHelpers'
// import { useQueryRequest } from '../../modules/apps/user-management/users-list/core/QueryRequestProvider' // src/app/modules/apps/user-management/users-list/core/QueryRequestProvider
import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import { ComponentLoading } from '../../modules/ComponentLoading'
import { WorkspaceEditModal } from './WorkspaceEditModal';
import { GdCheckModel } from './GdCheckModel';
import { RepairChartPaletteModel } from './RepairChartPaletteModel';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import {CopyToClipboard} from 'react-copy-to-clipboard'

import { WorkspaceDeleteAllModal } from '../customer-organizations/WorkspaceDeleteAllModal'

import * as _ from "lodash";
// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

let defaultPageIndex = 0
let defaultFilterValue = ''

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const WorkspacesListUrl = `${API_URL}/internal/customers`
const WorkspacesStatusGDCheck = `${API_URL}/workspace/gd/status/customers`

type Props = {
  className: string
}
let rowsData: any = [];
const WorkspacesList: React.FC<Props> = ({ className }) => {
  // const { updateState } = useQueryRequest()
  const [WorkspacesList, setWorkspacesList] = useState<any[]>([]);
  const [WorkspacesListAll, setWorkspacesListAll] = useState<any[]>([]);
  const [showHideModal, setshowHideModal] = useState(false)

  const [getTablePageIndex, setTablePageIndex] = useState<any>(0)
  const [getTableFilter, setCustomFilter] = useState<any>('')

  const [hideShowGdStatusModel, sethideShowGdStatusModel] = useState(false)
  const [hideShowRepairChartPaletteModel, sethideShowRepairChartPaletteModel] = useState(false)
  const [editorgData, seteditorgData] = useState<any[]>([]);
  const [workspaceId, setworkspaceId]: any = useState('')
  const [getComponentLoading, setComponentLoading] = useState(true)
  
  // const [copied, setCopied] = useState(false)

  const setCopied = () => {
    toast.success('GD link copied', {
      position: getHelpers.toastPosition(),
      autoClose: getHelpers.toastAutoClose(),
      theme: 'colored'
    });
  }
  
  // const [getOrganizationId, setWorkspaceId]: any = useState('');
  const [showHidePasswordModal, setshowHideWPDeleteModal]: any = useState(false)
  const showHideWPDeleteModal: any = () => {
    setshowHideWPDeleteModal(!setshowHideWPDeleteModal)
  }

  const navigate = useNavigate();
  const showHideModelFunction: any = () => {
    setshowHideModal(!setshowHideModal)
  }
  const hideshowGDStatusModelFunc = () => {
    sethideShowGdStatusModel(!hideShowGdStatusModel)
    // if(hideShowGdStatusModel)
    fetchWorkspacesList();
  }
  const hideshowRepairChartPaletteModelFunc = () => {
    sethideShowRepairChartPaletteModel(!hideShowRepairChartPaletteModel)
    // if(hideShowGdStatusModel)
    fetchWorkspacesList();
  }
  const editOrgModal: any = (data: any) => {
    seteditorgData(data);
  }

  const [isCheckAll, setIsCheckAll]: any = useState(false);
  const [isCheck, setIsCheck]: any = useState([]);
  const [listData, setListData]: any = useState([]);


  const previousPageCustom = (index: any, pageCount: any) => {
    // console.log('pageindex=',index)
    var newIndex = index
    if (index != 1) {
      newIndex = index - 1
    }
    defaultPageIndex = newIndex
    // return setTablePageIndex(newIndex)
  }
  const nextPageCustom = (index: any, pageCount: any) => {
    // console.log('pageindex=',index)
    var newIndex = index
    if (pageCount > index) {
      newIndex = index + 1
    }
    defaultPageIndex = newIndex
    // return setTablePageIndex(newIndex)
  }
  const gotoPageCustom = (index: any, pageCount: any, globalFilter: any) => {
    // console.log('globalFilter=',globalFilter)
    // setTablePageIndex(index)
    defaultPageIndex = index
  }
  const customFilter = (data: any) => {
    console.log('data===', data)
    setCustomFilter(data)
    return data
  }

  // const navigate = useNavigate();

  let inputArray: any = [];
  const fetchWorkspacesList: any = () => {

    let objSelect: any;
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const WorkspacesListList = axios.get(
      WorkspacesListUrl,
      config
    ).then(async (res: any) => {
      const { data: WorkspacesListData } = res
      WorkspacesListData.forEach((element: any) => {
        element[`checked`] = false;
        objSelect = { 'id': element._id, 'name': element._id }
        inputArray.push(objSelect);
      });
      setListData(inputArray)
      rowsData = WorkspacesListData;
      await setWorkspacesListAll(WorkspacesListData)
      await setWorkspacesList(WorkspacesListData)
      setComponentLoading(false)
      return [WorkspacesListAll, WorkspacesList]
    }
    ).catch((error) => {
      setComponentLoading(false)
      console.log(error)
    });
    return WorkspacesListList

  }


  const deleteWorkspace = async (workspaceId: any) => {
    // setTablePageIndex(defaultPageIndex)
    Swal.fire({
      title: getHelpers.ConfirmDeleteMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/internal/workspace/' + workspaceId,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });

              // navigate('/internal/Workspaces/list');
              fetchWorkspacesList();

            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }

          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          });
      }
    })
  }



  function GlobalFilter(props: any) {
    const { setGlobalFilter } = props
    const count = props.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(props.globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)

    // console.log('props=',props)
    // setCustomFilter(props.globalFilter)

    return (
      <div className="border-0 pt-6 mb-5">
        <div className="card-title" ></div>
        <div className="card-toolbar" >
          <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base" >


            {
              _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
              (
                <Dropdown className='customDotsMenu me-3'>
                  <Dropdown.Toggle className='btn btn-light btn-active-light-primary btn-sm fw-bolder btn-icon' variant="secondary">
                    <i className="bi bi-gear-fill"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <a className='dropdown-item py-3 cursor-pointer' onClick={() => sethideShowGdStatusModel(true)}>
                      <i className="bi bi-info-circle me-2"></i> 
                      Check GD Status
                    </a>
                    <a className='dropdown-item py-3 cursor-pointer' onClick={() => sethideShowRepairChartPaletteModel(true)}>
                      <i className="bi bi-palette-fill me-2"></i> 
                      Repair Chart Palette
                    </a>
                  </Dropdown.Menu>
                </Dropdown>
              ):('')
            }


            {/* {
              _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
                (
                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Check GD Status</Tooltip>}>
                    <div className='d-flex justify-content-center align-items-center' style={{ 'marginRight': '13px' }} onClick={() => sethideShowGdStatusModel(true)}>
                      <i className="bi bi-info-circle fa-2x" style={{ cursor: 'pointer' }}></i>
                    </div>
                  </OverlayTrigger>
                )
                : null} */}

            <div className="d-flex align-items-center position-relative my-1" >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={value || ""}
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                  // customFilter(e.target.value);
                }}
              />
            </div>
          </div>

        </div>
      </div>
    )
  }

  // function DefaultColumnFilter({
  //   column: { filterValue, preFilteredRows, setFilter },
  // }: any) {
  //   const count = preFilteredRows.length
  //   console.log('filterValue====',filterValue)
  //   return (
  //     <input
  //       type='text'
  //       data-kt-user-table-filter='search'
  //       className='customTableSearch form-control form-control-solid w-250px ps-14'
  //       value={filterValue || ''}
  //       onChange={e => {
  //         setFilter(e.target.value || undefined)
  //       }}
  //       placeholder={`Search ${count} records...`}
  //     />
  //   )
  // }


  function Table(props: any) {
    const { columns, data } = props

    const defaultColumn: any = React.useMemo(
      () => ({
        // Default Filter UI
        // Filter: DefaultColumnFilter,
      }),
      []
    )

    const setLocalStorage = (pageSize: any) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem('pageSize', pageSize)
    }

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,

        autoResetPage: true,
        autoResetFilters: true,

        // defaultColumn,
        initialState: {
          pageIndex: getTablePageIndex,
          pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
    )

    let currentPageStartIndex: any = 2;
    let previousIndexPage: any = 0;

    // setCustomFilter(globalFilter)
    console.log('globalFilter=', globalFilter, setGlobalFilter, preGlobalFilteredRows)

    return (
      <div>

        {
          getComponentLoading ?
            (
              <ComponentLoading></ComponentLoading>
            ) : ('')
        }

        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        <div className='table-responsive'>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer organisationTableList" {...getTableProps()}>
            <thead>

              {headerGroups.map((headerGroup: any) => (
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th className={" "} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {/* {console.log('column=',column)} */}

                      {column.isSorted
                        ? column.isSortedDesc
                          ? (<span className='table-sort-asc'></span>)
                          : (<span className='table-sort-desc'></span>)
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* <tbody {...getTableBodyProps()}>
              {page.map((row: any, i: any) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return <td  {...cell.getCellProps()}>{cell.render('Cell')}

                      </td>
                    })}
                  </tr>
                )
              })}
            </tbody> */}
            <tbody className='fw-bold' {...getTableBodyProps()}>
              {
                page.length > 0 ?
                  (
                    <>
                      {page.map((row: any, i: any) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                              return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                              </td>
                            })}
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                      </td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="dataTables_length" id="kt_customers_table_length">
              <select name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                value={pageSize}
                onChange={e => {
                  { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                }}
                style={{ width: '120px', height: '38px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div id="kt_table_users_paginate">
              <ul className="pagination">
                {/* <li className="page-item" onClick={() => gotoPage(0)} >
                  <a className="page-link">First</a>
                </li> */}
                <li className="page-item" onClick={() => {
                  previousPage();
                  previousPageCustom(pageIndex, pageCount);
                }} >
                  <a className="page-link prev-angle">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </li>
                {/* <div>{pageIndex}{pageSize}</div> */}
                {/* if ($x != 0 && $x % 3 == 0 && $x < count($rows)) */}
                {pageOptions.map((pageSize: any, rowIndex: any) => {
                  if (currentPageStartIndex <= rowIndex) {
                    currentPageStartIndex = currentPageStartIndex + 1
                    previousIndexPage = rowIndex - 1
                  }
                  return (
                    previousIndexPage <= currentPageStartIndex
                      ?
                      <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => {
                        gotoPage(rowIndex);
                        gotoPageCustom(rowIndex, pageCount, globalFilter)
                      }} >
                        <a className="page-link">{pageSize + 1} </a>
                        {/* {pageIndex + 1} */}
                      </li>
                      :
                      null
                  )

                })}

                <li className="page-item" onClick={() => {
                  nextPage();
                  nextPageCustom(pageIndex, pageCount)
                }} >
                  <a className="page-link next-angle">
                    {/* {'Next »'} */}
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }

  useEffect(() => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {

            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {

            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        console.clear()
        navigate('/error/404')
      });

    fetchWorkspacesList()

    window.addEventListener('workspaceListRefresh', (event: any) => {
      if (event['detail'].value) {
        setshowHideWPDeleteModal(false)
        fetchWorkspacesList()
      }
    })

  }, [])



  async function handleSelectAll(e: any) {
    let trueFalse: any = !e.target.checked;
    rowsData.forEach((element: any) => {
      element[`checked`] = !element.checked;
    });

    await setListData(rowsData);
    await setWorkspacesListAll(rowsData)
    await setWorkspacesList(rowsData)

    await setIsCheckAll(trueFalse);
  };

  const handleClick = (e: any) => {
  };
  const chartFunc = (value: any) => {
    // console.log('value=', value.charAt(0))
    let chatAt_value = value.charAt(0);
    return chatAt_value;
  }
  const columns: any = React.useMemo(
    () => [

      // {
      //   Header: (<div className="form-check form-check-sm form-check-custom form-check-solid ">
      //     <input className="form-check-input" type="checkbox" value=""
      //       name="selectAll"
      //       id="selectAll"
      //       onClick={handleSelectAll}
      //       checked={isCheckAll}
      //     /></div>
      //   ),
      //   accessor: '_id',
      //   disableSortBy: true,
      //   Cell: ({ row }: any) => {
      //     const data = row.original
      //     // console.log("result=",data);
      //     return (
      //       <div className="form-check form-check-sm form-check-custom form-check-solid ">
      //         <input key={data._id}
      //           name={data._id}
      //           id={data._id}
      //           onChange={(e: any) => { handleClick(data) }}
      //           checked={data.checked}
      //           className="form-check-input" type="checkbox"

      //         //  value={data._id}
      //         />
      //       </div>
      //     )
      //   }

      // },

      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              <div className='d-flex align-items-center'>
                {/* <div className="symbol symbol-35px symbol-circle me-3" data-bs-toggle="tooltip" data-kt-initialized="1">
                  <span className={"symbol-label text-inverse-warning fw-bolder bg-" + data.name.charAt(0)}>
                    {data.name.charAt(0).toUpperCase()}
                  </span>
                </div> */}
                {
                  data.avatar_id ?
                    (
                      data.avatar_id.file ?
                        (
                          <>
                            <span className={"symbol-label symbol symbol-circle symbol-35px overflow-hidden me-3"}>
                              <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(data.name) + " img-alt-35"} src={getUserInfo.domainUrl() + data.avatar_id.file_path} alt={chartFunc(data.name)} />
                            </span>
                            {/* <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                              <img src={getUserInfo.domainUrl() + data.avatar_id.file_path} alt='Metornic' />
                            </div> */}
                          </>
                        ) : (
                          data.name ?
                            (
                              <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                                <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                                  {data.name.charAt(0)}
                                </span>
                              </div>
                            ) : ('N/A')
                        )
                    ) : data.name ? (
                      <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                        <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                          {data.name.charAt(0)}
                        </span>
                      </div>
                    ) : ('N/A')
                }
                <div className='d-flex flex-column'>
                  <span key={data._id} className="text-capitalize fw-bolder " >{data.name}</span>
                  {
                    data.gd_status == true
                      ?
                      // <div className='badge bg-danger color_2'>Synched with GD</div>
                      ('')
                      :
                      <>
                        {
                          data.gd_status == false
                            ?
                            <div className='badge bg-danger color_2'>Not synched with GD</div>
                            :
                            <></>
                        }
                      </>

                  }
                </div>
              </div>
            </>
          )
        }
      },

      {
        Header: 'Organization',
        accessor: 'organizations',
        Cell: ({ row }: any) => {
          const data = row.original
          const organizations = data.organizations && data.organizations.length > 0 ? data.organizations : [];
          return (

            <>
              {
                organizations.length > 0 ?
                  (
                    organizations.map((org: any) =>
                      <span>
                        {
                          org._id ?
                            (
                              <span>
                                {
                                  org.name ?
                                    (
                                      <span className="text-capitalize badge m-1 bg-primary">{org.name}</span>
                                    ) : ('')
                                }
                              </span>
                            ) : ('')
                        }
                      </span>

                    )
                  ) : ('N/A')
              }
            </>

          )
        }
      },

      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                data.status == 'active' ?
                  (
                    <span key={data._id} className="text-capitalize badge-success badge " >{data.status}</span>
                  ) : (
                    <span key={data._id} className="text-capitalize badge-danger badge " >{data.status}</span>
                  )
              }
            </>
          )
        }

      },
      {
        Header: 'Users',
        accessor: 'users',
        Cell: ({ row }: any) => {
          const data = row.original
          const org_id: any = data._id;
          // const org_data = data.external_users_roles;
          const showTotals = 5
          const org_data = getHelpers.AvatarsDataArray(showTotals, data.user_id)['users']
          const totalUsers = getHelpers.AvatarsDataArray(showTotals, data.user_id)['total']
          // console.log('org_data=',getHelpers.AvatarsDataArray(showTotals,data.user_id))
          return (
            <>

              <div className="symbol-group symbol-hover flex-nowrap">
                {
                  org_data.length > 0 ?
                    (
                      <>

                        {org_data.map((roles: any) =>
                          roles ?
                            (
                              <>
                                {
                                  roles.role ?
                                    (
                                      <>
                                        <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={roles._id}>
                                          {
                                            roles.avatar_id ?
                                              (
                                                <>
                                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.first_name ? (roles.first_name + ' ' + roles.last_name) : (roles.email)}</Tooltip>}>
                                                    {
                                                      roles.avatar_id.file ?
                                                        (
                                                          <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(roles.first_name ? roles.first_name : roles.email) + " img-alt-35"} src={getUserInfo.domainUrl() + 'avatars/' + roles.avatar_id.file.filename} alt={chartFunc(roles.first_name ? roles.first_name : roles.email)} />
                                                          // <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/' + roles.avatar_id.file.filename} />
                                                        ) : (
                                                          <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(roles.first_name ? roles.first_name : roles.email) + " img-alt-35"} src={getUserInfo.domainUrl() + 'avatars/default.png'} alt={chartFunc(roles.first_name ? roles.first_name : roles.email)} />
                                                          // <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/default.png'} />
                                                        )
                                                    }
                                                  </OverlayTrigger>
                                                </>
                                              ) : roles.first_name && roles.last_name ? (
                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.first_name ? (roles.first_name + ' ' + roles.last_name) : (roles.email)}</Tooltip>}>
                                                  <span className={"symbol-label text-inverse-warning fw-bolder bg-" + roles.first_name.charAt(0)}>
                                                    {roles.first_name.charAt(0)}
                                                  </span>
                                                </OverlayTrigger>
                                              ) : (
                                                roles.email?
                                                (
                                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.first_name ? (roles.first_name + ' ' + roles.last_name) : (roles.email)}</Tooltip>}>
                                                    <span className={"symbol-label text-inverse-warning fw-bolder bg-" + roles.email.charAt(0)}>
                                                      {roles.email.charAt(0)}
                                                    </span>
                                                  </OverlayTrigger>
                                                ):('N/A')
                                              )
                                            // (
                                            //   <>
                                            //     <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.full_name ? (roles.full_name) : (roles.email)}</Tooltip>}>
                                            //       {
                                            //         roles.avatar_id.file ?
                                            //           (
                                            //             <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/' + roles.avatar_id.file.filename} />
                                            //           ) : (
                                            //             <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/default.png'} />
                                            //           )
                                            //       }
                                            //     </OverlayTrigger>
                                            //   </>
                                            // ) : roles.full_name ? (
                                            //   <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.full_name ? (roles.full_name) : (roles.email)}</Tooltip>}>
                                            //     <span className={"symbol-label text-inverse-warning fw-bolder bg-" + roles.full_name.charAt(0)}>
                                            //       {roles.full_name.charAt(0)}
                                            //     </span>
                                            //   </OverlayTrigger>
                                            // ) : (
                                            //   <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{roles.full_name ? (roles.full_name) : (roles.email)}</Tooltip>}>
                                            //     <span className={"symbol-label text-inverse-warning fw-bolder bg-" + roles.email.charAt(0)}>
                                            //       {roles.email.charAt(0)}
                                            //     </span>
                                            //   </OverlayTrigger>
                                            // )
                                          }
                                        </div>
                                      </>
                                    ) : ('N/A')
                                }
                              </>
                            ) : ('N/A')
                        )}

                        {
                          totalUsers > 0 ?
                            (
                              <Link to={"/internal/workspace/" + org_id + "/users"} className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                                <span className="symbol-label bg-primary text-inverse-dark fs-8 fw-bold" data-bs-toggle="tooltip" data-bs-trigger="hover" data-kt-initialized="1">+{totalUsers}</span>
                              </Link>
                            ) : ('')
                        }
                      </>
                    ) : ('N/A')
                }
              </div>

            </>

          )
        }
      },

      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const data = row.original
          const org_id: any = data._id;
          return (
            <>
              {
                // _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
                //   (
                <div className='customActionButtons'>
                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Basic Details</Tooltip>}>
                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/workspace/" + org_id}>
                      {/* <i className="bi bi-eye"></i> */}
                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor" />
                        <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor" />
                      </svg>
                      </span>
                    </Link>
                  </OverlayTrigger>
                  {
                    _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
                      (
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                          <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/workspace/" + org_id + "/edit"}>
                            {/* <i className="bi bi-pencil-square"></i> */}
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                              <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                              <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                            </svg>
                            </span>
                          </Link>
                        </OverlayTrigger>
                      ) : ('')
                  }
                  {
                    _.includes(store.getState().userData.permissions, 'view_users') ?
                      (
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Users</Tooltip>}>
                          <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/workspace/" + org_id + "/users"}>
                            {/* <i className="bi bi-people"></i> */}
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor" />
                              <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor" />
                              <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor" />
                              <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor" />
                            </svg>
                            </span>
                          </Link>
                        </OverlayTrigger>
                      ) : ('')
                  }
                  {
                    _.includes(store.getState().userData.permissions, 'manage_organizations_dashboards') ?
                      (
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Dashboards</Tooltip>}>
                          <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/internal/workspace/" + org_id + "/dashboards"}>
                            {/* <i className="bi bi-stack"></i> */}
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M17 10H11C10.4 10 10 9.6 10 9V8C10 7.4 10.4 7 11 7H17C17.6 7 18 7.4 18 8V9C18 9.6 17.6 10 17 10ZM22 4V3C22 2.4 21.6 2 21 2H11C10.4 2 10 2.4 10 3V4C10 4.6 10.4 5 11 5H21C21.6 5 22 4.6 22 4ZM22 15V14C22 13.4 21.6 13 21 13H11C10.4 13 10 13.4 10 14V15C10 15.6 10.4 16 11 16H21C21.6 16 22 15.6 22 15ZM18 20V19C18 18.4 17.6 18 17 18H11C10.4 18 10 18.4 10 19V20C10 20.6 10.4 21 11 21H17C17.6 21 18 20.6 18 20Z" fill="currentColor" />
                              <path d="M8 5C8 6.7 6.7 8 5 8C3.3 8 2 6.7 2 5C2 3.3 3.3 2 5 2C6.7 2 8 3.3 8 5ZM5 4C4.4 4 4 4.4 4 5C4 5.6 4.4 6 5 6C5.6 6 6 5.6 6 5C6 4.4 5.6 4 5 4ZM8 16C8 17.7 6.7 19 5 19C3.3 19 2 17.7 2 16C2 14.3 3.3 13 5 13C6.7 13 8 14.3 8 16ZM5 15C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17C5.6 17 6 16.6 6 16C6 15.4 5.6 15 5 15Z" fill="currentColor" />
                            </svg>
                            </span>
                          </Link>
                        </OverlayTrigger>
                      ) : ('')
                  }
                  {
                    data.gd_status && _.includes(store.getState().userData.permissions, 'manage_workspaces')
                      ?
                      <>
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Copy GD Link</Tooltip>}>
                            <CopyToClipboard text={`${process.env.REACT_APP_GD_BACKEND_URL}/dashboards/#/workspace/${data.gd_workspace_ids[0]}`} onCopy={() => setCopied()}>
                              <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'>GD</span>
                            </CopyToClipboard>
                            {/* <a href={`${process.env.REACT_APP_GD_BACKEND_URL}/dashboards/#/workspace/${data.gd_workspace_ids[0]}`} target="_blank" style={{ color: 'black', fontWeight: 600 }}>GD</a> */}
                        </OverlayTrigger>
                      </>
                      :
                      <></>
                  }

                  {
                    _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
                      (
                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Delete</Tooltip>}>
                          <>
                            {/* <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { deleteWorkspace(org_id) }}>
                            <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                              <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                              <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                            </svg>
                            </span>
                          </Link> */}
                            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' 
                              // onClick={() => { deleteWorkspace(org_id) }}
                              onClick={() => { setshowHideWPDeleteModal(true); setworkspaceId(data._id) }}
                            >
                              {/* <i className="bi bi-trash"></i> */}
                              <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                              </svg>
                              </span>
                            </span>
                          </>
                        </OverlayTrigger>
                      ) : ('')
                  }
                </div>
                // ) : ('N/A')
              }

              {/* onClick={() => previousPage()} */}

              {/* <div style={{ whiteSpace: 'nowrap' }} className="">
                <a href={"/internal/organization/" + org_id + "/users"} className="menu-link text-gray-900">Manage Users</a>
              </div>

              <div style={{ whiteSpace: 'nowrap' }} className=" ">
                <a href={"/organization/" + org_id + "/dashboards"} className="menu-link text-gray-900">Manage Dashboards</a>
              </div> */}

              {/* <div style={{ whiteSpace: 'nowrap' }} className=" px-3">
                <a href={"/organization/" + org_id + "/datasource"} className="menu-link px-3 text-gray-900">Manage Data Source</a>
              </div> */}
            </>
          )
        }

      },
    ],
    []
  )

  const data: any = WorkspacesList




  // console.log("WorkspacesList=",WorkspacesList);
  return (
    <>
      <ToastContainer></ToastContainer>
      <div className={`card ${className} card-border-radius min-height-400 `}>
        <div className="card-body pt-0 bg-white" >
          <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
            <Table columns={columns} data={data} />
          </div>
        </div>
        <Modal
          className='modal-sticky custom_modal modal-sticky-lg modal-sticky-bottom-right '
          id='kt_inbox_compose'
          role='dialog'
          data-backdrop='false'
          aria-hidden='true'
          tabIndex='-1'
          show={hideShowGdStatusModel}
          animation={false}
        >
          <GdCheckModel hideShowGdStatusModel={hideshowGDStatusModelFunc} />
        </Modal>

        {/* Chart Palette */}
          <Modal
            className='modal-sticky custom_modal modal-sticky-lg modal-sticky-bottom-right '
            id='kt_inbox_compose'
            role='dialog'
            data-backdrop='false'
            aria-hidden='true'
            tabIndex='-1'
            show={hideShowRepairChartPaletteModel}
            animation={false}
          >
            <RepairChartPaletteModel hideShowRepairChartPaletteModel={hideshowRepairChartPaletteModelFunc} />
          </Modal>
        {/* Chart Palette */}

        <WorkspaceEditModal organizationId={workspaceId} fetchWorkspacesList={fetchWorkspacesList} showHideModal={showHideModal} showHideModelFunction={showHideModelFunction} editorgData={editorgData} />

        <WorkspaceDeleteAllModal showHideWPDeleteModal={showHideWPDeleteModal} showHidePasswordModal={showHidePasswordModal} workspaceId={workspaceId} />

      </div>
    </>
  )
}

// export { WorkspacesList }
export default connect(mapStateToProps)(WorkspacesList);