/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import axios from 'axios'
import { useNavigate ,Link} from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const recentCustomersUrl = `${API_URL}/internal/recentcustomers`
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
type Props = {
  className: string
}

const WorkspaceTable: React.FC<Props> = ({ className }) => {

  const [recentCustomers, setrecentCustomers] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(true)
  const navigate = useNavigate();
  useEffect(() => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {
            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {
            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        navigate('/error/404')
      });
    fetchRecentCustomers()

  }, [])
  const fetchRecentCustomers: any = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const recentCustomersList = axios.get(
      recentCustomersUrl,
      config
    )
      .then(async (res: any) => {
        const { data: recentCustomersData } = res
        setComponentLoading(false)
        return await setrecentCustomers(recentCustomersData)
      })
      .catch((error) => {
        console.log(error)
        setComponentLoading(false)
      });
    return recentCustomersList

  }
  return (
    <div className={`card ${className} overflow-hidden`}>

      {
        getComponentLoading ?
          (
            <ComponentLoading></ComponentLoading>
          ) : ('')
      }
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1 heading_f'>Recent Workspaces </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>List of Workspaces</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
        </div>
      </div>
      {
        recentCustomers.length > 0 ?
          (
            <>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <tbody>
                      {recentCustomers.map((workspaceObj) => (
                        <tr key={workspaceObj._id}>
                          <td>
                            <span className='fw-bolder'>
                              {workspaceObj.name}
                            </span>
                          </td>
                          {/* <td width={100}>
                            <span className="badge badge-success">
                              {workspaceObj.status}
                            </span>
                          </td> */}
                          <td width={50}>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {/* <a
                                                                href='#'
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            >
                                                                <i className="fa fa-users text-primary"></i>
                                                            </a> */}
                              <a
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <Link to={'/internal/workspace/' + workspaceObj._id} className='text-gray-600'>
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr064.svg'
                                    className='svg-icon-2'
                                  />
                                </Link>
                              </a>
                            </div>
                            {/* <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className="fa fa-users text-primary"></i>
                              </a>
                            </div> */}
                          </td>
                        </tr>
                      )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: getHelpers.NoDataFound() }}></div>
          )
      }

    </div>
  )
}

export { WorkspaceTable }
