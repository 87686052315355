/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { StatsCard } from '../../pages/overview/StatsCard'
import { WorkspaceTable } from './WorkspaceTable'
import { RecentOrganization } from './RecentOrganizations'
import { RecentDashboards } from './RecentDashboards'

import axios from 'axios'
import { getFactory } from "@gooddata/api-client-bear";

import { connect, useDispatch } from 'react-redux';
import { ActionCreators } from '../../../redux/actions/profile';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { store } from '../../../redux/store/index';

const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions
  }
}

const OverviewPage: FC = () => (

  <>
    <div className='row mt-10'>
      <div className='col-xl-5'>
        <StatsCard
          className='card-xl-stretch mb-xl-8'
          chartColor='primary'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
        {/* <RecentOrganization className='card-xxl-stretch mb-5 mb-xl-8' /> */}
        <RecentDashboards className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
      <div className='col-xl-7'>
        <WorkspaceTable className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>

    </div>
    <div className='row mt-10'>
    </div>
  </>
)

const InternalDashboardOverviewWrapper: FC = () => {

  const intl = useIntl()
  useEffect(() => {
    // ssoEnvironment('8a232875a5fa28d6cd783c08b246068e')
  }, [])
  // const ssoEnvironment = (userId: any) => {
  //   axios.post(process.env.REACT_APP_BASE_API_URL + 'openpgp/sso-environment', {
  //     userId: userId
  //   })
  //     .then((res) => {
  //       getEncryptedClaims(res.data)
  //     })
  // }

  // var ssoLoginData = ''

  // const getEncryptedClaims = (resData: any) => {
  //   axios.post(process.env.REACT_APP_BASE_API_URL + 'openpgp/encrypted-claims', resData.data)
  //     .then((res) => {
  //       localStorage.setItem("encryptedClaims", JSON.stringify(res.data.encryptedClaim))
  //       localStorage.setItem("ssoProvider", JSON.stringify(res.data.userId))
  //       const domain = process.env.REACT_APP_GD_BACKEND_URL;
  //       const sdk = getFactory({ domain });
  //       const encryptedClaims = JSON.parse(res.data.encryptedClaim)
  //       const ssoProvider = res.data.userId;
  //       const targetUrl = "/gdc/account/token";
  //       sdk.user
  //         .loginSso(encryptedClaims, ssoProvider, targetUrl)
  //         .then((response) => {

  //           // console.log('sdk.user.getCurrentProfile()=', sdk.user.getCurrentProfile())
  //           // console.log('response.responseBody=', response.responseBody)

  //           ssoLoginData = response.responseBody
  //         })
  //         .catch(error => {
  //           // console.log('error=');
  //           console.error(error);
  //         });
  //     })
  // }

  return (
    <>
      <OverviewPage />
    </>
  )
}

// export { InternalDashboardOverviewWrapper }
export default connect(mapStateToProps)(InternalDashboardOverviewWrapper);
