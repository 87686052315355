import { FC, useState, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../_metronic/helpers'
import { initialUser, User } from './OrganizationValidationModal'
import clsx from 'clsx'
// import { useListView } from '../../modules/apps/user-management/users-list/core/ListViewProvider'
// import { UsersListLoading } from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
// import { createUser, updateUser } from '../../modules/apps/user-management/users-list/core/_requests'
// import { useQueryResponse } from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import { ToastContainer, toast } from 'react-toastify';
import * as getHelpers from '../../modules/auth/GetHelpers'
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';

import Select from 'react-select'
// import Creatable, { useCreatable } from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
// import CreatableSelect from 'react-select/creatable';

import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const internalRolesPermissionsUrl = `${API_URL}/external/roles-permissions`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
type Props = {
  // isUserLoading: boolean
  // user: User
  modelHideshow(): any
  fetchorganizationsList(): any
}

var myOrganization = {}

const AddWorkspaceModalFormAsideMenu: FC<Props> = ({ modelHideshow, fetchorganizationsList }) => {
  // const { setItemIdForUpdate } = useListView()
  // const { refetch } = useQueryResponse()
  const [activeInactive, setActiveInactive] = useState(true)
  const [getNewOrganization, setNewOrganization] = useState(true)
  const [getOrganization, setOrganization] = useState({})
  const [getSelectedOrganization, setSelectedOrganization] = useState({})
  const [HideShow, setHideShow] = useState(true)
  const [getfname, setfname] = useState('')
  const [getlname, setlname] = useState('')
  const [getRole, setGetRole] = useState('')
  const [isDisabled, setDisabled] = useState(false);
  const [getspinner, setspinner] = useState(false)
  const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);
  const [getOrganizations, setOrganizations] = useState<any[]>([]);

  const [selectedOption, setSelectedOption] = useState<any>(null);
  
  const internalRolePermissionList = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const internalRolesList = axios.get(
      internalRolesPermissionsUrl,
      config
    ).then(async (res) => {
      const { data: rolespermissionsDataResult } = res
      return await setinternalRolesPermissionsListResult(rolespermissionsDataResult)
    }
    ).catch();
    // console.log(error)
    return internalRolesList

  }

  const [userForEdit] = useState<User>({
    // ...user,
    // name: user.name || initialUser.name,
    // organization: user.organization || initialUser.organization,
  })

  const editUserSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(100, 'Maximum 100 characters')
      .required('Workspace name is required'),
    organization: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required('Organization Name is required'),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      // refetch()
    }
    // setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          // await updateUser(values)
        } else {
          const formDataObj : any = {
            name: values.name,
            organization: getSelectedOrganization,
          }
          setspinner(true)
          saveOrganization(formDataObj)
        }
      } catch (ex) {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        console.error(ex)
      } finally {

        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const saveOrganization = (data: any) => {
    axios.post(process.env.REACT_APP_BASE_API_URL + 'workspace/add', data)
      .then(async (res) => {
        setspinner(false)
        if (res.data.status) {
          const GdformData = {
            dashboard_name: res.data.workspacedata.name,
            workspaceId: res.data.workspacedata._id,
            // emails: [res.data.user.email],
            message: res.data.message
          }
          // await GoodDataDashboardSave(GdformData)
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
            getOrganizationsData();
            // fetchorganizationsList();

            window.dispatchEvent(new CustomEvent(
              'workspaceAddExternalUser', {
                detail: {
                  value: true
                }
              }
            ))
            
            modelHideshow();

            // window.location.reload();
        } else {
          
          if(res.data.errors && res.data.errors.length > 0){
              if(res.data.errors[0] && res.data.errors[0]['msg']){
                  toast.error(res.data.errors[0]['msg'], {
                      position: getHelpers.toastPosition(),
                      autoClose: getHelpers.toastAutoClose(),
                      theme: 'colored'
                  });
              } else {
                  toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                      position: getHelpers.toastPosition(),
                      autoClose: getHelpers.toastAutoClose(),
                      theme: 'colored'
                  });
              }
          } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
              });
          }

          setspinner(false)
          
        }
      })
      .catch(error => {
        
        if(error.response){
          if(error.response.data){
            if(error.response.data.errors && error.response.data.errors.length > 0){
                if(error.response.data.errors[0] && error.response.data.errors[0]['msg']){
                    toast.error(error.response.data.errors[0]['msg'], {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
            } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
              });
            }
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
          }
        } else {
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
          });
        }

        setspinner(false)
        // fetchorganizationsList();
      })
  }

  const getOrganizationsData = () => {
    axios.get(process.env.REACT_APP_BASE_API_URL + 'external/organizations')
      .then(async (res) => {
        setspinner(false)
        setOrganizations([res.data[0]])
        // optionsOrganizations = res.data
        var singleOrgData = res.data[0]
        singleOrgData['value'] = res.data[0]['_id']
        singleOrgData['label'] = res.data[0]['name']
        setSelectedOrganization(singleOrgData)
      })
      .catch(error => {
        
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });

        setspinner(false)
        // fetchorganizationsList();
      })
  }

  const notify = () => {
    // console.log("formik", formik.errors)
    // let check_error = Object.keys(formik.errors).length  ;
    if (JSON.stringify(formik.errors) !== '{}') {
      toast.error(Object.values(formik.errors)[0], {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });

    }
  }

  useEffect(() => {
    internalRolePermissionList();
    getOrganizationsData();
  }, []);

  const handleChange = (
    newValue: any,
    actionMeta: ActionMeta<any>
  ) => {
    // console.group();
    // console.log('Value Changed=',newValue);
    // userForEdit.organization = newValue
    setOrganization(newValue)
    // console.log('handleChange getOrganization=',getOrganization)
    // console.groupEnd();
  };

  return (
    <>
      <ToastContainer />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-body scroll-yx pb-2'>
          <div
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className="row">

              <div className='col-sm-6 mb-7'>
                <label className='fw-bolder fs-6 mb-2'>Organization</label>

                {/* <CreatableSelect
                  isClearable
                  onChange={handleChange}
                  // onInputChange={handleInputChange}
                  options={getOrganizations}
                  name='organization'
                /> */}

                <Select
                  className='customSelectDropdown'
                  // isClearable
                  onChange={handleChange}
                  // onInputChange={handleInputChange}
                  options={getOrganizations}
                  name='organization'
                  value={getSelectedOrganization}
                />
                
                {formik.touched.organization && formik.errors.organization && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.organization}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-sm-6 mb-7'>
                <label className='fw-bolder fs-6 mb-2'>Workspace Name</label>
                <input
                  placeholder='Name'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='name'
                  name='name'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer bg-light flex-row-reverse">
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={() => notify()}
          >{!getspinner && (
            <span className='indicator-label'>Save</span>
          )}
            {getspinner && (
              <span >
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='button' // {()=>{showHideModel(cancelModel)}}
            onClick={() => modelHideshow()}
            className='btn btn-white'
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export { AddWorkspaceModalFormAsideMenu }