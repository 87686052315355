import { FC, useState, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { ToastContainer, toast } from 'react-toastify';
import * as getHelpers from '../../modules/auth/GetHelpers'
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
type Props = {
  // isUserLoading: boolean
  // user: User
  modelHideshow(): any
  fetchorganizationsList(): any
}

var myOrganization = {}

const ExternalOrganizationEditModalForm: FC<Props> = ({ modelHideshow, fetchorganizationsList }) => {
  const [activeInactive, setActiveInactive] = useState(true)
  const [getNewOrganization, setNewOrganization] = useState(true)
  const [getIndustry, setIndustry] = useState({})
  const [HideShow, setHideShow] = useState(true)
  const [getRole, setGetRole] = useState('')
  const [isDisabled, setDisabled] = useState(false);
  const [getspinner, setspinner] = useState(false)
  const [getIndustries, setIndustries] = useState<any[]>([]);

  const OrganizationsModalSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(100, 'Maximum 100 characters')
      .required('Organization name is required'),
    address: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(100, 'Maximum 200 characters'),
      // .required('Address is required'),
    industry_id: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required('Industry is required')
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      // refetch()
    }
    // setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: {
        name: '',
        address: '',
        industry_id: ''
    },
    validationSchema: OrganizationsModalSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        // if (isNotEmpty(values.id)) {
        //   await updateUser(values)
        // } else {
          const formDataObj : any = {
            name: values.name,
            address: values.address,
            industry_id: getIndustry,
          }
          setspinner(true)
          saveOrganization(formDataObj)
        // }
      } catch (ex) {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        console.error(ex)
      } finally {

        setSubmitting(true)
        cancel(true)
      }
    },
  })
  const saveOrganization = (data: any) => {
    axios.post(process.env.REACT_APP_BASE_API_URL + 'external/organizations', data)
      .then(async (res) => {
        setspinner(false)
        if (res.data.status) {
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });

          window.dispatchEvent(new CustomEvent(
            'organizationListRefresh', 
            {
                detail: {
                    value:true
                }
            }
          ))
            modelHideshow();
            getIndustriesData();
        } else {
          
          if(res.data.errors && res.data.errors.length > 0){
              if(res.data.errors[0] && res.data.errors[0]['msg']){
                  toast.error(res.data.errors[0]['msg'], {
                      position: getHelpers.toastPosition(),
                      autoClose: getHelpers.toastAutoClose(),
                      theme: 'colored'
                  });
              } else {
                  toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                      position: getHelpers.toastPosition(),
                      autoClose: getHelpers.toastAutoClose(),
                      theme: 'colored'
                  });
              }
          } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
              });
          }

          setspinner(false)
          
        }
      })
      .catch(error => {
        
        if(error.response.data.errors && error.response.data.errors.length > 0){
            if(error.response.data.errors[0] && error.response.data.errors[0]['msg']){
                toast.error(error.response.data.errors[0]['msg'], {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
            } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
            }
        } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }

        setspinner(false)
        // fetchorganizationsList();
      })
  }
  const getIndustriesData = () => {
    axios.get(process.env.REACT_APP_BASE_API_URL + 'external/industries/list')
      .then(async (res) => {
        setspinner(false)
        setIndustries(res.data)
        // optionsOrganizations = res.data
      })
      .catch(error => {
        
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });

        setspinner(false)
        // fetchorganizationsList();
      })
  }
  
  const notify = () => {
    // console.log("formik", formik.errors)
    // let check_error = Object.keys(formik.errors).length  ;
    if (JSON.stringify(formik.errors) !== '{}') {
      toast.error(Object.values(formik.errors)[0], {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });

    }
  }

  const handleChange = (
    newValue: any,
    actionMeta: ActionMeta<any>
  ) => {
    setIndustry(newValue._id)
  };

  useEffect(() => {
    getIndustriesData();
  }, []);

  return (
    <>
      <ToastContainer />
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-body scroll-yx pb-2'>
          <div
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className="row">
              <div className='col-sm-6 mb-7'>
                <label className='fw-bolder fs-6 mb-2'>Name</label>
                <input
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='name'
                  name='name'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>
              <div className='col-sm-6 mb-7'>
                <label className='fw-bolder fs-6 mb-2'>Industry</label>
                <Select
                  className='customSelectDropdown'
                  isClearable
                  onChange={handleChange}
                  // onInputChange={handleInputChange}
                  options={getIndustries}
                  name='industry_id'
                />
                {formik.touched.industry_id && formik.errors.industry_id && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.industry_id}</span>
                  </div>
                )}
              </div>
              <div className='col-sm-12 mb-7'>
                <label className='fw-bolder fs-6 mb-2'>Address</label>
                <input
                  {...formik.getFieldProps('address')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.address && formik.errors.address },
                    {
                      'is-valid': formik.touched.address && !formik.errors.address,
                    }
                  )}
                  type='address'
                  name='address'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.address}</span>
                  </div>
                )}
              </div>
            </div>
            
          </div>
        </div>
        <div className="modal-footer bg-light flex-row-reverse">
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={() => notify()}
          >{!getspinner && (
            <span className='indicator-label'>Save</span>
          )}
            {getspinner && (
              <span >
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='button' // {()=>{showHideModel(cancelModel)}}
            onClick={() => modelHideshow()}
            className='btn btn-white'
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export { ExternalOrganizationEditModalForm }