import { FC, useState, useEffect, useCallback } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useFormik } from 'formik'
import { useNavigate, useParams } from "react-router-dom";
// import { useListView } from '../../modules/apps/user-management/users-list/core/ListViewProvider'
// import { useQueryResponse } from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import { ToastContainer, toast } from 'react-toastify';
import * as getHelpers from '../../modules/auth/GetHelpers'
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';
import { isNotEmpty, toAbsoluteUrl } from '../../../_metronic/helpers'
import * as Yup from 'yup'
import axios from 'axios'
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import clsx from 'clsx'

import { initialUser, User } from './OrganizationValidationModal'

type Props = {
    organizationId: any
    showHideModel(): any
    fetchWorkspacesList(): any
    organization: any
}

const OrgWorkspaceAddModel: FC<Props> = ({ organizationId, showHideModel, fetchWorkspacesList, organization }) => {
  const [showModal, setShowModal] = useState(false)
  
  const navigate = useNavigate();
  let { paramValue } = useParams();
  // const { setItemIdForUpdate } = useListView()
  // const { refetch } = useQueryResponse()
  const [activeInactive, setActiveInactive] = useState(true)
  const [getNextAddUser, setNextAddUser] = useState(true)
  const [getNewOrganization, setNewOrganization] = useState(true)
  const [getOrganization, setOrganization] = useState({})
  const [getOrgValue, setOrgValue] = useState({})
  const [HideShow, setHideShow] = useState(true)
  const [getfname, setfname] = useState('')
  const [getlname, setlname] = useState('')
  const [getRole, setGetRole] = useState('')
  const [isDisabled, setDisabled] = useState(false);
  const [getspinner, setspinner] = useState(false)
  const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);
  const [getOrganizations, setOrganizations] = useState<any[]>([]);

//   const modelHideshow = () => {
//     setShowModal(!setShowModal)
//   }
  
  const [iniValues] = useState<User>({
    name: '',
  })
  const editUserSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(100, 'Maximum 100 characters')
      .required('Workspace name is required'),
  })

  const formik = useFormik({
    initialValues: iniValues,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const formDataObj: any = {
            email:"",
            first_name:"",
            invite:true,
            last_name:"",
            role:"",
            password:"",
            name: values.name,
            organization: getOrganization,
            // role: !activeInactive ? getHelpers.findInArray(internalRolesPermissionsListResult)['id'] : '',
        }
        setspinner(true)
        saveWorkspace(formDataObj)
      } catch (ex) {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      // refetch()
    }
    // setItemIdForUpdate(undefined)
  }

  const saveWorkspace = (data: any) => {
    axios.post(process.env.REACT_APP_BASE_API_URL + 'users', data)
      .then(async (res) => {
        // alert('then')

        setspinner(false)
        if (res.data.status) {

          window.dispatchEvent(new CustomEvent(
            'workspaceListRefresh',
            {
              detail: {
                value: true
              }
            }
          ))
          const GdformData = {
            dashboard_name: res.data.workspacedata.name,
            workspaceId: res.data.workspacedata._id,
            emails: [res.data.user.email],
            message: res.data.message
          }
          // await GoodDataDashboardSave(GdformData)
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
          fetchWorkspacesList();
          saveWorkpaceColorplate(res.data.workspaceId);
          getOrganizationsData();

          setTimeout(() => {
            if (getNextAddUser) {
              // showHideModel();
              navigate("/internal/workspace/" + res.data.workspacedata._id + "/users/add");
            }
            showHideModel();
          }, 1500);

        } else {

          if (res.data.errors && res.data.errors.length > 0) {
            if (res.data.errors[0] && res.data.errors[0]['msg']) {
              toast.error(res.data.errors[0]['msg'], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }

          setspinner(false)

        }
      })
      .catch(error => {
        // alert('catch')
        console.log('error', error);
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                toast.error(error.response.data.errors[0]['msg'], {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
                });
              } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
                });
              }
            } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        } else {
          if (error.response.status == false) {
            toast.error(error.response.message, {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        }

        setspinner(false)
        // fetchorganizationsList();
      })
  }

  const saveWorkpaceColorplate = (workspaceId: any) => {
    axios.get(process.env.REACT_APP_BASE_API_URL + 'workspace/style/setting/' + workspaceId)
      .then(async (res) => {
        console.log('success');
      })
      .catch(error => {
        console.log('plate error');
      })
  }

  const getOrganizationsData = () => {
    axios.get(process.env.REACT_APP_BASE_API_URL + 'internal/organizations')
      .then(async (res) => {
        setspinner(false)
        setOrganizations(res.data)
        // optionsOrganizations = res.data
      })
      .catch(error => {

        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });

        setspinner(false)
        // fetchorganizationsList();
      })
  }

  const handleChange = (
    newValue: any,
    actionMeta: ActionMeta<any>
  ) => {
    // console.group();
    // console.log('Value Changed=',newValue);
    // userForEdit.organization = newValue
    setOrganization(newValue)
    // console.log('handleChange getOrganization=',getOrganization)
    // console.groupEnd();
  };

  const notify = () => {
    // console.log("formik", formik.errors)
    // let check_error = Object.keys(formik.errors).length  ;
    if (JSON.stringify(formik.errors) !== '{}') {
      toast.error(Object.values(formik.errors)[0], {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    }
  }

  useEffect(() => {
    var orgObj = {
        label: organization['name'],
        value: organization['_id'],
        _id: organization['_id'],
    }
    setOrgValue(orgObj)
    
    var orgData : any = organization
    orgData.label = organization['name']
    orgData.value = organization['_id']
    orgData._id = organization['_id']
    console.log('orgData=',orgData)
    setOrganization(orgData)
    // internalRolePermissionList();
    getOrganizationsData();
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [organizationId])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='false'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* <UserEditModalHeader /> */}
            <div className='modal-header bg-primary'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder text-white'>Create Workspace</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => { showHideModel() }}
                style={{ cursor: 'pointer' }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* Modal Body */}
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                <div className='modal-body scroll-yx pb-2'>
                <div
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    <div className="row">

                    <div className='col-sm-6 mb-7'>
                        <label className='fw-bolder fs-6 mb-2'>Organization</label>

                        <CreatableSelect
                            className='customSelectDropdown'
                            onChange={handleChange}
                            // onInputChange={handleInputChange}
                            options={getOrganizations}
                            value={getOrgValue}
                            isDisabled={true}
                            name='organization'
                            theme={(theme) => ({
                                ...theme,
                                // borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: 'orangered',
                                    primary25: '#f6f6f9',
                                    primary: '#793CFB',
                                },
                            })}
                        />

                        {formik.touched.organization && formik.errors.organization && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.organization}</span>
                            </div>
                        </div>
                        )}
                    </div>

                    <div className='col-sm-6 mb-7'>
                        <label className='fw-bolder fs-6 mb-2'>Workspace Name</label>
                        <input
                        placeholder='Name'
                        {...formik.getFieldProps('name')}
                        className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            { 'is-invalid': formik.touched.name && formik.errors.name },
                            {
                            'is-valid': formik.touched.name && !formik.errors.name,
                            }
                        )}
                        type='name'
                        name='name'
                        autoComplete='off'
                        disabled={formik.isSubmitting}
                        />
                        {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert'>{formik.errors.name}</span>
                        </div>
                        )}
                    </div>

                    </div>

                    <div className='fv-row mb-3'>
                    <div className='d-flex flex-stack'>
                        <div className='me-5'>
                        <p className='fs-6 fw-bolder text-gray-900 mb-0'>User</p>
                        <p className="fs-7 fw-bolder text-muted "> Next create user? </p>
                        </div>
                        <div className='form-check form-switch form-check-custom form-check-solid'>
                        <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <span className='form-check-label text-muted me-2'>No</span>
                            <input
                            className='form-check-input'
                            type='checkbox'
                            name='resend_invite'
                            checked={getNextAddUser}
                            onClick={() =>
                                setNextAddUser(!getNextAddUser)
                            }
                            />
                            <span className='form-check-label text-muted'>Yes</span>
                        </label>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="modal-footer bg-light flex-row-reverse">
                <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => notify()}
                >{!getspinner && (
                    <span className='indicator-label'>Save</span>
                )}
                    {getspinner && (
                    <span >
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    )}
                </button>
                <button
                    type='button' // {()=>{showHideModel(cancelModel)}}
                    onClick={() => showHideModel()}
                    className='btn btn-white'
                >
                    Cancel
                </button>
                </div>
            </form>
            {/* Modal Body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export { OrgWorkspaceAddModel }
