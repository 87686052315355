import {FC, useState, createContext, useContext} from 'react'

import Spinner from 'react-bootstrap/Spinner';
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'

const ComponentLoading: FC = () => {
  return (
    <div className="componentLoading bg-white">

      <img width={"40px"} src={toAbsoluteUrl('/media/logos/loading-icon.gif')} />
          
      {/* <div className="rotating">
        <img className="front" src={toAbsoluteUrl('/media/icon-front.svg')} />
        <img className="back" src={toAbsoluteUrl('/media/icon-back.svg')} />
      </div> */}
      
        {/* <Spinner
            className='color-primary'
            as="span"
            animation="border"
            // size="sm"
            role="status"
            aria-hidden="true"
        >
            <span className="sr-only">Loading...</span>
        </Spinner> */}
        {/* <span style={{fontSize: "1.75rem", fontWeight:"600", fontFamily:"Averia Serif Libre"}}>stringo media</span> */}
        <div className='mt-5'>
            <span className='text-dark'>Loading ...</span>
        </div>
    </div>
  )
}

export {ComponentLoading}