
import { FC, useState, useEffect } from 'react'


import { Modal } from 'react-bootstrap'

import axios from 'axios'
import { WorkspaceUserEditModal } from './WorkspaceUserEditModel'

import { useParams, useNavigate } from "react-router-dom";

import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

import * as Yup from 'yup'
import { useFormik } from 'formik'

// import {initialUser, User} from '../../../app/modules/apps/user-management/users-list/core/_models' 
// import { initialUser, User } from './OrganizationValidationModal'
import clsx from 'clsx'
// import { useListView } from '../../../app/modules/apps/user-management/users-list/core/ListViewProvider'
// import {UsersListLoading} from '../../../app/modules/apps/user-management/users-list/components/loading/UsersListLoading' 
// import { createUser, updateUser } from '../../../app/modules/apps/user-management/users-list/core/_requests'
// import { useQueryResponse } from '../../../app/modules/apps/user-management/users-list/core/QueryResponseProvider'
import { ToastContainer, toast } from 'react-toastify';

import '../../../../node_modules/react-toastify/dist/ReactToastify.css';


const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const OrganizationsListUrl = `${API_URL}/internal/customers`

// type Props ={
//     test23:boolean
// }
interface PostsProps {
    showHideEditUserModal: any;
    showEditUserModal: any;
    userIdOrg: any;
    fetchorganizationsUsersList(): any;
    rowprops: any
}

const OrganizationsEditUserModal: FC<PostsProps> = ({ showEditUserModal, showHideEditUserModal, userIdOrg, fetchorganizationsUsersList, rowprops }) => {

    let { organisationUsersId }: any = useParams();
    // const { setItemIdForUpdate } = useListView()
    // const { refetch } = useQueryResponse()
    const [getspinner, setspinner] = useState(false)
    const [singleUser, setsingleuser]: any = useState<any[]>([]);
    const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);

    const fetchOrganizationUserById: any = () => {
        // console.log("inn function")
        const fetchOrgUserAPI = `${API_URL}/users/` + userIdOrg
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const singleuserData = axios.get(
            fetchOrgUserAPI,
            config
        ).then(async (res: any) => {
            // console.log("response=",res.data);
            formik.values.email = res.data.email
            formik.values.first_name = res.data.first_name
            formik.values.last_name = res.data.last_name
            formik.values.job_title = res.data.job_title
            // formik.values.role = res.data.custom_role._id
            await setsingleuser(res.data);
            return setsingleuser;

        }
        ).catch(console.log);
        return singleuserData

    }

    const internalRolePermissionList = () => {
        const internalRolesPermissionsUrl = `${API_URL}/roles-permissions`

        const config = {
            headers: { Authorization: BearerToeken }
        };
        const internalRolesList = axios.get(
            internalRolesPermissionsUrl,
            config
        ).then(async (res) => {
            const { data: rolespermissionsDataResult } = res
            return await setinternalRolesPermissionsListResult(rolespermissionsDataResult)
        }
        ).catch(console.log);
        return internalRolesList

    }

    const updateOrganizationuserSchema = Yup.object().shape({
        email: Yup.string()
            .email('Wrong email format')
            .required('Email is required'),
        role: Yup.string().required('Role is required'),
        job_title: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters'),
        // .required('First name is required')
        first_name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('First name is required'),
        last_name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('Last name is required'),
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            // refetch()
        }
        // setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: {
            role: '',
            first_name: '',
            last_name: '',
            // confirm_password: '',
            email: '',
            job_title: '',
            // invite: '',
        },
        validationSchema: updateOrganizationuserSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {
                // console.log("formik.values=", formik.values);
                // console.log("singleUser=", singleUser);
                const formDataObj: any = {}
                // const formDataObj = {
                //     email: values.email,
                //     first_name: getfname,
                //     last_name: getlname,
                //     role: values.role,
                // }
                formDataObj.email = formik.values.email
                formDataObj.job_title = formik.values.job_title
                formDataObj.first_name = formik.values.first_name
                formDataObj.last_name = formik.values.last_name
                formDataObj.role = formik.values.role
                setspinner(true)
                updateOrganizationUser(formDataObj)
                // }
            } catch (ex) {
                toast.error("Sorry,looks like there are some errors detected!.Please try again.", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                console.error(ex)
            } finally {
                setSubmitting(true)
                cancel(true)
            }
        },
    })

    const updateOrganizationUser = (data: any) => {
        // console.log('data=',data)
        axios.put(process.env.REACT_APP_BASE_API_URL + '/users/' + organisationUsersId + '/' + userIdOrg, data)
            .then((res) => {
                // console.log("response=", res);
                setspinner(false)
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    showHideEditUserModal();
                    fetchorganizationsUsersList();
                } else {

                    toast.error("Sorry,looks like there are some errors detected!.Please try again..", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    showHideEditUserModal();
                }
            })
            .catch(error => {
                toast.error("Sorry,looks like there are some errors detected!.Please try again.", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)
                fetchorganizationsUsersList();
            })
    }

    const notify = () => {
        // console.log('formik.errors=',formik.errors)
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });

        }
    }

    useEffect(() => {
        internalRolePermissionList()
        fetchOrganizationUserById()

        let rowsData;
        if (showEditUserModal) {
            rowsData = rowprops
            // console.log(rowsData);
            formik.values.job_title = rowsData.job_title
            formik.values.email = rowsData.email
            formik.values.first_name = rowsData.first_name
            formik.values.last_name = rowsData.last_name
            // console.log('rowsData',rowsData);
            if (rowsData.role_users) {
                formik.values.role = rowsData.role_users._id
            }
        }

    }, [rowprops])

    return (
        <div>
            {/* <ToastContainer /> */}
            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showEditUserModal}
                animation={false}
            >

                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        {/* begin::Modal content */}
                        <div className='modal-content'>
                            {/* <UserEditModalHeader /> */}
                            <div className='modal-header bg-primary'>
                                {/* begin::Modal title */}
                                <h2 className='fw-bolder text-white'>Edit User </h2>
                                {/* end::Modal title */}

                                {/* begin::Close */}
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => { showHideEditUserModal() }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                                {/* end::Close */}
                            </div>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                                <div className='modal-body scroll-y pb-2'>
                                    <div
                                        className='d-flex flex-column scroll-y me-n7 pe-7'
                                        id='kt_modal_add_user_scroll'
                                        data-kt-scroll='true'
                                        data-kt-scroll-activate='{default: false, lg: true}'
                                        data-kt-scroll-max-height='auto'
                                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                        data-kt-scroll-offset='300px'
                                    >
                                        <div className='row'>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'> Role </label>
                                                <select
                                                    {...formik.getFieldProps('role')}
                                                    name='role'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.role && formik.errors.role },
                                                        {
                                                            'is-valid': formik.touched.role && !formik.errors.role,
                                                        }
                                                    )}
                                                >
                                                    <option value="">Select Role</option>
                                                    {internalRolesPermissionsListResult.map((role) => (
                                                        <option value={role._id}>{role.label.charAt(0).toUpperCase() + role.label.slice(1)}</option>
                                                    )

                                                    )}
                                                    {formik.touched.role && formik.errors.role && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert'>{formik.errors.role}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </select>
                                            </div>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Email</label>
                                                <input
                                                    placeholder='Email'
                                                    {...formik.getFieldProps('email')}
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.email && formik.errors.email },
                                                        {
                                                            'is-valid': formik.touched.email && !formik.errors.email,
                                                        }
                                                    )}
                                                    type='email'
                                                    name='email'
                                                    autoComplete='off'
                                                    disabled={formik.isSubmitting}
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className='fv-plugins-message-container'>
                                                        <span role='alert'>{formik.errors.email}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>First Name </label>
                                                <input
                                                    {...formik.getFieldProps('first_name')}
                                                    placeholder='First name'

                                                    type='text'
                                                    name='first_name'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.first_name && formik.errors.first_name },
                                                        {
                                                            'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                                        }
                                                    )}
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Last Name </label>
                                                <input
                                                    {...formik.getFieldProps('last_name')}
                                                    placeholder='Last name'

                                                    type='text'
                                                    name='last_name'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.last_name && formik.errors.last_name },
                                                        {
                                                            'is-valid': formik.touched.last_name && !formik.errors.last_name,
                                                        }
                                                    )}
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <div className='col-sm-12 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Job Title</label>
                                                <input
                                                    {...formik.getFieldProps('job_title')}
                                                    placeholder='Job Title'

                                                    type='text'
                                                    name='job_title'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.job_title && formik.errors.job_title },
                                                        {
                                                            'is-valid': formik.touched.job_title && !formik.errors.job_title,
                                                        }
                                                    )}
                                                    autoComplete='off'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        onClick={() => notify()}
                                    >{!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => showHideEditUserModal()}
                                        className='btn btn-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                            {/* end::Modal body */}
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
            </Modal>

        </div>

    )

}

export { OrganizationsEditUserModal }