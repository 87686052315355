import { lazy, FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { MasterDashboardLayout } from '../../_metronic/layout/MasterDashboardLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import InternalDashboardOverviewWrapper from '../pages/overview/InternalDashboardOverviewWrapper'
import { OverviewWrapper } from '../pages/overview/OverviewWrapper'
import Workspaces from '../pages/customer-organizations/WorkspacesPage'

import Organizations from '../pages/customer-organizations/OrganizationsPage'

import ExternalOrganizations from '../pages/customer-organizations/ExternalOrganizationsPage'
import { ExternalOrganizationDetailPage } from '../pages/customer-organizations/ExternalOrganizationDetailPage'
import { ExternalOrganizationDetailEditPage } from '../pages/customer-organizations/ExternalOrganizationDetailEditPage'
import ExternalOrganizationDetailContactsPage from '../pages/customer-organizations/ExternalOrganizationDetailContactsPage'

import ExternalWorkspacesBasicDetailPage from '../pages/customer-organizations/ExternalWorkspacesBasicDetailPage'

import WorkspaceDashboardsPage from '../pages/customer-organizations/WorkspaceDashboardsPage'
import { OrganizationDetailPage } from '../pages/customer-organizations/OrganizationDetailPage'
import { OrganizationDetailEditPage } from '../pages/customer-organizations/OrganizationDetailEditPage'
import OrganizationDetailContactsPage from '../pages/customer-organizations/OrganizationDetailContactsPage'
import OrganizationDetailSpecialtiesPage from '../pages/customer-organizations/OrganizationDetailSpecialtiesPage'
import OrganizationDetailWorkspacesPage from '../pages/customer-organizations/OrganizationDetailWorkspacesPage'
import WorkspaceArchiveDashboardPage from '../pages/customer-organizations/WorkspaceArchiveDashboardPage'
import WorkspacesUsers from '../pages/customer-organizations/WorkspacesUsersPage'
import WorkspacesBasicDetailPage from '../pages/customer-organizations/WorkspacesBasicDetailPage'
import WorkspacesEditPage from '../pages/customer-organizations/WorkspacesEditPage'
import ExternalWorkspacesEditPage from '../pages/customer-organizations/ExternalWorkspacesEditPage'
import SwitchWorkspaceManageUser from '../pages/customer-organizations/switchOrganization/SwitchWorkspaceManageUser'
import { SwitchWorkspaceWrapper } from '../pages/customer-organizations/switchOrganization/SwitchWorkspaceWrapper'
import { DashboardCreatePage } from '../pages/customer-organizations/DashboardCreatePage'
import ExternalDashboardCreateWrapper from '../pages/customer-organizations/ExternalDashboardCreatePage'
import Roles from '../pages/customer-roles/RolesPage'
import ExternalRoles from '../pages/customer-roles/ExternalRolesPage'
import PermissionsRole from '../pages/customer-permissions/PermissionsRole'
import ExternalPermissionsRole from '../pages/customer-permissions/ExternalPermissionsRole'
import { DataSourcesPage } from '../pages/data-sources/DatasourcesPage'
import { InternalUsers } from '../pages/internal-users/InternalUsersPage'
import {InternalGDUsersPage} from '../pages/internal-users/InternalGDUsersPage'
import {InternalGDWorkspacesPage} from '../pages/internal-users/InternalGDWorkspacesPage'
import InternalRoles from '../pages/internal-roles/InternalRolesPage'
import { InternalPermissions } from '../pages/internal-permissions/InternalUsersPage'
import FilterTableComponent from '../pages/customer-organizations/filterTest'
import * as getHelpers from '../../app/modules/auth/GetHelpers'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import InternalIndustries from '../pages/industry/InternalIndustries'
import InternalUtilities from '../pages/industry/InternalUtilities'
import GoodDataPageWrapper from '../pages/layout-builder/GoodDataPageWrapper'
import GoodDataPageEditWrapper from '../pages/layout-builder/GoodDataPageEditWrapper'
import GoodDataUsersPageWrapper from '../pages/layout-builder/GoodDataUsersPageWrapper'
import ProfilePage from '../modules/profile/ProfilePage'
import GoodDataTestPageWrapper from '../pages/layout-builder/GoodDataTestPageWrapper'
// import axios from 'axios'

const PrivateRoutes = () => {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const GoodDataPageWrapper = lazy(() => import('../pages/layout-builder/GoodDataPageWrapper'))
  // const GoodDataPageEditWrapper = lazy(() => import('../pages/layout-builder/GoodDataPageEditWrapper'))
  // const GoodDataUsersPageWrapper = lazy(() => import('../pages/layout-builder/GoodDataUsersPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to={getHelpers.LogoLink()} />} />
        <Route path='internal/dashboard' element={<InternalDashboardOverviewWrapper />} />
        <Route path='user/:userId' element={<GoodDataUsersPageWrapper />} />
        <Route path='overview' element={<OverviewWrapper />} />
        <Route path='internal/workspaces/list' element={<Workspaces />} />

        <Route path='organizations' element={<ExternalOrganizations />} />
        <Route path='organizations/:organizationId' element={<ExternalOrganizationDetailPage />} />
        <Route path='organizations/:organizationId/edit' element={<ExternalOrganizationDetailEditPage />} />
        <Route path='organizations/:organizationId/contacts' element={<ExternalOrganizationDetailContactsPage />} />

        <Route path='internal/organizations' element={<Organizations />} />
        <Route path='internal/organizations/:organizationId' element={<OrganizationDetailPage />} />
        <Route path='internal/organizations/:organizationId/edit' element={<OrganizationDetailEditPage />} />
        <Route path='internal/organizations/:organizationId/contacts' element={<OrganizationDetailContactsPage />} />
        <Route path='internal/organizations/:organizationId/specialities' element={<OrganizationDetailSpecialtiesPage />} />
        <Route path='internal/organizations/:organizationId/workspaces' element={<OrganizationDetailWorkspacesPage />} />
        {/* <Route path='internal/organizations/list' element={<Organizations />} /> */}

        <Route path='workspace/:organisationUsersId' element={<ExternalWorkspacesBasicDetailPage />} />
        <Route path='workspace/:organisationUsersId/edit' element={<ExternalWorkspacesEditPage />} />

        <Route path='testFilter' element={<FilterTableComponent />} />
        <Route path='internal/workspace/:workspaceId/dashboards/archive' element={<WorkspaceArchiveDashboardPage organisationUsersId={""} />} />
        <Route path='internal/workspace/:workspaceId/dashboards' element={<WorkspaceDashboardsPage organisationUsersId={""} />} />
        <Route path='dashboard/create/:workspaceId' element={<DashboardCreatePage />} />
        <Route path='external/dashboard/create' element={<ExternalDashboardCreateWrapper />} />
        <Route path='external/dashboard/create/:workspaceId' element={<ExternalDashboardCreateWrapper />} />
        <Route path='internal/workspace/:organisationUsersId' element={<WorkspacesBasicDetailPage />} />
        <Route path='internal/workspace/:organisationUsersId/edit' element={<WorkspacesEditPage />} />
        
        <Route path='internal/workspace/:organisationUsersId/users' element={<WorkspacesUsers />} />
        <Route path='internal/workspace/:organisationUsersId/users/:addUser' element={<WorkspacesUsers />} />

        <Route path='users/:organisationUsersId' element={<SwitchWorkspaceManageUser />} />
        <Route path='internal/workspaces/role' element={<Roles />} />
        <Route path='roles' element={<ExternalRoles />} />
        <Route path='internal/workspaces/permission' element={<PermissionsRole />} />
        <Route path='permissions' element={<ExternalPermissionsRole />} />
        <Route path='internal/users/list' element={<InternalUsers />} />
        <Route path='internal/gd-users' element={<InternalGDUsersPage />} />
        <Route path='internal/gd-workspaces' element={<InternalGDWorkspacesPage />} />
        <Route path='internal/users/role' element={<InternalRoles />} />
        <Route path='internal/users/permission' element={<InternalPermissions />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='workspaces' element={<SwitchWorkspaceWrapper />} />

        <Route path='internal/industries' element={<InternalIndustries />} />
        <Route path='internal/utilities' element={<InternalUtilities />} />
        
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
      <Route path='dashboard/:dashboardId/test' element={<GoodDataTestPageWrapper />} />
      <Route element={<MasterDashboardLayout />}>
        <Route path='dashboard/:dashboardId' element={<GoodDataPageWrapper />} />
       
        <Route path='dashboard/:dashboardId/edit' element={<GoodDataPageEditWrapper />} />
        <Route path='dashboard-page' element={<OverviewWrapper />} />
      </Route>
      
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
