/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useNavigate, Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../../app/modules/auth/GetUserInfo'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../redux/actions/externalPermissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const allUsersUrl = `${API_URL}/internal/all/users`
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const organizationsUrl = `${API_URL}/internal/customers`
const internalUsersUrl = `${API_URL}/internal/admins`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const loggedUserName = localStorage.getItem('full_name')
let currentActiveWorkspaceId: any = ''
type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}
const OverviewStatsCard: React.FC<Props> = ({ className, chartColor, chartHeight, strokeColor }) => {
  const [getAllWorkspace, setAllWorkspace] = useState<any[]>([]);
  const [getMyDashboards, setMyDashboards] = useState<any[]>([]);
  const [shareDashboard, setshareDashboard] = useState<any[]>([]);
  
  const [getMyDashboardsCount, setMyDashboardsCount] = useState<any>(null);
  const [getMySharedDashboardsCount, setMySharedDashboardsCount] = useState<any>(null);

  const [getComponentLoading, setComponentLoading] = useState(true)
  const [checkExternalRole, setCheckExternalRole] = useState(getUserInfo.roleLabel())
  const navigate = useNavigate();
  const findOrgId = async (data: any) => {
    let userData: any = await localStorage.getItem('userinfo');
    userData = JSON.parse(userData);
    // console.log()
    let currentOrgId = ''
    for (let i = 0; i < data.length; i++) {
      // console.log('userData',userData.organisation_id);
      // console.log('data[i]._id',data[i]._id)
      if (data[i]._id == userData.workspace_id) {
        // currentActiveWorkspaceId = data[i]._id;
        currentOrgId = data[i]._id;
        // console.log('currentOrgId',currentOrgId)
      }

    }
    return currentOrgId;
  }
  const fetchAllOrg = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/workspaces",
    )
      .then(async (response) => {
        if (response.data) {
          // console.log()
          currentActiveWorkspaceId = await findOrgId(response.data.workspaces);
          setAllWorkspace(response.data.workspaces)
        }
        setComponentLoading(false)
      })
      .catch((error) => {
        setComponentLoading(false)
        console.log(error)
      });

  };

  /////////////// organization workspaces

  // const getData = async () => {
  //   await axios.get(
  //     process.env.REACT_APP_BASE_API_URL + 'gooddata/dashboards',
  //   )
  //     .then((res) => {
  //       setMyDashboards(res.data.myDashboards)
  //       setshareDashboard(res.data.mySharedDashboards)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     });
  // };

  const getData = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/org/workspaces",
    )
      .then(async (res) => {
        var myDashboardsCount : any = 0
        var mySharedDashboardsCount : any = 0
        if(res.data.workspaces != undefined && res.data.workspaces.length > 0){
          res.data.workspaces.forEach((workspace:any) => {
            myDashboardsCount = _.size(workspace.myDashboards);
            mySharedDashboardsCount = _.size(workspace.mySharedDashboards);
          
            console.log(`Workspace ID: ${workspace._id}`);
            console.log(`myDashboards count: ${myDashboardsCount}`);
            console.log(`mySharedDashboards count: ${mySharedDashboardsCount}`);
            console.log('---');
          });
        }
        setMyDashboardsCount(myDashboardsCount)
        setMySharedDashboardsCount(mySharedDashboardsCount)
      })
      .catch((error) => {
        // console.log(error)
      });

  };
  ///////////////

  const getMe = () => {
    axios.get(process.env.REACT_APP_BASE_API_URL + "me").then(async (res) => {
      let resData = await res.data;
      setCheckExternalRole(resData.role.name)
    }).catch(error => {
      console.log(error)
    });
  };
  useEffect(() => {

    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {
            getMe();
          } else {
            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {
            getMe();
          } else {
            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        console.clear()
        navigate('/error/404')
      });
    fetchAllOrg()
    getData();
  }, []);
  const switchWorkspace = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/workspace/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
        window.location.reload();
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }
  return (
    <>
      <ToastContainer />
      <div className={`card ${className} mb-5`}>
        <div className={`card-header border-0 py-5 pt-7 heading_f bg-${chartColor}`} style={{ height: '150px' }}>
          <div>
            <h3 className='card-title fw-bolder text-white fs-24'>
              {
                getUserInfo.fullName() ?
                  (
                    getUserInfo.fullName()
                  ) : (
                    getUserInfo.email()
                  )
              }
            </h3>
            <span className='card-title fw-bold text-white'>Welcome to your Customer Dashboard!</span>
          </div>
          <div className='card-toolbar'></div>
        </div>
        <div className='card-body p-0'>
          <div className='card-p mt-n20 position-relative customColBox'>

            <div className='rowx g-0 customBoxsCol'>
              <div className='customBoxCol colx bg-gray-200 px-6 py-8 rounded-2 me-7x mb-7x'>
                {/* <i className="fa fa-users text-primary fs-2x d-block my-2"></i> */}
                <div>
                  {/* <img height='35px' src='/media/icons/duotune/abstract/abs027.svg' /> */}
                  <span className='svg-icon svg-icon-muted svg-icon-2hx'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor" />
                      <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor" />
                    </svg>
                  </span>
                </div>
                <a className='count fs-1 fw-bolder text-gray-700' >
                  {getAllWorkspace.length}
                </a><br />
                <span className='text-gray-600 fw-bolder fs-4 countText'>
                  Workspaces
                </span>
              </div>
              <div className='customBoxCol colx bg-gray-200 px-6 py-8 rounded-2 mb-7x'>
                <div>
                  <span className='svg-icon svg-icon-muted svg-icon-2hx'>
                    <span className="svg-icon svg-icon-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
                        <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor" />
                        <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor" />
                        <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor" />
                        <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
                      </svg>
                    </span>
                  </span>
                </div>
                <a className='count fs-1 fw-bolder text-gray-700'>
                  {/* {getMyDashboards.length + shareDashboard.length} */}
                  {getMyDashboardsCount + getMySharedDashboardsCount}
                </a><br />
                <span className='text-gray-600 fw-bolder fs-4 countText'>
                  Dashboards
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      {
        _.includes(store.getState().userExternalData.externalPermissions, 'view_workspaces') ?
          (
            <div className={`card ${className} overflow-hidden mb-5`}>
              {
                getComponentLoading ?
                  (
                    <ComponentLoading></ComponentLoading>
                  ) : ('')
              }
              {
                getAllWorkspace.length > 0 ?
                  (
                    <>
                      <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bolder fs-3 mb-1 heading_f'>Workspaces</span>
                          <span className='text-muted mt-1 fw-bold fs-7'>List of Workspaces</span>
                        </h3>
                      </div>
                      <div className='card-body py-3'>
                        <div className='tab-content'>
                          <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                              <thead>
                                <tr className='border-0'>
                                  <th className='p-0 min-w-150px'></th>
                                  <th className='p-0 min-w-50px'></th>
                                </tr>
                              </thead>
                              <tbody>
                                {getAllWorkspace.map((objworkspace: any, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-45px me-2 symbol-circle'>
                                          <span className={'symbol-label bg-' + objworkspace.name.charAt(0)}>
                                            <span className='text-white fs-3 fw-bolder'>{objworkspace.name.charAt(0)}</span>
                                          </span>
                                        </div>
                                        <div>
                                          <span className='text-dark fw-bolder text-hover-primary fs-5 d-block'>
                                            {objworkspace.name}
                                          </span>
                                          {currentActiveWorkspaceId == objworkspace._id
                                            ?
                                            <span className='badge badge-success mt-1'>Current</span>
                                            :
                                            ('')
                                          }

                                        </div>
                                      </div>
                                    </td>
                                    <td className='text-end'>
                                      {
                                        _.includes(store.getState().userExternalData.externalPermissions, 'manage_workspaces') ?
                                          (
                                            <>
                                              {currentActiveWorkspaceId == objworkspace._id
                                                ?
                                                ('')
                                                :
                                                <a
                                                  onClick={() => switchWorkspace(objworkspace._id)}
                                                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                                >
                                                  <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-2'
                                                  />
                                                </a>
                                              }
                                            </>
                                          ) : ('')
                                      }

                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: getHelpers.NoDataFound() }}></div>
                  )
              }
            </div>
          ) : ('')
      }



    </>
  )
}

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const color = getCSSVariableValue('--kt-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  }
}

// export { OverviewStatsCard }
export default connect(mapStateToProps)(OverviewStatsCard);