import {FC, useState, createContext, useContext} from 'react'

import Spinner from 'react-bootstrap/Spinner';

const AsideComponentLoading: FC = () => {
  return (
    <div className="asideComponentLoading bg-primary">
        <Spinner
            className='color-primary'
            as="span"
            animation="border"
            // size="sm"
            role="status"
            aria-hidden="true"
        >
            <span className="sr-only">Loading...</span>
        </Spinner>
        {/* <span style={{fontSize: "1.75rem", fontWeight:"600", fontFamily:"Averia Serif Libre"}}>stringo media</span> */}
        <div className='mt-5'>
            <span className='text-white'>Loading ...</span>
        </div>
    </div>
  )
}

export {AsideComponentLoading}