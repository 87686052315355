/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios'
import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import Swal from 'sweetalert2'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../modules/auth/GetHelpers'
import OrganizationAddSpecialtyModal from './OrganizationAddSpecialtyModal'
import { OrganizationEditSpecialtyModal } from './OrganizationEditSpecialtyModal'
import OrganizationDetailHeader from '../../pages/customer-organizations/OrganizationDetailHeader'
import { KTSVG } from '../../../_metronic/helpers'
import { useLocation } from 'react-router'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const BearerToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${BearerToken}`

// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const OrganizationDetailSpecialtiesPage: FC = () => {
  const [getOrganizationData, setOrganizationData]: any = useState<any>({});

  const [showModal, setShowModal] = useState(false)
  // const [workspaceData, setworkspaceData]: any = useState<any[]>([]);
  const [organizationContactsData, setOrganizationContactsData]: any = useState<any[]>([]);
  const [getDashboardsCount, setDashboardsCount]: any = useState<any>(0);
  const [getspinner, setspinner] = useState(false)
  const [roleEditProps, setroleEditProps] = useState<any[]>([]);
  const [showEditUserModal, setshowEditUserModal]: any = useState(false)
  const [showHidePasswordModal, setshowHidePasswordModal]: any = useState(false)
  const [getOrgContactId, setOrgContactUserId]: any = useState('');
  const [tabDashboar, settabDashboar] = useState(false)
  const [rowprops, setrowprops] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(true)
  const location = useLocation();

  const [getSpecialtyData, setSpecialtyData] = useState({})

  const showHideModel: any = () => {
    setShowModal(!setShowModal)
  }

  const showHideEditUserModal: any = () => {
    setshowEditUserModal(!setshowEditUserModal)
  }

  const showHideSetPasswordModal: any = () => {
    setshowHidePasswordModal(!setshowHidePasswordModal)
  }

  const rowPropData: any = (rowData: any) => {
    setrowprops(rowData);
    fetchOrganizationContactById(rowData._id)
  }

  const navigate = useNavigate();
  let { organizationId }: any = useParams();

  const organizationSpecialtiesURL = `${API_URL}/internal/organizations/` + organizationId + '/specialties'
  const organizationURL = `${API_URL}/internal/organizations/` + organizationId

  const fetchOrganizationContactById = (id: any) => {
    const singleuserData = axios.get(
      `${API_URL}/internal/organizations/` + organizationId + '/specialties/' + id,
      {
        headers: { Authorization: BearerToken }
      }
    ).then((res: any) => {
      var data = {
        name: res.data.specialtyDoc.name,
        organization_id: res.data.specialtyDoc.organization_id,
      }
      setSpecialtyData(data)
    }).catch((err) => {
      console.log('err=', err)
    });
    // return singleuserData
  }

  const fetchOrganization: any = () => {
    const config = {
      headers: { Authorization: BearerToken }
    };
    const organizationsList = axios.get(
      organizationURL,
      config
    ).then(async (res: any) => {
      const organizationRes = res.data.organization
      setOrganizationData(organizationRes)
      setComponentLoading(false)
      return setOrganizationData;
    }
    ).catch((error) => {
      console.log('error=', error.response.data)
      if(error.response.data.status == 404){
        navigate("/internal/organizations");
      }
    });
    return organizationsList
  }

  const fetchOrganizationSpecialtiesList: any = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const organizationsList = axios.get(
      organizationSpecialtiesURL,
      config
    ).then(async (res: any) => {
      await setOrganizationContactsData(res.data.userspecialities);
      setComponentLoading(false)
      return [setOrganizationContactsData];


    }
    ).catch(console.log);
    return organizationsList

  }

  

  const deleteContact = async (contactId: any) => {
    // setTablePageIndex(defaultPageIndex)
    Swal.fire({
      title: getHelpers.ConfirmDeleteMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/internal/organizations/' + organizationId + '/contacts/' + contactId,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });

              fetchOrganizationSpecialtiesList();

            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }

          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          });
      }
    })
  }


  function SearchInPut(props: any) {
    const { setGlobalFilter } = props
    const count = props.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(props.globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
    return (
      <div className="border-0 pt-6 mb-5" >
        {
          getComponentLoading ?
            (
              <ComponentLoading></ComponentLoading>
            ) : ('')
        }
        <div className="card-title" ></div>
        <div className="card-toolbar">
          <div className="search_btn" data-kt-customer-table-toolbar="base" >
            <div className="d-flex align-items-center position-relative my-1 mx-2" >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={value || ""}
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />
            </div>
            <OrganizationAddSpecialtyModal fetchOrganizationSpecialtiesList={fetchOrganizationSpecialtiesList} />
          </div>
        </div>
      </div>
    )
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length

    return (
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='customTableSearch form-control form-control-solid w-250px ps-14'
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  function OrganizationSpecialtiesTable(props: any) {
    const { columns, data } = props

    const defaultColumn: any = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
    const setLocalStorage = (pageSize: any) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem('pageSize', pageSize)
    }
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,

    )

    // console.log("rows data", rows);

    return (
      <div>
        <SearchInPut
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className='table-responsive'>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer organizationSpecialtiesTableList " {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th className="" {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (<span className='table-sort-asc'></span>)
                          : (<span className='table-sort-desc'></span>)
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='fw-bold' {...getTableBodyProps()}>
              {
                page.length > 0 ?
                  (
                    <>
                      {page.map((row: any, i: any) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                              return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                              </td>
                            })}
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                      </td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="dataTables_length" id="kt_customers_table_length">
              <select name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                value={pageSize}
                onChange={e => {
                  { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                }}
                style={{ width: '120px', height: '38px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div id="kt_table_users_paginate">
              <ul className="pagination">
                <li className="page-item" onClick={() => previousPage()} >
                  <a className="page-link prev-angle">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </li>
                {pageOptions.map((pageSize: any, rowIndex: any) => {

                  return (
                    <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                      <a className="page-link">{pageSize + 1} </a>
                    </li>
                  )

                })}
                <li className="page-item" onClick={() => nextPage()} >
                  <a className="page-link next-angle">
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }

  useEffect(() => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {

            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {

            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        console.clear()
        navigate('/error/404')
      });

    fetchOrganization()
    fetchOrganizationSpecialtiesList()
  }, [])
  
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Specialities',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                data.name
              }
            </>
          )
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }: any) => {
          const data = row.original
          // console.log('data=',data)
          return (
            <>
              {
                _.includes(store.getState().userData.permissions, 'manage_organizations') ?
                  (
                    <div className='customActionButtons'>
                      <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                        <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { setshowEditUserModal(true); setOrgContactUserId(data._id); rowPropData(data) }}>
                          {/* <i className="bi bi-pencil-square"></i> */}
                          <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                            <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                            <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                          </svg>
                          </span>
                        </a>
                      </OverlayTrigger>
                    </div>
                  ) : ('N/A')
              }
            </>
          )
        }
      },
    ],
    []
  )
  const data: any = organizationContactsData;
  return (
    <>
    <ToastContainer></ToastContainer>
      {
        _.includes(store.getState().userData.permissions, 'view_organizations') ?
          (
            <>
              <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                  <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">{getOrganizationData.name} Organization</h1>
                  <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                    <li className="breadcrumb-item text-muted">
                      <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                    </li>
                    <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                    <li className="breadcrumb-item text-muted">
                      <Link to={'/internal/organizations'} className="text-muted text-hover-primary">Organizations</Link>
                    </li>
                    <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                    <li className="breadcrumb-item text-muted">Specialities</li>
                  </ul>
                </div>
              </div>

              <OrganizationDetailHeader></OrganizationDetailHeader>

              <div className='row gy-5 g-xl-8'>
                <div className='col-xxl-12'>
                  {!tabDashboar &&
                    <div className="card card-xxl-stretch mb-5 mb-xl-12 card-border-radius min-height-400" >
                      <div className="card-body pt-0 bg-white" >
                        <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
                          <OrganizationSpecialtiesTable columns={columns} data={data} />
                        </div>
                      </div>
                    </div>
                  }
                  {/* {tabDashboar &&
                <WorkspaceDashboardsPage organisationUsersId={organizationId} />
              } */}
                </div>
                    {
                        getOrgContactId &&
                            <OrganizationEditSpecialtyModal 
                                getSpecialtyData={getSpecialtyData} 
                                showEditUserModal={showEditUserModal} 
                                showHideEditUserModal={showHideEditUserModal} 
                                getOrgContactId={getOrgContactId} 
                                fetchorganizationsUsersList={fetchOrganizationSpecialtiesList} 
                                rowprops={rowprops} 
                            />
                    }
              </div>
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
          )
      }


    </>
  )
}

// export { OrganizationDetailSpecialtiesPage }
export default connect(mapStateToProps)(OrganizationDetailSpecialtiesPage);