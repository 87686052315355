
import { FC, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { useParams, useNavigate } from "react-router-dom";
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}


const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const OrganizationsListUrl = `${API_URL}/internal/customers`

// type PageProps = {
//     // className: string;
//     internalAdminsListResult:any;
//     internalAdminList():any
//   }

const AddInternalUserModal: FC = () => {

    let { organisationUsersId }: any = useParams();

    const [showModal, setShowModal] = useState(false)
    const [organizationsList, setorganizationsList] = useState<any[]>([]);
    // const { setItemIdForUpdate } = useListView()
    // const { refetch } = useQueryResponse()
    const [activeInactive, setActiveInactive] = useState(true)
    const [HideShow, setHideShow] = useState(true)
    const [getfname, setfname] = useState('')
    const [getlname, setlname] = useState('')
    const [getRole, setGetRole] = useState('625d27bc4a175519dc952ef8')

    const [getCount, setCount] = useState(0)

    const [organizationData, setorganizationData]: any = useState<any[]>([]);
    const [organizationUsersData, setorganizationUsersData]: any = useState<any[]>([]);
    const [singleUser, setsingleuser]: any = useState<any[]>([]);

    const [getspinner, setspinner] = useState(false)
    const [internalAdminsListResult, setinternalAdminsListResult] = useState<any[]>([]);


    const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);
    
    const [getWorkspacesExist, setWorkspacesExist]: any = useState<any[]>([]);
    const [getOrganizationsExist, setOrganizationsExist]: any = useState<any[]>([]);



    const showHideModel: any = () => {
        setShowModal(!setShowModal)
    }


    // const OrgUsersURL = `${API_URL}/internal/org/` + organisationUsersId

    const internalAdminList = () => {

        const internalAdminsURL = `${API_URL}/internal/admins`

        const config = {
            headers: { Authorization: BearerToeken }
        };
        const internalAdminsList = axios.get(
            internalAdminsURL,
            config
        ).then(async (res) => {
            const { data: internalAdminsresultData } = res
            await setinternalAdminsListResult(internalAdminsresultData)
            // return  res.data
        }
        ).catch(console.log);
        return internalAdminsList

    }







    const internalRolePermissionList = () => {
        const internalRolesPermissionsUrl = `${API_URL}/internal/roles-permissions`

        const config = {
            headers: { Authorization: BearerToeken }
        };
        const internalRolesList = axios.get(
            internalRolesPermissionsUrl,
            config
        ).then(async (res) => {
            const { data: rolespermissionsDataResult } = res
            return await setinternalRolesPermissionsListResult(rolespermissionsDataResult)
        }
        ).catch(console.log);
        return internalRolesList

    }

    const handleFNameChange = (event: any) => {
        setfname(event.target.value);
    }
    const handleLNameChange = (event: any) => {
        setlname(event.target.value);
    }

    const handleRoleChange = (event: any) => {
        // console.log('in form')
        // setGetRole(event.target.value);
        // console.log('formik.values.role',formik.values.role)
        formik.values.role = event.target.value;
        // console.log('formik.values.role1',formik.values.role)


    }




    const addOrganizationuserSchema = Yup.object().shape({
        email: Yup.string()
            .email('Wrong email format')
            .required('Email is required'),
        // company: Yup.string()
        //     .min(3, 'Minimum 3 characters')
        //     .max(50, 'Maximum 50 characters')
        //     .required('Name is required'),
        password: !activeInactive ? Yup.string()
            .min(8, 'Minimum 8 characters')
            .max(50, 'Maximum 8 characters')
            // .matches(/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, "Please enter special characters ")
            // .matches(
            //     /^(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            //     'Need one special character'
            // )
            .required('Password is required') : Yup.string(),

        confirm_password: !activeInactive ? Yup.string()
            .min(8, 'Minimum 8 characters')
            .max(50, 'Maximum 8 characters')
            .required('Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match') : Yup.string(),
        role: Yup.string().required('Role is required'),
        job_title: !activeInactive ? Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            // .required('First name is required') 
            : Yup.string(),
        first_name: !activeInactive ? Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('First name is required') : Yup.string(),
        last_name: !activeInactive ? Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('Last name is required') : Yup.string()
    })


    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            // refetch()
        }
        // setItemIdForUpdate(undefined)
    }




    const formik = useFormik({
        initialValues: {
            role: '',
            first_name: '',
            last_name: '',
            job_title: '',
            // company: '',
            password: '',
            confirm_password: '',
            email: '',
            invite: '',
        },
        validationSchema: addOrganizationuserSchema,
        onSubmit: async (values, { setSubmitting }) => {
            // console.log("values", values)
            setSubmitting(true)
            try {


                const formDataObj = {
                    // company: values.company,
                    email: values.email,
                    invite: activeInactive,
                    // role: values.role,
                    role: values.role,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    job_title: values.job_title,
                    password: values.password
                }

                setspinner(true)
                saveInternaluser(formDataObj)
                // }
            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again.", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });


                console.error(ex)
            } finally {

                setSubmitting(true)
                cancel(true)
            }
        },
    })




    const saveInternaluser = (data: any) => {

        setCount(getCount + 1)

        axios.post(process.env.REACT_APP_BASE_API_URL + 'internal/users/', data)
            .then((res) => {
                setspinner(false)
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });

                    window.dispatchEvent(new CustomEvent(
                        'internalUsersListRefresh',
                        {
                            detail: {
                                value: true
                            }
                        }
                    ))
                    // if(res.data.workDocs != undefined){
                    //     setWorkspacesExist(res.data.workDocs)
                    // }
                    // if(res.data.orgDocs != undefined){
                    //     setOrganizationsExist(res.data.orgDocs)
                    // }
                    internalAdminList();
                    formik.resetForm();
                    setActiveInactive(true)
                    setTimeout(() => {
                        showHideModel();
                    }, getHelpers.toastAutoClose());
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again..", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    showHideModel();
                }
            })
            .catch(error => {

                // if(error.response.data.workDocs != undefined){
                //     setWorkspacesExist(error.response.data.workDocs)
                // }
                // if(error.response.data.orgDocs != undefined){
                //     setOrganizationsExist(error.response.data.orgDocs)
                // }

                if(error.response){
                    if(error.response.data){
                        if(error.response.data.message){
                            toast.error(error.response.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            if(error.response.data.errors && error.response.data.errors.length > 0){
                                if(error.response.data.errors[0] && error.response.data.errors[0]['msg']){
                                    toast.error(error.response.data.errors[0]['msg'], {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }
                            } else {
                              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                  position: getHelpers.toastPosition(),
                                  autoClose: getHelpers.toastAutoClose(),
                                  theme: 'colored'
                              });
                            }
                        }
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
                setspinner(false)
                internalAdminList();
            })
    }


    const notify = () => {
        // console.log('formik.errors=',Object.values(formik.errors)[0])
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });

        }
    }


    useEffect(() => {
        // setOrganizationsExist([])
        // setWorkspacesExist([])
        internalRolePermissionList()
        internalAdminList()

    }, [getCount])

    return (
        <div>
            {
                _.includes(store.getState().userData.permissions,'manage_admins')?
                (
                    <button type="button" className="btn btn-primary btn-sm fw-bolder btn-z-1500 btn-primary customIconButton" onClick={() => { setShowModal(true) }}>
                        {/* <i className="bi bi-person-plus-fill btnIcon"></i> */}
                        <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                        </span>
                        <span className='btnTxt'>User</span>
                    </button>
                ):('')
            }
            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showModal}
                animation={false}
            >

                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        <div className='modal-content'>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                                <div className='modal-header bg-primary'>
                                    <h2 className='fw-bolder text-white'>Create User</h2>
                                    <div
                                        className='btn btn-icon btn-sm btn-active-icon-primary'
                                        data-kt-users-modal-action='close'
                                        onClick={() => { showHideModel() }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                </div>
                                <div className='modal-body scroll-y pb-2'>
                                    <ToastContainer />
                                    <div
                                        className='d-flex flex-column scroll-y me-n7 pe-7'
                                        id='kt_modal_add_user_scroll'
                                        data-kt-scroll='true'
                                        data-kt-scroll-activate='{default: false, lg: true}'
                                        data-kt-scroll-max-height='auto'
                                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                        data-kt-scroll-offset='300px'
                                    >

                                        <div className='row'>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'> Role </label>
                                                <select
                                                    {...formik.getFieldProps('role')}
                                                    name='role'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.role && formik.errors.role },
                                                        {
                                                            'is-valid': formik.touched.role && !formik.errors.role,
                                                        }
                                                    )}
                                                // value={formik.values.}
                                                >
                                                    <option value="">Select Role</option>
                                                    {internalRolesPermissionsListResult.map((role) => (
                                                        <option value={role._id}>{role.label.charAt(0).toUpperCase() + role.label.slice(1)}</option>
                                                    )
                                                    )}
                                                    {formik.touched.role && formik.errors.role && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert'>{formik.errors.role}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </select>

                                            </div>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Email</label>
                                                <input
                                                    placeholder='Email'
                                                    {...formik.getFieldProps('email')}
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.email && formik.errors.email },
                                                        {
                                                            'is-valid': formik.touched.email && !formik.errors.email,
                                                        }
                                                    )}
                                                    type='email'
                                                    name='email'
                                                    autoComplete='off'
                                                    disabled={formik.isSubmitting}
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className='fv-plugins-message-container'>
                                                        <span role='alert'>{formik.errors.email}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='fv-row mb-3'>
                                            <div className='d-flex flex-stack'>
                                                <div className='me-5'>
                                                    <p className='fs-6 fw-bolder text-gray-900 mb-0'>Invite User</p>
                                                    <p className="fs-7 fw-bolder text-muted "> By sending email notification </p>
                                                </div>
                                                <div className='form-check form-switch form-check-custom form-check-solid'>
                                                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                                        <span className='form-check-label text-muted me-2'>No</span>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            name='invite'
                                                            checked={activeInactive}
                                                            onClick={() =>
                                                                setActiveInactive(!activeInactive)
                                                            }
                                                        />
                                                        <span className='form-check-label text-muted'>Yes</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        {!activeInactive && (
                                            <div className='row'>
                                                <div className='col-sm-6 mb-7'>

                                                    <label className='fw-bolder fs-6 mb-2'>First Name </label>
                                                    <input
                                                        placeholder='First name'
                                                        {...formik.getFieldProps('first_name')}
                                                        type='text'
                                                        name='first_name'
                                                        className={clsx(
                                                            'form-control form-control-solid mb-3 mb-lg-0',
                                                            { 'is-invalid': formik.touched.first_name && formik.errors.first_name },
                                                            {
                                                                'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                                            }
                                                        )}
                                                        // onChange={handleFNameChange}
                                                        autoComplete='off'
                                                    />
                                                </div>
                                                <div className='col-sm-6 mb-7'>
                                                    <label className='fw-bolder fs-6 mb-2'>Last Name </label>
                                                    <input
                                                        placeholder='Last name'
                                                        {...formik.getFieldProps('last_name')}
                                                        type='text'
                                                        name='last_name'
                                                        className={clsx(
                                                            'form-control form-control-solid mb-3 mb-lg-0',
                                                            { 'is-invalid': formik.touched.last_name && formik.errors.last_name },
                                                            {
                                                                'is-valid': formik.touched.last_name && !formik.errors.last_name,
                                                            }
                                                        )}
                                                        // onChange={handleLNameChange}
                                                        autoComplete='off'
                                                    />
                                                </div>
                                                <div className='col-sm-6 mb-7'>
                                                    <label className='fw-bolder fs-6 mb-2'>Password </label>
                                                    <input
                                                        placeholder='Password'
                                                        {...formik.getFieldProps('password')}
                                                        type='password'
                                                        name='password'
                                                        className={clsx(
                                                            'form-control form-control-solid mb-3 mb-lg-0',
                                                            { 'is-invalid': formik.touched.password && formik.errors.password },
                                                            {
                                                                'is-valid': formik.touched.password && !formik.errors.password,
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                        disabled={formik.isSubmitting}
                                                    />
                                                    {formik.touched.password && formik.errors.password && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert'>{formik.errors.password}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-sm-6 mb-7'>
                                                    <label className='fw-bolder fs-6 mb-2'>Confirm Password </label>
                                                    <input
                                                        placeholder='Password'
                                                        {...formik.getFieldProps('confirm_password')}
                                                        type='password'
                                                        name='confirm_password'
                                                        className={clsx(
                                                            'form-control form-control-solid mb-3 mb-lg-0',
                                                            { 'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password },
                                                            {
                                                                'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                        disabled={formik.isSubmitting}
                                                    />
                                                    {formik.touched.confirm_password && formik.errors.confirm_password && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert'>{formik.errors.confirm_password}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-sm-12 mb-7'>
                                                    <label className='fw-bolder fs-6 mb-2'>Job Title </label>
                                                    <input
                                                        placeholder='Job Title'
                                                        {...formik.getFieldProps('job_title')}
                                                        type='text'
                                                        name='job_title'
                                                        className={clsx(
                                                            'form-control form-control-solid mb-3 mb-lg-0',
                                                            { 'is-invalid': formik.touched.job_title && formik.errors.job_title },
                                                            {
                                                                'is-valid': formik.touched.job_title && !formik.errors.job_title,
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                    />
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        onClick={() => notify()}
                                    >{!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => showHideModel()}
                                        className='btn btn-white me-3'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>

    )

}

// export { AddInternalUserModal }
export default connect(mapStateToProps)(AddInternalUserModal);