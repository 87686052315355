/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { WorkspaceUserEditModal } from './WorkspaceUserEditModel'
import AddDashboardModal from './AddDashboardModal'

import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { GdCheckDashboardModel } from './GdCheckDashboardModel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { DashboardCloneModal } from './DashboardCloneModal'
import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'

import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

type Props = {
  className: string;
  orgId: any
}
let workspaceDashboardsUrl: any;

const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`


interface PageProps {
  data: any,
  fetchWorkspacesList(): any
  setComponentLoading: any
}

const SwitcherStatus: React.FC<PageProps> = ({ data, fetchWorkspacesList, setComponentLoading }) => {
  let { organisationUsersId }: any = useParams();
  const [getUserStatus, setUserStatus] = useState(true)
  const [getStatusId, setStatusId] = useState<any>(0)

  const changeStatus = async (status: any, id: any) => {

    setStatusId(id)
    // setComponentLoading(true)
    // console.log('statusIndex=',statusIndex)

    var formData: any = {}
    formData.dashboard_id = data._id
    formData.status = status

    axios.put(process.env.REACT_APP_BASE_API_URL + '/allow-external/', formData)
      .then(async (res) => {
        // setspinner(false)
        // console.log('error1=',res.data)
        if (res.data.status) {
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });

          setUserStatus(getUserStatus)
          fetchWorkspacesList();

          setTimeout(() => {
            // setComponentLoading(false)
          }, 500);

        } else {

          if (res.data.errors && res.data.errors.length > 0) {
            if (res.data.errors[0] && res.data.errors[0]['msg']) {
              toast.error(res.data.errors[0]['msg'], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        }
      })
      .catch(error => {
        // console.log('error2=',error.response.data.errors.length,error.response.data.errors[0]['msg'])
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
            toast.error(error.response.data.errors[0]['msg'], {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        } else {
          if (error.response.data.message) {
            toast.error(error.response.data.message, {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        }
        // setspinner(false)
      })

  }

  useEffect(() => {
    // console.log('data=',data)
    if (data.allow_to_external) {
      setUserStatus(true)
    } else {
      setUserStatus(false)
    }

  }, [])

  return (
    <div style={{ 'position': 'relative' }}>
      {
        data._id == getStatusId ?
          (
            <>
              {/* <ComponentLoading></ComponentLoading> */}
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </>
          ) : (
            <div className='form-check form-switch form-check-custom form-check-solid'>
              <label className='form-check form-check-custom form-check-solid form-switch'>
                {/* <span className='form-check-label text-muted me-2'>No</span> */}
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='allow_to_external'
                  checked={getUserStatus}
                  onClick={() =>
                    changeStatus(!getUserStatus, data._id)
                  }
                />
                {/* <span className='form-check-label text-muted'>Yes</span> */}
              </label>
            </div>
          )
      }
    </div>
  )

}


const WorkspaceDashboardsList: React.FC<Props> = ({ className, orgId }) => {

  // import { json } from 'stream/consumers'
  let { workspaceId } = useParams();
  let { status } = useParams();
  // console.log("status=",status);
  workspaceId = orgId ? orgId : workspaceId
  if (status) {
    workspaceDashboardsUrl = `${API_URL}/internal-workspace/dashboards/` + workspaceId + '/trash'
  } else {
    workspaceDashboardsUrl = `${API_URL}/internal-workspace/dashboards/` + workspaceId + '/active'
  }
  const [WorkspaceDashboardsListState, setWorkspaceDashboardsListState] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false)
  const [hideShowGdStatusModel, sethideShowGdStatusModel] = useState(false)

  const [getComponentLoading, setComponentLoading] = useState(true)

  const [showEditUserModal, setshowEditUserModal]: any = useState(false)
  const [getDashboardObject, setDashboardObject] = useState<any>({});

  const showHideEditUserModal: any = () => {
    setshowEditUserModal(!setshowEditUserModal)
  }

  const showHideModel = () => {
    setShowModal(!setShowModal)
  }
  const hideshowGDStatusModelFunc = () => {
    sethideShowGdStatusModel(!hideShowGdStatusModel)
    // if(hideShowGdStatusModel)
    fetchWorkspacesList();
  }
  const fetchWorkspacesList = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const organizationsListList = axios.get(
      workspaceDashboardsUrl,
      config
    ).then(async (res: any) => {
      setComponentLoading(false)
      return setWorkspaceDashboardsListState(res.data)
    }
    ).catch((error) => {
      setComponentLoading(false)
    }
    );
    return organizationsListList

  }

  const setCopied = () => {
    toast.success('GD link copied', {
      position: getHelpers.toastPosition(),
      autoClose: getHelpers.toastAutoClose(),
      theme: 'colored'
    });
  }

  const deleteDashboard = async (dahboardId: any) => {
    Swal.fire({
      title: getHelpers.ConfirmArchiveMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Archive'
    }).then((result) => {
      if (result.isConfirmed) {
        setComponentLoading(true)
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/internal-dashboards/trash/' + dahboardId,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });

              // navigate('/internal/organizations');
              fetchWorkspacesList();
            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
            setComponentLoading(false)
          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
            setComponentLoading(false)
          });
      }
    })
  }


  function SearchInPut(props: any) {
    const { setGlobalFilter } = props
    const count = props.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(props.globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)

    return (
      <>
        <div className="border-0 pt-6 mb-5" >

          {
            getComponentLoading ?
              (
                <ComponentLoading></ComponentLoading>
              ) : ('')
          }
          <div className="card-title" ></div>
          <div className="card-toolbar">
            <div className="search_btn" data-kt-customer-table-toolbar="base" >
              {
                _.includes(store.getState().userData.permissions, 'manage_organizations_dashboards') ?
                  (
                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Check GD Status</Tooltip>}>
                      <div className='d-flex justify-content-center align-items-center' style={{ 'marginRight': '13px' }} onClick={() => sethideShowGdStatusModel(true)}  >
                        {/* //*/}
                        <i className="bi bi-info-circle fa-2x" style={{ cursor: 'pointer' }}></i>
                      </div>
                    </OverlayTrigger>
                  )
                  :
                  ('')
              }
              <div className="d-flex align-items-center position-relative my-1 mx-2" >
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-6'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='customTableSearch form-control form-control-solid w-250px ps-14'
                  placeholder='Search'
                  value={value || ""}
                  onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                />
              </div>
              <AddDashboardModal fetchWorkspacesList={fetchWorkspacesList} />

            </div>

          </div>
        </div>
      </>
    )
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length

    return (
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='customTableSearch form-control form-control-solid w-250px ps-14'
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }
  const setLocalStorage = (pageSize: any) => {
    // console.log('pageSize',pageSize)
    localStorage.setItem('pageSize', pageSize)
  }
  const formatAMPM = (date: any) => {
    let createFormat = new Date(date);
    let day = createFormat.getDate()
    let month = createFormat.getMonth() + 1
    let year = createFormat.getFullYear()
    var hours = createFormat.getHours();
    var minutes: any = createFormat.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = month + '/' + day + '/' + year + ' ' + hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  function WorkspaceDashboardListTable(props: any) {
    const { columns, data } = props
    const defaultColumn: any = React.useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,

    )

    // console.log("rows data", rows);
    let currentPageStartIndex: any = 2;
    let previousIndexPage: any = 0;

    return (
      <div>

        <SearchInPut
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        <div className='table-responsive'>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer dashboardsTableList" {...getTableProps()}>
            <thead>

              {headerGroups.map((headerGroup: any) => (
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th className={" "} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {/* {console.log('column=',column)} */}

                      {column.isSorted
                        ? column.isSortedDesc
                          ? (<span className='table-sort-asc'></span>)
                          : (<span className='table-sort-desc'></span>)
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='fw-bold' {...getTableBodyProps()}>
              {
                page.length > 0 ?
                  (
                    <>
                      {page.map((row: any, i: any) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                              return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                              </td>
                            })}
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                      </td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="dataTables_length" id="kt_customers_table_length">
              <select name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                value={pageSize}
                onChange={e => {
                  { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                }}
                style={{ width: '120px', height: '38px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div id="kt_table_users_paginate">
              <ul className="pagination">

                <li className="page-item" onClick={() => previousPage()} >
                  <a className="page-link prev-angle">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </li>

                {pageOptions.map((pageSize: any, rowIndex: any) => {

                  if (currentPageStartIndex <= rowIndex) {
                    currentPageStartIndex = currentPageStartIndex + 1
                    previousIndexPage = rowIndex - 1
                  }
                  return (

                    previousIndexPage <= currentPageStartIndex
                      ?
                      <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                        <a className="page-link">{pageSize + 1} </a>

                      </li>
                      :
                      null

                  )

                })}

                <li className="page-item" onClick={() => nextPage()} >
                  <a className="page-link next-angle">

                    <i className="fa fa-chevron-right"></i>
                  </a>
                </li>




              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }
  const chartFunc = (value: any) => {
    console.log('value=', value.charAt(0))
    let chatAt_value = value.charAt(0);
    return chatAt_value;
  }
  const columns: any = React.useMemo(
    () => [

      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const data = row.original

          return (
            <>
              {
                data.gd_dashboard ?
                  data.gd_dashboard[0] ?
                    (
                      <>
                        <Link className='fw-bolder text-dark' key={data.gd_dashboard[0]._id} to={'/dashboard/' + data._id}>
                          {data.name}
                        </Link>
                        <div>

                          {
                            data.gd_status == true
                              ?
                              // <div className='badge bg-danger color_2'>Synched with GD</div>
                              ('')
                              :
                              <>
                                {
                                  data.gd_status == false
                                    ?
                                    <div className='badge bg-danger color_2'>Not synched with GD</div>
                                    :
                                    null
                                }
                              </>
                          }
                        </div>
                      </>
                    ) :
                    (<Link to={'#'}>
                      {data.name}
                    </Link>)

                  : (
                    <Link to={'#'}>
                      {data.name}
                    </Link>
                  )
              }

            </>
          )
        }

      },

      {
        Header: 'Managed By',
        accessor: 'manage_by',
        Cell: ({ row }: any) => {
          const data = row.original
          if (data.createdBy.length == 0) {
            data.workspaceAdmins.push(data.createdBy);
          }
          const showTotals = 4
          const workspaceAdmins = getHelpers.AvatarsDataAdminUsers(showTotals, data.workspaceAdmins)['users']
          const totalUsers = getHelpers.AvatarsDataAdminUsers(showTotals, data.workspaceAdmins)['total']
          return (
            <>

              <div className="symbol-group symbol-hover flex-nowrap">

                {
                  workspaceAdmins.length > 0 ?
                    (
                      <>

                        {workspaceAdmins.map((user: any) =>
                          user ?
                            (
                              <>
                                {
                                  user._id ?
                                    (
                                      <>
                                        <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={user._id}>
                                          {
                                            user.avatar_id ?
                                              (
                                                <>
                                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{user.first_name ? (user.first_name + ' ' + user.last_name) : (user.email)}</Tooltip>}>
                                                    {
                                                      user.avatar_id.file ?
                                                        (
                                                          <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(user.first_name ? user.first_name : user.email) + " img-alt-35"} src={getUserInfo.domainUrl() + 'avatars/' + user.avatar_id.file.filename} alt={chartFunc(user.first_name ? user.first_name : user.email)} />
                                                          // <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/' + user.avatar_id.file.filename} />
                                                        ) : (
                                                          <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(data.full_name) + " img-alt-35"} src={getUserInfo.domainUrl() + 'avatars/default.png'} alt={chartFunc(data.full_name)} />
                                                          // <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/default.png'} />
                                                        )
                                                    }
                                                  </OverlayTrigger>
                                                </>
                                              ) : user.first_name && user.last_name ? (
                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{user.first_name ? (user.first_name + ' ' + user.last_name) : (user.email)}</Tooltip>}>
                                                  <span className={"symbol-label text-inverse-warning fw-bolder bg-" + user.first_name.charAt(0)}>
                                                    {user.first_name.charAt(0)}
                                                  </span>
                                                </OverlayTrigger>
                                              ) : (
                                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{user.first_name ? (user.first_name + ' ' + user.last_name) : (user.email)}</Tooltip>}>
                                                  <span className={"symbol-label text-inverse-warning fw-bolder bg-" + user.email.charAt(0)}>
                                                    {user.email.charAt(0)}
                                                  </span>
                                                </OverlayTrigger>
                                              )

                                          }
                                        </div>
                                      </>
                                    ) : ('')
                                }
                              </>
                            ) : ('')
                        )}

                        {
                          totalUsers > 0 ?
                            (
                              <>
                                <div className="symbol symbol-35px symbol-circle">
                                  <span className="symbol-label bg-primary text-inverse-dark fs-8 fw-bold" data-bs-toggle="tooltip" data-bs-trigger="hover" data-kt-initialized="1">+{totalUsers}</span>
                                </div>
                              </>
                            ) : ('')
                        }
                      </>
                    ) : ('')
                }
              </div>

            </>

          )
        }
      },
      {
        Header: 'Shared With',
        accessor: 'shared_with',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <div className="symbol-group symbol-hover flex-nowrap">
              <>
                {data.users ?
                  data.users[0] ?
                    (
                      data.users.map((user: any) => (
                        user.avatar_id && user.avatar_id[0] ?
                          (
                            <>
                              <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={user._id}>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{user.first_name ? (user.first_name + ' ' + user.last_name) : (user.email)}</Tooltip>}>
                                  {
                                    <img width="100%" className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-img-" + chartFunc(user.first_name ? user.first_name : user.email) + " img-alt-35"} src={getUserInfo.domainUrl() + 'avatars/' + user.avatar_id[0].file.filename} alt={chartFunc(user.first_name ? user.first_name : user.email)} />
                                    // <img alt="avatar" src={getUserInfo.domainUrl() + 'avatars/' + user.avatar_id[0].file.filename} />
                                  }
                                </OverlayTrigger>
                              </div>
                            </>
                          ) : user.first_name && user.last_name ? (
                            <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={user._id}>
                              <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{user.first_name} {user.last_name}</Tooltip>}>
                                <span className={"symbol-label text-inverse-warning fw-bolder bg-" + user.first_name.charAt(0)}>
                                  {user.first_name.charAt(0)}
                                </span>
                              </OverlayTrigger>
                            </div>
                          )
                            : (
                              user.email ?
                                (
                                  <div className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={user._id}>
                                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{user.email}</Tooltip>}>
                                      <span className={"symbol-label text-inverse-warning fw-bolder bg-" + user.email.charAt(0)}>
                                        {user.email.charAt(0)}
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                ) : (user.email)
                            )
                      ))
                    )
                    : ('N/A')
                  : ('N/A')
                }
              </>
            </div>
          )
        }
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: ({ row }: any) => {
          const data = row.original

          return (
            <>
              {
                data.gd_dashboard ?
                  data.gd_dashboard[0] ?
                    <>
                      <div>

                        {formatAMPM(data.gd_dashboard[0].updated)}
                      </div>
                      {/* <div>
                      {
                       data.timeZone  ?
                       data.timeZone :''
                      }
                    </div> */}
                    </>
                    :
                    'N/A'

                  : 'N/A'
              }

            </>
          )
        }

      },
      
      {
        Header: 'External Access?',
        accessor: 'external_access',
        Cell: ({ row, index }: any) => {
          const data = row.original
          console.log('index=', row['id'])
          const dataStatus = data.status == 'active' ? true : false
          return (
            <>

              {
                _.includes(store.getState().userData.permissions, 'manage_organizations_dashboards') ?
                  (
                    <SwitcherStatus data={data} fetchWorkspacesList={fetchWorkspacesList} setComponentLoading={setComponentLoading} ></SwitcherStatus>
                  ) : ('N/A')
              }

            </>
          )
        }

      },

      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const data = row.original
          // console.log('data=',data)
          return (
            <>
              {
                _.includes(store.getState().userData.permissions, 'manage_organizations_dashboards') ?
                  (
                    <>
                      {
                        data.gd_dashboard ?
                          data.gd_dashboard[0] ?
                            (
                              <>
                                <div className='customActionButtons'>
                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>View</Tooltip>}>
                                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={'/dashboard/' + data._id} >
                                      {/* <i className="bi bi-eye"></i> */}
                                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor" />
                                        <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor" />
                                      </svg>
                                      </span>
                                    </Link>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={'/dashboard/' + data._id + '/edit'} >
                                      {/* <i className="bi bi-pencil-square"></i> */}
                                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                                        <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                                        <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                                      </svg>
                                      </span>
                                    </Link>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Archive</Tooltip>}>
                                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { deleteDashboard(data._id) }} >
                                      {/* <i className="bi bi-trash"></i> */}
                                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                                      </svg>
                                      </span>
                                    </Link>
                                  </OverlayTrigger>
                                  {
                                    data.gd_status && _.includes(store.getState().userData.permissions, 'manage_workspaces')
                                      ?
                                      <>
                                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Copy GD Link</Tooltip>}>
                                          <CopyToClipboard text={`${process.env.REACT_APP_GD_BACKEND_URL}/dashboards/#/workspace/${data.gd_dashboard[0]['workspaceId']}/dashboard/${data.gd_dashboard[0]['identifier']}`} onCopy={() => setCopied()}>
                                            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'>GD</span>
                                          </CopyToClipboard>
                                        </OverlayTrigger>
                                      </>
                                      :
                                      <></>
                                  }
                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                                    <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' onClick={() => { setshowEditUserModal(true); setDashboardObject(data) }}>
                                      {/* <i className="bi bi-pencil-square"></i> */}
                                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.5" x="7" y="2" width="14" height="16" rx="3" fill="currentColor"/><rect x="3" y="6" width="14" height="16" rx="3" fill="currentColor"/></svg>
                                      </span>
                                    </a>
                                  </OverlayTrigger>
                                </div>


                              </>
                            ) :
                            (
                              <>
                                <div className='customActionButtons'>
                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>View</Tooltip>}>
                                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={'#'} >
                                      {/* <i className="bi bi-eye"></i> */}
                                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor" />
                                        <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor" />
                                      </svg>
                                      </span>
                                    </Link>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={'#'} >
                                      {/* <i className="bi bi-pencil-square"></i> */}
                                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                                        <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                                        <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                                      </svg>
                                      </span>
                                    </Link>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Archive</Tooltip>}>
                                    <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { deleteDashboard(data._id) }} >
                                      {/* <i className="bi bi-trash"></i> */}
                                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                                      </svg>
                                      </span>
                                    </Link>
                                  </OverlayTrigger>
                                </div>
                              </>
                            )
                          : (
                            <>
                              <div className='customActionButtons'>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>View</Tooltip>}>
                                  <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={'#'} >
                                    {/* <i className="bi bi-eye"></i> */}
                                    <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor" />
                                      <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor" />
                                    </svg>
                                    </span>
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                                  <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={'#'} >
                                    {/* <i className="bi bi-pencil-square"></i> */}
                                    <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor" />
                                      <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor" />
                                      <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor" />
                                    </svg>
                                    </span>
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Archive</Tooltip>}>
                                  <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"#"} onClick={() => { deleteDashboard(data._id) }}>
                                    {/* <i className="bi bi-trash"></i> */}
                                    <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                                      <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                                      <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                                    </svg>
                                    </span>
                                  </Link>
                                </OverlayTrigger>
                              </div>
                            </>
                          )
                      }
                    </>
                  ) : ('N/A')
              }
            </>
          )
        }

      },

    ],
    []
  )
  useEffect(() => {
    fetchWorkspacesList()
  }, [])

  const data: any = WorkspaceDashboardsListState && WorkspaceDashboardsListState.length > 0 ? WorkspaceDashboardsListState : []

  return (
    <>
    <ToastContainer></ToastContainer>
    <div className={`card ${className} card-border-radius`}>

      {
        getComponentLoading ?
          (
            <ComponentLoading></ComponentLoading>
          ) : ('')
      }

      <div>
        <Modal
          className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
          id='kt_inbox_compose'
          role='dialog'
          data-backdrop='false'
          aria-hidden='true'
          tabIndex='-1'
          show={showModal}
          animation={false}
        >

          <WorkspaceUserEditModal showHideModel={showHideModel} />
        </Modal>
      </div>

      {
        data.length == 0 ?
          (
            <div className='card-body pt-0 bg-white'>
              <div className='border-0 pt-6'>
                <div className='card-toolbar text-right'>
                  <AddDashboardModal fetchWorkspacesList={fetchWorkspacesList} />
                </div>
              </div>
            </div>
          ) : ('')
      }

      {data.length > 0 ?
        <>
          <div className="card-body pt-0 bg-white  min-height-400" >
            <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
              <WorkspaceDashboardListTable columns={columns} data={data} />
            </div>
          </div>
          <Modal
            className='modal-sticky custom_modal modal-sticky-lg modal-sticky-bottom-right '
            id='kt_inbox_compose'
            role='dialog'
            data-backdrop='false'
            aria-hidden='true'
            tabIndex='-1'
            show={hideShowGdStatusModel}
            animation={false}
          >
            <GdCheckDashboardModel hideShowGdStatusModel={hideshowGDStatusModelFunc} />
          </Modal>

          <DashboardCloneModal showEditUserModal={showEditUserModal} showHideEditUserModal={showHideEditUserModal} getDashboardObject={getDashboardObject} fetchWorkspacesList={fetchWorkspacesList} />

        </>
        :
        <div className='card'><div className='card-body text-center bg-light m-5 fs-5'>No data found</div></div>
      }

    </div>
    </>
  )
}

// export { WorkspaceDashboardsList }
export default connect(mapStateToProps)(WorkspaceDashboardsList);