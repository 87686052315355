import * as getUserInfo from '../../../app/modules/auth/GetUserInfo'
import * as _ from "lodash"
import axios from 'axios'

// console.log('hepler');
const themeSettings = () => {
    var themeData : any = localStorage.getItem('themeSettings');
    return JSON.parse(themeData)
}

const avatarDirectory = () => {
    return "avatars"
}

const ConfirmDeleteMsg = () => {
    return "Are you sure want to delete?"
}
const ConfirmArchiveMsg = () => {
    return "Are you sure want to archive?"
}
const ConfirmDeletePermanentlyMsg = () => {
    return "Are you sure want to delete permanently?"
}

const ConfirmDeleteIcon : any = () => {
    return "question"
}

const toastPosition : any = () => {
    return 'bottom-right'
}

const toastAutoClose : any = () => {
    return 1500
}

const NoDataFound = () => {
    return "<div class='card'><div class='card-body text-center bg-light m-5 fs-5'>No data found</div></div>"
}

const NoPermissions = () => {
    return "<div class='card mt-10'><div class='card-body text-center bg-light m-5 fs-5'>You don't have permission to access this page</div></div>"
}

const LogoLink = () => {
    if(getUserInfo.userType() === 'internal'){
        return '/internal/dashboard'
    } else {
        return '/overview'
    }
}

const AvatarsArray = (showCount:any,arrayData:any) => {
    // console.log('arrayData=',arrayData)
    const newArray : any = []
    arrayData.forEach((element:any) => {
        // console.log('element',element)
        if(element.user_id){
            if(element.user_id.role){
                newArray.push(element)
            }
        }
    });
    const getData = _.chunk(newArray, showCount)[0]?_.chunk(newArray, showCount)[0]:[]
    // console.log('newArray.length=',newArray.length)
    // console.log('getData.length=',getData.length)
    return {
        users: getData,
        total: newArray.length > showCount? newArray.length - getData.length:0
    };
}

const AvatarsDataArray = (showCount:any,arrayData:any) => {
    // console.log('arrayData=',showCount,arrayData)
    const newArray : any = []
    arrayData.forEach((element:any) => {
        // console.log('element',element)
        // if(element){
            if(element.role){
                newArray.push(element)
            }
        // }
    });
    const getData = _.chunk(newArray, showCount)[0]?_.chunk(newArray, showCount)[0]:[]
    // console.log('newArray.length=',newArray.length)
    // console.log('getData.length=',getData.length)
    return {
        users: getData,
        total: newArray.length > showCount? newArray.length - getData.length:0
    };
}

const AvatarsDataAdminUsers = (showCount:any,arrayData:any) => {
    // console.log('arrayData=',showCount,arrayData)
    const newArray : any = []
    arrayData.forEach((element:any) => {
        // console.log('element',element)
        // if(element){
            if(element._id){
                newArray.push(element)
            }
        // }
    });
    const getData = _.chunk(newArray, showCount)[0]?_.chunk(newArray, showCount)[0]:[]
    // console.log('newArray.length=',newArray.length)
    // console.log('getData.length=',getData.length)
    return {
        users: getData,
        total: newArray.length > showCount? newArray.length - getData.length:0
    };
}

const WorkspacesAvatarsDataArray = (showCount:any,arrayData:any) => {
    // console.log('arrayData=',showCount,arrayData)
    const newArray : any = []
    arrayData.forEach((element:any) => {
        // console.log('element',element)
        // if(element){
            // if(element.role){
                newArray.push(element)
            // }
        // }
    });
    const getData = _.chunk(newArray, showCount)[0]?_.chunk(newArray, showCount)[0]:[]
    // console.log('newArray.length=',newArray.length)
    // console.log('getData.length=',getData.length)
    return {
        users: getData,
        total: newArray.length > showCount? newArray.length - getData.length:0
    };
}

const findInArray = (data:any) => {
    const object : any = _.find(data,{name:'admin'})
    // console.log('object=',object)
    return object
}

const doesHasPermissions = (array:any,workspaceId:any) => {
    // console.log('array==',array)
    const object : any = _.find(array,{workspace_id:workspaceId})
    // console.log('object=',object)

    if(object != undefined){
        if(object.permissions != undefined){
            return object.permissions
        }
    }
    return []
}

const hasManageSettings = (array:any,permission:string) => {
    var access = false
    // console.log('array=',array)
    if(array.length > 0){
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            // console.log('element=',element)
            if(element.permissions != undefined){
                if(element.permissions.length > 0){
                    const data = _.includes(element.permissions,permission)
                    // console.log('data=',data)
                    if(data == true){
                        return true;
                    }
                    break;
                }
            }
        }
    }
    return access;
}

const hasAccessInAnyWorkspace = (array:any,permission:string) => {
    var access = false
    // console.log('array=',array)
    if(array.length > 0){
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            // console.log('element=',element)
            if(element.permissions != undefined){
                if(element.permissions.length > 0){
                    const data = _.includes(element.permissions,permission)
                    // console.log('data=',data)
                    if(data == true){
                        return true;
                    }
                    break;
                }
            }
        }
    }
    return access;
}

export {
    ConfirmDeleteMsg,
    ConfirmArchiveMsg,
    ConfirmDeletePermanentlyMsg,
    ConfirmDeleteIcon,
    NoDataFound,
    NoPermissions,
    LogoLink,
    AvatarsArray,
    AvatarsDataArray,
    WorkspacesAvatarsDataArray,
    toastPosition,
    toastAutoClose,
    AvatarsDataAdminUsers,
    findInArray,
    themeSettings,
    doesHasPermissions,
    hasManageSettings,
    hasAccessInAnyWorkspace,
}