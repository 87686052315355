import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/verify_token`
export const LOGIN_URL = `${API_URL}/v1/authentication/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`
export const SET_PASSWORD_URL = `${API_URL}/forgot-password`
export const INVITE_SET_PASSWORD_URL = `${API_URL}/invitation`

// Server should return AuthModel
// export function login(email: string, password: string) {
//   return axios.post<AuthModel>(LOGIN_URL, {
//     email,
//     password,
//   })
// }

export async function setPasswordRequest(reqBody:any,idAndToken:any) {
  try {
    let res = await axios({
      method: 'put',
      url: SET_PASSWORD_URL+idAndToken,
      data: reqBody
    });
    // console.log(res.data)
    // let data = res.data;
    return res;
  } catch (error:any) {
    // console.log(error.response); // this is the main part. Use the response property from the error object
    return error.response.data;
  }
}

export async function inviteSetPasswordRequest(reqBody:any,idAndToken:any) {
  try {
    let res = await axios({
      method: 'put',
      url: INVITE_SET_PASSWORD_URL+idAndToken,
      data: reqBody
    });
    // console.log(res.data)
    // let data = res.data;
    return res;
  } catch (error:any) {
    // console.log(error.response); // this is the main part. Use the response property from the error object
    return error.response.data;
  }
}

export async function login(reqBody:any) {
  try {
    let res = await axios({
      method: 'post',
      url: LOGIN_URL,
      data: reqBody
    });
    // console.log(res.data)
    // let data = res.data;
    return res;
  } catch (error:any) {
    // console.log(error.response); // this is the main part. Use the response property from the error object
    return error.response.data;
  }

}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
