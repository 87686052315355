/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC,useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import { Link } from "react-router-dom";
import InternalUsersTable from '../../pages/internal-users/InternalUsersTable'
import AddInternalUserModal from '../../pages/internal-users/AddInternalUserModal'
import { ToastContainer, toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL
const internalAdminUrl = `${API_URL}/internal/admins`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

const InternalUsersPage: FC = () => (
  <>
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-12'>
        <InternalUsersTable  className='card card-xxl-stretch mb-5 mb-xl-12 card-border-radius'  />
      </div>
    </div>
  </>
)

const InternalUsers: FC = () => {
  const intl = useIntl()
  return (
    <>
      <ToastContainer />
  
      {/* <PageTitle breadcrumbs={[]}>Internal Users  </PageTitle><br/> */}


      <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Internal Users</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted"> 
              <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
            </li>
            <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
            <li className="breadcrumb-item text-muted">Internal Users</li>
          </ul>
        </div>
        <div className="d-flex align-items-center gap-2 gap-lg-3">
        <AddInternalUserModal  />
        </div>
      </div>
        
      <InternalUsersPage   />
    </>
  )
}

export {InternalUsers}
