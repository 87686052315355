
import { FC, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { useParams, useNavigate } from "react-router-dom";
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../modules/auth/GetHelpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { initialUser, User } from './OrganizationValidationModal'
import clsx from 'clsx'
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToken = `Bearer ${ApiToken}`

interface ModalProps {
    fetchOrganizationContactsList(): any
}

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const OrganizationContactAddUserModal: FC<ModalProps> = ({ fetchOrganizationContactsList }) => {

    let { organizationId }: any = useParams();

    const [showModal, setShowModal] = useState(false)
    const [activeInactive, setActiveInactive] = useState(true)
    const [getfname, setfname] = useState('')
    const [getlname, setlname] = useState('')
    const [getspinner, setspinner] = useState(false)
    const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);
    const [getSelectedSpecialty, setSelectedSpecialty] = useState({})
    const [getSpecialties, setSpecialties] = useState<any[]>([]);

    const showHideModel: any = () => {
        setShowModal(!setShowModal)
    }
    const internalRolePermissionList = () => {
        // setActiveInactive(false)
        const internalRolesPermissionsUrl = `${API_URL}/roles-permissions`

        const config = {
            headers: { Authorization: BearerToken }
        };
        const internalRolesList = axios.get(
            internalRolesPermissionsUrl,
            config
        ).then(async (res) => {
            const { data: rolespermissionsDataResult } = res
            return await setinternalRolesPermissionsListResult(rolespermissionsDataResult)
        }
        ).catch(console.log);
        return internalRolesList

    }
    const handleFNameChange = (event: any) => {
        setfname(event.target.value);
    }
    const handleLNameChange = (event: any) => {
        setlname(event.target.value);
    }
    const handleRoleChange = (event: any) => {
        // formik.values.role = event.target.value;
    }
    const addOrgContactSchema = Yup.object().shape({
        email: Yup.string()
            .email('Wrong email format')
            .required('Email is required'),
        // password: Yup.string()
        //     .min(8, 'Minimum 8 characters')
        //     .max(50, 'Maximum 8 characters')
        //     .matches(
        //         /^(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        //         'Need one special character'
        //     )
        //     .required('Password is required'),
        // confirm_password: Yup.string()
        //     .min(8, 'Minimum 8 characters')
        //     .max(50, 'Maximum 8 characters')
        //     .required('Password is required')
        //     .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        phone_number: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 20 characters')
            // .required('First name is required') 
           ,
        job_title: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            // .required('First name is required') 
           ,
        first_name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('First name is required'),
        last_name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('Last name is required'),
        // role: Yup.string().required('Role is required'),
        // specialty_id: Yup.object().shape({
        //         label: Yup.string(),
        //         value: Yup.string(),
        //     })
        //     .required('Specialty is required'),
    })
    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            // refetch()
        }
        // setItemIdForUpdate(undefined)
    }
    const formik = useFormik({
        initialValues: {
            // role: '',
            first_name: '',
            last_name: '',
            phone_number: '',
            job_title: '',
            email: '',
            invite: '',
            specialty_id: []
            // password: '',
            // confirm_password: '',
        },
        validationSchema: addOrgContactSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {
                const formDataObj = {
                    email: values.email,
                    invite: activeInactive,
                    // role: values.role,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    job_title: values.job_title,
                    phone_number: values.phone_number,
                    specialty_id: getSelectedSpecialty,
                    // password: values.password
                }
                setspinner(true)
                saveOrgContact(formDataObj)
                // }
            } catch (ex) {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                console.error(ex)
            } finally {

                setSubmitting(true)
                cancel(true)
            }
        },
    })
    const saveOrgContact = (data: any) => {
        axios.post(process.env.REACT_APP_BASE_API_URL + '/internal/organizations/' + organizationId + '/save', data)
            .then((res) => {
                setspinner(false)
                // console.log('error1=',res.data)
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    
                    window.dispatchEvent(new CustomEvent('dispatchOrganization', {
                        detail: {
                            value: true,
                            data: res.data
                        }
                    }))
                    
                        setShowModal(false);
                        fetchOrganizationContactsList();
                } else {
                    
                    setShowModal(true);

                    if(res.data.errors && res.data.errors.length > 0){
                        if(res.data.errors[0] && res.data.errors[0]['msg']){
                            toast.error(res.data.errors[0]['msg'], {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            if(res.data.message){
                                toast.error(res.data.message, {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            }
                        }
                    } else {
                        if(res.data.message){
                            toast.error(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    }
                }
            })
            .catch(error => {
                
                setShowModal(true);

                // console.log('error2=',error.response.data.errors.length,error.response.data.errors[0]['msg'])
                if(error.response.data.errors && error.response.data.errors.length > 0){
                    if(error.response.data.errors[0] && error.response.data.errors[0]['msg']){
                        toast.error(error.response.data.errors[0]['msg'], {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        if(error.response.data.message){
                            toast.error(error.response.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    }
                } else {
                    if(error.response.data.message){
                        toast.error(error.response.data.message, {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                }
                setspinner(false)
            })
    }

    const notify = () => {
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    const getSpecialtiesData = () => {
        axios.get(process.env.REACT_APP_BASE_API_URL + 'internal/specialties/'+organizationId)
            .then(async (res) => {
                setspinner(false)
                setSpecialties(res.data)
                // loadData()
                // optionsOrganizations = res.data
            })
            .catch(error => {
            
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
        
                setspinner(false)
                // fetchorganizationsList();
            })
    }
    
    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        // console.group();
        // console.log('Value Changed=',JSON.stringify(newValue));
        // userForEdit.organization = newValue
        setSelectedSpecialty(newValue)
        // console.log('handleChange getSelectedSpecialty=',getSelectedSpecialty)
        // console.groupEnd();
    };

    useEffect(() => {
        getSpecialtiesData();
        internalRolePermissionList()
    }, [])
    return (
        <div className='btn_col'>

            {
                _.includes(store.getState().userData.permissions,'manage_organizations')?
                (
                    <button type="button" className="btn btn-primary btn-md fw-bolder btn-z-1500 btn-primary  my-1" onClick={() => { setShowModal(true) }}>
                        {/* <i className="bi bi-person-plus-fill"></i> */}
                        <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
<rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
<rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
</svg>
                          </span>
                        <span className='btnTxt'>Contact</span>
                    </button>
                ):('')
            }

            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showModal}
                animation={false}
            >
                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        {/* begin::Modal content */}
                        <div className='modal-content'>
                            {/* <UserEditModalHeader /> */}
                            <div className='modal-header bg-primary'>
                                <h2 className='fw-bolder text-white'>Add Contact</h2>
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => { showHideModel() }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                            </div>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                                <div className='modal-body scroll-yx pb-2'>
                                    <div
                                        className='d-flex flex-column scroll-yx me-n7 pe-7'
                                        id='kt_modal_add_user_scroll'
                                        data-kt-scroll='true'
                                        data-kt-scroll-activate='{default: false, lg: true}'
                                        data-kt-scroll-max-height='auto'
                                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                        data-kt-scroll-offset='300px'
                                    >
                                        <div className="row">
                                            <div className='col-sm-12 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Email</label>
                                                <input
                                                    placeholder='Email'
                                                    {...formik.getFieldProps('email')}
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.email && formik.errors.email },
                                                        {
                                                            'is-valid': formik.touched.email && !formik.errors.email,
                                                        }
                                                    )}
                                                    type='email'
                                                    name='email'
                                                    autoComplete='off'
                                                    disabled={formik.isSubmitting}
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className='fv-plugins-message-container'>
                                                        <span role='alert'>{formik.errors.email}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>First Name </label>
                                                <input
                                                    placeholder='First name'
                                                    type='text'
                                                    {...formik.getFieldProps('first_name')}
                                                    name='first_name'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.first_name && formik.errors.first_name },
                                                        {
                                                            'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                                        }
                                                    )}
                                                    // onChange={handleFNameChange}
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Last Name </label>
                                                <input
                                                    placeholder='Last name'
                                                    type='text'
                                                    {...formik.getFieldProps('last_name')}
                                                    name='last_name'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.last_name && formik.errors.last_name },
                                                        {
                                                            'is-valid': formik.touched.last_name && !formik.errors.last_name,
                                                        }
                                                    )}
                                                    // onChange={handleLNameChange}
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Contact Phone</label>
                                                <input
                                                    placeholder='Contact Phone'

                                                    type='text'
                                                    {...formik.getFieldProps('phone_number')}
                                                    name='phone_number'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.phone_number && formik.errors.phone_number },
                                                        {
                                                            'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                                                        }
                                                    )}
                                                    // onChange={handleFNameChange}
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <div className='col-sm-6 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Job Title</label>
                                                <input
                                                    placeholder='Job Title'

                                                    type='text'
                                                    {...formik.getFieldProps('job_title')}
                                                    name='job_title'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.job_title && formik.errors.job_title },
                                                        {
                                                            'is-valid': formik.touched.job_title && !formik.errors.job_title,
                                                        }
                                                    )}
                                                    // onChange={handleFNameChange}
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <div className='col-sm-12 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Specialty </label>
                                                <CreatableSelect
                                                    className='customSelectDropdown'
                                                    isMulti
                                                    isClearable
                                                    onChange={handleChange}
                                                    // onInputChange={handleInputChange}
                                                    options={getSpecialties}
                                                    name='specialty_id'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        onClick={() => notify()}
                                    >{!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button
                                        type='button' // {()=>{showHideModel(cancelModel)}}
                                        onClick={() => showHideModel()}
                                        className='btn btn-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                            {/* end::Modal body */}
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
            </Modal>
        </div>
    )
}

// export { OrganizationContactAddUserModal }
export default connect(mapStateToProps)(OrganizationContactAddUserModal);