/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { KTSVG } from '../../../_metronic/helpers'
import axios from 'axios'
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import { ComponentLoading } from '../../modules/ComponentLoading'
import { ExternalOrganizationEditModal } from './ExternalOrganizationEditModal';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

const OrganizationListUrl = `${API_URL}/external/organizations/list`
type Props = {
  className: string
}
let rowsData: any = [];
const ExternalOrganizationsList: React.FC<Props> = ({ className }) => {

  const [OrganizationList, setOrganizationList] = useState<any[]>([]);
  const [OrganizationListAll, setOrganizationListAll] = useState<any[]>([]);
  const [showHideModal, setshowHideModal] = useState(false)

  const [editorgData, seteditorgData] = useState<any>({});
  const [OrganizationId, setOrganizationId] = useState('')

  const [getComponentLoading, setComponentLoading] = useState(true)

  const navigate = useNavigate();

  const showHideModelFunction: any = () => {
    setshowHideModal(!setshowHideModal)
  }

  const editOrgModal: any = (data: any) => {
    seteditorgData(data);
  }

  const [isCheckAll, setIsCheckAll]: any = useState(false);
  const [isCheck, setIsCheck]: any = useState([]);
  const [listData, setListData]: any = useState([]);


  // const navigate = useNavigate();

  let inputArray: any = [];
  const fetchOrganizationsList: any = () => {

    let objSelect: any;
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const OrganizationListList = axios.get(
      OrganizationListUrl,
      config
    ).then(async (res: any) => {
      const { data: OrganizationListData } = res
      OrganizationListData.forEach((element: any) => {
        element[`checked`] = false;
        objSelect = { 'id': element._id, 'name': element._id }
        inputArray.push(objSelect);
      });
      setListData(inputArray)
      rowsData = OrganizationListData;
      await setOrganizationListAll(OrganizationListData)
      await setOrganizationList(OrganizationListData)
      setComponentLoading(false)
      return [OrganizationListAll, OrganizationList]
    }
    ).catch((error) => {
      setComponentLoading(false)
      console.log(error)
    });
    return OrganizationListList
  }

  function GlobalFilter(props: any) {
    const { setGlobalFilter } = props
    const count = props.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(props.globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)

    return (

      <div className="border-0 pt-6 mb-5">

        {/* <div className="componentLoading">
          <span style={{fontSize: "1.75rem", fontWeight:"600", fontFamily:"Averia Serif Libre"}}>stringo media</span>
          <span>Loading ...</span>
        </div> */}

        <div className="card-title" ></div>
        <div className="card-toolbar" >
          <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base" >
            <div className="d-flex align-items-center position-relative my-1" >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={value || ""}
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />

            </div>
          </div>

        </div>
      </div>


    )
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length

    return (
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='customTableSearch form-control form-control-solid w-250px ps-14'
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  function Table(props: any) {
    const { columns, data } = props

    const defaultColumn: any = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
    const setLocalStorage = (pageSize: any) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem('pageSize', pageSize)
    }
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
    )

    let currentPageStartIndex: any = 2;
    let previousIndexPage: any = 0;

    return (
      <div>

        <ToastContainer />

        {
          getComponentLoading ?
            (
              <ComponentLoading></ComponentLoading>
            ) : ('')
        }

        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        <div className='table-responsive'>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer organizationTableList" {...getTableProps()}>
            <thead>

              {headerGroups.map((headerGroup: any) => (
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th className={" "} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {/* {console.log('column=',column)} */}

                      {column.isSorted
                        ? column.isSortedDesc
                          ? (<span className='table-sort-asc'></span>)
                          : (<span className='table-sort-desc'></span>)
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='fw-bold' {...getTableBodyProps()}>
              {
                page.length > 0?
                (
                  <>
                  {page.map((row: any, i: any) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                          return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                          </td>
                        })}
                      </tr>
                    )
                  })}
                  </>
                ):(
                  <tr>
                    <td colSpan={5}>
                      <div className='card-body text-center bg-light tableDataNotFound'>No data found</div>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="dataTables_length" id="kt_customers_table_length">
              <select name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                value={pageSize}
                onChange={e => {
                  { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                }}
                style={{ width: '120px', height: '38px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div id="kt_table_users_paginate">
              <ul className="pagination">
                {/* <li className="page-item" onClick={() => gotoPage(0)} >
                  <a className="page-link">First</a>
                </li> */}
                <li className="page-item" onClick={() => previousPage()} >
                  <a className="page-link prev-angle">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </li>
                {/* <div>{pageIndex}{pageSize}</div> */}
                {/* if ($x != 0 && $x % 3 == 0 && $x < count($rows)) */}
                {pageOptions.map((pageSize: any, rowIndex: any) => {
                  if (currentPageStartIndex <= rowIndex) {
                    currentPageStartIndex = currentPageStartIndex + 1
                    previousIndexPage = rowIndex - 1
                  }
                  return (
                    previousIndexPage <= currentPageStartIndex
                      ?
                      <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                        <a className="page-link">{pageSize + 1} </a>
                        {/* {pageIndex + 1} */}
                      </li>
                      :
                      null
                  )

                })}

                <li className="page-item" onClick={() => nextPage()} >
                  <a className="page-link next-angle">
                    {/* {'Next »'} */}
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }

  useEffect(() => {

    fetchOrganizationsList()

    window.addEventListener('organizationListRefresh', (event:any) => {
      if (event['detail'].value) {
        fetchOrganizationsList()
      }
    })

  }, [])



  async function handleSelectAll(e: any) {
    let trueFalse: any = !e.target.checked;
    rowsData.forEach((element: any) => {
      element[`checked`] = !element.checked;
    });

    await setListData(rowsData);
    await setOrganizationListAll(rowsData)
    await setOrganizationList(rowsData)

    await setIsCheckAll(trueFalse);
  };

  const handleClick = (e: any) => {
  };
  const columns: any = React.useMemo(
    () => [

      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              <div className='d-flex align-items-center'>
              {
                data.avatar_id ?
                    (
                        data.avatar_id.file?
                        (
                            <>
                            <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                                <img src={getUserInfo.domainUrl()+data.avatar_id.file_path} alt='Avatar' />
                            </div>
                            </>
                        ):(
                            data.name ?
                            (
                                <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                                    <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                                        {data.name.charAt(0)}
                                    </span>
                                </div>
                            ):('N/A')
                        )
                    ) : data.name ? (
                        <div className="symbol symbol-35px symbol-circle me-3 text-uppercase">
                            <span className={'symbol-label text-inverse-warning fw-bolder bg-' + data.name.charAt(0)}>
                                {data.name.charAt(0)}
                            </span>
                        </div>
                    ):('N/A')
                }
                <div className='d-flex flex-column'>
                  <span key={data._id} className="text-capitalize fw-bolder " >{data.name}</span>
                </div>
              </div>
            </>
          )
        }
      },

      
      {
        Header: 'Industry',
        accessor: 'industry_id',
        disableSortBy: false,
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                data.industry_id?
                (
                  data.industry_id.name?
                  (
                    <span className="text-capitalize badge m-1 bg-info">{data.industry_id.name}</span>
                  ):('N/A')
                ):('N/A')
              }
            </>
          )
        }
      },
      
      {
        Header: 'Workspaces',
        accessor: 'workspaces',
        disableSortBy: false,
        Cell: ({ row }: any) => {
          const data = row.original
          const workspaces = data.workspaces && data.workspaces.length > 0?data.workspaces:[];
          
          const showTotals = 5
          const org_data = getHelpers.WorkspacesAvatarsDataArray(showTotals, data.workspaces)['users']
          const totalUsers = getHelpers.WorkspacesAvatarsDataArray(showTotals, data.workspaces)['total']
          // console.log('org_data=',org_data)
          // console.log('totalUsers=',totalUsers)
          return (
            <div className="symbol-group symbol-hover flex-nowrap">
              {
                org_data.length > 0 ?
                  (
                    <>

                      {org_data.map((item: any) =>
                        (
                          item?
                          (
                            <Link to={"/workspace/" + item._id} className="symbol symbol-35px symbol-circle text-uppercase" data-bs-toggle="tooltip" data-kt-initialized="1" key={item._id}>
                              {
                                item.avatar_id?
                                (
                                  item.avatar_id.file?
                                  (
                                      <>
                                      <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{item.name ? (item.name) : ('N/A')}</Tooltip>}>
                                          <img src={getUserInfo.domainUrl()+item.avatar_id.file_path} alt='Avatar' />
                                      </OverlayTrigger>
                                      </>
                                  ):(
                                      item.name ?
                                      (
                                        <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{item.name ? (item.name) : ('N/A')}</Tooltip>}>
                                              <span className={'symbol-label text-inverse-warning fw-bolder bg-' + item.name.charAt(0)}>
                                                  {item.name.charAt(0)}
                                              </span>
                                        </OverlayTrigger>
                                      ):('N/A')
                                  )
                                ):(
                                  item.name?
                                  (
                                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>{item.name ? (item.name) : ('N/A')}</Tooltip>}>
                                      <span className={"symbol-label text-inverse-warning fw-bolder bg-" + item.name.charAt(0)}>
                                        {item.name.charAt(0)}
                                      </span>
                                    </OverlayTrigger>
                                  ):('N/A')
                                )
                              }
                            </Link>
                          ):('')
                        )
                      )}

                      {
                        totalUsers > 0 ?
                          (
                            <div className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                              <span className="symbol-label bg-primary text-inverse-dark fs-8 fw-bold" data-bs-toggle="tooltip" data-bs-trigger="hover" data-kt-initialized="1">+{totalUsers}</span>
                            </div>
                          ) : ('')
                      }
                    </>
                  ) : ('N/A')
              }
            </div>

          )
        }
      },

      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {
                data.status == 'active' ?
                  (
                    <span key={data._id} className="text-capitalize badge-success badge " >{data.status}</span>
                  ) : (
                    <span key={data._id} className="text-capitalize badge-danger badge " >{data.status}</span>
                  )
              }
            </>
          )
        }
      },

      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const data = row.original
          const org_id: any = data._id;
          return (
            <>
              <div className='customActionButtons'>
                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Basic Details</Tooltip>}>
                  <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/organizations/"+org_id}><i className="bi bi-eye"></i></Link>
                </OverlayTrigger>
                {
                  _.includes(store.getState().userExternalData.externalPermissions,'manage_organizations')?
                  (
                    <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Edit</Tooltip>}>
                      <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/organizations/"+org_id+"/edit"}><i className="bi bi-pencil-square"></i></Link>
                    </OverlayTrigger>
                  ):('')
                }
                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Manage Contacts</Tooltip>}>
                  <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1' to={"/organizations/"+org_id+"/contacts"}><i className="bi bi-people"></i></Link>
                </OverlayTrigger>
              </div>
            </>
          )
        }
      },
    ],
    []
  )

  const data: any = OrganizationList

  return (
    <div className={`card ${className} card-border-radius min-height-400 `}>
      <div className="card-body pt-0 bg-white" >
        <div className="dataTables_wrapper dt-bootstrap4 no-footer" >
          <Table columns={columns} data={data} />
        </div>
      </div>
      <ExternalOrganizationEditModal OrganizationId={OrganizationId} fetchOrganizationsList={fetchOrganizationsList} showHideModal={showHideModal} showHideModelFunction={showHideModelFunction} editorgData={editorgData} />
    </div>
  )
}

export { ExternalOrganizationsList }