
import { FC, useState, useEffect } from 'react'


import { Modal } from 'react-bootstrap'

import axios from 'axios'


import { useParams, useNavigate } from "react-router-dom";

import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

import * as Yup from 'yup'
import { useFormik } from 'formik'


import clsx from 'clsx'
// import { useListView } from '../../modules/apps/user-management/users-list/core/ListViewProvider'
// import { useQueryResponse } from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';

const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')

type props = {
    industriesListData():any
}

const IndustriesModal: FC<props> = ({industriesListData}) => {

    const [showModal, setShowModal] = useState(false)
    // const { setItemIdForUpdate } = useListView()
    // const { refetch } = useQueryResponse()
    const [getspinner, setspinner] = useState(false)

    const showHideModel: any = () => {
        setShowModal(!setShowModal)
    }

    const formSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('Industry Name is required')
    })

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: formSchema,
        onSubmit: async (values, { setSubmitting }) => {
            // console.log("values", values)
            setSubmitting(true)
            try {
                const formDataObj = {
                    name: values.name,
                }
                setspinner(true)
                saveFormData(formDataObj)
            } catch (ex) {
                toast.error("Sorry,looks like there are some errors detected!.Please try again.", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                console.error(ex)
            } finally {

                setSubmitting(true)
                cancel(true)
            }
        },
    })

    const saveFormData = (data: any) => {
        axios.post(process.env.REACT_APP_BASE_API_URL + 'internal/industries', data)
            .then((res) => {
                setspinner(false)
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                        showHideModel();
                        industriesListData();
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again..", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    showHideModel();
                }
            })
            .catch(error => {
                toast.error("Sorry,looks like there are some errors detected!.Please try again...", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)
            })
    }

    const notify = () => {
        // console.log('formik.errors=',formik.errors)
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            // refetch()
        }
        // setItemIdForUpdate(undefined)
    }

    useEffect(() => {
    }, [])

    return (
        <div>
        <ToastContainer />
            <button type="button" className="btn btn-primary btn-sm fw-bolder btn-z-1500 btn-primary customIconButton" onClick={() => { setShowModal(true) }}>
                {/* <i className="bi bi-person-plus-fill btnIcon"></i> */}
                <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
<rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
<rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
</svg>
                </span>
                <span className='btnTxt'>Industry</span> 
            </button>
            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showModal}
                animation={false}
            >
                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        <div className='modal-content'>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                                <div className='modal-header bg-primary'>
                                    <h2 className='fw-bolder text-white'>Add Industry</h2>
                                    <div
                                        className='btn btn-icon btn-sm btn-active-icon-primary'
                                        data-kt-users-modal-action='close'
                                        onClick={() => { showHideModel() }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                </div>
                                <div className='modal-body scroll-y pb-2'>
                                    <div
                                        className='d-flex flex-column scroll-y me-n7 pe-7'
                                        id='kt_modal_add_user_scroll'
                                        data-kt-scroll='true'
                                        data-kt-scroll-activate='{default: false, lg: true}'
                                        data-kt-scroll-max-height='auto'
                                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                        data-kt-scroll-offset='300px'
                                    >
                                        <div className='row'>
                                            <div className='col-sm-12 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Name </label>
                                                <input
                                                    {...formik.getFieldProps('name')}
                                                    type='text'
                                                    name='name'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                                        {
                                                            'is-valid': formik.touched.name && !formik.errors.name,
                                                        }
                                                    )}
                                                    autoComplete='off'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        onClick={() => notify()}
                                    >{!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => showHideModel()}
                                        className='btn btn-white me-3'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export { IndustriesModal }