/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { FC, useState, useEffect } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { TopbarDashboard } from './TopbarDashboard'
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import axios from 'axios'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../../redux/actions/externalPermissions';
import { ActionRoles } from '../../../../redux/actions/roles';
import { store } from '../../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state: any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

const HeaderWrapperDashboard: FC = () => {
  // export function HeaderWrapperDashboard() {
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config

  const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
  const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
  const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);

  let params: any = useParams();
  const dashboardID = params.dashboardId;
  const [getWorkspaceId, setWorkspaceId] = useState('')
  const [getDashboard, setDashboard] = useState<any>({})
  const [editViewMode, iseditViewMode] = useState<boolean>(false);
  const [getDashboards, setdashboards] = useState<any[]>([]);

  const [showInputDashboardNameField, setShowInputDashboardNameField] = useState(false);

  const dispatch = useDispatch();

  const fetchVerifyAccess = async () => {
    const API_URL = process.env.REACT_APP_API_URL
    const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
    const ApiToken = localStorage.getItem('api_token')
    const BearerToken = `Bearer ${ApiToken}`
    const config = {
      headers: { Authorization: BearerToken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.internalPermissions) {
            if (res.data.internalPermissions.permissions) {
              dispatch(ActionExternalPermissions.externalUserPermissions(res.data.externalPermissions.permissions))
              dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
              setUserInternalPermissions(res.data.internalPermissions.permissions)
              setUserExternalPermissions(res.data.externalPermissions.permissions)
              // setKey(getKey+1)
            }
          }
          if (res.data.internalRoles) {
            if (res.data.internalRoles.roles) {
              dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
              setUserInternalRoles(res.data.internalRoles.roles)
              // setKey(getKey+1)
            }
          }
        }
      })
      .catch((error) => { });
  }

  const getDashboardById = async () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    await axios.get(
      process.env.REACT_APP_API_URL + '/dashboard/' + dashboardID,
      config
    )
      .then(async (res) => {
        setWorkspaceId(res.data.workspace_id)
        setdashboards(res.data.name)
        setDashboard(res.data)
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const getCurrentUserProfile = async () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    await axios.get(
      process.env.REACT_APP_API_URL + '/gd-current-user',
      config
    )
      .then(async (res) => {
        console.log('profile=',res.data)
      })
      .catch((error) => {
        console.log(error)
      });
  }

  window.addEventListener('hideshowDashboardEditIcon', (event: any) => {
    // console.log('check', event['detail'].toEdit);
    if (!event['detail'].toEdit) {
      iseditViewMode(event['detail'].toEdit);
      // console.log('editViewModeedit', editViewMode);
    } else {
      iseditViewMode(event['detail'].toEdit)
      // console.log('editViewModeView', editViewMode);
    }
  })
  useEffect(() => {
    fetchVerifyAccess()
    getDashboardById()
    let paramData = params['*']
    if (!paramData.includes("edit")) {
      iseditViewMode(false)
    } else {
      iseditViewMode(true)
    }

  }, []);
  const editDashboardName = () => {
    setShowInputDashboardNameField(true);
  }
  const renameDashboard = (e: any) => {
    setdashboards(e.target.value);
  }
  const saveDashboardName = () => {
    setShowInputDashboardNameField(false);
    let formData = {
      name: getDashboards
    }
    axios.post(
      process.env.REACT_APP_BASE_API_URL + 'gd/dashboard/save/' + dashboardID, formData
    ).then(async (response) => {
      if (response.data) {
        if (response.data.status) {
          window.dispatchEvent(new CustomEvent(
            'newDashboardName',
            {
              detail: {
                name: response.data.name
              }
            }
          ))
        }
      }
    })
      .catch((error) => {
        console.log(error)
      });

  }
  return (
    <>
      <div
        id='kt_header'
        className={clsx('header', classes.header.join(' '), 'dashboardTopHeader align-items-stretch')}
        {...attributes.headerMenu}
      >
        <div
          className={clsx(
            classes.headerContainer.join(' '),
            'd-flex align-items-stretch justify-content-between'
          )}
        >
          {!aside.display && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
              <Link to='/dashboard' className='d-lg-none'>
                {/* <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2.svg')} className='h-30px' /> */}
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/favicon.svg')} className='h-30px' />
              </Link>
            </div>
          )}
          {/* end::Logo */}

          {aside.display && (
            <>
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                {/* Start */}
                {
                  getUserInfo.userType() == 'internal' ?
                    (
                      <a
                        href={"#/internal/workspace/" + getWorkspaceId + "/dashboards"}
                        className='btn btn-sm btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_create_app'
                        id='kt_toolbar_primary_button'
                      >
                        {/* <i className="bi bi-arrow-left mt--1 me-1"></i> */}
                        <span className="svg-icon svg-icon-muted svg-icon-18">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z" fill="currentColor" />
                            <path opacity="0.3" d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z" fill="currentColor" />
                          </svg>
                        </span>
                        <span className='fw-bolder'>Back</span>
                      </a>
                    ) :
                    ('')
                }
                {
                  getUserInfo.userType() == 'external' ?
                    (
                      <a
                        href={"/"}
                        className='btn btn-sm btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_create_app'
                        id='kt_toolbar_primary_button'
                      >
                        {/* <i className="bi bi-arrow-left mt--1 me-1"></i> */}
                        <span className="svg-icon svg-icon-muted svg-icon-18">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z" fill="currentColor" />
                            <path opacity="0.3" d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z" fill="currentColor" />
                          </svg>
                        </span>
                        <span className='fw-bolder'>Back</span>
                      </a>
                    ) :
                    ('')
                }
                <div className='ml-10 fs-3 dashboardTitleInTopbar'>
                  {/* <DashboardTitle />
                   */}
                  {
                    showInputDashboardNameField
                      ?
                      <>
                        <div className='' >
                          <input
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            name='dashboardName'
                            type='text'
                            placeholder='Enter dashboard name'
                            onInput={(event) => renameDashboard(event)}
                            value={getDashboards}
                          />

                        </div>
                      </>
                      :
                      <span className='fw-bolder' onClick={getCurrentUserProfile}>{getDashboards}</span>
                  }

                </div>
                {
                  editViewMode == true
                    ?
                    (
                      showInputDashboardNameField == true
                        ? (
                          getDashboards.length > 0
                            ?
                            <span className='edit-dashboard-icon' onClick={saveDashboardName}>
                              <span className="svg-icon svg-icon-primary svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <polygon points="0 0 24 0 24 24 0 24" />
                                  <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) " />
                                </g>
                              </svg></span>
                            </span>
                            :
                            null
                        )
                        :
                        <span className='edit-dashboard-icon' onClick={editDashboardName}>

                          <span className="svg-icon svg-icon-muted svg-icon-1hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />
                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />
                          </svg>
                          </span>
                        </span>
                    )
                    :
                    ('')
                }
              </div>

            </>

          )}

          {/* begin::Wrapper */}
          <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
            {/* begin::Navbar */}
            {header.left === 'menu' && (
              <div className='d-flex align-items-stretch' id='kt_header_nav'>
                <Header />
              </div>
            )}

            {header.left === 'page-title' && (
              <div className='d-flex align-items-center' id='kt_header_nav'>
                <div className='ml-10 fs-3'>
                  {/* <DashboardTitle /> */}
                  <span className='fw-bolder'>{getDashboards}</span>
                </div>
              </div>
            )}

            <div className='d-flex align-items-stretch flex-shrink-0'>

              {
                getUserInfo.userType() == 'internal' ?
                  <>
                    {
                      _.includes(store.getState().userData.permissions, 'manage_organizations_dashboards') ||
                        _.includes(store.getState().userExternalData.externalPermissions, 'manage_organizations_dashboards') ?
                        (
                          <TopbarDashboard />
                        ) : ('')
                    }
                  </>
                  :
                  <TopbarDashboard />
              }

            </div>
          </div>
          {/* end::Wrapper */}
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(HeaderWrapperDashboard);