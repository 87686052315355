/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import ExternalCreateDashboard from '../../pages/customer-organizations/ExternalCreateDashboard'
import axios from 'axios'
import * as getUserInfo from '../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../redux/actions/externalPermissions';
import { ActionExternalWorkspacePermissions } from '../../../redux/actions/externalWorkspacePermissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`


const ExternalDashboardCreateWrapper: FC = () => {

  const [workspaceData, setworkspaceData]: any = useState<any[]>([]);
  const [WorkspaceUsersData, setWorkspaceUsersData]: any = useState<any[]>([]);
  const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
  const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
  const [getExternalWorkspacePermissions, setUserAllExternalPermissions] = useState<any[]>([]);
  const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);
  const [getKey, setKey] = useState<any>(0);

  const { workspaceId } = useParams();
  const [getComponentLoading, setComponentLoading] = useState(true)
  const navigate = useNavigate();
  const fetchorganizationsUsersList: any = () => {
    const OrgUsersURL = `${API_URL}/internal/org/` + workspaceId
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const organizationsList = axios.get(
      OrgUsersURL,
      config
    ).then(async (res: any) => {
      await setWorkspaceUsersData(res.data.users);
      await setworkspaceData(res.data.organisation)

      setComponentLoading(false)

      return [setWorkspaceUsersData, setworkspaceData];


    }
    ).catch(console.log);
    return organizationsList

  }
  

  const dispatch = useDispatch();
  
  const fetchVerifyAccess = async () => {
    const API_URL = process.env.REACT_APP_API_URL
    const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
    const ApiToken = localStorage.getItem('api_token')
    const BearerToken = `Bearer ${ApiToken}`
    const config = {
      headers: { Authorization: BearerToken }
    };
    axios.get(
        GET_USER_BY_ACCESSTOKEN_URL,
        config
    )
      .then(async (res) => {
        if (res.data.status) {
          if(res.data.internalPermissions){
            if(res.data.internalPermissions.permissions){
              dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
              setUserInternalPermissions(res.data.internalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.externalPermissions){
            // console.log('res.data.externalPermissions=',res.data.externalPermissions)
            if(res.data.externalPermissions.permissions){
              // getWPermissions = res.data.externalPermissions.permissions
              var data = getHelpers.doesHasPermissions(res.data.externalPermissions.permissions,workspaceId)
              dispatch(ActionExternalPermissions.externalUserPermissions(data))
              dispatch(ActionExternalWorkspacePermissions.externalWorkspacePermissions(res.data.externalPermissions.permissions))
              setUserExternalPermissions(data)
              setUserAllExternalPermissions(res.data.externalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.internalRoles){
            if(res.data.internalRoles.roles){
              dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
              setUserInternalRoles(res.data.internalRoles.roles)
              setKey(getKey+1)
            }
          }
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchVerifyAccess()
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {

            // navigate('/error/404')
          } else {
            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {
          } else {
            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        console.clear()
        navigate('/error/404')
      });
    // fetchorganizationsUsersList()
  }, [])

  return (
    <>

    {
      // _.includes(store.getState().userExternalData.externalPermissions,'manage_organizations_dashboards')?
      // (
        <>
          <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">

                {/* {workspaceData.name}  */}
                Workspace</h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                <Link to={'/'} className="text-muted text-hover-primary">Home</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">Create Dashboard</li>
              </ul>
            </div>
          </div>

          <div className='row gy-5 g-xl-8'>
            <div className='col-xxl-12'>
              <ExternalCreateDashboard className='card-xxl-stretch mb-5 mb-xl-12' />
            </div>
          </div>
        </>
      // ):(
      //   <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
      // )
    }

    </>
  )
}

// export { ExternalDashboardCreateWrapper }
export default connect(mapStateToProps)(ExternalDashboardCreateWrapper);