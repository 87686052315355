/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios'
import Dropdown from 'react-bootstrap/Dropdown';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTable, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../modules/auth/GetHelpers'
import WorkspaceAddUserModal from './WorkspaceAddUserModal'
import { KTSVG } from '../../../_metronic/helpers'
import WorkspaceHeaderSection from './WorkspaceHeaderSection';
import { useLocation } from 'react-router'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const WorkspacesBasicDetailPage: FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [workspaceData, setworkspaceData]: any = useState<any[]>([]);
  const [workspaceUsersData, setworkspaceUsersData]: any = useState<any[]>([]);
  const [getDashboardsCount, setDashboardsCount]: any = useState<any>(0);
  const [getspinner, setspinner] = useState(false)
  const [roleEditProps, setroleEditProps] = useState<any[]>([]);
  const [showEditUserModal, setshowEditUserModal]: any = useState(false)
  const [showHidePasswordModal, setshowHidePasswordModal]: any = useState(false)
  const [workspaceUserId, setworkspaceUserId]: any = useState('');
  const [tabDashboar, settabDashboar] = useState(false)
  const [rowprops, setrowprops] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(false)
  const location = useLocation();

  const showHideModel: any = () => {
    setShowModal(!setShowModal)
  }

  const showHideEditUserModal: any = () => {
    setshowEditUserModal(!setshowEditUserModal)
  }

  const showHideSetPasswordModal: any = () => {
    setshowHidePasswordModal(!setshowHidePasswordModal)
  }

  const rowPropData: any = (rowData: any) => {
    setrowprops(rowData);
  }

  const navigate = useNavigate();
  let { organisationUsersId }: any = useParams();

  const WorkspaceUsersURL = `${API_URL}/internal/workspace/` + organisationUsersId

  const fetchWorkspaceUsersList: any = () => {
    setComponentLoading(true)
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const organizationsList = axios.get(
      WorkspaceUsersURL,
      config
    ).then(async (res: any) => {
      await setDashboardsCount(res.data.dashboardsCount);
      await setworkspaceUsersData(res.data.users);
      await setworkspaceData(res.data.workspace)

      setComponentLoading(false)
      return [setworkspaceUsersData, setworkspaceData];


    }
    ).catch(console.log);
    return organizationsList

  }

  function SearchInPut(props: any) {
    const { setGlobalFilter } = props
    const count = props.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(props.globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
    return (

      <div className="border-0 pt-6 mb-5" >

        {
          getComponentLoading ?
            (
              <ComponentLoading></ComponentLoading>
            ) : ('')
        }
        <div className="card-title" ></div>
        <div className="card-toolbar">
          <div className="search_btn" data-kt-customer-table-toolbar="base" >
            <div className="d-flex align-items-center position-relative my-1 mx-2" >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='customTableSearch form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={value || ""}
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />
            </div>
            <WorkspaceAddUserModal fetchWorkspaceUsersList={fetchWorkspaceUsersList} />

          </div>

        </div>
      </div>
    )
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length

    return (
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='customTableSearch form-control form-control-solid w-250px ps-14'
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  function WorkspaceUsersTable(props: any) {
    const { columns, data } = props

    const defaultColumn: any = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
    const setLocalStorage = (pageSize: any) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem('pageSize', pageSize)
    }
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    }: any = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : 10 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,

    )

    // console.log("rows data", rows);

    return (
      <div>
        <SearchInPut
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className='table-responsive'>
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer organisationUsersTableList " {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th className="" {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (<span className='table-sort-asc'></span>)
                          : (<span className='table-sort-desc'></span>)
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='fw-bold' {...getTableBodyProps()}>
              {page.map((row: any, i: any) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return <td  {...cell.getCellProps()}>{cell.render('Cell')}
                      </td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="dataTables_length" id="kt_customers_table_length">
              <select name="kt_customers_table_length"
                className="form-select form-select-sm form-select-solid"
                value={pageSize}
                onChange={e => {
                  { setPageSize(Number(e.target.value)); setLocalStorage(e.target.value) }
                }}
                style={{ width: '120px', height: '38px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
            <div id="kt_table_users_paginate">
              <ul className="pagination">
                <li className="page-item" onClick={() => previousPage()} >
                  <a className="page-link prev-angle">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </li>
                {pageOptions.map((pageSize: any, rowIndex: any) => {

                  return (
                    <li className={`page-item ${pageIndex == 0 && rowIndex == 0 ? "active" : pageIndex + 1 == rowIndex + 1 ? "active" : ""}`} onClick={() => gotoPage(rowIndex)} >
                      <a className="page-link">{pageSize + 1} </a>
                    </li>
                  )

                })}
                <li className="page-item" onClick={() => nextPage()} >
                  <a className="page-link next-angle">
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }

  useEffect(() => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {

            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {

            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        console.clear()
        navigate('/error/404')
      });
      
      // if(_.includes(store.getState().userData.permissions,'view_workspaces')){
        fetchWorkspaceUsersList()
      // }

  }, [])

  const columns: any = React.useMemo(
    () => [
      {
        Header: (<div className="form-check form-check-sm form-check-custom form-check-solid"><input className="form-check-input" type="checkbox" value="" /></div>),
        accessor: '_id',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <div className="form-check form-check-sm form-check-custom form-check-solid"><input key={data._id} className="form-check-input" type="checkbox" value={data._id} /></div>
          )
        }

      },
      {
        Header: 'Name',
        accessor: 'full_name',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              <div className='d-flex align-items-center'>
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3" data-bs-toggle="tooltip" data-kt-initialized="1">

                  {/* {console.log('data=',data)} */}

                  <div key={data._id} >
                    {
                      data.avatar ?
                        (
                          data.avatar[0] ?
                            (
                              <span className={"symbol-label"}>
                                <img width="100%" src={getUserInfo.domainUrl() + 'avatars/' + data.avatar[0]['file']['filename']} />
                              </span>
                            ) : (
                              <>
                                {
                                  data.full_name ?
                                    (
                                      <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.full_name.charAt(0)}>
                                        {data.full_name.charAt(0)}
                                      </span>
                                    ) : (
                                      <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.email.charAt(0)}>
                                        {data.email.charAt(0)}
                                      </span>
                                    )
                                }
                              </>
                            )
                        ) : (
                          data.full_name ?
                            (
                              <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.full_name.charAt(0)}>
                                {data.full_name.charAt(0)}
                              </span>
                            ) : (
                              <span className={"symbol-label text-inverse-warning fw-bolder text-uppercase bg-" + data.email.charAt(0)}>
                                {data.email.charAt(0)}
                              </span>
                            )
                        )
                    }
                  </div>

                </div>
                <div className='d-flex flex-column'>
                  <span className="text-capitalize fw-bolder">
                    {data.full_name ? (data.full_name) : ('')}
                  </span>
                  <div>
                    {data.email ? (data.email) : ('')}
                  </div>
                </div>
              </div>
            </>
          )
        }

      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              {data.status == 'active' &&
                <span key={data._id} className="text-capitalize badge badge-success" >{data.status}</span>
              }

              {data.status == 'inactive' &&
                <span key={data._id} className="text-capitalize badge badge-danger" >{data.status}</span>
              }
            </>
          )
        }

      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
            {
              data.role?
              (
                data.role.label?
                (
                  <span key={data._id} className="text-capitalize badge bg-info m-1 " >{data.role.label}</span>
                ):('N/A')
              ):('N/A')
            }
            </>
          )
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }: any) => {
          const data = row.original
          return (
            <>
              <Dropdown className='customDotsMenu'>
                <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Action</Tooltip>}>
                  <Dropdown.Toggle className='btn btn-light btn-active-light-primary btn-sm fw-bolder btn-icon' variant="secondary">
                    <i className="bi bi-three-dots-vertical"></i>
                  </Dropdown.Toggle>
                </OverlayTrigger>
                <Dropdown.Menu>
                  <a className='dropdown-item py-3 cursor-pointer' onClick={() => { setshowEditUserModal(true); setworkspaceUserId(data._id); rowPropData(data) }}><i className="bi bi-pencil-square me-2"></i> Edit</a>
                  <a className='dropdown-item py-3 cursor-pointer' onClick={() => { setshowHidePasswordModal(true); setworkspaceUserId(data._id) }}><i className="bi bi-lock me-2"></i> Set Password</a>
                  <a className='dropdown-item py-3 cursor-pointer' onClick={() => { deleteWorkspaceUser(data) }}><i className="bi bi-trash me-2"></i> Delete</a>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )
        }

      },

    ],
    []
  )

  const deleteWorkspaceUser = async (rowData: any) => {
    Swal.fire({
      title: getHelpers.ConfirmDeleteMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { Authorization: BearerToeken }
        };
        axios.delete(
          process.env.REACT_APP_API_URL + '/internal/users/workspace/' + rowData._id + '/' + organisationUsersId,
          config
        )
          .then(async (res) => {
            if (res.data.status) {
              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
              fetchWorkspaceUsersList();
            } else {
              toast.error(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          })
          .catch((error) => {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          });
      }
    })
  }

  const data: any = workspaceUsersData;
  return (
    <>
      {
        _.includes(store.getState().userData.permissions,'view_workspaces')?
        (
          <>
          <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">{workspaceData.name} Workspace</h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">
                  <Link to={'/internal/workspaces/list'} className="text-muted text-hover-primary">Workspaces</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">Users</li>
              </ul>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              {/* <a className="btn btn-sm fw-bolder btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Button</a> */}
            </div>
          </div>
    
          <WorkspaceHeaderSection organizationId={organisationUsersId} fetchWorkspaceUsersList={fetchWorkspaceUsersList} workspaceData={workspaceData} workspaceUsersData={workspaceUsersData} getDashboardsCount={getDashboardsCount} getComponentLoading={getComponentLoading} />
    
          <div className='card mb-5 mb-xl-10'>
    
              {
                  getComponentLoading ?
                  (
                      <ComponentLoading></ComponentLoading>
                  ) : ('')
              }
    
              <div
              className='card-header border-0 cursor-pointer'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
              >
                  <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Basic Details</h3>
                  </div>
              </div>
              <div id='kt_account_profile_details' className='collapse show'>
                  <div className='pb-2'>
                      
                      <div id='kt_account_profile_details' className='collapse show'>
                          <div className='card-body pb-3 border-top p-9'>
                              <div className='mb-5'>
                              
                                  <div className="row mb-7">
                                      <label className="col-lg-4 fw-bold text-muted">Workspace Name</label>
                                      <div className="col-lg-8">
                                      {
                                          workspaceData.name?
                                          (
                                          <span className="fw-bolder fs-6">{workspaceData.name}</span>
                                          ):(
                                          <span className="fw-bolder fs-6">N/A</span>
                                          )
                                      }
                                      </div>
                                  </div>
                              
                              <div className="row mb-7">
                                  <label className="col-lg-4 fw-bold text-muted">Organization</label>
                                  <div className="col-lg-8">
                                  {
                                      workspaceData.organization_ids?
                                      (
                                        workspaceData.organization_ids.length > 0?
                                        (
                                          workspaceData.organization_ids[0]?
                                          (
                                            workspaceData.organization_ids[0].name?
                                            (
                                              <span className="fw-bolder fs-6">{workspaceData.organization_ids[0].name}</span>
                                            ):(
                                              <span className="fw-bolder fs-6">N/A</span>
                                            )
                                          ):(
                                            <span className="fw-bolder fs-6">N/A</span>
                                          )
                                        ):(
                                          <span className="fw-bolder fs-6">N/A</span>
                                        )
                                      ):(
                                        <span className="fw-bolder fs-6">N/A</span>
                                      )
                                  }
                                  </div>
                              </div>
    
                              </div>
                          </div>
                      </div>
                      
                  </div>
              </div>
          </div>
          </>
        ):(
          <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
        )
      }


    </>
  )
}

// export { WorkspacesBasicDetailPage }
export default connect(mapStateToProps)(WorkspacesBasicDetailPage);