import clsx from 'clsx'
import React, { FC, useState, useEffect } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenuDashboard, QuickLinks, Search } from '../../../partials'
import { useLayout } from '../../core'
import { useNavigate, Link } from 'react-router-dom'
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import axios from 'axios'
import { getFactory } from "@gooddata/api-client-bear";
import Dropdown from 'react-bootstrap/Dropdown';
import { ToastContainer, toast } from 'react-toastify';
import GdDashboardShareEditButtons from './GdDashboardShareEditButtons'
import { Navigate } from 'react-router-dom'
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
// let userLocalStorageData : any = localStorage.getItem('userinfo');
// userLocalStorageData = JSON.parse(userLocalStorageData)
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'
let currentActiveOrgName: any = ''
let currentActiveOrgId: any = ''

const TopbarDashboard: FC = () => {
  const { config } = useLayout()
  const [getAllWorkspace, setAllWorkspace] = useState<any[]>([]);
  const navigate = useNavigate();
  const findOrgId = async (data: any) => {
    let userData: any = await localStorage.getItem('userinfo');
    userData = JSON.parse(userData);
    let currentOrgId = ''
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id == userData.workspace_id) {
        currentOrgId = data[i].name;
        currentActiveOrgId = data[i]._id
      }
    }
    return currentOrgId;
  }
  const fetchAllOrg = async () => {
    await axios.get(
      process.env.REACT_APP_BASE_API_URL + "me/workspaces",
    )
      .then(async (response) => {
        if (response.data) {
          currentActiveOrgName = await findOrgId(response.data.workspaces);
          setAllWorkspace(response.data.workspaces)
        }
      })
      .catch((error) => {
        console.log(error)
      });

  };

  useEffect(() => {

    fetchAllOrg()

  }, []);
  
  const switchWorkspace = async (id: any) => {
    await axios.put(process.env.REACT_APP_BASE_API_URL + "me/workspace/" + id, { headers: { Authorization: BearerToeken } }).then(async (res) => {
      let status = await res.data.status
      if (status) {
        toast.success(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        let userInfo: any = await localStorage.getItem('userinfo');
        userInfo = JSON.parse(userInfo)
        userInfo.workspace_id = await id;
        userInfo = JSON.stringify(userInfo);
        localStorage.setItem('userinfo', userInfo)
        window.location.reload();
      } else {
        toast.error(res.data.message, {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
      }

      // }, 2000);
    }).catch(error => {
      toast.error(error.response.data.errors, {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
    });
  }
  const createDashboard = () =>{
    // console.log('data[i].organisation_id.name',currentActiveOrgId)
    let routePath = `external/dashboard/create/${currentActiveOrgId}`
    navigate(routePath)
  }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <ToastContainer />

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div className='GdDashboardShareEditButtons'>
            <GdDashboardShareEditButtons></GdDashboardShareEditButtons>
        </div>

        <div className='GdDashboardShareEditButtonsToggle'>
            <div
            className={clsx('d-flex align-items-center cursor-pointer symbol symbol-30px symbol-md-40px', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            style={{ marginLeft: '10px' }}
            >

            {
                getUserInfo.userAvatar() ?
                (
                <div className="symbol symbol-circle symbol-50px overflow-hidden" data-bs-toggle="tooltip" data-kt-initialized="1">
                    <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
                </div>
                ):('')
            }
            </div>
            <HeaderUserMenuDashboard />
        </div>
        
      </div>

    </div>
  )
}

export { TopbarDashboard }
