import { FC, useState, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate, useParams } from "react-router-dom";
import { isNotEmpty, toAbsoluteUrl } from '../../../_metronic/helpers'
import { initialUser, User } from './OrganizationValidationModal'
import clsx from 'clsx'
import { ToastContainer, toast } from 'react-toastify';
import * as getHelpers from '../../modules/auth/GetHelpers'
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const internalRolesPermissionsUrl = `${API_URL}/roles-permissions`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
type Props = {
  // isUserLoading: boolean
  // user: User
  modelHideshow(): any
  fetchorganizationsList(): any
}

var myOrganization = {}

const WorkspaceEditModalForm: FC<Props> = ({ modelHideshow, fetchorganizationsList }) => {
  const navigate = useNavigate();
  let { paramValue } = useParams();
  const [activeInactive, setActiveInactive] = useState(true)
  const [getNextAddUser, setNextAddUser] = useState(true)
  const [getNewOrganization, setNewOrganization] = useState(true)
  const [getOrganization, setOrganization] = useState({})
  const [HideShow, setHideShow] = useState(true)
  const [getfname, setfname] = useState('')
  const [getlname, setlname] = useState('')
  const [getRole, setGetRole] = useState('')
  const [isDisabled, setDisabled] = useState(false);
  const [getspinner, setspinner] = useState(false)
  const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);
  const [getOrganizations, setOrganizations] = useState<any[]>([]);

  const [selectedOption, setSelectedOption] = useState<any>(null);

  const internalRolePermissionList = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const internalRolesList = axios.get(
      internalRolesPermissionsUrl,
      config
    ).then(async (res) => {
      const { data: rolespermissionsDataResult } = res
      return await setinternalRolesPermissionsListResult(rolespermissionsDataResult)
    }
    ).catch(console.log);
    return internalRolesList

  }

  const handleFNameChange = (event: any) => {
    setfname(event.target.value);
  }
  const handleLNameChange = (event: any) => {
    setlname(event.target.value);
  }
  const handleRoleChange = (event: any) => {
    formik.values.role = event.target.value;

  }
  const [userForEdit] = useState<User>({
    // ...user,
    role: '',
    // organization_id: user.organization_id || initialUser.organization_id,
    // name: user.name || initialUser.name,
    // organization: user.organization || initialUser.organization,
    // first_name: user.first_name || initialUser.first_name,
    // last_name: user.last_name || initialUser.last_name,
    // password: user.password || initialUser.password,
    // confirm_password: user.confirm_password || initialUser.confirm_password,
    // email: user.email || initialUser.email,
  })
  const editUserSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format'),
    // email: Yup.string(),
    // .required('Email is required'),
    name: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(100, 'Maximum 100 characters')
      .required('Workspace name is required'),
    organization: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
      // .nullable()
      // .min(3, 'Minimum 3 characters')
      // .max(50, 'Maximum 50 characters')
      .required('Organization Name is required'),
    // organization_id: !getNewOrganization ? Yup.string()
    // .required('Organization field is required') : Yup.string(),
    password: !activeInactive ? Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(50, 'Maximum 8 characters')
      // .matches(/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, "Please enter special characters ")
      // .matches(
      //   /^(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   'Need one special character'
      // )
      .required('Password is required') : Yup.string(),

    confirm_password: !activeInactive ? Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(50, 'Maximum 8 characters')
      .required('Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match') : Yup.string(),
    // role: !activeInactive ? Yup.string().required('Select Role') : Yup.string(),
    first_name: !activeInactive ? Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(100, 'Maximum 100 characters')
      .required('First Name is required') : Yup.string(),
    last_name: !activeInactive ? Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(100, 'Maximum 100 characters')
      .required('Last Name is required') : Yup.string()
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      // refetch()
    }
    // setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          // await updateUser(values)
        } else {
          const formDataObj: any = {
            name: values.name,
            organization: getOrganization,
            email: values.email,
            invite: activeInactive,
            // role: !activeInactive ? values.role : '',
            role: !activeInactive ? getHelpers.findInArray(internalRolesPermissionsListResult)['id'] : '',
            first_name: !activeInactive ? values.first_name : '',
            last_name: !activeInactive ? values.last_name : '',
            password: values.password
          }
          setspinner(true)
          saveOrganization(formDataObj)
        }
      } catch (ex) {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        console.error(ex)
      } finally {

        setSubmitting(true)
        cancel(true)
      }
    },
  })
  const saveOrganization = (data: any) => {
    axios.post(process.env.REACT_APP_BASE_API_URL + 'users', data)
      .then(async (res) => {
        // alert('then')

        setspinner(false)
        if (res.data.status) {

          window.dispatchEvent(new CustomEvent(
            'workspaceListRefresh',
            {
              detail: {
                value: true
              }
            }
          ))
          const GdformData = {
            dashboard_name: res.data.workspacedata.name,
            workspaceId: res.data.workspacedata._id,
            emails: [res.data.user.email],
            message: res.data.message
          }
          // await GoodDataDashboardSave(GdformData)
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
          saveWorkpaceColorplate(res.data.workspaceId);
          getOrganizationsData();

          setTimeout(() => {
            if (getNextAddUser) {
              // modelHideshow();
              navigate("/internal/workspace/" + res.data.workspacedata._id + "/users/add");
            }
            modelHideshow();
          }, 1500);

        } else {

          if (res.data.errors && res.data.errors.length > 0) {
            if (res.data.errors[0] && res.data.errors[0]['msg']) {
              toast.error(res.data.errors[0]['msg'], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }

          setspinner(false)

        }
      })
      .catch(error => {
        // alert('catch')
        console.log('error', error);
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
              if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                toast.error(error.response.data.errors[0]['msg'], {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
                });
              } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
                });
              }
            } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        } else {
          if (error.response.status == false) {
            toast.error(error.response.message, {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        }

        setspinner(false)
        // fetchorganizationsList();
      })
  }
  const saveWorkpaceColorplate = (workspaceId: any) => {
    axios.get(process.env.REACT_APP_BASE_API_URL + 'workspace/style/setting/' + workspaceId)
      .then(async (res) => {
        console.log('success');
      })
      .catch(error => {
        console.log('plate error');
      })
  }
  const getOrganizationsData = () => {
    axios.get(process.env.REACT_APP_BASE_API_URL + 'internal/organizations')
      .then(async (res) => {
        setspinner(false)
        setOrganizations(res.data)
        // optionsOrganizations = res.data
      })
      .catch(error => {

        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });

        setspinner(false)
        // fetchorganizationsList();
      })
  }

  const notify = () => {
    // console.log("formik", formik.errors)
    // let check_error = Object.keys(formik.errors).length  ;
    if (JSON.stringify(formik.errors) !== '{}') {
      toast.error(Object.values(formik.errors)[0], {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });

    }
  }
  // const toastSuccess = ()=>{
  //   toast.success("its works", {
  //     position: getHelpers.toastPosition(),
  // autoClose: getHelpers.toastAutoClose(),
  //     theme:'colored'
  //       });
  // }

  useEffect(() => {
    internalRolePermissionList();
    getOrganizationsData();
  }, []);

  const handleChange = (
    newValue: any,
    actionMeta: ActionMeta<any>
  ) => {
    // console.group();
    // console.log('Value Changed=',newValue);
    // userForEdit.organization = newValue
    setOrganization(newValue)
    // console.log('handleChange getOrganization=',getOrganization)
    // console.groupEnd();
  };

  // console.log('formik isSubmitting=',formik.isSubmitting)
  // console.log("isUserLoading=",isUserLoading)
  return (
    <>
      {/* <ToastContainer></ToastContainer> */}
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-body scroll-yx pb-2'>
          <div
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className="row">

              <div className='col-sm-6 mb-7'>
                <label className='fw-bolder fs-6 mb-2'>Organization</label>

                <CreatableSelect
                  className='customSelectDropdown'
                  isClearable
                  onChange={handleChange}
                  // onInputChange={handleInputChange}
                  options={getOrganizations}
                  name='organization'
                />

                {formik.touched.organization && formik.errors.organization && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.organization}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-sm-6 mb-7'>
                <label className='fw-bolder fs-6 mb-2'>Workspace Name</label>
                <input
                  placeholder='Name'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='name'
                  name='name'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>

            </div>

            <div className='fv-row mb-3'>
              <div className='d-flex flex-stack'>
                <div className='me-5'>
                  <p className='fs-6 fw-bolder text-gray-900 mb-0'>User</p>
                  <p className="fs-7 fw-bolder text-muted "> Next create user? </p>
                </div>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                    <span className='form-check-label text-muted me-2'>No</span>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='resend_invite'
                      checked={getNextAddUser}
                      onClick={() =>
                        setNextAddUser(!getNextAddUser)
                      }
                    />
                    <span className='form-check-label text-muted'>Yes</span>
                  </label>
                </div>
              </div>
            </div>


          </div>
        </div>
        <div className="modal-footer bg-light flex-row-reverse">
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={() => notify()}
          >{!getspinner && (
            <span className='indicator-label'>Save</span>
          )}
            {getspinner && (
              <span >
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='button' // {()=>{showHideModel(cancelModel)}}
            onClick={() => modelHideshow()}
            className='btn btn-white'
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export { WorkspaceEditModalForm }