/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { ComponentLoading } from '../../modules/ComponentLoading'
// import { store } from '../../../redux/store/index';
const API_URL = process.env.REACT_APP_API_URL
const WorkspacesRepairChartPalette = `${API_URL}/workspace/gd/repair-chart-palette`
const WorkspacesRemoveGDCheck = `${API_URL}/workspace/user/not-synched`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
interface EditModalProps {
    hideShowRepairChartPaletteModel(): any;
}

const RepairChartPaletteModel: FC<EditModalProps> = ({ hideShowRepairChartPaletteModel }) => {
    const [getComponentLoading, setComponentLoading] = useState(true)
    const [getGdNonExistWorkspaces, setGdNonExistWorkspaces] = useState([])
    const [getspinner, setspinner] = useState(false)
    const [getResponseMessage, setResponseMessage] = useState('')
    useEffect(() => {
        checkRepairChartPalette()
    }, [])
    const checkRepairChartPalette = () => {
        setComponentLoading(true);
        let objSelect: any;
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const WorkspacesListList = axios.get(
            WorkspacesRepairChartPalette,
            config
        ).then(async (res: any) => {
            setResponseMessage(res.data.message)
            setComponentLoading(false);
        }
        ).catch((error) => {
            setResponseMessage(error.response.data.message)
            setComponentLoading(false);
            console.log(error)
        });
    }
    const checkgetGdNonExistWorkspaceFunc = () => {
        if (getGdNonExistWorkspaces.length > 0) {
            return false
        } else {
            return true
        }
    }
    return (
        <>
            <div className="modal-content bg-white border-radius-0">
                <div className="modal-header bg-primary">
                    <h2 className="fw-bolder text-white">Repair Chart Palette</h2>
                    <div
                        className='btn btn-icon btn-sm btn-active-icon-primary'
                        data-kt-users-modal-action='close'
                        style={{ cursor: 'pointer' }}
                        onClick={() => { hideShowRepairChartPaletteModel() }}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                </div>
                <div>
                    <div className=''>
                        <form id="kt_modal_add_role" className="form" noValidate >
                            <div className='modal-body scroll-y'>
                                <div className=" table-responsivex" >
                                    {
                                        getComponentLoading ?
                                            (
                                                <div className='loader-min-h'>
                                                    <ComponentLoading></ComponentLoading>

                                                </div>
                                            ) : <div className='card'><div className='card-body text-center bg-light m-5 fs-5'>{getResponseMessage}</div></div>
                                    }

                                </div>
                            </div>
                            {/* <div className="modal-footer bg-light flex-row-reverse">
                                <button
                                    type='button'
                                    onClick={() => { hideShowRepairChartPaletteModel() }}
                                    className='btn btn-white'
                                >
                                    Cancel
                                </button>
                            </div> */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { RepairChartPaletteModel }
