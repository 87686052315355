/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import ExternalRolesTable from './ExternalRolesTable'
import { useNavigate } from "react-router-dom";
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import axios from 'axios'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../redux/actions/externalPermissions';
import { ActionExternalWorkspacePermissions } from '../../../redux/actions/externalWorkspacePermissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}


const RolePage: FC = () => (
  <>
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-12'>
      <ExternalRolesTable className='card-xxl-stretch mb-5 mb-xl-12' />
      </div>
    </div>
  </>
)

const ExternalRoles: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const [getStorePermissions, setStorePermissions] = useState<any>({})
  const [getStoreRoles, setStoreRoles] = useState<any>({})
  
  const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
  const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
  const [getExternalWorkspacePermissions, setUserAllExternalPermissions] = useState<any[]>([]);
  const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);
  const [getKey, setKey] = useState<any>(0);

  const dispatch = useDispatch();
  
  const fetchVerifyAccess = async () => {
    const API_URL = process.env.REACT_APP_API_URL
    const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
    const ApiToken = localStorage.getItem('api_token')
    const BearerToken = `Bearer ${ApiToken}`
    const config = {
      headers: { Authorization: BearerToken }
    };
    axios.get(
        GET_USER_BY_ACCESSTOKEN_URL,
        config
    )
      .then(async (res) => {
        if (res.data.status) {
          if(res.data.internalPermissions){
            if(res.data.internalPermissions.permissions){
              dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
              setUserInternalPermissions(res.data.internalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.externalPermissions){
            // console.log('res.data.externalPermissions=',res.data.externalPermissions)
            if(res.data.externalPermissions.permissions){
              // getWPermissions = res.data.externalPermissions.permissions
              // var data = getHelpers.doesHasPermissions(res.data.externalPermissions.permissions,organisationUsersId)
              // dispatch(ActionExternalPermissions.externalUserPermissions(data))
              dispatch(ActionExternalWorkspacePermissions.externalWorkspacePermissions(res.data.externalPermissions.permissions))
              // setUserExternalPermissions(data)
              setUserAllExternalPermissions(res.data.externalPermissions.permissions)
              setKey(getKey+1)
            }
          }
          if(res.data.internalRoles){
            if(res.data.internalRoles.roles){
              dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
              setUserInternalRoles(res.data.internalRoles.roles)
              setKey(getKey+1)
            }
          }
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchVerifyAccess()
  }, []);

  return (
    <>
    {
      // _.includes(getStorePermissions,'view_settings')?
      getHelpers.hasManageSettings(getExternalWorkspacePermissions,'view_settings')?
      (
        <>
          <PageTitle breadcrumbs={[]}>Roles  </PageTitle>
          <RolePage />
        </>
      ):(
        <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
      )
    }
    </>
  )
}

// export {Roles}
export default connect(mapStateToProps)(ExternalRoles);
