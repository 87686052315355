import React, { useState } from 'react'
import { isNotEmpty, toAbsoluteUrl } from '../../../../_metronic/helpers'
// import { IProfileDetails, profileDetailsInitValues as initialValues } from './../../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
export type Props = {
  getchangePasswordProps() : any,
  // required : true
}
const ProfileChangePassword: React.FC<Props> = ({getchangePasswordProps}) => {

  const [loading, setLoading] = useState(false)

  const [getchangeEmail, setchangeEmail] = useState(false)
  const [getchangePassword, setchangePassword] = useState(false)

  const [getEmail, setEmail] = useState(getUserInfo.email())
  const [getPassword, setPassword] = useState('')

  const editProfileSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(50, 'Maximum 50 characters')
      // .matches(
      //   /^(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   'Need one special character'
      // )
      .required('Current Password is required'),
    password: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(50, 'Maximum 50 characters')
      // .matches(
      //   /^(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   'Need one special character'
      // )
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })

  const formik = useFormik({
    initialValues: {
      old_password: '',
      password: '',
      confirm_password: ''
    },
    validationSchema: editProfileSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        setLoading(true)
        updateProfile(values)
      } catch (ex) {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const updateProfile = (data: any) => {
    // console.log('updateProfile=',data)
    // return;
    axios.put(process.env.REACT_APP_BASE_API_URL + 'me', data)
      .then(async (res) => {
        setLoading(false)
        if (res.data.status) {
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        } else {
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        setLoading(false)
        // fetchorganizationsList();
      })
  }

  const updateEmailBtn = () => {
    // setchangeEmail(true)
    // console.log('formik.values', formik.values);
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.put(process.env.REACT_APP_BASE_API_URL + 'me/password', formik.values, config)
      .then(async (res) => {
        // console.log('res',res.data)
        if (res.data.status) {
          // let localStorageUserData: any = await localStorage.getItem('userinfo');
          // localStorageUserData = await JSON.parse(localStorageUserData);
          // let resUserdata = res.data.user;
          // localStorageUserData.email = resUserdata.email;
          // localStorageUserData = await JSON.stringify(localStorageUserData)
          // await localStorage.setItem('userinfo', localStorageUserData)


          // console.log(res.data)
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
          setLoading(false)
          getchangePasswordProps()
          // 
        } else {
          toast.error(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
          setLoading(false)
        }
      })
      .catch(error => {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        setLoading(false)
        // fetchorganizationsList();
      })
  }
  const changeEmailCancelBtn = () => {
    setchangeEmail(false)
  }
  const submitErrorCheck = () => {
    // console.log(formik.values,'formik')
    if (JSON.stringify(formik.errors) !== '{}' && formik.values.password === formik.values.confirm_password) {
      // console.log('true')
      return true;
    } else {
      if (formik.values.old_password.length > 7 && formik.values.password === formik.values.confirm_password) {
        return false;
      } else {
        return true;
      }
    }
  }

  return (
    <>
      {/* <ToastContainer /> */}
      <p>Password must be at least 8 character and contain symbols</p>
      <div className='row'>
        <div className='col-lg-4 fv-row'>
          <label htmlFor="">Current Password</label>
          <input
            type='password'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            placeholder='******'
            {...formik.getFieldProps('old_password')}
          />
          {formik.touched.old_password && formik.errors.old_password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.old_password}</div>
            </div>
          )}
        </div>
        <div className='col-lg-4 fv-row'>
          <label htmlFor="">New Password</label>
          <input
            type='password'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            placeholder='******'
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          )}
        </div>
        <div className='col-lg-4 fv-row'>
          <label htmlFor="">Confirm New Password</label>
          <input
            type='password'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            placeholder='******'
            {...formik.getFieldProps('confirm_password')}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.confirm_password}</div>
            </div>
          )}
        </div>
      </div>
      <div className='row mb-6 mt-5'>
        <div className='col-lg-12'>
          <button className='btn btn-primary me-2' disabled={submitErrorCheck()} onClick={updateEmailBtn}>Update Password</button>
          {/* <button className='btn btn-primary' onClick={changeEmailCancelBtn}>Cancel</button> */}
        </div>
      </div>
    </>
  )
}

export { ProfileChangePassword }