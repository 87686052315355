import React, { useState } from 'react'
import { isNotEmpty, toAbsoluteUrl } from '../../../../_metronic/helpers'
// import { IProfileDetails, profileDetailsInitValues as initialValues } from './../../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
import { LoginMethodCard } from './LoginMethodCard'
import { useNavigate } from "react-router-dom";
import { ComponentLoading } from '../../../modules/ComponentLoading'
// const profileDetailsSchema = Yup.object().shape({
//   first_name: Yup.string().required('First name is required'),
//   last_name: Yup.string().required('Last name is required'),
//   company: Yup.string().required('Company name is required'),
//   companySite: Yup.string().required('Company site is required'),
//   country: Yup.string().required('Country is required'),
//   language: Yup.string().required('Language is required'),
//   timeZone: Yup.string().required('Time zone is required'),
//   currency: Yup.string().required('Currency is required'),
// })

const Settings: React.FC = () => {
  const navigate = useNavigate();
  // const [data, setData] = useState<IProfileDetails>(initialValues)
  // const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
  //   const updatedData = Object.assign(data, fieldsToUpdate)
  //   setData(updatedData)
  // }

  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(getUserInfo.domainUrl() + getUserInfo.userAvatar())
  const [getAvatarComponentLoading, setAvatarComponentLoading] = useState(false)
  // console.log('getUserInfo.userAvatar()',process.env.REACT_APP_BASE_API_AVATAR_URL+getUserInfo.userAvatar())
  const [userForEdit, setuserForEdit] = useState({
    first_name: getUserInfo.firstName(),
    last_name: getUserInfo.lastName(),
    company: getUserInfo.company(),
    job_title: getUserInfo.jobTitle(),
    avatar: getUserInfo.domainUrl() + getUserInfo.userAvatar(),//'/media/avatars/300-1.jpg',
    avatar_id: ''
  })

  const editProfileSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('First Name is required'),
    last_name: Yup.string()
      .min(3, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('First Name is required'),
    company: Yup.string()
      .min(3, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('Company is required'),
    job_title: Yup.string()
      .min(3, 'Minimum 2 characters')
      .max(50, 'Maximum 50 characters')
      .required('Job Title is required'),
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editProfileSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        setLoading(true)
        updateProfile(values)
      } catch (ex) {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const updateProfile = (data: any) => {
    // console.log('updateProfile=',data)
    // return;
    axios.put(process.env.REACT_APP_BASE_API_URL + 'me', data)
      .then(async (res) => {
        setLoading(false)
        // console.log(res.data)
        if (res.data.status) {
          let localStorageUserData: any = await localStorage.getItem('userinfo');
          localStorageUserData = await JSON.parse(localStorageUserData);
          // console.log('localStorageUserData.file_name',localStorageUserData.file_name)
          let resUserdata = res.data.user;
          // console.log('1filename', localStorageUserData.file_name)
          localStorageUserData.file_name = resUserdata.file_name;
          // console.log('2filename', localStorageUserData.file_name)
          localStorageUserData.file_path = resUserdata.file_path;
          localStorageUserData.first_name = resUserdata.first_name;
          localStorageUserData.last_name = resUserdata.last_name;
          localStorageUserData.job_title = resUserdata.job_title;
          localStorageUserData.company = resUserdata.company;
          localStorageUserData = await JSON.stringify(localStorageUserData)
          await localStorage.setItem('userinfo', localStorageUserData)
          //  await localStorage.setItem('userinfo',user_data);
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
          setTimeout(() => {
            navigate('/profile/overview')
          }, 1500);
        } else {
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        setLoading(false)
        // fetchorganizationsList();
      })
  }

  const onImageChange = (event: any) => {
    setAvatarComponentLoading(true)
    let fileSize = event.target.files[0]['size'];
    if (fileSize > 1060322) {
      toast.error("Max 1MB photo is allowed ", {
        position: getHelpers.toastPosition(),
        autoClose: getHelpers.toastAutoClose(),
        theme: 'colored'
      });
      setAvatarComponentLoading(false);
    } else {
      if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        if (img['type'] != 'image/jpeg' && img['type'] != 'image/jpg' && img['type'] != 'image/png') {
          toast.error("Allowed file types: png, jpg, jpeg.", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        } else {
          var formdata = new FormData();
          formdata.append("avatar", event.target.files[0]);
          uploadAvatar(formdata)
        }
      }
    }
  }

  const uploadAvatar = (data: any) => {
    axios.post(process.env.REACT_APP_BASE_API_URL + 'profile/avatar', data)
      .then(async (res) => {
        setLoading(false)
        setAvatarComponentLoading(false)
        if (res.data.status) {
          // console.log('res.data.file_path=', res.data['file_path'])
          // console.log('res.data.upload_id=', res.data['upload']['_id'])
          let obj = userForEdit;
          // console.log('before',obj)
          setImage(getUserInfo.domainUrl() + res.data['file_path'])
          // console.log("getUserInfo.domainUrl() + res.data['file_path']", getUserInfo.domainUrl() + res.data['file_path'])
          obj['avatar'] = getUserInfo.domainUrl() + res.data['file_path'];
          obj['avatar_id'] = await res.data.upload._id;
          setuserForEdit(obj)
          // console.log('after',userForEdit)
          // getFieldProps();
          toast.success(res.data.message, {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        } else {
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        setAvatarComponentLoading(false)
        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
          position: getHelpers.toastPosition(),
          autoClose: getHelpers.toastAutoClose(),
          theme: 'colored'
        });
        setLoading(false)
        // fetchorganizationsList();
      })
  }

  return (
    <>

      <ToastContainer />
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input-avatar showImage image-input-outline'
                    data-kt-image-input='true'
                    style={{ backgroundImage: `url(${toAbsoluteUrl('../../../../../media/avatars/avatar.svg')})`, backgroundSize: '118px auto', backgroundPosition: 'center' }}
                  >
                    {
                      getAvatarComponentLoading ?
                        (
                          <ComponentLoading></ComponentLoading>
                        ) : ('')
                    }
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{ backgroundImage: `url(${image})` }}
                    ></div>
                    {/* {image} */}

                    <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                      <i className="bi bi-pencil-fill fs-7"></i>
                      <input
                        onChange={onImageChange}
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                      />
                      <input type="hidden" name="avatar_remove" />
                    </label>

                  </div>
                  {/* {
                    avatarSizeMessage.length > 0

                      ?
                      <div className="form-text text-gray-900">{avatarSizeMessage}</div>
                      : */}
                  <div className="form-text text-gray-900">Allowed file types: png, jpg, jpeg.</div>
                  {/* } */}



                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Full Name</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='First name'
                        {...formik.getFieldProps('first_name')}
                      />
                      {formik.touched.first_name && formik.errors.first_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.first_name}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Last name'
                        {...formik.getFieldProps('last_name')}
                      />
                      {formik.touched.last_name && formik.errors.last_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.last_name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Company</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Company name'
                    {...formik.getFieldProps('company')}
                  />
                  {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.company}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Job Title</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Job title'
                    {...formik.getFieldProps('job_title')}
                  />
                  {formik.touched.job_title && formik.errors.job_title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.job_title}</div>
                    </div>
                  )}
                </div>
              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
              >
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <LoginMethodCard />

    </>
  )
}

export { Settings }