import { FC, useState, useEffect } from 'react'


import { Modal } from 'react-bootstrap'

import axios from 'axios'
import { WorkspaceUserEditModal } from '../customer-organizations/WorkspaceUserEditModel'

import { useParams, useNavigate } from "react-router-dom";

import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { ToastContainer, toast } from 'react-toastify';

import '../../../../node_modules/react-toastify/dist/ReactToastify.css';


const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const OrganizationsListUrl = `${API_URL}/internal/customers`

// type Props ={
//     test23:boolean
// }
interface PostsProps {
    showHideEditUserModal: any;
    showEditUserModal: any;
    industryId: any;
    internalAdminList(): any;
    rowprops: any
}

const IndustriesEditModal: FC<PostsProps> = ({ showEditUserModal, showHideEditUserModal, industryId, internalAdminList, rowprops }) => {

    let { organisationUsersId }: any = useParams();
    // const { setItemIdForUpdate } = useListView()
    // const { refetch } = useQueryResponse()
    const [getspinner, setspinner] = useState(false)
    const [singleUser, setsingleuser]: any = useState<any[]>([]);

    const fetchIndustryById: any = () => {
        // console.log("inn function")
        const fetchOrgUserAPI = `${API_URL}/internal/industries/` + industryId
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const singleuserData = axios.get(
            fetchOrgUserAPI,
            config
        ).then(async (res: any) => {
            formik.values.name = res.data.name
            await setsingleuser(res.data);
            return setsingleuser;

        }
        ).catch(console.log);
        return singleuserData

    }

    const formSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Minimum 2 characters')
            .max(100, 'Maximum 100 characters')
            .required('Industry Name is required')
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            // refetch()
        }
        // setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: formSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {
                const formDataObj = singleUser
                formDataObj.name = formik.values.name
                setspinner(true)
                updateFormData(formDataObj)
            } catch (ex) {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                console.error(ex)
            } finally {
                setSubmitting(true)
                cancel(true)
            }
        },
    })

    const updateFormData = (data: any) => {
        axios.put(process.env.REACT_APP_BASE_API_URL + 'internal/industries/' + industryId, data)
            .then((res) => {
                // console.log("response=", res);
                setspinner(false)
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    showHideEditUserModal();
                    internalAdminList();
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    showHideEditUserModal();
                }
            })
            .catch(error => {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)
                internalAdminList();
            })
    }


    const notify = () => {

        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });

        }
    }

    useEffect(() => {
        fetchIndustryById()
        let rowsData;
        let enterOnce = 0;
        if (showEditUserModal && !enterOnce) {
            rowsData = rowprops
            formik.values.name = rowsData.name
            enterOnce = 1;
        }
    }, [rowprops])

    return (
        <div>
            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showEditUserModal}
                animation={false}
            >
                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        {/* begin::Modal content */}
                        <div className='modal-content'>
                            {/* <UserEditModalHeader /> */}
                            <div className='modal-header bg-primary'>
                                {/* begin::Modal title */}
                                <h2 className='fw-bolder text-white'>Edit Industry </h2>
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => { showHideEditUserModal() }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                                {/* end::Close */}
                            </div>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                            <div className='modal-body scroll-y pb-2'>
                                <ToastContainer />
                                <div
                                        className='d-flex flex-column scroll-y me-n7 pe-7'
                                        id='kt_modal_add_user_scroll'
                                        data-kt-scroll='true'
                                        data-kt-scroll-activate='{default: false, lg: true}'
                                        data-kt-scroll-max-height='auto'
                                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                        data-kt-scroll-offset='300px'
                                    >
                                    <div className='row'>
                                        <div className='col-sm-12 mb-7'>
                                            <label className='fw-bolder fs-6 mb-2'>Name </label>
                                            <input
                                                {...formik.getFieldProps('name')}
                                                type='text'
                                                name='name'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    { 'is-invalid': formik.touched.name && formik.errors.name },
                                                    {
                                                        'is-valid': formik.touched.name && !formik.errors.name,
                                                    }
                                                )}
                                                autoComplete='off'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer bg-light flex-row-reverse">
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => notify()}

                                >
                                    {!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                    {getspinner && (
                                        <span >
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                <button
                                    type='button'
                                    onClick={() => showHideEditUserModal()}
                                    className='btn btn-white me-3'
                                >
                                    Cancel
                                </button>
                            </div>
                            </form>
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
            </Modal>
        </div>
    )
}
export { IndustriesEditModal }