/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import * as Yup from 'yup'
import clsx from 'clsx'
import { FastField, useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import '../../../../../node_modules/react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../../../_metronic/helpers'
import * as getHelpers from '../../../../app/modules/auth/GetHelpers'
import axios from 'axios';
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import Dropdown from 'react-bootstrap/Dropdown';
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';
import { useParams, useNavigate } from "react-router-dom";
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as _ from "lodash";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import type { Value } from "react-multi-date-picker"
import { threadId } from 'worker_threads'
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
export type Props = {
    showModal(): any
}
const shareWithData: any = {
    options: []
};
// const ownerDashbaord: any = {
//     options: []
// };
interface Option {
    readonly label: string;
    readonly value: string;
}
let new_user_data: any = []
let removedUsersSharedList: any = [];
// let ShareddashboardUsersDifference : any = {
//      options : {
//         values : ''
//      }
// };
const DashboardShareModel: React.FC<Props> = ({ showModal }) => {
    let shared_dashboard_user_list: any = []
    // let ownerDashbaord: any = {}
    let { dashboardId } = useParams();
    const [count, setCount] = useState(1)
    const [getPublicLink, setPublicLink] = useState(false)
    const [accessTab, setAccessTab] = useState(false);
    const [publicTab, setPublicTab] = useState(true);
    const [getTimeZoneName, setTimeZoneName] = useState<any>([]);
    const [getAllSelectedEmails, setAllSelectedEmails] = useState<any>([]);
    const [getshareddashboardUsers, setShareddashboardUsers] = useState<any>([]);
    // const [getShareddashboardUsersDifference, setShareddashboardUsersDifference] = useState<any>([]);

    const [selectedTimezone, setSelectedTimezone] = useState<any>({ label: '', value: '' })
    const date = new Date();
    const [getExpiredDate, setExpiredDate] = useState<Value>(date.setDate(date.getDate() + 7));
    const [getWorkspaceId, setWorkspaceId] = useState<any>(null);
    const [getspinner, setspinner] = useState(false)
    const [link, setLink] = useState('');
    const [getExpireMessageStatus, setExpireMessageStatus] = useState<any>('');

    const [getOwnerDashboardObject, setOwnerDashboardObject] = useState<any>({});

    var dashboardOwner = '';
    const getSelectedTimeZoneFunc = async (event: any) => {
        setSelectedTimezone(event);
        setCount(count + 1);
    }
    const publicLinkBtn = () => {
        if (getPublicLink == true) {
            setPublicLink(false)
        } else {
            setPublicLink(true)
        }
    }
    const onCopyLink = useCallback(() => {
        // setCopied(true);
        toast.success("Link Copied", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
        });
    }, [])
    const dataTimeSetStata = async (e: any) => {
        setExpiredDate(new Date(e));
        let expireTime: any = new Date(e);
        if (selectedTimezone) {
            if (selectedTimezone.value) {
                const nDate = new Date().toLocaleString('en-US', {
                    timeZone: selectedTimezone.value
                });
                if (Date.parse(expireTime) > Date.parse(nDate)) {
                    setExpireMessageStatus('')
                } else {
                    setExpireMessageStatus('Date/Time Expired.')
                }
            }
        }
    }
    const getDashboardsData = async () => {
        const config = {
            headers: { Authorization: BearerToeken }
        };
        await axios.get(
            process.env.REACT_APP_BASE_API_URL + 'dashboard/' + dashboardId,
            config
        )
            .then(async (res) => {

                dashboardOwner = res.data.ower_dashboard;
                setTimeout(() => {
                    sharedDashboardUsers()
                }, 200);
                if (res.data.publicExpiredDate != '') {
                    setExpiredDate(res.data.publicExpiredDate)
                    setPublicLink(true)
                }
                if (res.data.tiemZone) {
                    let timeZoneObj = { label: res.data.tiemZone, value: res.data.tiemZone };
                    const nDate = new Date().toLocaleString('en-US', {
                        timeZone: res.data.tiemZone
                    });
                    if (Date.parse(res.data.publicExpiredDate) > Date.parse(nDate)) {
                        // setExpireMessageStatus(false);
                        // settime
                        setExpireMessageStatus('')
                        // expireMessage = ''
                    } else {
                        // expireMessage = 'Date/Time Expired.'
                        setExpireMessageStatus('Date/Time Expired.')

                        // setExpireMessageStatus(true);
                    }
                    await setSelectedTimezone(timeZoneObj);
                }
                setWorkspaceId(res.data.workspace_id)
                setLink(window.location.origin + '/#/dashboard/public/' + res.data.gernel_access_link)
                setCount(count + 1);

                setTimeout(() => {
                    standardUsersRequest(res.data.workspace_id)
                }, 200);
            })
            .catch((error) => {
                console.log(error)
            });
    };
    const shareDashboard = (formData: any) => {
        setspinner(true)
        axios.post(process.env.REACT_APP_BASE_API_URL + 'external/dashboard/share', formData)
            .then((res) => {
                setspinner(false)
                if (res.data.status) {
                    if (formData.removedshareUserList) {
                        axios.post(process.env.REACT_APP_BASE_API_URL + 'external/dashboard/share-remove', formData)
                            .then((res) => {
                                setspinner(false)
                                if (res.data.status) {

                                    toast.success(res.data.message, {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                    showModal()
                                } else {
                                    if (res.data.message) {
                                        toast.error(res.data.message, {
                                            position: getHelpers.toastPosition(),
                                            autoClose: getHelpers.toastAutoClose(),
                                            theme: 'colored'
                                        });
                                    } else {
                                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                            position: getHelpers.toastPosition(),
                                            autoClose: getHelpers.toastAutoClose(),
                                            theme: 'colored'
                                        });
                                    }
                                    setspinner(false)
                                }
                            })
                            .catch(error => {
                                setspinner(false)
                                // console.log(error)

                                if (error.response) {
                                    if (error.response.data) {
                                        if (error.response.data.message) {
                                            toast.error(error.response.data.message, {
                                                position: getHelpers.toastPosition(),
                                                autoClose: getHelpers.toastAutoClose(),
                                                theme: 'colored'
                                            });
                                        } else {
                                            if (error.response.data.errors && error.response.data.errors.length > 0) {
                                                if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                                                    toast.error(error.response.data.errors[0]['msg'], {
                                                        position: getHelpers.toastPosition(),
                                                        autoClose: getHelpers.toastAutoClose(),
                                                        theme: 'colored'
                                                    });
                                                } else {
                                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                                        position: getHelpers.toastPosition(),
                                                        autoClose: getHelpers.toastAutoClose(),
                                                        theme: 'colored'
                                                    });
                                                }
                                            } else {
                                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                                    position: getHelpers.toastPosition(),
                                                    autoClose: getHelpers.toastAutoClose(),
                                                    theme: 'colored'
                                                });
                                            }
                                        }
                                    } else {
                                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                            position: getHelpers.toastPosition(),
                                            autoClose: getHelpers.toastAutoClose(),
                                            theme: 'colored'
                                        });
                                    }
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }

                            })
                    } else {


                        toast.success(res.data.message, {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                        showModal()
                    }
                } else {
                    if (res.data.message) {
                        toast.error(res.data.message, {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                    setspinner(false)
                }
            })
            .catch(error => {
                setspinner(false)
                // console.log(error)

                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.message) {
                            toast.error(error.response.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            if (error.response.data.errors && error.response.data.errors.length > 0) {
                                if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                                    toast.error(error.response.data.errors[0]['msg'], {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            }
                        }
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }

            })
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault();


        const formData = {
            dashboardId: dashboardId,
            workspaceId: getWorkspaceId,
            getAllSelectedEmails: getAllSelectedEmails,
            removedshareUserList: removedUsersSharedList,
            updateExistingUser: getshareddashboardUsers
        }
        shareDashboard(formData)
    }
    const standardUsersRequest = (workspaceId: any) => {
        axios.get(process.env.REACT_APP_BASE_API_URL + 'workspace/standard/users/' + workspaceId)
            .then(async (res) => {
                if (res.data.users && res.data.users.length > 0) {
                    var isDisabled = false;
                    res.data.users.forEach((userData: any, index: any) => {
                        const found = shared_dashboard_user_list.find((obj: any) => {
                            return obj.email == userData['email']
                        });
                        if (found != undefined) {
                            isDisabled = true;
                        } else {
                            isDisabled = false;
                        }
                        if (dashboardOwner != userData['_id']) {
                            shareWithData.options.push({
                                email: userData['email'],
                                label: userData['email'],
                                value: userData['_id'],
                                user_id: userData['_id'],
                                first_name: userData['first_name'] ? userData['first_name'] : '',
                                last_name: userData['last_name'] ? userData['last_name'] : '',
                                isValid: true,
                                isDisabled: isDisabled
                            })
                        }
                    });
                }
                setCount(count + 1);
            })
            .catch(error => {
                console.log(error)
            })
    }
    const sharedDashboardUsers = () => {
        axios.get(process.env.REACT_APP_BASE_API_URL + 'external/shared-dashboard-users/' + dashboardId)
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    res.data.forEach((shareData: any, index: any) => {
                        // console.log(dashboardOwner,'=1',shareData['user_id']);
                        if (dashboardOwner != shareData['user_id']) {
                            shared_dashboard_user_list.push({
                                email: shareData['email'],
                                label: shareData['email'],
                                value: shareData['user_id'],
                                user_id: shareData['user_id'],
                                first_name: shareData['first_name'] ? shareData['first_name'] : '',
                                last_name: shareData['last_name'] ? shareData['last_name'] : '',
                                permission: shareData['permission'] ? shareData['permission'] : '',
                                isValid: true
                            })
                        }
                        // else if (dashboardOwner == shareData['user_id']) {
                        //     let dashboardObject = {
                        //         email: shareData['email'],
                        //         label: shareData['email'],
                        //         value: shareData['user_id'],
                        //         user_id: shareData['user_id'],
                        //         first_name: shareData['first_name'] ? shareData['first_name'] : '',
                        //         last_name: shareData['last_name'] ? shareData['last_name'] : '',
                        //         permission: shareData['permission'] ? shareData['permission'] : '',
                        //         isValid: true
                        //     }
                        //     // setOwnerDashboardObject(dashboardObject)
                        // }
                    });
                }
                setShareddashboardUsers(shared_dashboard_user_list);

            })
            .catch(error => {
                console.log(error)
            })
    }
    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        var isNew = 0;
        if (actionMeta.action != 'clear') {
            if (typeof actionMeta['option'] === 'undefined') {
                if (actionMeta.removedValue) {
                    if (actionMeta.removedValue.label) {
                        var email = actionMeta.removedValue.label
                        isNew = 0
                    }
                }
            }
            else {
                var email = actionMeta.option.label
                if (typeof actionMeta.option['__isNew__'] !== 'undefined') {
                    isNew = 1
                }
            }
            // const emailPattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
            if (typeof actionMeta['option'] !== 'undefined') {
                if (!emailPattern.test(email)) {
                    var lastItem = newValue.length - 1;
                    newValue[lastItem] = createOption(email, false);
                    // toast.error("Invalid email", {
                    //     position: getHelpers.toastPosition(),
                    // autoClose: getHelpers.toastAutoClose(),
                    //     theme: 'colored'
                    // });
                }
                else {
                    if (isNew) {
                        const config = {
                            headers: { Authorization: BearerToeken }
                        };
                        const userData = axios.get(
                            process.env.REACT_APP_BASE_API_URL + 'userByEmail/' + email,
                            config
                        )
                            .then(async (res) => {
                                new_user_data = await res.data
                                console.log('new_user_data', new_user_data);
                                if (new_user_data.status) {
                                    setspinner(true)
                                    const newOption = {
                                        email: new_user_data.data[0]['email'],
                                        label: new_user_data.data[0]['email'],
                                        value: new_user_data.data[0]['_id'],
                                        user_id: new_user_data.data[0]['_id'],
                                        first_name: new_user_data.data[0]['first_name'] ? new_user_data.data[0]['first_name'] : '',
                                        last_name: new_user_data.data[0]['last_name'] ? new_user_data.data[0]['last_name'] : '',
                                        isValid: true,
                                        message: 'To be invited'
                                    };
                                    newValue.splice(newValue.length - 1, 1);
                                    newValue.push(newOption);
                                    setTimeout(() => {
                                        setAllSelectedEmails(newValue)
                                        setspinner(false)
                                    }, 200);
                                } else {
                                }
                                return res.data;
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                    }


                }
            }
        }
        setAllSelectedEmails(newValue)
    }
    const shareDashboardShareLink = (data: any) => {
        // setLoading(true)
        setspinner(true)
        axios.post(process.env.REACT_APP_BASE_API_URL + 'external/dashboard/share-link', data)
            .then((res) => {
                setspinner(false)
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    showModal()
                    // console.log('return res.send', res.data)
                } else {
                    if (res.data.message) {
                        toast.error(res.data.message, {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                    setspinner(false)
                }
            })
            .catch(error => {
                setspinner(false)
                // console.log(error)

                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.message) {
                            toast.error(error.response.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            if (error.response.data.errors && error.response.data.errors.length > 0) {
                                if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                                    toast.error(error.response.data.errors[0]['msg'], {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            }
                        }
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }

            })
    }
    const handleSharePublicLink = async (e: any) => {
        e.preventDefault();
        let getDate: any = await getExpiredDate;
        let expiredDateConvert = new Date(getDate);
        // console.log('getDate',expiredDateConvert);
        const formData = {
            dashboardId: dashboardId,
            workspaceId: getWorkspaceId,
            publicLink: getPublicLink ? link : '',
            tiemZone: getPublicLink ? selectedTimezone.value : '',
            expiredDate: getPublicLink ? expiredDateConvert : '',
        }
        shareDashboardShareLink(formData)
    }
    const createOption = (label: string, valid: boolean) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
        isValid: valid,
        __isNew__: true
    });
    const changePermission = async (eventValue: any, index: any) => {
        getshareddashboardUsers[index]['permission'] = await eventValue;
        setShareddashboardUsers(getshareddashboardUsers);
        setCount(count + 1);
    }
    const changeDropDownUserPermission = async (eventValue: any, index: any) => {
        getAllSelectedEmails[index]['permission'] = await eventValue;
        setAllSelectedEmails(getAllSelectedEmails);
        setCount(count + 1);
    }
    const verifyUser = (itmemData: any, event: any, index: any) => {
        event.preventDefault();
        const sharedUserList = getAllSelectedEmails;
        const email = itmemData.value;
        const config = {
            headers: { Authorization: BearerToeken }
        };
        const userData = axios.get(
            process.env.REACT_APP_BASE_API_URL + 'userByEmail/' + email,
            config
        )
            .then(async (res) => {
                new_user_data = await res.data
                if (new_user_data.status) {
                    const newOption = {
                        email: new_user_data.data[0]['email'],
                        label: new_user_data.data[0]['email'],
                        value: new_user_data.data[0]['_id'],
                        user_id: new_user_data.data[0]['_id'],
                        first_name: new_user_data.data[0]['first_name'] ? new_user_data.data[0]['first_name'] : '',
                        last_name: new_user_data.data[0]['last_name'] ? new_user_data.data[0]['last_name'] : '',
                        isValid: true
                    };
                    sharedUserList.splice(index, 1);
                    sharedUserList.push(newOption);
                    setAllSelectedEmails([])
                    setTimeout(() => {
                        setAllSelectedEmails(sharedUserList)
                    }, 50);
                } else {
                    toast.error(new_user_data.msg, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
                return res.data;
            })
            .catch((error) => {
                console.log(error)
            });
    }
    const removeshareduser = (key: any, removedUser: any) => {
        shareWithData.options.push(removedUser);
        for (let i = 0; i < shareWithData.options.length; i++) {
            if (removedUser['email'] == shareWithData.options[i]['email']) {
                shareWithData.options[i]['isDisabled'] = false;
            }

        }
        getshareddashboardUsers.splice(key, 1)
        var newArray = []
        for (const key in getshareddashboardUsers) {
            const element = getshareddashboardUsers[key];
            newArray.push(element)
        }
        removedUsersSharedList.push(removedUser.user_id)
        setShareddashboardUsers(newArray);
    }
    const fetchTimeZone = () => {
        const config = {
            headers: { Authorization: BearerToeken }
        };
        axios.get(
            process.env.REACT_APP_BASE_API_URL + 'share-dashboard/time-zone',
            config
        )
            .then(async (res) => {
                // console.log('res=',res.data);
                let timeZoneArr = [];
                for (let i = 0; i < res.data.length; i++) {
                    // const element = array[i];
                    let timeZoneObj = {
                        value: res.data[i]['value'],
                        label: res.data[i]['name']
                    }
                    timeZoneArr.push(timeZoneObj);
                }
                // console.log('timeZoneArr',timeZoneArr)
                await setTimeZoneName(timeZoneArr);
                // console.log('getTimeZoneName',getTimeZoneName)
            })
            .catch((error) => {
                console.log(error)
            });
    }
    useEffect(() => {
        shareWithData.options.length = 0;
        removedUsersSharedList = [];
        setAllSelectedEmails([]);
        setShareddashboardUsers([]);
        fetchTimeZone()
        getDashboardsData();

    }, [])
    const removeNewUser = async (i: any) => {
        await getAllSelectedEmails.splice(i, 1)
        await setAllSelectedEmails([]);
        await setAllSelectedEmails(getAllSelectedEmails);
        setCount(count + 1);
    }
    const checkPublicShareLink = () => {
        if (getPublicLink == true) {

            if (selectedTimezone) {
                if (link.length > 0 && selectedTimezone.value.length > 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
    const tabDisableUnable = () => {
        // console.log('getshareddashboardUsers', getshareddashboardUsers);
        // console.log('ShareddashboardUsersDifference',ShareddashboardUsersDifference.options.values);
        // let difference = _.difference(getshareddashboardUsers, ShareddashboardUsersDifference.options. );
        // console.log('difference',difference);
        if (getAllSelectedEmails.length > 0) {
            // console.log('public tab disabled');
            return true;
        } else {
            return false;

            // console.log('public tab not disabled');

        }
    }
    const tabDisableAccess = () => {
        return false;
    }
    // const handleSelectTab = (key : any) =>{
    //     // console.log('key',key)
    //     if(key == 'Manageaccess'){
    //         if(getAllSelectedEmails.length > 0 ){
    //            console.log('public tab disabled');
    //         }else{
    //            console.log('public tab not disabled');

    //         }
    //     }else{

    //     }
    // }
    return (
        <div>
            <ToastContainer />
            <div
                className='modal fade show d-block'
                id='kt_modal_share_dashboard'
                role='dialog'
                tabIndex={-1}
                aria-modal='false'
            >
                <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                    <div className='modal-content'>
                        <div className='modal-header bg-primary'>
                            <h2 className='fw-bolder text-white'>Share</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => { showModal() }}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                        </div>
                        <div className="customSharingTab">
                            <Tabs
                                defaultActiveKey="Manageaccess"
                                id="uncontrolled-tab-example"
                                // onSelect={handleSelectTab}
                                className="mt-6 "
                                style={{ paddingLeft: '24px' }}
                            >
                                {/* disabled={tabDisableAccess()} */}
                                <Tab eventKey="Manageaccess" title="Manage access" >
                                    <form id='kt_modal_add_user_form' className='form' onSubmit={handleSubmit} noValidate>
                                        <div className="modal-body scroll-yx pb-2">
                                            <div className='row me-n7 pe-7'>
                                                <div className='col-sm-12 mb-7' >
                                                    <label className='fw-bold fs-6 mb-2'>Share with People</label>
                                                    <CreatableSelect
                                                        placeholder={"Select or enter new email"}
                                                        className='customSelectDropdown'
                                                        isMulti
                                                        isClearable={true}
                                                        onChange={handleChange}
                                                        options={shareWithData.options}
                                                        name='userEmails'
                                                        value={getAllSelectedEmails}
                                                    />
                                                </div>
                                                {getAllSelectedEmails.length > 0 ?
                                                    <div className='col-sm-12 mb-7'>
                                                        <div><h5>New Users</h5></div>
                                                        <div className='table-responsivex custom-table-height'>
                                                            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                                                <thead>
                                                                    <tr className='border-0'>
                                                                        <th className='p-0 min-w-150px'></th>
                                                                        <th className='p-0 min-w-50px'></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody key={count}>
                                                                    {getAllSelectedEmails.map((item: any, index: any) =>
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <div className='symbol symbol-45px me-2 symbol-circle'>
                                                                                        {
                                                                                            item.__isNew__ ?
                                                                                                (

                                                                                                    <span className={'symbol-label text-uppercase bg-' + item.label.charAt(0)}>
                                                                                                        <span className='text-white fs-3 fw-bolder'>
                                                                                                            {item.label.charAt(0)}

                                                                                                        </span>

                                                                                                    </span>
                                                                                                ) : (
                                                                                                    item.first_name ?
                                                                                                        (
                                                                                                            <span className={'symbol-label text-uppercase bg-' + item.first_name.charAt(0)}>
                                                                                                                <span className='text-white fs-3 fw-bolder'>{item.first_name.charAt(0)}</span>

                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span className={'symbol-label text-uppercase bg-' + item.label.charAt(0)}>
                                                                                                                <span className='text-white fs-3 fw-bolder'>{item.label.charAt(0)}</span>

                                                                                                            </span>
                                                                                                        )
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        <span className='text-dark text-hover-primary fs-5 d-block'>
                                                                                            {
                                                                                                item.first_name && item.last_name ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <div className='fw-bolder'>
                                                                                                                {item.first_name + ' ' + item.last_name}
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                {item.label}

                                                                                                                {getspinner &&
                                                                                                                    <span ><span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>
                                                                                                                }
                                                                                                            </div>


                                                                                                        </>
                                                                                                    ) : (

                                                                                                    
                                                                                                       
                                                                                                        item.isValid === false ?
                                                                                                            <>
                                                                                                                <div>{item.label}</div>
                                                                                                                <div style={{color:'red'}}>Invalid Email .</div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <div>{item.label }
                                                                                                                    {getspinner &&
                                                                                                                        <span ><span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {
                                                                                                                    item.__isNew__
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            <div className=' badge bg-success color_1'>To be invited</div>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        ('')
                                                                                                                }

                                                                                                            </>
                                                                                                    )
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className='text-end' key={count}>
                                                                                <select className='form-select' value={item.permission} onChange={(event) => changeDropDownUserPermission(event.target.value, index)}>
                                                                                    <option value="can_view">Can View</option>
                                                                                    <option value="can_edit">Can Edit</option>
                                                                                </select>
                                                                            </td>
                                                                            <td > {item._id}
                                                                                <a
                                                                                    onClick={(e) => removeNewUser(index)}
                                                                                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                                                                >
                                                                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                                }
                                                {getshareddashboardUsers.length > 0
                                                    ?
                                                    <div><h5>Existing Users</h5></div>
                                                    :
                                                    ('')
                                                }

                                                <div className='table-responsivex custom-table-height'>
                                                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                                        <thead>
                                                            <tr className='border-0'>
                                                                <th className='p-0 min-w-150px'></th>
                                                                <th className='p-0 min-w-50px'></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody key={count}>
                                                            {/* <div >{JSON.stringify(getOwnerDashboardObject)}</div> */}
                                                            {/* {getOwnerDashboardObject.user_id

                                                                ?
                                                                <tr key={getOwnerDashboardObject.user_id}>
                                                                    <td>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-45px me-2 symbol-circle'>
                                                                                {
                                                                                    getOwnerDashboardObject.__isNew__ ?
                                                                                        (
                                                                                            <span className={'symbol-label text-uppercase bg-' + getOwnerDashboardObject.label.charAt(0)}>
                                                                                                <span className='text-white fs-3 fw-bolder'>
                                                                                                    {getOwnerDashboardObject.label.charAt(0)}
                                                                                                </span>
                                                                                            </span>
                                                                                        ) : (
                                                                                            getOwnerDashboardObject.first_name ?
                                                                                                (
                                                                                                    <span className={'symbol-label text-uppercase bg-' + getOwnerDashboardObject.first_name.charAt(0)}>
                                                                                                        <span className='text-white fs-3 fw-bolder'>{getOwnerDashboardObject.label.charAt(0)}</span>
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )
                                                                                        )
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <span className='text-dark text-hover-primary fs-5 d-block'>
                                                                                    {
                                                                                        getOwnerDashboardObject.first_name && getOwnerDashboardObject.last_name ?
                                                                                            (
                                                                                                <>
                                                                                                    <div className='fw-bolder'>
                                                                                                        {getOwnerDashboardObject.first_name + ' ' + getOwnerDashboardObject.last_name}
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {getOwnerDashboardObject.label}
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                getOwnerDashboardObject.isValid === false ?
                                                                                                    <>
                                                                                                        <div>{getOwnerDashboardObject.label}</div>
                                                                                                        <div style={{ color: 'red' }}>Invalid Email</div>
                                                                                                    </>

                                                                                                    : getOwnerDashboardObject.__isNew__ === true ?
                                                                                                        <>
                                                                                                            <div>{getOwnerDashboardObject.label} </div>
                                                                                                            <div >
                                                                                                                <button className='btn btn-primary' onClick={(event: any) => { verifyUser(getOwnerDashboardObject, event, getOwnerDashboardObject._id) }}>Verify</button>
                                                                                                            </div>
                                                                                                        </>

                                                                                                        : getOwnerDashboardObject.label
                                                                                            )
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td className='text-end' >
                                                                        <select className='form-select' disabled>
                                                                            <option value="is_owner">Is owner</option>
                                                                        </select>
                                                                    </td>
                                                                    <td >

                                                                    </td>
                                                                </tr>

                                                                :
                                                                ('')

                                                            } */}
                                                            {getshareddashboardUsers.map((item: any, index: any) =>

                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-45px me-2 symbol-circle'>
                                                                                {
                                                                                    item.__isNew__ ?
                                                                                        (
                                                                                            <span className={'symbol-label text-uppercase bg-' + item.label.charAt(0)}>
                                                                                                <span className='text-white fs-3 fw-bolder'>
                                                                                                    {item.label.charAt(0)}
                                                                                                </span>
                                                                                            </span>
                                                                                        ) : (
                                                                                            item.first_name ?
                                                                                                (
                                                                                                    <span className={'symbol-label text-uppercase bg-' + item.first_name.charAt(0)}>
                                                                                                        <span className='text-white fs-3 fw-bolder'>{item.first_name.charAt(0)}</span>
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <span className={'symbol-label text-uppercase bg-' + item.label.charAt(0)}>
                                                                                                        <span className='text-white fs-3 fw-bolder'>{item.label.charAt(0)}</span>
                                                                                                    </span>
                                                                                                )
                                                                                        )
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <span className='text-dark text-hover-primary fs-5 d-block'>
                                                                                    {
                                                                                        item.first_name && item.last_name ?
                                                                                            (
                                                                                                <>
                                                                                                    <div className='fw-bolder'>
                                                                                                        {item.first_name + ' ' + item.last_name}
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {item.label}
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                item.isValid === false ?
                                                                                                    <>
                                                                                                        <div>{item.label}</div>
                                                                                                        <div style={{ color: 'red' }}>Invalid Email</div>
                                                                                                    </>

                                                                                                    : item.__isNew__ === true ?
                                                                                                        <>
                                                                                                            <div>{item.label} </div>
                                                                                                            <div >
                                                                                                                <button className='btn btn-primary' onClick={(event: any) => { verifyUser(item, event, index) }}>Verify</button>
                                                                                                            </div>
                                                                                                        </>

                                                                                                        : item.label
                                                                                            )
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td className='text-end' key={count}>
                                                                        <select className='form-select' value={item.permission} onChange={(event) => changePermission(event.target.value, index)}>
                                                                            <option value="can_view">Can View</option>
                                                                            <option value="can_edit">Can Edit</option>
                                                                        </select>
                                                                    </td>
                                                                    <td > {item._id}
                                                                        <a
                                                                            onClick={(event) => removeshareduser(index, item)}
                                                                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                                                        >
                                                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer bg-light flex-row-reverse">
                                            <button type="submit" className="btn btn-primary btn-primary" >
                                                {!getspinner && (
                                                    <span className='indicator-label'>Save User Permissions
                                                    </span>
                                                )}
                                                {getspinner && (
                                                    <span >
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                            <button type="reset" className="btn btn-white" data-bs-dismiss="modal" onClick={() => { showModal() }}> Cancel </button>
                                        </div>

                                    </form>
                                </Tab>
                                {/* disabled={tabDisableUnable()} */}
                                <Tab eventKey="publicLink" title="Public link" >
                                    <form id='kt_modal_add_user_form' className='form' onSubmit={handleSharePublicLink} noValidate>
                                        <div className="modal-body scroll-yx pb-2">
                                            <div className='row me-n7 pe-7'>
                                                <div className='col-sm-12 mb-7' >
                                                    {
                                                        getPublicLink ?
                                                            (
                                                                <>
                                                                    {/* <div className='row' >
                                                                        <div className='col-sm-12' key={count}>
                                                                            {getExpireMessageStatus}
                                                                            {getExpireMessageStatus.length > 0 ?
                                                                                (
                                                                                    <><div className="alert alert-dismissible bg-danger d-flex flex-column flex-sm-row p-5 mb-10">
                                                                                        <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                                                                                            <h5 className="mb-2 text-white"><i className="bi bi-flaticon2-bell-4"></i>Date & Time</h5>
                                                                                            <span>
                                                                                                {getExpireMessageStatus}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div></>
                                                                                ) : ('')
                                                                            }

                                                                        </div>
                                                                    </div> */}
                                                                    <div className='row'>
                                                                        <div className='col-sm-7'>
                                                                            <label htmlFor="">&nbsp;</label>
                                                                            <div className="input-group mb-3">
                                                                                <CopyToClipboard
                                                                                    onCopy={onCopyLink}
                                                                                    options={{ message: 'Whoa!' }}
                                                                                    text={link}>
                                                                                    <input type="text" readOnly className='form-control' value={link} />
                                                                                </CopyToClipboard>
                                                                                <CopyToClipboard onCopy={onCopyLink} text={link}>
                                                                                    <span className='btn btn-primary'>  Copy Link </span>
                                                                                </CopyToClipboard>
                                                                            </div>

                                                                        </div>
                                                                        <div className='col-sm-5'>
                                                                            <label htmlFor="">Expires On</label>
                                                                            <div className='form-control-datepicker'>
                                                                                <DatePicker
                                                                                    value={getExpiredDate} onChange={(date) => dataTimeSetStata(date)}
                                                                                    format="MM/DD/YYYY HH:mm A"
                                                                                    plugins={[
                                                                                        <TimePicker position="bottom" hideSeconds />
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                            <div style={{ color: '#CA0B00' }}>
                                                                                {getExpireMessageStatus}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-sm-7' key={count}>
                                                                            <label htmlFor="">Time Zone
                                                                            </label>
                                                                            {/* {currentTimeZoneDateTime(selectedTimezone)} */}

                                                                            <Select onChange={getSelectedTimeZoneFunc}
                                                                                className="basic-single"
                                                                                classNamePrefix="select"
                                                                                isDisabled={false}
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isRtl={false}
                                                                                value={selectedTimezone}
                                                                                isSearchable={true}
                                                                                name="name"
                                                                                options={getTimeZoneName}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            ) : ('')
                                                    }
                                                    <div className="row" style={{ marginTop: '8px' }}>
                                                        <div className='col-sm-12 d-flex-end'>
                                                            <a onClick={publicLinkBtn} className="btn btn-secondary">
                                                                {
                                                                    getPublicLink ?
                                                                        (
                                                                            <span>Cancel Public Link</span>
                                                                        ) : (
                                                                            <span>Get a Public Link</span>
                                                                        )
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="modal-footer bg-light flex-row-reverse">
                                            <button type="submit" className="btn btn-primary btn-primary" disabled={checkPublicShareLink()}>
                                                {!getspinner && (
                                                    <span className='indicator-label'>Save Link Setting</span>
                                                )}
                                                {getspinner && (
                                                    <span >
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                            <button type="reset" className="btn btn-white" data-bs-dismiss="modal" onClick={() => { showModal() }}> Cancel </button>
                                        </div>

                                    </form>
                                </Tab>
                            </Tabs>
                        </div>

                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </div>
    )

}

export { DashboardShareModel }
