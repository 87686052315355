/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import SwitchWorkspacePage from './SwitchWorkspacePage'

const SwitchWorkspaceWrapper: FC = () => {
  const intl = useIntl()

  useEffect(() => {
  }, [])


  return (
    <>
      <PageTitle breadcrumbs={[]}>Overview</PageTitle>
      <SwitchWorkspacePage className='card-xl-stretch mb-xl-8'/>
    </>
  )
}

export { SwitchWorkspaceWrapper }
