/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import WorkspacesList from './WorkspacesList'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { WorkspaceUserEditModal } from './WorkspaceUserEditModel'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
const WorkspaceListUrl = `${API_URL}/internal/customers`


const WorkspacesPage: FC = () => (

  <>
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-12'>
        <WorkspacesList className='card-xxl-stretch mb-5 mb-xl-12' />
      </div>

    </div>

  </>
)

const Workspaces: FC = () => {

  const [showModal, setShowModal] = useState(false)

  const [workspacesList, setWorkspacesList] = useState<any[]>([]);
  const showHideModel: any = () => {
    setShowModal(!setShowModal)
  }
  const fetchWorkspacesList: any = () => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const workspacesListList = axios.get(
      WorkspaceListUrl,
      config
    ).then(async (res: any) => {
      const { data: workspacesListData } = res
      return await setWorkspacesList(workspacesListData)
    }
    ).catch(console.log);
    return workspacesListList
  }

  useEffect(() => {
  }, [])

  return (
    <>
    {
      _.includes(store.getState().userData.permissions,'view_workspaces')?
      (
        <>
          <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Workspaces</h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">Workspaces</li>
              </ul>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              {
                _.includes(store.getState().userData.permissions,'manage_workspaces')?
                (
                  <a className="btn btn-sm fw-bolder btn-primary customIconButton" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => { setShowModal(true) }}>
                      <span className="svg-icon svg-icon-muted svg-icon-18"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/><rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/><rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/></svg></span>
                      <span className='btnTxt'>Workspace</span> 
                  </a>
                ):('')
              }
            </div>
          </div>
          <div>
            <Modal
              className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
              id='kt_inbox_compose'
              role='dialog'
              data-backdrop='false'
              aria-hidden='true'
              tabIndex='-1'
              show={showModal}
              animation={false}
            >
              <WorkspaceUserEditModal showHideModel={showHideModel} fetchWorkspacesList={fetchWorkspacesList} />
            </Modal>
          </div>
          <WorkspacesPage />
        </>
      ):(
        <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
      )
    }
    </>
  )
}

// export { Workspaces }
export default connect(mapStateToProps)(Workspaces);