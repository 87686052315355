
import { FC, useState, useEffect } from 'react'


import { Modal } from 'react-bootstrap'

import axios from 'axios'
import { WorkspaceUserEditModal } from './WorkspaceUserEditModel'

import { useParams, useNavigate } from "react-router-dom";

import { KTSVG } from '../../../_metronic/helpers'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { ToastContainer, toast } from 'react-toastify';
import '../../../../node_modules/react-toastify/dist/ReactToastify.css';
const API_URL = process.env.REACT_APP_API_URL
const ApiToken = localStorage.getItem('api_token')
const BearerToken = `Bearer ${ApiToken}`
const OrganizationsListUrl = `${API_URL}/internal/customers`

// type Props ={
//     test23:boolean
// }
interface PostsProps {
    showHideEditUserModal: any;
    showEditUserModal: any;
    getDashboardObject: any;
    fetchWorkspacesList(): any;
}

const DashboardCloneModal: FC<PostsProps> = ({ showEditUserModal, showHideEditUserModal, getDashboardObject, fetchWorkspacesList }) => {

    let { workspaceId }: any = useParams();

    let { organisationUsersId }: any = useParams();
    // const { setItemIdForUpdate } = useListView()
    // const { refetch } = useQueryResponse()
    const [getspinner, setspinner] = useState(false)
    const [getDashboardData, setDashboardData]: any = useState<any[]>([]);
    const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);
    
    const [getSelectedSpecialtyValue, setSelectedSpecialtyValue] = useState<any[]>([])
    const [getSelectedSpecialty, setSelectedSpecialty] = useState<any[]>([])
    const [getSpecialties, setSpecialties] = useState<any[]>([]);

    const internalRolePermissionList = () => {
        const internalRolesPermissionsUrl = `${API_URL}/roles-permissions`

        const config = {
            headers: { Authorization: BearerToken }
        };
        const internalRolesList = axios.get(
            internalRolesPermissionsUrl,
            config
        ).then(async (res) => {
            const { data: rolespermissionsDataResult } = res
            return await setinternalRolesPermissionsListResult(rolespermissionsDataResult)
        }
        ).catch(console.log);
        return internalRolesList

    }

    const dashboardCloneSchema = Yup.object().shape({
        name: Yup.string()
            .required('name is required'),
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            // refetch()
        }
        // setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: dashboardCloneSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {
                const formDataObj = getDashboardData
                // formDataObj.organization_id = workspaceId
                formDataObj.name = formik.values.name
                formDataObj.workspaceId = workspaceId
                setspinner(true)
                dashboardClone(formDataObj)
                // }
            } catch (ex) {
                toast.error("Sorry,looks like there are some errors detected!.Please try again...", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                console.error(ex)
            } finally {
                setSubmitting(true)
                cancel(true)
            }
        },
    })

    const dashboardClone = (data: any) => {
        // console.log('getContactData=',getContactData)
        axios.post(process.env.REACT_APP_BASE_API_URL + '/internal/dashboard/clone', data)
            .then((res) => {
                // console.log("response=", res);
                setspinner(false)
                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    showHideEditUserModal();
                    fetchWorkspacesList();
                } else {

                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    showHideEditUserModal();
                }
            })
            .catch(error => {
                if(error.response.data.errors && error.response.data.errors.length > 0){
                    if(error.response.data.errors[0] && error.response.data.errors[0]['msg']){
                        toast.error(error.response.data.errors[0]['msg'], {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        if(error.response.data.message){
                            toast.error(error.response.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    }
                } else {
                    if(error.response.data.message){
                        toast.error(error.response.data.message, {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                }
                setspinner(false)
                fetchWorkspacesList();
            })
    }

    const notify = () => {
        // console.log('formik.errors=',formik.errors)
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    // const getSpecialtiesData = () => {
    //     axios.get(process.env.REACT_APP_BASE_API_URL + 'internal/specialties/'+workspaceId)
    //         .then(async (res) => {
    //             setspinner(false)
    //             setSpecialties(res.data)
    //             // loadData()
    //             // optionsOrganizations = res.data
    //         })
    //         .catch(error => {
            
    //             toast.error("Sorry,looks like there are some errors detected!.Please try again", {
    //                 position: getHelpers.toastPosition(),
    //                 autoClose: getHelpers.toastAutoClose(),
    //                 theme: 'colored'
    //             });
        
    //             setspinner(false)
    //             // fetchorganizationsList();
    //         })
    // }
    
    // const handleChange = (
    //     newValue: any,
    //     actionMeta: ActionMeta<any>
    // ) => {
    //     setSelectedSpecialty(newValue)
    //     setSelectedSpecialtyValue(newValue)
    // };

    useEffect(() => {
        // getSpecialtiesData();
        // internalRolePermissionList()
        if (getDashboardObject) {
            formik.values.name = getDashboardObject.name+' - Copy'
            setDashboardData(getDashboardObject)
        }

    }, [getDashboardObject])

    return (
        <div>
            {/* <ToastContainer></ToastContainer> */}
            <Modal
                className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
                id='kt_inbox_compose'
                role='dialog'
                data-backdrop='false'
                aria-hidden='true'
                tabIndex='-1'
                show={showEditUserModal}
                animation={false}
            >
                <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='false'
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog custom_modal modal-dialog-centered mw-650px'>
                        {/* begin::Modal content */}
                        <div className='modal-content'>
                            {/* <UserEditModalHeader /> */}
                            <div className='modal-header bg-primary'>
                                {/* begin::Modal title */}
                                <h2 className='fw-bolder text-white'>Clone Dashboard </h2>
                                {/* end::Modal title */}

                                {/* begin::Close */}
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => { showHideEditUserModal() }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                                {/* end::Close */}
                            </div>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                                <div className='modal-body scroll-yx pb-2'>
                                    <div
                                        className='d-flex flex-column scroll-yx me-n7 pe-7'
                                        id='kt_modal_add_user_scroll'
                                        data-kt-scroll='true'
                                        data-kt-scroll-activate='{default: false, lg: true}'
                                        data-kt-scroll-max-height='auto'
                                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                        data-kt-scroll-offset='300px'
                                    >
                                        <div className='row'>
                                            <div className='col-sm-12 mb-7'>
                                                <label className='fw-bolder fs-6 mb-2'>Name </label>
                                                <input
                                                    placeholder='Name'
                                                    type='text'
                                                    {...formik.getFieldProps('name')}
                                                    name='name'
                                                    className={clsx(
                                                        'form-control form-control-solid mb-3 mb-lg-0',
                                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                                        {
                                                            'is-valid': formik.touched.name && !formik.errors.name,
                                                        }
                                                    )}
                                                    // onChange={handleFNameChange}
                                                    autoComplete='off'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer bg-light flex-row-reverse">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        onClick={() => notify()}
                                    >{!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                        {getspinner && (
                                            <span >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <button
                                        type='button' // {()=>{showHideModel(cancelModel)}}
                                        onClick={() => showHideEditUserModal()}
                                        className='btn btn-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                            {/* end::Modal body */}
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
            </Modal>

        </div>

    )

}

export { DashboardCloneModal }