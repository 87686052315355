/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import WorkspaceArchiveDashboardsList from './WorkspaceArchiveDashboardsList'
import WorkspaceHeaderSection from './WorkspaceHeaderSection';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import axios from 'axios'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
interface PageProps {
  organisationUsersId: any;
}
const WorkspaceArchiveDashboardPage: FC<PageProps> = ({ organisationUsersId }) => {
  const [workspaceData, setworkspaceData]: any = useState<any[]>([]);
  const [workspaceUsersData, setworkspaceUsersData]: any = useState<any[]>([]);
  const [getDashboardsCount, setDashboardsCount]: any = useState<any>(0);
  let { workspaceId }: any = useParams();
  const [getComponentLoading, setComponentLoading] = useState(false)
  const navigate = useNavigate();
  const WorkspaceUsersURL = `${API_URL}/internal/workspace/` + workspaceId
  const fetchWorkspaceUsersList: any = () => {
    setComponentLoading(true)
    const config = {
      headers: { Authorization: BearerToeken }
    };
    const workspacesList = axios.get(
      WorkspaceUsersURL,
      config
    ).then(async (res: any) => {
      await setDashboardsCount(res.data.dashboardsCount);
      await setworkspaceUsersData(res.data.users);
      await setworkspaceData(res.data.workspace)
      setComponentLoading(false)
      return [setworkspaceUsersData, setworkspaceData];
    }
    ).catch(console.log);
    return workspacesList
  }
  useEffect(() => {
    const config = {
      headers: { Authorization: BearerToeken }
    };
    axios.get(
      GET_USER_BY_ACCESSTOKEN_URL,
      config
    )
      .then(async (res) => {
        if (res.data.status) {
          if (res.data.userType == 'external') {
            navigate('/error/404')
          }
        } else if (res.data.userType == 'User type not found') {
          if (getUserInfo.userType() == 'external') {
            navigate('/error/404')
          }
        }
      })
      .catch((error) => {
        console.clear()
        navigate('/error/404')
      });
    fetchWorkspaceUsersList()
  }, [])
  return (
    <>
    {
      _.includes(store.getState().userData.permissions,'manage_organizations_dashboards')?
      (
        <>
          <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">{workspaceData.name} Workspace</h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">
                  <Link to={'/internal/workspaces/list'} className="text-muted text-hover-primary">Workspaces</Link>
                </li>
                <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                <li className="breadcrumb-item text-muted">Dashboards</li>
              </ul>
            </div>
          </div>
          <WorkspaceHeaderSection organizationId={workspaceId} fetchWorkspaceUsersList={fetchWorkspaceUsersList} workspaceData={workspaceData} workspaceUsersData={workspaceUsersData} getDashboardsCount={getDashboardsCount} getComponentLoading={getComponentLoading} />
          <div className='row gy-5 g-xl-8'>
            <div className='col-xxl-12'>
              <WorkspaceArchiveDashboardsList className='card-xxl-stretch mb-5 mb-xl-12' orgId={workspaceId} />
            </div>
          </div>
        </>
      ):(
        <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
      )
    }
    </>
  )
}
// export { WorkspaceArchiveDashboardPage }
export default connect(mapStateToProps)(WorkspaceArchiveDashboardPage);
