/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import * as getHelpers from '../../../app/modules/auth/GetHelpers'
import axios from 'axios'
import { ComponentLoading } from '../../modules/ComponentLoading'
import Swal from 'sweetalert2'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const API_URL = process.env.REACT_APP_API_URL

const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`

const InternalGDWorkspacesPage: FC = () => {

  const [getGdWorkspaces, setGdWorkspaces] = useState<any[]>([]);
  const [getGdStringomediaUsers, setGdStringomediaUsers] = useState<any[]>([]);
  const [getComponentLoading, setComponentLoading] = useState(false)
  const [getspinner, setspinner] = useState(false)

  const [getCheckedUsers, setCheckedUsers] = useState<any[]>([]);
  const [isallChecked, allChecked]: any = useState(false);
  const [checked, setChecked]: any = useState([]);

  const fetchGdUsers = () => {
    setComponentLoading(true)
    axios.get(process.env.REACT_APP_BASE_API_URL + 'script/gd-workspaces')
      .then(async (res) => {
        setComponentLoading(false)
        if (res.data.status) {

          setGdWorkspaces(res.data.workspaces)
          setGdStringomediaUsers(res.data.stringomediaUsers)

        } else {

          if (res.data.errors && res.data.errors.length > 0) {
            if (res.data.errors[0] && res.data.errors[0]['msg']) {
              toast.error(res.data.errors[0]['msg'], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }

          setComponentLoading(false)

        }
      })
      .catch(error => {

        if (error.response.data.errors && error.response.data.errors.length > 0) {
          if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
            toast.error(error.response.data.errors[0]['msg'], {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          } else {
            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
              position: getHelpers.toastPosition(),
              autoClose: getHelpers.toastAutoClose(),
              theme: 'colored'
            });
          }
        } else {
          toast.error("Sorry,looks like there are some errors detected!.Please try again", {
            position: getHelpers.toastPosition(),
            autoClose: getHelpers.toastAutoClose(),
            theme: 'colored'
          });
        }

        setComponentLoading(false)
        // fetchorganizationsList();
      })

  }

  // checked all
  // const handleCheckedAll = (event: any) => {
  //   // console.log('event=',event.target.value,event.target.checked)
    
  //   var isChecked = isAll;
  //   if (event.target.checked) {
  //     isChecked = 1;
      
  //     var updatedList : any = [];
  //     getGdWorkspaces.forEach((element:any) => {
  //       updatedList.push(element.workspaceId);
  //     });

  //     setChecked(updatedList);
  //   } else {
  //     isChecked = 0;
  //     setChecked([]);
  //   }
  //   setIsAll(isChecked);
    

  //   console.log('isAll=',isAll)
  // };

  // Add/Remove checked item from list
  // const handleCheck = (event: any) => {
  //   console.log('event=',event,event.target.value)
  //   var updatedList = [...checked];
  //   if (event.target.checked) {
  //     updatedList = [...checked, event.target.value];
  //   } else {
  //     updatedList.splice(checked.indexOf(event.target.value), 1);
  //   }
  //   setChecked(updatedList);
  //   console.log('checked=',checked)
  // };

  const handleCheck = (event:any) => {
    
    let productName = event.target.name;
    let checked = event.target.checked;

    if(productName == 'selectAll'){

      allChecked(checked)
      var list = getGdWorkspaces.map(item => ({ ...item, isChecked: checked }));
      setGdWorkspaces([...list]);
      const checkedItems = list.filter(item => item.isChecked);
      // getAllCheckedUsers();
      var getItems : any = [];
      checkedItems.forEach((element:any) => {
        getItems.push(element.workspaceId)
      });
      setCheckedUsers(getItems)

    } else {
      // console.log('event=',event.target.checked,event.target.name,event.target.value)
      const index = getGdWorkspaces.findIndex((list) => list.workspaceId == event.target.value);
      getGdWorkspaces[index].isChecked = event.target.checked;
      setGdWorkspaces([...getGdWorkspaces]);
      getAllCheckedUsers();
      // const checkedItems = getGdWorkspaces.filter(item => item.isChecked);
    }

  };

  const getAllCheckedUsers = () => {
    const checkedItems = getGdWorkspaces.filter(item => item.isChecked);
    var getItems : any = [];
    checkedItems.forEach((element:any) => {
      getItems.push(element.workspaceId)
    });
    setCheckedUsers(getItems)
  }

  const deleteGdWorkspaces = () => {
    Swal.fire({
      title: getHelpers.ConfirmDeleteMsg(),
      icon: getHelpers.ConfirmDeleteIcon().toString(),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        setspinner(true)
        axios.post(process.env.REACT_APP_BASE_API_URL + 'script/gd-workspaces',{
          workspaces: getCheckedUsers
        })
          .then(async (res) => {
            setspinner(false)
            if (res.data.status) {

              fetchGdUsers()
              setChecked([])
              allChecked(false)

              toast.success(res.data.message, {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });

            } else {

              if (res.data.errors && res.data.errors.length > 0) {
                if (res.data.errors[0] && res.data.errors[0]['msg']) {
                  toast.error(res.data.errors[0]['msg'], {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                  });
                } else {
                  toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                  });
                }
              } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
                });
              }

              setspinner(false)

            }
          })
          .catch(error => {

            if (error.response.data.errors && error.response.data.errors.length > 0) {
              if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                toast.error(error.response.data.errors[0]['msg'], {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
                });
              } else {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                  position: getHelpers.toastPosition(),
                  autoClose: getHelpers.toastAutoClose(),
                  theme: 'colored'
                });
              }
            } else {
              toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
              });
            }

            setspinner(false)
            // fetchorganizationsList();
          })
      }
    })
  }

  useEffect(() => {
    fetchGdUsers()
  }, []);


  const intl = useIntl()
  return (
    <>
      <ToastContainer />
      <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">Delete Unused GD Workspaces</h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
            </li>
            <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
            <li className="breadcrumb-item text-muted">
              <Link to={'/internal/utilities'} className="text-muted text-hover-primary">Utilities</Link>
            </li>
            <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
            <li className="breadcrumb-item text-muted">Delete Unused GD Workspaces</li>
          </ul>
        </div>
        <div className="d-flex align-items-center gap-2 gap-lg-3">
          {/* <AddInternalUserModal  /> */}
        </div>
      </div>

      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          {/* <InternalUsersTable  className='card card-xxl-stretch mb-5 mb-xl-12 card-border-radius'  /> */}
          <div className={`card card-xxl-stretch mb-5 mb-xl-12 card-border-radius overflow-hidden`}>

            {/* <ToastContainer /> */}

            {
              getComponentLoading ?
                (
                  <ComponentLoading></ComponentLoading>
                ) : ('')
            }


            <div className='card-body bg-white'>

              <div className="alert alert-primary" role="alert">
              Following workspaces exist in GD but are no longer in use of this application. You can delete them to make GD data more cleaner.
              </div>

              {/* {JSON.stringify(getGdWorkspaces)} */}
              {/* {JSON.stringify(getGdStringomediaUsers)} */}

              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  {
                    getCheckedUsers.length > 0?
                    (
                      <span>
                        Selected {getCheckedUsers.length>1?('Users'):('User')}:  
                        <span className='mx-2'>{getCheckedUsers.length}</span>
                      </span>
                    ):('')
                  }
                </div>

                <div>
                  <button type="button" onClick={() => { deleteGdWorkspaces(); }} className="btn btn-primary btn-primary" disabled={getspinner || getCheckedUsers.length > 0?false:true}>
                    {!getspinner && (
                      <span className='indicator-label'>Delete {getCheckedUsers.length>1?('Workspaces'):('Workspace')}</span>
                    )}
                    {getspinner && (
                      <span >
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              
              </div>

              <br  />

              <div className="form-check form-check-custom form-check-solid mt-2 mb-2">
                <label className="form-check-label d-flex ml-0" htmlFor={'selectAll'}>
                  {/* <input className="form-check-input me-3" type="checkbox" value={1} id={'selectAll'} onChange={handleCheckedAll} /> */}
                  <input
                    className="form-check-input me-3"
                    type="checkbox"
                    name="selectAll"
                    id={'selectAll'}
                    checked={isallChecked}
                    onChange={handleCheck}
                  />
                  Select All
                </label>
              </div>

              <br  />

              <div className='row'>

                {getGdWorkspaces.map((item: any, index: any) => (
                  <>
                    <div className='col-sm-6'>

                      <div key={index} className="form-check form-check-custom form-check-solid mt-2 mb-2">
                        <label className="form-check-label d-flex ml-0" htmlFor={item.workspaceId}>
                          <input className="form-check-input me-3" type="checkbox" value={item.workspaceId} id={item.workspaceId} onChange={handleCheck} checked={item.isChecked} />
                          {item.projectTitle}

                          {/* <OverlayTrigger placement={'top'} overlay={<Tooltip className='fw-bolder'>Data, Dashboards and Users.</Tooltip>}><span className="svg-icon svg-icon-muted svg-icon-18" style={{marginLeft:'5px'}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/><rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/><rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/></svg></span></OverlayTrigger> */}

                          {/* {
                            item.deleteStatus == 'deleted' ?
                              (<><span className='text-danger' style={{ marginLeft: '5px' }}>({item.deleteStatus})</span></>) : ('')
                          } */}
                        </label>
                      </div>
                        {/* <div key={index}>
                            <label htmlFor={item.workspace_id}>
                                <input value={item.workspace_id} id={item.workspace_id} type="checkbox" onChange={handleCheck} />
                                <span className={isChecked(item.workspace_id)}> {item.workspace_name} including its dashboards and users.</span>
                            </label>
                        </div> */}
                    </div>
                  </>
                ))}

              </div>

              {/* <div className='table-responsive'>
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer internalUsersTableList">
                        <thead></thead>
                        </table>
                    </div> */}

            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export { InternalGDWorkspacesPage }
