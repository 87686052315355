/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OverviewStatsCard from '../../pages/overview/OverviewStatsCard'
import { RecentDashboardsTable } from '../../pages/overview/RecentDashboardsTable'

const OverviewPage: FC = () => (

  <>
    {/* begin::Row */}
    <div className='row mt-10'>
      <div className='col-xl-5'>
        <OverviewStatsCard
          className='card-xl-stretch mb-xl-8'
          chartColor='primary'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xl-7'>
        <RecentDashboardsTable className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>

    </div>

  </>
)

const OverviewWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Overview</PageTitle>
      <OverviewPage />
    </>
  )
}

export { OverviewWrapper }
