/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { getLayout, ILayout, LayoutSetup, useLayout } from '../../../_metronic/layout/core'
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
//////////////////////////////////////////////////////////
//Begin: GoodData Dashboard///////////////////////////////
//////////////////////////////////////////////////////////
import "@gooddata/sdk-ui-filters/styles/css/main.css";
import "@gooddata/sdk-ui-charts/styles/css/main.css";
import "@gooddata/sdk-ui-geo/styles/css/main.css";
import "@gooddata/sdk-ui-pivot/styles/css/main.css";
import "@gooddata/sdk-ui-kit/styles/css/main.css";
import "@gooddata/sdk-ui-ext/styles/css/main.css";
import { InsightView } from "@gooddata/sdk-ui-ext";
import bearFactory, { ContextDeferredAuthProvider } from "@gooddata/sdk-backend-bear";
import { BackendProvider, WorkspaceProvider } from "@gooddata/sdk-ui";
import { ColumnChart, BarChart } from "@gooddata/sdk-ui-charts";
// import { modifyAttribute, modifyMeasure } from "@gooddata/sdk-model";
import "@gooddata/sdk-ui-dashboard/styles/css/main.css";
import { Dashboard, DashboardConfig, HiddenTopBar, IButtonBarProps } from "@gooddata/sdk-ui-dashboard";
// //console.log('HiddenTopBar',HiddenTopBar)
import * as _ from "lodash";
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { ComponentLoading } from '../../modules/ComponentLoading'
import {
  newAttribute,
  newMeasure,
  IAttribute,
  IMeasure,
  IMeasureDefinition,
  idRef,
  modifyAttribute, modifyMeasure
} from "@gooddata/sdk-model";
import { toPng } from 'html-to-image';
import { setTimeout } from 'timers';
import { ITopBarProps } from "@gooddata/sdk-ui-dashboard/esm/presentation/topBar/topBar/types";
import axios from 'axios'
import { getFactory } from "@gooddata/api-client-bear";
import { DashboardLoader } from '../../modules/DashboardComponentLoader'
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import internal from 'stream';
import { relative } from 'path';
// import {GdDashboardSaveButtonProps} from '../../../_metronic/layout/components/header/GdDashboardSaveButtonProps'
let backendUrl: any = process.env.REACT_APP_GD_BACKEND_URL
const backend = bearFactory()
  .onHostname(backendUrl)
  .withAuthentication(new ContextDeferredAuthProvider());
const ApiToken = localStorage.getItem('api_token')
const BearerToeken = `Bearer ${ApiToken}`
let dashboard_id = '';
let workspaceId = '';
const GoodDataDashboardEdit: React.FC = () => {
  const { setLayout } = useLayout()
  const ApiToken = localStorage.getItem('api_token')
  const BearerToeken = `Bearer ${ApiToken}`
  const [getAsideComponentLoading, setAsideComponentLoading] = useState(true)
  const [setHeight, setsetHeight] = useState(550);
  const [saveDashboard, setsaveDashboard] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);
  const [getEditShowDashboad, setEditShowDashboad] = useState<boolean>(true)
  const [checkSsoLogin, setcheckSsoLogin] = useState<boolean>(false)
  const [loadingMessage, setloadingMessage] = useState<any>('');
  const [loadingMessageError, setloadingMessageError] = useState<boolean>(false)
  const [hyperlinkText, sethyperlinkText] = useState<boolean>(false)

  // 
  const navigate = useNavigate();
  const iframeRef: any = useRef()

  let params = useParams();
  const dashboardParamId = params.dashboardId;

  const Config: DashboardConfig = {
    // hideSaveAsNewButton: true,
    // disableDefaultDrills : false
    initialRenderMode: "edit"
  };
  // TopBarComponent={HiddenTopBar}
  const url = process.env.REACT_APP_GD_BACKEND_URL + `/dashboards/embedded/#/workspace/` + workspaceId + `/dashboard/` + dashboard_id + `?hideControl=[topBar]`
  // hideControl=[widgetsCatalogue,deleteButton,saveAsButton,shareAsButton,topBar]&
  // "https://stringomedia.on.gooddata.com/dashboards/embedded/#/workspace/hcpedkf3fpdh29glbrndqj0xu8kjoljz/dashboard/aaAxaLgZdXxf?hideControl=[widgetsCatalogue,deleteButton,saveAsButton,shareAsButton]&showNavigation=false&setHeight=2500px"
  // const iframeURL = "https://stringomedia.on.gooddata.com/analyze/embedded/#/uija2uiown2uiow091m497yb1hh9ywht/abqoZR6DoDfK/edit?setHeight=700px";
  const onButtonClick = useCallback(() => {

    if (ref.current === null) {
      return
    }

    toPng(ref.current, { cacheBust: true, })
      .then((screenShotImageBase64) => {
        let captureObj = {
          dashboard_id: dashboardParamId,
          base64Image: screenShotImageBase64
        }
        axios.post(
          process.env.REACT_APP_BASE_API_URL + 'dashboard/screenShotSave', captureObj
        )
          .then(async (res) => {
            // console.log('res.data', res.data);
          })
          .catch((error) => {
          });
      })
      .catch((err) => {
        //console.log(err)
      })


  }, [ref])
  // let heightValue: any = window.outerHeight;
  useEffect(() => {
    setsetHeight(window.innerHeight - 110.5);
    // console.log('screen-width' , window.innerWidth);
    // console.log('screen-height' , window.innerHeight);
    // alert('Step 2 Edit dashboard component')
    // heightValue = ;
    setloadingMessage('Step 2 loading...')
    const fetchDashboardData = async () => {
      const config = {
        headers: { Authorization: BearerToeken }
      };
      await axios.get(
        process.env.REACT_APP_API_URL + '/dashboard/' + dashboardParamId,
        config
      )
        .then(async (res) => {
          if (res.data.gd_dashboard) {
            if (res.data.gd_dashboard[0]) {
              dashboard_id = res.data.gd_dashboard[0].identifier
              workspaceId = res.data.gd_dashboard[0].workspaceId
              setloadingMessage('Step 3 loading...')
              if (getUserInfo.userType() == 'external') {
                setloadingMessage('Step 4 loading ...')
                axios.get(
                  process.env.REACT_APP_BASE_API_URL + 'gooddata/checking-user/'+workspaceId,
                )
                  .then(async (res) => {
                    let resData = await res.data.newUser;
                    let resSplit = resData.split('/')
                    // setloadingMessage('Step 4 u')
                    ssoEnvironment(resSplit[4])
                  })
                  .catch((error) => {
                    console.log('check user error problem', error);
                    setloadingMessage('There is some problem with the dashboard')
                    setloadingMessageError(true);
                  });
              } else {
                setloadingMessage('Step 4 loading ...')
                axios.get(
                  process.env.REACT_APP_BASE_API_URL + `gooddata/internal/${dashboardParamId}/check-user`,
                )
                  .then(async (res) => {
                    let resData = await res.data.newUser;
                    let resSplit = resData.split('/')
                    // setloadingMessage('Step 4 loading...')
                    ssoEnvironment(resSplit[4])
                  })
                  .catch((error) => {
                    console.log('check user error problem', error);
                    setloadingMessage('There is some problem with the dashboard.')
                    setloadingMessageError(true);
                  });
              }
            } else {
              console.log('Cannot find gd_dashboard Object');
              setloadingMessageError(true);
            }
          } else {
            console.log('Cannot find gd_dashboard');
            setloadingMessage('There is some problem with the dashboard.')
            setloadingMessageError(true);
          }
        })
        .catch((error) => {
          console.log('gd_dashboard problem', error);
          setloadingMessage('There is some problem with the dashboard.')
          setloadingMessageError(true);
        });
    }
    fetchDashboardData();

    let messageArr : any = []
    window.addEventListener('message', (event) => {
      // console.log('gdc', event.data.gdc);
      if (event.data.gdc) {
        if (messageArr.length < 4) {
          setloadingMessage('Step 6 loading ...')
        }
        messageArr.push(event.data)
        if (event.data.gdc.event.name == "resized") {
          if (event.data.gdc.event.data) {
            // 
          }
        }
      }
      console.log('messageArr=',messageArr.length)
      if (messageArr.length == 10) {
        // setTimeout(() => {
          switchToEdit();
        // }, 2000);
        setloadingMessage('Step 7 loading ...')
        return [console.log('comleted')]
      }
    },);
  }, []);
  const switchToEdit = () => {
    console.log('switchToEdit=')
    var targetFrame: any = document?.getElementById("frame");
    let frame = targetFrame.contentWindow;
    var targetOrigin = process.env.REACT_APP_GD_BACKEND_URL;
    var input = {
      "gdc": {
        "product": "dashboard",
        "event": {
          "name": "switchToEdit"
        }
      }
    };
    frame.postMessage(input, targetOrigin);
    setTimeout(() => {
      setloadingMessage('Step 7 complete')
      setAsideComponentLoading(false);
      // onButtonClick();
    }, 3000);
  }
  const ssoEnvironment = (userId: any) => {
    setloadingMessage('Step 5 loading ...');
    axios.post(process.env.REACT_APP_BASE_API_URL + 'openpgp/sso-environment', {
      userId: userId
    })
      .then((res) => {
        // setloadingMessage('Step 5 loading...');
        getEncryptedClaims(res.data)
      }).catch((error) => {
        console.log('There are some problem with sso enviroment', error);
        setloadingMessage('There is some problem with the dashboard')
        setloadingMessageError(true);
      });
  }
  var ssoLoginData: any = ''
  const getEncryptedClaims = async (resData: any) => {
    // setloadingMessage('Step 5 loading...');
    axios.post(process.env.REACT_APP_BASE_API_URL + 'openpgp/encrypted-claims', resData.data)
      .then(async (res) => {
        await localStorage.setItem("encryptedClaims", JSON.stringify(res.data.encryptedClaim))
        await localStorage.setItem("ssoProvider", JSON.stringify(res.data.userId))
        const domain = await process.env.REACT_APP_GD_BACKEND_URL;
        const sdk = await getFactory({ domain });
        const encryptedClaims = await JSON.parse(res.data.encryptedClaim)
        const ssoProvider = await res.data.userId;
        const targetUrl = "/gdc/account/token";
        sdk.user
          .loginSso(encryptedClaims, ssoProvider, targetUrl)
          .then((response) => {
            ssoLoginData = response.responseBody
            setcheckSsoLogin(true)
            // setloadingMessage('Step 5 loading...');
            // setTimeout(() => {
            //   onButtonClick();
            // }, 5000);
          })
          .catch(error => {
            console.log('There are some problem in customer login', error);
            // setloadingMessage('This  dashboard could not be loaded. One of this reason could be that your browser did not allow third party cookies.')
            setloadingMessage('The dashboard could not be loaded.')
            sethyperlinkText(true)
            // setloadingMessageError(true);
          });
      }).catch(error => {
        console.log('There are some problem in encrypted claims', error);
        setloadingMessage('There is some problem with the dashboard')
        setloadingMessageError(true);
      });
  }

  if (saveDashboard) {
    window.addEventListener('message', (event) => {
      if (event.data.gdc) {

        if (event.data.gdc.event.name == 'dashboardUpdated') {
          window.dispatchEvent(new CustomEvent(
            'hideshowDashboardEditIcon',
            {
              detail: {
                toEdit: false
              }
            }
          ))
          setTimeout(() => {
            navigate('/dashboard/' + dashboardParamId)
            setsaveDashboard(false)
          }, 200);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
        }
        if (event.data.gdc.event.name == "switchedToView") {
          setTimeout(() => {
            navigate('/dashboard/' + dashboardParamId)
            setsaveDashboard(false)
          }, 200);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
        }
        if (event.data.gdc.event.name == "availableCommands") {
          let checkEditOrNot = _.filter(
            event.data.gdc.event.data, function (o) {
              return o == 'saveDashboard';
            }
          );
          if (checkEditOrNot.length > 0) {
            setEditShowDashboad(false)
          } else {
            setEditShowDashboad(true)

          }
        }
      }
    },);
  }
  const screenReload = () => {
    window.location.reload();
  }
  return (
    <>
      {/* <div >

    </div> */}
      <div style={{ 'position': 'relative', 'height': setHeight }} ref={ref}>

        {
          getAsideComponentLoading ?
            (
              <div className="componentLoading bg-white">
                <div id="splash-screen" className="splash-screen" style={{ zIndex: 2000 }}>
                  {
                    hyperlinkText || loadingMessageError?
                    (
                      <>
                        <img src="/media/logos/loading-icon.svg" className='loadingIcon width-30' alt="Stringo Media | Analytics Platform" />
                      </>
                    ):(
                      <>
                        <img src={toAbsoluteUrl('/media/logos/loading-icon.gif')} className='loadingIcon' alt="Stringo Media | Analytics Platform" />
                        {/* <div className="rotating loadingIcon">
                          <img className="front" src={toAbsoluteUrl('/media/black-icon-front.svg')} />
                          <img className="back" src={toAbsoluteUrl('/media/black-icon-back.svg')} />
                        </div> */}
                      </>
                    )
                  }
                  {/* <span style={{ fontSize: '1.75rem', fontWeight: '600', fontFamily: 'Averia Serif Libre' }}>stringo media */}
                  {/* </span> */}
                  <span >{loadingMessage} { hyperlinkText == true? <><a className='link cursor-pointer' onClick={screenReload}>Please reload</a>.</> : ('') }</span>
                  {
                    loadingMessageError == true

                      ?
                      <div style={{ marginTop: '5px' }}><button onClick={screenReload} className='btn btn-sm btn-primary'>Try again</button></div>
                      :
                      ('')

                  }
                  {/* {
                    hyperlinkText == true
                      ?
                      <div style={{ marginTop: '5px' }}><a href='https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Callow-or-block-cookies' target="_blank"> Try this </a> and relod this page.</div>
                      :
                      ('')

                  } */}
                </div>
              </div>
              // <DashboardLoader></DashboardLoader>
            ) : ('')
        }

        {checkSsoLogin
          ?
          <div style={{ 'height': setHeight, 'position': 'relative' }} >

            <iframe id='frame' src={url} height={setHeight} width="100%" >
            </iframe>
          </div>
          :
          ('')
        }

      </div>
    </>
  )
}
export { GoodDataDashboardEdit }
