/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

import { Modal } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';

import axios from 'axios'
// import{OrganizationListToolbar} from '../customer-organizations/OrganizationListBar'
import { WorkspaceUserEditModal } from './WorkspaceUserEditModel'
import Multiselect from 'multiselect-react-dropdown';
import * as getUserInfo from '../../../app/modules/auth/GetUserInfo'
import * as getHelpers from '../../../app/modules/auth/GetHelpers'

import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionExternalPermissions } from '../../../redux/actions/externalPermissions';
import { ActionExternalWorkspacePermissions } from '../../../redux/actions/externalWorkspacePermissions';
import { ActionRoles } from '../../../redux/actions/roles';
import {store} from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state:any) => {
  return {
    profile: state.user.profile,
    userData: state.userData.permissions,
    userExternalData: state.userExternalData.permissions,
    userRolesData: state.userRolesData.roles
  }
}

type Props = {
    className: string
}

const shareWithData: any = {
    options: []
};

const ExternalCreateDashboard: React.FC<Props> = ({ className }) => {
    let { organisationUsersId }: any = useParams();
    let { workspaceId } = useParams();
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [getLoading, setLoading] = useState(false)
    const [getStandardUsers, setStandardUsers] = useState<any[]>([]);
    const [getAllSelectedEmails, setAllSelectedEmails] = useState<any>([]);
    const [getspinner, setspinner] = useState(false)
    const [internalRolesPermissionsListResult, setinternalRolesPermissionsListResult] = useState<any[]>([]);
    const showHideModel: any = () => {
        setShowModal(!setShowModal)
    }
    const [getWorkspaces, setWorkspaces] = useState<any[]>([]);
    const [getWorkspace, setWorkspace] = useState<any>({});

    const [getUserInternalPermissions, setUserInternalPermissions] = useState<any[]>([]);
    const [getUserExternalPermissions, setUserExternalPermissions] = useState<any[]>([]);
    const [getExternalWorkspacePermissions, setUserAllExternalPermissions] = useState<any[]>([]);
    const [getUserInternalRoles, setUserInternalRoles] = useState<any[]>([]);
    const [getKey, setKey] = useState<any>(0);

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            // refetch()
        }
    }

    /////////////// organization workspaces
    const fetchOrgWorkspaces = async () => {
        // alert('fetchOrgWorkspaces')
        await axios.get(
        process.env.REACT_APP_BASE_API_URL + "me/org/workspaces",
        )
        .then(async (response) => {
            // alert(1)
            // setAsideComponentLoading(false)
            if (response.data) {
                // alert(2)
                // console.log('response.data.workspaces=',response.data.workspaces.length)
                var singleWorkspace : any = {}
                var workspacesData : any = []
                for (let index = 0; index < response.data.workspaces.length; index++) {
                    const element = response.data.workspaces[index];
                    // console.log('element=',element)
                    workspacesData.push({
                        label:element['name'],
                        value:element['_id']
                    })
                    if(workspaceId == element['_id']){
                        singleWorkspace['label'] = element['name']
                        singleWorkspace['value'] = element['_id']
                    }
                }
                // console.log('workspacesData=',workspacesData)
                setWorkspaces(workspacesData)
                setWorkspace(singleWorkspace)
            }
        })
        .catch((error) => {
            // setAsideComponentLoading(false)
        });

    };
    ///////////////

    const createDashboard = (formData: any) => {
        setLoading(true)
        setspinner(true)
        // console.log("createDashboard request")
        axios.post(process.env.REACT_APP_BASE_API_URL + 'external/dashboard', formData)
            .then((res) => {
                // setspinner(false)
                // console.log(res.data)

                if (res.data.status) {
                    
                    // toast.error(res.data.message, {
                    //     position: getHelpers.toastPosition(),
                    //     autoClose: getHelpers.toastAutoClose(),
                    //     theme: 'colored'
                    // });

                    createGoodDataDashboard({
                        dashboard_name: formData.dashboard_name,
                        workspaceId: res.data.workspace._id,
                        gd_workspaceId: res.data.workspace.gd_workspace_ids[0],
                        dashboardId: res.data.dashboard ? res.data.dashboard._id : '',
                        getAllSelectedEmails: formData.getAllSelectedEmails
                    })

                    standardUsersRequest()
                } else {

                    if (res.data.message) {
                        toast.error(res.data.message, {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }

                    setspinner(false)

                }

            })
            .catch(error => {
                setspinner(false)
                console.log(error)

                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.message) {
                            toast.error(error.response.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            if (error.response.data.errors && error.response.data.errors.length > 0) {
                                if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                                    toast.error(error.response.data.errors[0]['msg'], {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            }
                        }
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }

            })
    }

    const createGoodDataDashboard = (data: any) => {
        // console.log("createDashboard request")
        axios.post(process.env.REACT_APP_BASE_API_URL + 'gooddata/dashboard/save', data)
            .then((res) => {
                setspinner(false)

                toast.success(res.data.message, {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });

                setLoading(false)
                showHideModel()
                // fetchWorkspacesList()
                standardUsersRequest()
                setspinner(false)

                setTimeout(() => {
                    navigate('/dashboard/' + data.dashboardId + '/edit')
                }, getHelpers.toastAutoClose());

            })
            .catch(error => {
                setspinner(false)
                console.log(error)

                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.message) {
                            toast.error(error.response.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            if (error.response.data.errors && error.response.data.errors.length > 0) {
                                if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                                    toast.error(error.response.data.errors[0]['msg'], {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                } else {
                                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                        position: getHelpers.toastPosition(),
                                        autoClose: getHelpers.toastAutoClose(),
                                        theme: 'colored'
                                    });
                                }
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            }
                        }
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
            })
    }

    const handleSubmit = (e: any) => {

        e.preventDefault();

        const formData = {
            dashboard_name: e.target.dashboard_name.value,
            // workspaceId: workspaceId,
            // userEmails: getSelectedEmails,//[e.target.emails.value],//['david@yopmail.com']
            getAllSelectedEmails: getAllSelectedEmails,
            workspace: getWorkspace
        }

        if(getWorkspace.label == undefined){
            toast.error("Workspace is required", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
            return;
        }
        createDashboard(formData)

        // console.log(`Form submitted`, JSON.stringify(formData));

    }

    const standardUsersRequest = () => {
        
        axios.get(process.env.REACT_APP_BASE_API_URL + 'workspace/standard/users/' + workspaceId)
            .then((res) => {
                // console.log('res.data=',res.data);
                if (res.data.users && res.data.users.length > 0) {
                    res.data.users.forEach((userData: any, index: any) => {
                        if (userData['_id'] != getUserInfo._id()) {
                            shareWithData.options.push({
                                email: userData['email'],
                                label: userData['email'],
                                value: userData['_id'],
                                user_id: userData['_id'],
                                first_name: userData['first_name'] ? userData['first_name'] : 'untitled',
                                last_name: userData['last_name'] ? userData['last_name'] : 'untitled',
                            })
                        }
                        // console.log('shareWithData.options',shareWithData.options)
                        // if (!multiselectEmailsData.options.includes(res.data.users[key]['user_id']['email'])) {
                        //     multiselectEmailsData.options.push(res.data.users[key]['user_id'])
                        // }
                    });
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    // const onSelect = (selectedList: any, selectedItem: any) => {
    //   console.log('selectedList remove=', selectedList)
    //   setSelectedEmails(selectedList)
    // }

    // const onRemove = (selectedList: any, removedItem: any) => {
    //   console.log('selectedList remove=', selectedList)
    //   setSelectedEmails(selectedList)
    // }

    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        setAllSelectedEmails(newValue)
    }

    const workspaceHandleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        // console.group();
        setWorkspace(newValue)
        // console.log('handleChange getWorkspace=',getWorkspace)
        // console.groupEnd();
    }

    const changePermission = (eventValue: any, valueId: any) => {
        var selectedEmail = _.find(getAllSelectedEmails, { value: valueId });
        selectedEmail['access_permission'] = eventValue
        var newArray = []
        for (const key in getAllSelectedEmails) {
            const element = getAllSelectedEmails[key];
            if (element['value'] == valueId) {
                element['access_permission'] = eventValue
            }
            newArray.push(element)
        }
        setAllSelectedEmails(newArray)
    }

    const removeItem = (key: any) => {
        getAllSelectedEmails.splice(key, 1)

        var newArray = []
        for (const key in getAllSelectedEmails) {
            const element = getAllSelectedEmails[key];
            newArray.push(element)
        }
        // console.log('newArray=2',newArray)

        setAllSelectedEmails(newArray)
    }

    const dispatch = useDispatch();
    
    const fetchVerifyAccess = async () => {
        const API_URL = process.env.REACT_APP_API_URL
        const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
        const ApiToken = localStorage.getItem('api_token')
        const BearerToken = `Bearer ${ApiToken}`
        const config = {
        headers: { Authorization: BearerToken }
        };
        axios.get(
            GET_USER_BY_ACCESSTOKEN_URL,
            config
        )
        .then(async (res) => {
            if (res.data.status) {
                if(res.data.internalPermissions){
                    if(res.data.internalPermissions.permissions){
                    dispatch(ActionPermissions.internalUserPermissions(res.data.internalPermissions.permissions))
                    setUserInternalPermissions(res.data.internalPermissions.permissions)
                    setKey(getKey+1)
                    }
                }
                if(res.data.externalPermissions){
                    // console.log('res.data.externalPermissions=',res.data.externalPermissions)
                    if(res.data.externalPermissions.permissions){
                        // getWPermissions = res.data.externalPermissions.permissions
                        var data = getHelpers.doesHasPermissions(res.data.externalPermissions.permissions,organisationUsersId)
                        dispatch(ActionExternalPermissions.externalUserPermissions(data))
                        dispatch(ActionExternalWorkspacePermissions.externalWorkspacePermissions(res.data.externalPermissions.permissions))
                        setUserExternalPermissions(data)
                        setUserAllExternalPermissions(res.data.externalPermissions.permissions)
                        setKey(getKey+1)
                    }
                }
                if(res.data.internalRoles){
                    if(res.data.internalRoles.roles){
                        dispatch(ActionRoles.internalUserRoles(res.data.internalRoles.roles))
                        setUserInternalRoles(res.data.internalRoles.roles)
                        setKey(getKey+1)
                    }
                }
            }
        })
        .catch((error) => {});
    }

    useEffect(() => {
        fetchVerifyAccess()
        fetchOrgWorkspaces()
        // fetchWorkspacesList()
        shareWithData.options.length = 0;
        standardUsersRequest()
    }, [workspaceId])

    return (
        <>
            <ToastContainer />
            <div className={`card ${className}`}>
                {/* begin::Body */}
                <div className='card-body py-3 pt-8 pb-8'>

                    <form id='kt_modal_add_user_form' className='form' onSubmit={handleSubmit} noValidate>

                        <div className="modal-body scroll-yx pb-2">
                            <div className='row me-n7 pe-7'>

                                <div className='col-sm-12 mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Workspaces</label>
                                    <Select
                                        className='customSelectDropdown'
                                        isClearable
                                        onChange={workspaceHandleChange}
                                        // onInputChange={handleInputChange}
                                        options={getWorkspaces}
                                        name='industry_id'
                                        value={getWorkspace}
                                        theme={(theme) => ({
                                            ...theme,
                                            // borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'orangered',
                                                primary25: '#f6f6f9',
                                                primary: '#793CFB',
                                            },
                                        })}
                                    />
                                    {/* {formik.touched.industry_id && formik.errors.industry_id && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.industry_id}</span>
                                        </div>
                                    )} */}
                                </div>

                                <div className='col-sm-12 mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Dashboard Name</label>
                                    <input
                                        className=
                                        'form-control form-control-solid mb-3 mb-lg-0'
                                        // value={dashboardName}
                                        type='text'
                                        // onChange={(event)=>changeDashboardText(event)}
                                        name='dashboard_name'
                                        autoComplete='off'
                                    />
                                </div>

                                <div className='col-sm-12 mb-7'>
                                    <label className='fw-bold fs-6 mb-2'>Share with</label>

                                    {/* <Multiselect
                                    options={multiselectEmailsData.options} // Options to display in the dropdown
                                    selectedValues={''} // Preselected value to persist in dropdown
                                    onSelect={onSelect} // Function will trigger on select event
                                    onRemove={onRemove} // Function will trigger on remove event
                                    displayValue="email" // Property name to display in the dropdown options
                                    isObject={true}
                                /> */}

                                    <CreatableSelect
                                        placeholder={"Select or enter new email"}
                                        isMulti
                                        isClearable
                                        onChange={handleChange}
                                        options={shareWithData.options}
                                        name='userEmails'
                                    // onInputChange={handleInputChange}
                                    />

                                </div>

                                <div className='col-sm-12 mb-7'>
                                    <div className='table-responsivex'>
                                        <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                            <thead>
                                                <tr className='border-0'>
                                                    <th className='p-0 min-w-150px'></th>
                                                    <th className='p-0 min-w-50px'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getAllSelectedEmails.map((item: any, index: any) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='symbol symbol-45px me-2 symbol-circle'>
                                                                    {
                                                                        item.__isNew__ ?
                                                                            (
                                                                                <span className={'symbol-label text-uppercase bg-' + item.label.charAt(0)}>
                                                                                    <span className='text-white fs-3 fw-bolder'>{item.label.charAt(0)}</span>
                                                                                </span>
                                                                            ) : (
                                                                                item.first_name ?
                                                                                    (
                                                                                        <span className={'symbol-label text-uppercase bg-' + item.first_name.charAt(0)}>
                                                                                            <span className='text-white fs-3 fw-bolder'>{item.first_name.charAt(0)}</span>
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span className={'symbol-label text-uppercase bg-' + item.label.charAt(0)}>
                                                                                            <span className='text-white fs-3 fw-bolder'>{item.label.charAt(0)}</span>
                                                                                        </span>
                                                                                    )
                                                                            )
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span className='text-dark text-hover-primary fs-5 d-block'>
                                                                        {
                                                                            item.first_name && item.last_name ?
                                                                                (
                                                                                    <>
                                                                                        <div className='fw-bolder'>
                                                                                            {item.first_name + ' ' + item.last_name}
                                                                                        </div>
                                                                                        <div>
                                                                                            {item.label}
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    item.label
                                                                                )
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='text-end'>
                                                            <select className='form-select' onChange={(event) => changePermission(event.target.value, item.value)}>
                                                                <option value="can_view">Can View</option>
                                                                <option value="can_edit">Can Edit</option>
                                                            </select>
                                                        </td>
                                                        {/* <td className='text-end'>
                                                    <a
                                                        onClick={(event) => removeItem(index)}
                                                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                                    >
                                                        <KTSVG
                                                        path='/media/icons/duotune/arrows/arr064.svg'
                                                        className='svg-icon-2'
                                                        />
                                                    </a>
                                                </td> */}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                            <div className="flex-row-reverse">
                                <button type="submit" className="btn btn-primary btn-primary">
                                    {!getspinner && (
                                        <span className='indicator-label'>Save</span>
                                    )}
                                    {/* Submit */}
                                    {getspinner && (
                                        <span >
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                {/* <button type="reset" className="btn btn-white" data-bs-dismiss="modal" onClick={() => { showHideModel() }}> Cancel </button> */}
                            </div>

                        </div>

                    </form>

                </div>
                {/* begin::Body */}

            </div>
        </>
    )
}

// export { ExternalCreateDashboard }
export default connect(mapStateToProps)(ExternalCreateDashboard);