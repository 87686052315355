/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import * as getUserInfo from '../../modules/auth/GetUserInfo'
import { ComponentLoading } from '../../modules/ComponentLoading'
import * as getHelpers from '../../modules/auth/GetHelpers'
import { KTSVG, isNotEmpty, toAbsoluteUrl } from '../../../_metronic/helpers'
import WorkspaceHeaderSection from './WorkspaceHeaderSection';
import * as Yup from 'yup'
import clsx from 'clsx'
import { FastField, useFormik } from 'formik'
import { useLocation } from 'react-router'
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Redux Apply
import { connect, useDispatch } from 'react-redux';
import { ActionPermissions } from '../../../redux/actions/permissions';
import { ActionRoles } from '../../../redux/actions/roles';
import { store } from '../../../redux/store/index';
import * as _ from "lodash";

// Redux Apply
const mapStateToProps = (state: any) => {
    return {
        profile: state.user.profile,
        userData: state.userData.permissions,
        userRolesData: state.userRolesData.roles
    }
}

const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/authentication/user/verify_access`
const ApiToken = localStorage.getItem('api_token')
const BearerToken = `Bearer ${ApiToken}`
const WorkspacesEditPage: FC = () => {

    const navigate = useNavigate();
    let { organisationUsersId }: any = useParams();

    const [showModal, setShowModal] = useState(false)
    const [workspaceData, setworkspaceData]: any = useState<any[]>([]);
    const [workspaceUsersData, setworkspaceUsersData]: any = useState<any[]>([]);
    const [getDashboardsCount, setDashboardsCount]: any = useState<any>(0);
    const [getspinner, setspinner] = useState(false)
    const [roleEditProps, setroleEditProps] = useState<any[]>([]);
    const [showEditUserModal, setshowEditUserModal]: any = useState(false)
    const [showHidePasswordModal, setshowHidePasswordModal]: any = useState(false)
    const [workspaceUserId, setworkspaceUserId]: any = useState('');
    const [tabDashboar, settabDashboar] = useState(false)
    const [rowprops, setrowprops] = useState<any[]>([]);
    const [getComponentLoading, setComponentLoading] = useState(true)
    const [getAvatarComponentLoading, setAvatarComponentLoading] = useState(false)
    const location = useLocation();

    const [getOrganization, setOrganization] = useState({})
    const [getOrganizations, setOrganizations] = useState<any[]>([])
    const [disableOnOrgExist, setDisableOnOrgExist] = useState(false)
    const [image, setImage] = useState('')
    const [loading, setLoading] = useState(false)

    const showHideModel: any = () => {
        setShowModal(!setShowModal)
    }

    const showHideEditUserModal: any = () => {
        setshowEditUserModal(!setshowEditUserModal)
    }

    const showHideSetPasswordModal: any = () => {
        setshowHidePasswordModal(!setshowHidePasswordModal)
    }

    const rowPropData: any = (rowData: any) => {
        setrowprops(rowData);
    }

    const WorkspaceUsersURL = `${API_URL}/internal/workspace/` + organisationUsersId

    const [WorkspaceInitialValues, setWorkspaceInitialValues] = useState({
        name: '',
        organization: getOrganization,
        avatar: '',
        avatar_id: ''
    })

    const getOrganizationsData = () => {
        axios.get(process.env.REACT_APP_BASE_API_URL + 'internal/organizations')
            .then(async (res) => {
                setspinner(false)
                setOrganizations(res.data)
            })
            .catch(error => {
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)
            })
    }

    const fetchEditWorkspace = () => {
        const config = {
            headers: { Authorization: BearerToken }
        };
        const organizationsList = axios.get(
            WorkspaceUsersURL + '/edit',
            config
        ).then(async (res: any) => {

            const workspaceResData = res.data.workspace
            const organizationResData = res.data.organization

            if (workspaceResData.name) {
                formik.initialValues.name = workspaceResData.name
            }
            if (organizationResData) {
                var orgObj = {
                    label: organizationResData['name'],
                    value: organizationResData['_id'],
                    _id: organizationResData['_id'],
                }
                setOrganization(orgObj)
            }
            if (workspaceResData.avatar_id) {
                setImage(getUserInfo.domainUrl() + workspaceResData.avatar_id.file_path)
            }
            // setOrganizationData(workspaceResData)
            setComponentLoading(false)
            setAvatarComponentLoading(false)
            checkOrganizationExistInWorkspace(organizationResData)
            // return setOrganizationData;
        }
        ).catch((error) => {
            console.log('error=', error)
        });
        return organizationsList
    }

    const checkOrganizationExistInWorkspace = (data: any) => {
        if (!_.isEmpty(data)) {
            setDisableOnOrgExist(true)
        } else {
            setDisableOnOrgExist(false)
        }
    }

    const fetchWorkspaceUsersList: any = () => {
        const config = {
            headers: { Authorization: BearerToken }
        };
        const organizationsList = axios.get(
            WorkspaceUsersURL,
            config
        ).then(async (res: any) => {
            await setDashboardsCount(res.data.dashboardsCount);
            await setworkspaceUsersData(res.data.users);
            await setworkspaceData(res.data.workspace);

            setComponentLoading(false)
            return [setworkspaceUsersData, setworkspaceData];


        }
        ).catch(console.log);
        return organizationsList

    }

    const editWorkspaceSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .required('Workspace Name is required'),
        organization: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        })
            .required('Organization is required'),
    })

    const formik = useFormik({
        initialValues: WorkspaceInitialValues,
        validationSchema: editWorkspaceSchema,
        onSubmit: async (values) => {

            try {

                //   let formData: any = {
                //       name: values.name,
                //       organization: getOrganization,
                //   }

                let formData: any = WorkspaceInitialValues
                formData.name = values.name
                formData.organization = getOrganization

                updateWorkspaceData(formData)

            } catch (ex) {

                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setspinner(false)

            }
        },
    })

    const updateWorkspaceData = (formData: any) => {
        setspinner(true)
        axios.put(
            process.env.REACT_APP_API_URL + '/workspace/' + organisationUsersId,
            formData,
            { headers: { Authorization: BearerToken } }
        )
            .then(async (res) => {

                if (res.data.status) {
                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                    fetchEditWorkspace()
                    fetchWorkspaceUsersList()
                    setTimeout(() => {
                        navigate('/internal/workspace/' + organisationUsersId)
                    }, 1500);
                } else {

                    if (res.data.errors && res.data.errors.length > 0) {
                        if (res.data.errors[0] && res.data.errors[0]['msg']) {
                            toast.error(res.data.errors[0]['msg'], {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    } else {
                        if (res.data.message) {
                            toast.error(res.data.message, {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        } else {
                            toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                position: getHelpers.toastPosition(),
                                autoClose: getHelpers.toastAutoClose(),
                                theme: 'colored'
                            });
                        }
                    }

                }
                setspinner(false)
            })
            .catch((error) => {

                setspinner(false)

                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.errors && error.response.data.errors.length > 0) {
                            if (error.response.data.errors[0] && error.response.data.errors[0]['msg']) {
                                toast.error(error.response.data.errors[0]['msg'], {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            }
                        } else {
                            if (error.response.data.message) {
                                toast.error(error.response.data.message, {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            } else {
                                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                                    position: getHelpers.toastPosition(),
                                    autoClose: getHelpers.toastAutoClose(),
                                    theme: 'colored'
                                });
                            }
                        }
                    } else {
                        toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                            position: getHelpers.toastPosition(),
                            autoClose: getHelpers.toastAutoClose(),
                            theme: 'colored'
                        });
                    }
                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }

                setspinner(false)
                // fetchorganizationsList();
            })
    }

    const submitErrorCheck = () => {
        if (JSON.stringify(formik.errors) !== '{}') {
            toast.error(Object.values(formik.errors)[0], {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
        }
    }

    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<any>
    ) => {
        // console.group();
        // console.log('Value Changed=',JSON.stringify(newValue));
        // userForEdit.organization = newValue
        setOrganization(newValue)
        // console.log('handleChange getOrganization=',getOrganization)
        // console.groupEnd();
    };


    const onImageChange = (event: any) => {
        setAvatarComponentLoading(true)
        let fileSize = event.target.files[0]['size'];
        if (fileSize > 1060322) {
            toast.error("Max 1MB photo is allowed ", {
                position: getHelpers.toastPosition(),
                autoClose: getHelpers.toastAutoClose(),
                theme: 'colored'
            });
            setAvatarComponentLoading(false);
        } else {
            if (event.target.files && event.target.files[0]) {
                let img = event.target.files[0];
                if (img['type'] != 'image/jpeg' && img['type'] != 'image/jpg' && img['type'] != 'image/png') {
                    toast.error("Allowed file types: png, jpg, jpeg.", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                } else {
                    var formdata = new FormData();
                    formdata.append("avatar", event.target.files[0]);
                    uploadAvatar(formdata)
                }
            }
        }
    }

    const uploadAvatar = (data: any) => {
        axios.post(process.env.REACT_APP_BASE_API_URL + 'workspace/avatar', data)
            .then(async (res) => {
                setLoading(false)
                setAvatarComponentLoading(false)
                if (res.data.status) {
                    // console.log('res.data.file_path=', res.data['file_path'])
                    // console.log('res.data.upload_id=', res.data['upload']['_id'])
                    let obj = WorkspaceInitialValues;
                    // console.log('before',obj)
                    setImage(getUserInfo.domainUrl() + res.data['file_path'])
                    obj['avatar'] = getUserInfo.domainUrl() + res.data['file_path'];
                    obj['avatar_id'] = await res.data.upload._id;

                    setWorkspaceInitialValues(obj)

                    toast.success(res.data.message, {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });

                } else {
                    toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                        position: getHelpers.toastPosition(),
                        autoClose: getHelpers.toastAutoClose(),
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                setAvatarComponentLoading(false)
                toast.error("Sorry,looks like there are some errors detected!.Please try again", {
                    position: getHelpers.toastPosition(),
                    autoClose: getHelpers.toastAutoClose(),
                    theme: 'colored'
                });
                setLoading(false)
                // fetchorganizationsList();
            })
    }

    useEffect(() => {
        const config = {
            headers: { Authorization: BearerToken }
        };
        axios.get(
            GET_USER_BY_ACCESSTOKEN_URL,
            config
        )
            .then(async (res) => {
                if (res.data.status) {
                    if (res.data.userType == 'external') {
                        navigate('/error/404')
                    }
                } else if (res.data.userType == 'User type not found') {
                    if (getUserInfo.userType() == 'external') {
                        navigate('/error/404')
                    }
                }
            })
            .catch((error) => {
                console.clear()
                navigate('/error/404')
            });
        getOrganizationsData()
        fetchEditWorkspace()
        fetchWorkspaceUsersList()
    }, [])

    const data: any = workspaceUsersData;
    return (
        <>
            <ToastContainer></ToastContainer>
            <div id="kt_app_toolbar_container" className="customBreadcrumbs col-md-12 d-flex flex-stack btn-toolbar-add py-2 py-lg-3">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bolder fs-3 flex-column justify-content-center my-0">{workspaceData.name} Workspace</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <Link to={'/internal/dashboard'} className="text-muted text-hover-primary">Home</Link>
                        </li>
                        <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                        <li className="breadcrumb-item text-muted">
                            <Link to={'/internal/workspaces/list'} className="text-muted text-hover-primary">Workspaces</Link>
                        </li>
                        <li className="breadcrumb-item"> <span className="bullet bg-gray-400 w-5px h-2px"></span> </li>
                        <li className="breadcrumb-item text-muted">Users</li>
                    </ul>
                </div>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    {/* <a className="btn btn-sm fw-bolder btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Button</a> */}
                </div>
            </div>

            <WorkspaceHeaderSection organizationId={organisationUsersId} fetchWorkspaceUsersList={fetchWorkspaceUsersList} workspaceData={workspaceData} workspaceUsersData={workspaceUsersData} getDashboardsCount={getDashboardsCount} getComponentLoading={getComponentLoading} />

            <div className='card mb-5 mb-xl-10'>

                {
                    getComponentLoading ?
                        (
                            <ComponentLoading></ComponentLoading>
                        ) : ('')
                }
                {
                    _.includes(store.getState().userData.permissions, 'manage_workspaces') ?
                        (
                            <>
                                <div
                                    className='card-header cursor-pointer'
                                    role='button'
                                    data-bs-toggle='collapse'
                                    data-bs-target='#kt_account_profile_details'
                                    aria-expanded='true'
                                    aria-controls='kt_account_profile_details'
                                >
                                    <div className='card-title m-0'>
                                        <h3 className='fw-bolder m-0'>Edit</h3>
                                    </div>
                                </div>
                                <div id='kt_account_profile_details' className='collapse show'>
                                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                        <div className='card-body border-top p-9 border-0'>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Avatar</label>
                                                <div className='col-lg-8'>
                                                    <div
                                                        className='image-input image-input-outline'
                                                        data-kt-image-input='true'
                                                        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                                                    >
                                                        {
                                                            getAvatarComponentLoading ?
                                                                (
                                                                    <ComponentLoading></ComponentLoading>
                                                                ) : ('')
                                                        }
                                                        <div
                                                            className='image-input-wrapper w-125px h-125px'
                                                            style={{ backgroundImage: `url(${image})` }}
                                                        ></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>
                                                            <input
                                                                onChange={onImageChange}
                                                                type="file"
                                                                name="avatar"
                                                                accept=".png, .jpg, .jpeg"
                                                            />
                                                            <input type="hidden" name="avatar_remove" />
                                                        </label>

                                                    </div>

                                                    <div className="form-text text-gray-900">Allowed file types: png, jpg, jpeg.</div>

                                                </div>
                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Workspace Name</label>

                                                <div className='col-lg-8'>
                                                    <div className='row'>
                                                        <div className='col-lg-12 fv-row'>
                                                            <input
                                                                placeholder='Name'
                                                                {...formik.getFieldProps('name')}
                                                                type='text'
                                                                name='name'
                                                                className={clsx(
                                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                                    { 'is-invalid': formik.touched.name && formik.errors.name },
                                                                    {
                                                                        'is-valid': formik.touched.name && !formik.errors.name,
                                                                    }
                                                                )}

                                                                autoComplete='off'
                                                                disabled={formik.isSubmitting}
                                                            />
                                                            {formik.touched.name && formik.errors.name && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                        <span role='alert'>{formik.errors.name}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bolder fs-6'>Organization</label>

                                                <div className='col-lg-8'>
                                                    <div className='row'>
                                                        <div className='col-lg-12 fv-row'>
                                                            <CreatableSelect
                                                                className='customSelectDropdown'
                                                                isClearable
                                                                onChange={handleChange}
                                                                // onInputChange={handleInputChange}
                                                                isDisabled={disableOnOrgExist}
                                                                options={getOrganizations}
                                                                name='organization'
                                                                value={getOrganization}
                                                            />
                                                            {formik.touched.organization && formik.errors.organization && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                        <span role='alert'>{formik.errors.organization}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                            <button type="submit" className="btn btn-primary btn-primary" onClick={() => submitErrorCheck()}>
                                                {!getspinner && (
                                                    <span className='indicator-label'>Save</span>
                                                )}
                                                {getspinner && (
                                                    <span >
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </>
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: getHelpers.NoPermissions() }}></div>
                        )
                }
            </div>

        </>
    )
}

// export { WorkspacesEditPage }
export default connect(mapStateToProps)(WorkspacesEditPage);